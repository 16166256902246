import { Description,  Title, Negotiable, TaxInclusive, Speed, Tenor } from "../../../../../Constant";

// "id": 0,
        // "product_class_id": 0,
        // "name": "string",
        // "description": "string",
        // "invoice_type_id": 0,
        // "invoice_cycle_id": 0,
        // "credit_term_id": 0,
        // "is_negotiable": true,
        // "is_tax_inclusive": true,
        // "tenor": "string",
        // "speed": "string"

export const productType_form_fields = (LabelName, colSize) => ([
    {
      id: 'name',
      name: 'name',
      label: LabelName ? `${LabelName}` : `${Title}`,
      type: 'text',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'description',
      name: 'description',
      label: `${Description}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'is_negotiable',
      name: 'is_negotiable',
      label: `${Negotiable}`,
      type: 'radio',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
      options: [
          {'label': 'Yes', 'value': 1},
          {'label': 'No', 'value': 0},
      ],
  },
  {
    id: 'is_tax_inclusive',
    name: 'is_tax_inclusive',
    label: `${TaxInclusive}`,
    type: 'radio',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
    options: [
        {'label': 'Yes', 'value': 1},
        {'label': 'No', 'value': 0},
    ],
},
{
  id: 'speed',
  name: 'speed',
  label: `${Speed}`,
  type: 'text',
  value: '',
  required: true,
  colSize: colSize ? `${colSize}` : 12,
},
{
  id: 'tenor',
  name: 'tenor',
  label: `${Tenor}`,
  type: 'text',
  value: '',
  required: true,
  colSize: colSize ? `${colSize}` : 12,
},
  ]);