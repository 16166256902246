import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { createDropdownField } from '../../../../../Data/FormField/PublicData';
import { Country, Lga, RegionalContinent, State, Ward, Zone } from '../../../../../Constant';
import { ContactMgmtApi, ContactNationalityInfoUri, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, WardApi, ZoneApi } from '../../../../../api';
import { nationality_address_form_fields } from '../../../../../Data/FormField/CrmData';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { toast } from 'react-toastify';

const NationalityInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields, setFormFields] = useState(nationality_address_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  //============= Nationality =================//
  const wardDropdownField = createDropdownField(axiosPrivate,
    'nationality_ward_id',
    'nationality_ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [...formFields, continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField ];

          rowData.regional_continent_id = rowData.ward_nationality.lga.state.zone.country.regional_continent_id;
          rowData.regional_country_id = rowData.ward_nationality.lga.state.zone.regional_country_id;
          rowData.regional_zone_id = rowData.ward_nationality.lga.state.regional_zone_id;
          rowData.regional_state_id = rowData.ward_nationality.lga.regional_state_id;
          rowData.regional_lga_id = rowData.ward_nationality.regional_lga_id;
          //rowData.nationality_ward_id = rowData.nationality_ward_id;
          
          await continentDropdownField.fetchOptions();
          continentDropdownField.onChange(rowData.regional_continent_id, updatedFields);
          countryDropdownField.onChange(rowData.regional_country_id, updatedFields);
          zoneDropdownField.onChange(rowData.regional_zone_id, updatedFields);
          stateDropdownField.onChange(rowData.regional_state_id, updatedFields);
          lgaDropdownField.onChange(rowData.regional_lga_id, updatedFields);
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, continentDropdownField]);

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]); 
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    //alert(selectedZoneId)
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'nationality_ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      email: editRecord.email,
      is_active: editRecord.is_active,
      contact: {
          nationality_ward_id: editRecord.nationality_ward_id,
      }
    };
    
    try {
        await axiosPrivate.put(`${ContactMgmtApi}/${id}/${ContactNationalityInfoUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.last_name} ${resp.data.first_name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='nationality-info'>
        <Row>
          <Col sm='12'>
          <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default NationalityInformation;