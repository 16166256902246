/* import ErrorPage1 from '../Components/Pages/ErrorPages/ErrorPage400';
import ErrorPage2 from '../Components/Pages/ErrorPages/ErrorPage401';
import ErrorPage3 from '../Components/Pages/ErrorPages/ErrorPage403';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
 */
//import Logins from '../Auth/Signin'; 
import AccountVerification from '../Components/Pages/Auth/AccountVerification';
import Activated from '../Components/Pages/Auth/Activated';
import CreatePwd from '../Components/Pages/Auth/CreatePwd';
import LoginForm from '../Components/Pages/Auth/LoginForm';
import ForgetPwd from '../Auth/ForgetPwd';
import ResetPwd from '../Components/Pages/Auth/ResetPwd';
import Maintenance from '../Components/Pages/Auth/Maintenance';
import NotActivated from '../Components/Pages/Auth/NotActivated';

export const authRoutes = [
  // { path: `${process.env.PUBLIC_URL}/login`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/authentication/forget-password/:layout`, Component: <ForgetPwd /> },
  { path: `${process.env.PUBLIC_URL}/authentication/reset-password/:layout`, Component: <ResetPwd /> },
  { path: `${process.env.PUBLIC_URL}/authentication/account-verification/:layout`, Component: <AccountVerification /> },
  { path: `${process.env.PUBLIC_URL}/authentication/create-pwd/:layout`, Component: <CreatePwd /> },
  { path: `${process.env.PUBLIC_URL}/authentication/account-activated/:layout`, Component: <Activated /> },
  { path: `${process.env.PUBLIC_URL}/authentication/account-not-activated/:layout`, Component: <NotActivated /> },
  { path: `${process.env.PUBLIC_URL}/authentication/maintenance/:layout`, Component: <Maintenance /> },
  { path: `${process.env.PUBLIC_URL}/authentication/login-simple/:layout`, Component: <LoginForm /> },

  //Error
 /*  { path: `${process.env.PUBLIC_URL}/pages/errors/error400/:layout`, Component: <ErrorPage1 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error401/:layout`, Component: <ErrorPage2 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error403/:layout`, Component: <ErrorPage3 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error404/:layout`, Component: <ErrorPage4 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error500/:layout`, Component: <Error500 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error503/:layout`, Component: <Error503 /> },
 */];
