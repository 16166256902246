import React, { useContext, useEffect, useState } from 'react';
import Context from './index';
import { CompanyApi } from '../../../api';
//import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../Hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import CustomizerContext from '../../Customizer';

const OrganisationProvider = (props) => {
    const { layoutURL } = useContext(CustomizerContext);
    const [allData, setAllData] = useState([]);
    const [profile, setProfile] = useState([]);
    //const [application, setApplication] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    let {id} = useParams();

    const getAllCompaniesData = async () => {
        try {
            await axiosPrivate.get(`${CompanyApi}/${id}`).then((resp) => {
                setAllData(resp.data);
                setProfile({
                    name: resp.data.full_name,
                    shortname: resp.data.shortname,
                    email: resp.data.email_address,
                    imgSrc: `${require('../../../assets/images/user/user.png')}`,
                    link: `${process.env.PUBLIC_URL}/app/users/userprofile/${layoutURL}`,
                })
            });
        } catch (error) {
            //console.log('cancelled', error);
        } finally {
          setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCompaniesData();
    }, [setAllData]);

    /* const addNewApplication =  async (applicationData) => {
        const applicationTemp = {
            //id: allData.length + 1,
            title: applicationData.title,
            //icon: applicationData.icon,
            body: applicationData.description
        };
        setIsLoading(true);
        //setAllData([...allData, applicationTemp]);
        try {
             await axiosPrivate.post(ApplicationPostApi, applicationTemp).then((resp) => {
                setAllData([...allData, resp.data]);
                setIsLoading(false);
                toast.success(`${resp.data.title} is successfully added.`)
            });
        } catch (error) {
            console.log('Error Posting', error);
        }  
    }; */

    return (
        <Context.Provider
            value={{
                ...props,
                //addNewApplication: addNewApplication,
                organisation_id: id,
                profile,
                allData,
                isLoading,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default OrganisationProvider;