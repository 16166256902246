import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BackToList } from '../../../../../Constant';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { base_form_fields, createDropdownField } from '../../../../../Data/FormField/PublicData';
import CustomizerContext from '../../../../../_helper/Customizer';
import { Capacity, CapacityType } from '../../../../../Constant';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs';
import { useForm } from 'react-hook-form';
import { CapacityApi, CapacityTypeApi } from '../../../../../api'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../../Hooks/useAuth';


function CapacityForm() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${Capacity}`));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/capacities/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    
    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            capacity_type_id: newRecord.capacity_type_id,
            capacity: newRecord.name,
            description: newRecord.description,
            is_active: newRecord.is_active,
            //file: newRecord.file[0]
        };


        try {
            await axiosPrivate.post(CapacityApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(lists);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };

    const capacityTypeDropdownField = createDropdownField(axiosPrivate,
        'capacity_type_id',
        'capacity_type_id',
        `${CapacityType}`,
        `${CapacityTypeApi}?organisation_id=${userOrganisationId}`,
        '',12
      );
    
    
      useEffect(() => {
    
        const fetchData = async () => {
          try {
            if (!isNewFieldAdded) {
              const updatedFields = [capacityTypeDropdownField, ...formFields];
              const indexOfName = updatedFields.findIndex((formField) => formField.id === 'capacity_type_id');
    
              await updatedFields[0].fetchOptions();
              //await updatedFields[1].fetchOptions();
              //await updatedFields[2].fetchOptions();
              await updatedFields[indexOfName].fetchOptions();
              
              setFormFields(updatedFields);
              setIsNewFieldAdded(true);
            }
          } catch (error) {
            //console.error('Error fetching options:', error);
          }
        };
    
        fetchData();
    
      }, [formFields, isNewFieldAdded, capacityTypeDropdownField]);

    return (
        <>
            <Fragment>
                <Breadcrumbs parent="Sales" title="Capacity" mainTitle="Capacity" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Capacity"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>

        </>
    )
}

export default CapacityForm