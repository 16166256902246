import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BankApi, PfaApi, PfaBankAccountApi, PfcApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import { createDropdownField, pfa_bank_acc_fields } from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Bank, Pfa, Pfc } from '../../../../../Constant';

const PfaBankAccountNew= () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/pfa-bank-accounts/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(pfa_bank_acc_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const pfcDropdownField = createDropdownField(axiosPrivate,
    'pfc_id',
    'pfc_id',
    `${Pfc}`,
    `${PfcApi}`, '', 12
  );

  const pfaDropdownField = createDropdownField(axiosPrivate,
    'pfa_id',
    'pfa_id',
    `${Pfa}`,
    `${PfaApi}`, '', 12
  );

  const bankDropdownField = createDropdownField(axiosPrivate,
    'bank_id',
    'bank_id',
    `${Bank}`,
    `${BankApi}`, '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          // Find the index of the 'name' object
          const updatedFields = [pfcDropdownField, pfaDropdownField, bankDropdownField, ...formFields];
          
          await pfcDropdownField.fetchOptions();
          await bankDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, pfaDropdownField, pfcDropdownField, bankDropdownField]);

  pfcDropdownField.onChange = async (selectedpfcId, updatedFields) => { 
    const pfaField = updatedFields.find((field) => field.id === 'pfa_id');
    
    if (pfaField) {
      await pfaField.fetchOptions(selectedpfcId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          bank_id: newRecord.bank_id,
          pfc_id: newRecord.pfc_id,
          pfa_id: newRecord.pfa_id,
          fund_code: newRecord.fund_code,
          fund_name: newRecord.fund_name,
          bank_account: newRecord.bank_account,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(PfaBankAccountApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Pfa Bank Account" mainTitle="New Pfa Bank Account" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PfaBankAccountNew;