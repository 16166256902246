import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import useGeneralLedgerAccountController from "./GroupGeneralLedgerType.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { BackToList } from "../../../../../Constant";

const GroupGeneralLedgerType = () => {
  const { formTitle, formFields, backBtn, onSubmit } =
    useGeneralLedgerAccountController();

  return (
    <div className="mt-5">
      <Breadcrumbs
        parent="Settings"
        title="General Ledger Account"
        mainTitle="General Ledger Account"
      />
      <Fragment>
        <Card className="ribbon-wrapper-right b-l-secondary border-2 mt-5">
          <CardBody>
            <Link to={backBtn}>
              <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                {BackToList}
              </div>
            </Link>
            <GenerateForm
              onSubmit={onSubmit}
              formFields={formFields}
              formTitle={formTitle}
              listUrl={backBtn}
              onEdit={undefined}
              editRecord={undefined}
            />
          </CardBody>
        </Card>
      </Fragment>
    </div>
  );
};

export default GroupGeneralLedgerType;
