import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import GenerateForm from './GenerateForm';
import {ViewDetails, UpdateRecord } from '../../../Constant';

const ViewModal = ({ isOpen, toggle, rowData, children, modalTitle, editMode, onEdit, formFields, onSubmit }) => {
    //const [editFormValues, setEditFormValues] = useState({});
    
    /* const handleEdit = () => {
        // Invoke the onEdit callback when the "Edit" button is clicked
        if (editMode) {
            onEdit();
        }
    }; */

    /* const handleChange = (e) => {
        // Update the form values when input fields change
        setEditFormValues((prevValues) => ({
          ...prevValues,
          [e.target.name]: e.target.value,
        }));
    }; */

    return (
        <Modal isOpen={isOpen} toggle={toggle}  centered backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
            {/* {editMode ?  `${UpdateRecord}` : `${ViewDetails}`} */}
            {editMode ? (modalTitle ? `${modalTitle}` : `${UpdateRecord}`) : `${ViewDetails}`}
        </ModalHeader>
        <ModalBody>
            {editMode ? ( 
                <GenerateForm formFields={formFields} onEdit={onEdit} editRecord={rowData} />
            ) : (
            // Conditionally render either the rowData or the passed children
            rowData && !children ? (
                Object.entries(rowData).map(([key, value]) => (
                <p key={key}>
                    <strong>{key}:</strong> {value}
                </p>
                ))
                ) : (
                    children
                )
            )}
        </ModalBody>
        {/* {!editMode && (
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        )} */}
        </Modal>
    );
};

export default ViewModal;
