import { useLayoutEffect, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    CaseGroupSLAReportApi,
    CaseGroupApi,
    CaseTypeApi,
    PriorityApi,
    ResolutionStatusApi,
} from "../../../../api";
import useAuth from "../../../../Hooks/useAuth";
import { IResolutionStatusRes, IPriorityRes, ICaseRes, ICaseGroupRes, ICaseTypeRes, IEmployeeBasicRes, IContactBasicRes, IReport, IServiceTeamReport, ICaseDetail } from "./types";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper";
import { ContactTypeVal } from "../../../../Data/FormField/PublicData";
//import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export default function useCaseGroupSLAReport() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();

    const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);
    const [startDate, endDate] = dateRange;

    const [loading, setLoading] = useState(false);

    const [PriorityDropDown, setPriorityDropDown] = useState<IPriorityRes[]>([]);
    const [PriorityDropDownValue, setPriorityDropDownValue] = useState("0");

    const [ResolutionStatusDropDown, setResolutionStatusDropDown] = useState<IResolutionStatusRes[]>([]);
    const [ResolutionStatusDropDownValue, setResolutionStatusDropDownValue] = useState("0");

    const [CaseGroupDropDown, setCaseGroupDropDown] = useState<ICaseGroupRes[]>([]);
    const [CaseGroupDropDownValue, setCaseGroupDropDownValue] = useState("0");

    const [CaseTypeDropDown, setCaseTypeDropDown] = useState<ICaseTypeRes[]>([]);
    const [CaseTypeDropDownValue, setCaseTypeDropDownValue] = useState("0");
    
    const [TrackingNumberValue, setTrackingNumberValue] = useState("");

    const [printedData, setPrintedData] = useState<IReport[]>([]);

    const [caseSLADetails, setCaseSLADetails] = useState<ICaseDetail[]>([]);
    const [showPopup, setShowPopup] = useState(false);

    async function getPriority() {
        try {
            axiosPrivate
                .get(
                    `${PriorityApi}?&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setPriorityDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getResolutionStatus() {
        try {
            axiosPrivate
                .get(
                    `${ResolutionStatusApi}?&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setResolutionStatusDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getCaseGroup() {
        try {
            axiosPrivate
                .get(
                    `${CaseGroupApi}?organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setCaseGroupDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getCaseType() {
        try {
            axiosPrivate
                .get(
                    `${CaseTypeApi}?organisation_id=${userOrganisationId}&case_group_id=${CaseGroupDropDownValue}`
                )
                .then((res) => {
                    setCaseTypeDropDown(res.data.items);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    useEffect(() => {
        if (CaseGroupDropDownValue !== "0") getCaseType();
    }, [CaseGroupDropDownValue]);

    useLayoutEffect(() => {
        getCaseGroup();
        getPriority();
        getResolutionStatus();
    }, []);

    const getCaseURL = (data = {}) => {
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined && value !== 0)
        );
    
        const params = new URLSearchParams({
            organisation_id: userOrganisationId,
            ...filteredData
        }).toString();
    
        return `${CaseGroupSLAReportApi}?${params}`;
    }

    const getEmployeeContactDetails = (contactDetails: IEmployeeBasicRes) => {
        if (!contactDetails) {
            return "";
        }

        const contactType = contactDetails?.contact?.contact_type;
        const contactName =
          contactType === ContactTypeVal.Individual
            ? `${contactDetails?.contact?.last_name} ${contactDetails?.contact?.first_name} ${contactDetails?.contact?.middle_name}`
            : `${contactDetails?.contact?.company_name}`;
      
        return `${contactName} (${contactDetails?.employee_number})`;
    };

    const getContactDetails = (contactDetails: IContactBasicRes) => {
        if (!contactDetails) {
            return "";
        }

        const contactType = contactDetails?.contact_type;
        const contactName =
          contactType === ContactTypeVal.Individual
            ? `${contactDetails?.last_name} ${contactDetails?.first_name} ${contactDetails?.middle_name}`
            : `${contactDetails?.company_name}`;
      
        return `${contactName} (${contactDetails?.contact_number})`;
    };
      

    async function getTableFunction() {
        if(!startDate){
            toast.error('Select a date to generate report');
            return false;
        }

        const data = {organisation_id: userOrganisationId,
            case_group_id: CaseGroupDropDownValue,
            case_type_id: CaseTypeDropDownValue,
            priority_id: PriorityDropDownValue,
            resolution_status_id: ResolutionStatusDropDownValue,
            track_number:TrackingNumberValue,
            report:true,
            start_date: startDate ? formatDateWithTimeZone(startDate, "", "YYYY-MM-DD") : '', 
            end_date: endDate? formatDateWithTimeZone(endDate, "", "YYYY-MM-DD") : ''
        }
        setLoading(true); 
        setPrintedData([]);   
        await axiosPrivate
            .get(
                getCaseURL(data)
            )
            .then((res) => {
                const result: IReport[] = res.data.reports;
                setPrintedData(result);
                setLoading(false);
            })
            .catch((err) => {
                setPrintedData([]);
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });;
    }
    

    

    function generateSLADetailsPrint(res: ICaseDetail[]) {
        const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th className="text-center">S/N</th>
              <th>Track Number</th>
              <th>Contact</th>
              <th>Contact Type</th>
              <th>Link</th>
              <th>Case</th>
              <th>Case Group</th>
              <th>Case Type</th>
              <th>Impact</th>
              <th>Urgency</th>
              <th>Priority</th>
              <th>Resolution Status</th>
              <th>Opened By</th>
              <th>Date Created</th>
              <th>Due Date</th>
              <th>Service Team</th>
              <th>Resolved By</th>
              <th>Resolved Date</th>
            </tr>
          </thead>
          <tbody>
            ${res
                .map(
                    (item, index) =>
                        `<tr>
                        <td className="text-center">${index + 1}</td>
                        <td>${item.track_number}</td>
                        <td>${item.contact_name}</td>
                        <td>${item.contact_type}</td>
                        <td>${item.link}</td>
                        <td>${item.subject}</td>
                        <td>${item.case_group}</td>
                        <td>${item.case_type}</td>
                        <td>${item.impact}</td>
                        <td>${item.urgency}</td>
                        <td>${item.priority}</td>
                        <td>${item.resolution_status}</td>
                        <td>(${item.opened_by.contact_number}) ${item.opened_by.last_name} ${item.opened_by.first_name} ${item.opened_by.middle_name}</td>
                        <td>${formatDateWithTimeZone(item.created_at)}</td>
                        <td>${formatDateWithTimeZone(item.due_date)}</td>
                        <td>${item.service_team}</td>
                        <td>
                            ${item.resolved_by?.contact_number}) ${item.resolved_by?.last_name} ${item.resolved_by?.first_name} ${item.resolved_by?.middle_name}
                        </td>
                        <td>${item.resolved_at ? formatDateWithTimeZone(item.resolved_at) : ""}</td>
                        
                    </tr>`
                )
                .join("")}
            </tbody>
          <tfoot>
            <tr>
                <th className="text-center">S/N</th>
                <th>Track Number</th>
                <th>Contact</th>
                <th>Contact Type</th>
                <th>Link</th>
                <th>Case</th>
                <th>Case Group</th>
                <th>Case Type</th>
                <th>Impact</th>
                <th>Urgency</th>
                <th>Priority</th>
                <th>Resolution Status</th>
                <th>Opened By</th>
                <th>Date Created</th>
                <th>Due Date</th>
                <th>Service Team</th>
                <th>Resolved By</th>
                <th>Resolved Date</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
        URL.revokeObjectURL(blobUrl);
    }

    function exportSLADetailsToCSV(res: any[]) {
        const csvHeaders = [
            "S/N",
            "Track Number",
            "Contact",
            "Contact Type",
            "Link",
            "Case",
            "Case Group",
            "Case Type",
            "Impact",
            "Urgency",
            "Priority",
            "Resolution Status",
            "Opened By",
            "Date Created",
            "Due Date",
            "Service Team",
            "Resolved By",
            "Resolved Date",
        ];
        
        const data = res.map((item, index) => ({
            sn: index + 1,
            track_number: item.track_number,
            contact: `"${item.contact_name}"`,
            contact_type: item.contact_type,
            link: `"${item.link}"`,
            case: `"${item.subject}"`,
            case_group: item.case_group,
            case_type: item.case_type,
            impact: item.impact,
            urgency: item.urgency,
            priority: item.priority,
            resolution_status: item.resolution_status,
            opened_by: `"(${item.opened_by.contact_number}) ${item.opened_by.last_name} ${item.opened_by.first_name} ${item.opened_by.middle_name}"`,
            date_created: `"${formatDateWithTimeZone(item.created_at)}"`,
            due_date: `"${formatDateWithTimeZone(item.due_date)}"`,
            service_team: item.service_team,
            resolved_by: `"(${item.resolved_by?.contact_number}) ${item.resolved_by?.last_name} ${item.resolved_by?.first_name} ${item.resolved_by?.middle_name}"`,
            resolved_at: `"${item.resolved_at ? formatDateWithTimeZone(item.resolved_at) : ""}"`
        }));

        const dataKeys: (keyof (typeof data)[0])[] = [
            "sn",
            "track_number",
            "contact",
            "contact_type",
            "link",
            "case",
            "case_group",
            "case_type",
            "impact",
            "urgency",
            "priority",
            "resolution_status",
            "opened_by",
            "date_created",
            "service_team",
            "due_date",
            "resolved_by",
            "resolved_at",
        ];

        const csvContent =
            csvHeaders.join(",") +
            "\n" +
            data
                .map((row) => `${dataKeys.map((key) => row[key]) + ","}`)
                .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `case_report_${formatDateWithTimeZone(new Date(), "", "YYYY_MM_DD_HH_MM_SS")}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const exportToExcel = (printedData: IReport[]) => {
        const workbook = XLSX.utils.book_new();
    
        printedData.forEach((reportGroup) => {
            const sheetData: (string | number)[][] = [];
            const allMonths = [...new Set(reportGroup.service_team_reports.flatMap(teamReport =>
                teamReport.sla_stats.map(stat => stat.month)
            ))];
    
            // Add table headers
            const tableHeaders = ['PARAMETERS', 'TOTAL', ...reportGroup.service_team_reports.map(team => team.team_name)];
            const monthHeaders = ['Month', ...allMonths.flatMap(month => [month, ...Array(reportGroup.service_team_reports.length - 1).fill('')])];
            sheetData.push(tableHeaders);
            sheetData.push(monthHeaders);
    
            // Add table rows dynamically based on parameters
            const parameters = ['Met SLA', 'Unmet SLA', 'TOTAL', '%MET', '%NOT MET'];
            parameters.forEach(param => {
                const totalRow = allMonths.map(month => {
                    const totals = reportGroup.service_team_reports.map(teamReport => {
                        const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                        if (param === 'Met SLA') return slaStat ? slaStat.met_sla : 0;
                        if (param === 'Unmet SLA') return slaStat ? slaStat.unmet_sla : 0;
                        if (param === 'TOTAL') return slaStat ? slaStat.total : 0;
                        if (param === '%MET') return slaStat ? parseFloat(slaStat.percent_met) : 0;
                        if (param === '%NOT MET') return slaStat ? parseFloat(slaStat.percent_not_met) : 0;
                        return 0;
                    });
    
                    if (param === '%MET' || param === '%NOT MET') {
                        const total = totals.reduce((acc, val) => acc + val, 0);
                        return `${(total / totals.length).toFixed(2)}%`;
                    } else {
                        return totals.reduce((acc, val) => acc + val, 0);
                    }
                });
    
                const row = [param, ...totalRow, ...reportGroup.service_team_reports.flatMap((teamReport) => {
                    return allMonths.map(month => {
                        const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                        let value: string | number = '';
                        if (slaStat) {
                            if (param === 'Met SLA') value = slaStat.met_sla;
                            if (param === 'Unmet SLA') value = slaStat.unmet_sla;
                            if (param === 'TOTAL') value = slaStat.total;
                            if (param === '%MET') value = slaStat.percent_met;
                            if (param === '%NOT MET') value = slaStat.percent_not_met;
                        }
                        return value;
                    });
                })];
                sheetData.push(row);
            });
    
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, reportGroup.case_group_name);
        });
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        //saveAs(blob, 'SLA_Report.xlsx');

        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `CASE_SLA_Report_${formatDateWithTimeZone(new Date(), "", "YYYY_MM_DD_HH_MM_SS")}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const createExcelData = (serviceTeamReports: IServiceTeamReport[]) => {
        // Get unique months dynamically
        const allMonths = [...new Set(serviceTeamReports.flatMap(teamReport =>
            teamReport.sla_stats.map(stat => stat.month)
        ))];
    
        // Generate table headers
        const tableHeaders = ['PARAMETERS', 'TOTAL', ...serviceTeamReports.map(team => team.team_name)];
        const monthHeaders = ['Month', ...allMonths.flatMap(month => [month, ...Array(serviceTeamReports.length - 1).fill('')])];
    
        // Generate table rows dynamically based on parameters
        const parameters = ['Met SLA', 'Unmet SLA', 'TOTAL', '%MET', '%NOT MET'];
    
        const rows = parameters.map(param => {
            const totalRow = allMonths.map(month => {
                const totals = serviceTeamReports.map(teamReport => {
                    const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                    if (param === 'Met SLA') return slaStat ? slaStat.met_sla : 0;
                    if (param === 'Unmet SLA') return slaStat ? slaStat.unmet_sla : 0;
                    if (param === 'TOTAL') return slaStat ? slaStat.total : 0;
                    if (param === '%MET') return slaStat ? parseFloat(slaStat.percent_met) : 0;
                    if (param === '%NOT MET') return slaStat ? parseFloat(slaStat.percent_not_met) : 0;
                    return 0;
                });
    
                if (param === '%MET' || param === '%NOT MET') {
                    const total = totals.reduce((acc, val) => acc + val, 0);
                    return `${(total / totals.length).toFixed(2)}%`;
                } else {
                    return totals.reduce((acc, val) => acc + val, 0);
                }
            });
    
            const row = [
                param,
                ...totalRow,
                ...serviceTeamReports.flatMap((teamReport) => {
                    return allMonths.map(month => {
                        const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                        let value: string | number = '';
                        if (slaStat) {
                            if (param === 'Met SLA') value = slaStat.met_sla;
                            if (param === 'Unmet SLA') value = slaStat.unmet_sla;
                            if (param === 'TOTAL') value = slaStat.total;
                            if (param === '%MET') value = slaStat.percent_met;
                            if (param === '%NOT MET') value = slaStat.percent_not_met;
                        }
                        return value;
                    });
                })
            ];
    
            return row;
        });
    
        return [tableHeaders, monthHeaders, ...rows];
    };
    
    const exportToExcel2 = (printedData: IReport[]) => {
        const workbook = XLSX.utils.book_new();
    
        printedData.forEach((reportGroup) => {
            const sheetData = createExcelData(reportGroup.service_team_reports);
            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    
            // Merge cells for table headers
            const tableHeaders = sheetData[0];
            const allMonths = sheetData[1].filter((cell, index) => index > 0);
    
            tableHeaders.forEach((header, index) => {
                if (index > 1) {
                    worksheet[`!merges`] = worksheet[`!merges`] || [];
                    worksheet[`!merges`].push({
                        s: { r: 0, c: index },
                        e: { r: 0, c: index + allMonths.length - 1 },
                    });
                }
            });
    
            // Add border styling if worksheet['!ref'] is defined
            if (worksheet['!ref']) {
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
                        if (cell) {
                            cell.s = {
                                border: {
                                    top: { style: 'thin', color: { rgb: '000000' } },
                                    bottom: { style: 'thin', color: { rgb: '000000' } },
                                    left: { style: 'thin', color: { rgb: '000000' } },
                                    right: { style: 'thin', color: { rgb: '000000' } },
                                },
                            };
                        }
                    }
                }
            }
    
            XLSX.utils.book_append_sheet(workbook, worksheet, reportGroup.case_group_name);
        });
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `CASE_SLA_Report_${formatDateWithTimeZone(new Date(), "", "YYYY_MM_DD_HH_MM_SS")}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const fetchCaseDetails = async (case_group_id: number, service_team_id: number, sla_month: string, sla_status: string) => {
        try {
          const response = await axiosPrivate.get<ICaseDetail[]>(`${CaseGroupSLAReportApi}/case-details`, {
            params: { organisation_id:userOrganisationId, case_group_id, service_team_id, sla_month, sla_status }
          });
          setCaseSLADetails(response.data);
          setShowPopup(true);
        } catch (error) {
          console.error('Error fetching case details:', error);
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
      };

    return {
        printedData,
        CaseGroupDropDown,
        CaseTypeDropDown,
        PriorityDropDown,
        ResolutionStatusDropDown,
        dateRange,
        startDate,
        endDate,
        loading,
        showPopup,
        caseSLADetails,
        setDateRange,
        exportToExcel,
        exportSLADetailsToCSV,
        generateSLADetailsPrint,
        getTableFunction,
        getContactDetails,
        getEmployeeContactDetails,
        fetchCaseDetails,
        handlePopupClose,
        setCaseGroupDropDownValue,
        setCaseTypeDropDownValue,
        setPriorityDropDownValue,
        setResolutionStatusDropDownValue,
        setTrackingNumberValue,
    };
}


