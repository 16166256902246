import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useExpenseClassListController from "./ExpenseClassList.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import {
    DescriptionName,
    Description,
    Settings,
    ExpenseGroup,
    ExpenseCategory,
    Name,
    Status,
    DateCreated,
    DateModified,
    vatApply,
    whtApply,
} from "../../../../../Constant";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

const ExpenseClassList = () => {
    const {
        data,
        tableColumns,
        loading,
        newBtn,
        editMode,
        selectedRowData,
        viewModalOpen,
        title,
        formFields,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    } = useExpenseClassListController();

    return (
        <Fragment>
            <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title={title}
                                btnUrl={newBtn}
                                span1={undefined}
                                span2={undefined}
                                mainClasses={undefined}
                            />
                            <CardBody>
                                <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={loading}
                                    onView={handleView}
                                    // onDelete={handleDelete}
                                    onEdit={handleEdit}
                                    useTableAction={undefined}
                                    addToDefaultAction={undefined}
                                    onDelete={undefined}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={formFields}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                        <>
                            <Table>
                                <tbody>
                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Name}</th>
                                        <td>{selectedRowData.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Description}</th>
                                        <td>{selectedRowData.description}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{ExpenseCategory}</th>
                                        <td>
                                            {
                                                selectedRowData.expense_group
                                                    .expense_category.name
                                            }
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{ExpenseGroup}</th>
                                        <td>
                                            {selectedRowData.expense_group.name}
                                        </td>
                                    </tr>

                                    <tr className='border-bottom-primary'>
                                    <th scope='row'>{vatApply}</th>
                                    <td>{StatusYesNo(selectedRowData.is_vat_apply)}</td>
                                    </tr>

                                    <tr className='border-bottom-primary'>
                                    <th scope='row'>{whtApply}</th>
                                    <td>{StatusYesNo(selectedRowData.is_wht_apply)}</td>
                                    </tr>

                                    <tr className='border-bottom-primary'>
                                    <th scope='row'>{Status}</th>
                                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                                    </tr>

                                    <tr className='border-bottom-primary'>
                                    <th scope='row'>{DateCreated}</th>
                                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                                    </tr>

                                    <tr className='border-bottom-primary'>
                                    <th scope='row'>{DateModified}</th>
                                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                </ViewModal>
            </Container>
        </Fragment>
    );
};

export default ExpenseClassList;

export const StatusYesNo = (row: any) => {
    return row? (
        <span className="badge badge-light-success">Yes</span>
    ) : (
        <span className="badge badge-light-danger">No</span>
    );
};
