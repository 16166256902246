import { Input, Button, Form, FormGroup, Label } from "reactstrap";
import useCaseReport from "./controller";
import { Breadcrumbs } from "../../../../AbstractElements";
import { CaseGroup, CaseTypeName, DateRange, DownloadCSV, GenerateCSV, GenerateReport, PrintReport, Priority, Report, ResolutionStatus, SelectOption, TrackingNumber } from "../../../../Constant";
import DatePicker from "react-datepicker";
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper";
import { ContactTypeVal } from "../../../../Data/FormField/PublicData";
import Spinner from "../../../Common/Component/Spinner";

export default function CaseReport() {
    const {
        printedData,
        CaseGroupDropDown,
        CaseTypeDropDown,
        PriorityDropDown,
        ResolutionStatusDropDown,
        startDate,
        endDate,
        loading,
        setDateRange,
        exportToCSV,
        generatePrint,
        getTableFunction,
        getContactDetails,
        getEmployeeContactDetails,
        setCaseGroupDropDownValue,
        setCaseTypeDropDownValue,
        setPriorityDropDownValue,
        setResolutionStatusDropDownValue,
        setTrackingNumberValue,
    } = useCaseReport();

    return (
        <div>
            <Breadcrumbs
                parent={Report}
                title={"Case Report"}
                mainTitle={"Case Report"}
            />

            <br />

            <div className="row gap-y-3">
            <div className="col-md-4 mb-3">
                    <p className="mb-0">{CaseGroup}</p>
                    <Input
                        onChange={(e) =>
                            setCaseGroupDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {CaseGroupDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4  mb-3">
                    <p className="mb-0">{CaseTypeName}</p>
                    <Input
                        onChange={(e) =>
                            setCaseTypeDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {CaseTypeDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{Priority}</p>
                    <Input
                        onChange={(e) =>
                            setPriorityDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {PriorityDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{ResolutionStatus}</p>
                    <Input
                        onChange={(e) =>
                            setResolutionStatusDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {ResolutionStatusDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{TrackingNumber}</p>
                    <Input
                        onChange={(e) =>
                            setTrackingNumberValue(e.target.value)
                        }
                        type="text"
                    />
                </div>

                <div className="col-md-4  mb-3">
                    <p className="mb-0">{DateRange}</p>
                    <DatePicker
                        className="form-control w-100"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update: Date[] | any) => {
                            setDateRange(update);
                        }}
                        withPortal
                    />
                </div>

                <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
                    <Button
                        color="primary"
                        onClick={getTableFunction}
                        className="mt-4"
                    >
                        <i className="fa fa-book"></i>&nbsp;
                        {GenerateReport}
                    </Button>
                </div>
            </div>

            <br />
            <br />

            {loading ? <Spinner/> : ""}            
            {printedData.length !== 0 && (
                <>
                    <div className="row">
                        <Form className="col">
                            <FormGroup>
                                {/* <Label for="search">Search</Label>
                                <Input id="search" /> */}
                            </FormGroup>
                        </Form><br/><br />
                        <div className="col d-flex gap-2 justify-content-end align-items-center">
                            <Button
                                color="primary"
                                className="btn-xs"
                                onClick={() => generatePrint(printedData)}
                            >
                                <i className="fa fa-print"></i>&nbsp;
                                {PrintReport}
                            </Button>

                            <Button
                                color="warning"
                                className="btn-xs"
                                onClick={() => exportToCSV(printedData)}
                            >
                                <i className="fa fa-cloud-download"></i>&nbsp;
                                {DownloadCSV}
                            </Button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th className="text-center">S/N</th>
                                <th>Track Number</th>
                                <th>Contact</th>
                                <th>Contact Type</th>
                                <th>Link</th>
                                <th>Case</th>
                                <th>Case Group</th>
                                <th>Case Type</th>
                                <th>Impact</th>
                                <th>Urgency</th>
                                <th>Priority</th>
                                <th>Resolution Status</th>
                                <th>Opened By</th>
                                <th>Date Created</th>
                                <th>Due Date</th>
                                <th>Resolved By</th>
                                <th>Resolved Date</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {/* contact_branch.contact_number, contact_branch.name | contact.contact_number, contact.company_name | contact.contact_number, contact.last_name, contact.first_name | [product.name] */}
                                {printedData.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{item.track_number}</td>
                                        <td>{getContactDetails(item.contact)}</td>
                                        <td>{item.contact.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate"}</td>
                                        <td>{item.link?.contact_branch?.name}</td>
                                        <td>{item.subject}</td>
                                        <td>{item.case_type.case_group.name}</td>
                                        <td>{item.case_type.name}</td>
                                        <td>{item.priority.impact.name}</td>
                                        <td>{item.priority.urgency.name}</td>
                                        <td>{item.priority.name}</td>
                                        <td>{item.resolution_status.name}</td>
                                        <td>
                                            {getEmployeeContactDetails(item.opened_by_employee)}
                                        </td>
                                        <td>{formatDateWithTimeZone(item.created_at)}</td>
                                        <td>{formatDateWithTimeZone(item.due_date)}</td>
                                        <td>
                                            {getEmployeeContactDetails(item.resolved_by_employee)}
                                        </td>
                                        <td>{item.resolved_at ? formatDateWithTimeZone(item.resolved_at): ""}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
            )}
        </div>
    );
}
