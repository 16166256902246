import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { MaritalStatusApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import {marital_status_form_fields as formFields} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Alias, DateCreated, DateModified, MaritalStatus, Status } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const MaritalStatusList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const getAllMaritalStatusesData = async () => {
      try {
          await axiosPrivate.get(MaritalStatusApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllMaritalStatusesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
      name: editRecord.name,
      short_name: editRecord.short_name,
      description: editRecord.name,
      is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${MaritalStatusApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${MaritalStatusApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/public-data/marital-statuses/create/${layoutURL}`;
  const tableColumns = [
    {
        name: `${MaritalStatus}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${Alias}`,
      selector: row => `${row.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Marital Statuses" mainTitle="Marital Statuses" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
              <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{MaritalStatus}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Alias}</th>
                    <td>{selectedRowData.short_name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default MaritalStatusList;