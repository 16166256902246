import { H5 } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { CardHeader, Row, Col } from 'reactstrap';
import { PlusCircle } from 'react-feather';

const HeaderCard = ({ title, span1, span2, mainClasses, btnUrl }) => {
  return (
    <Fragment>
      <CardHeader className={`${mainClasses ? mainClasses : ''} b-l-secondary border-2`}>
       <Row>
          <Col md="6">
            <H5>{title}</H5>
            {span1 ? <span>{span1}</span> : ''}
            {span2 ? <span>{span2}</span> : ''}
          </Col>
          <Col md="6">
            <div className="text-end file-content">
              {btnUrl ? <Link className="btn btn-primary ms-2" style={{ color: 'white' }} to={btnUrl}> <PlusCircle /> New</Link> : ''}
            </div>
          </Col>
        </Row>
      </CardHeader>
    </Fragment>
  );
};

export default HeaderCard;
