import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Table, Label, Input } from 'reactstrap';
import { Breadcrumbs, Image } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CompanyApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, SalutationApi, SectorApi, WardApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { org_form_fields } from '../../../../Data/FormField/Organisation';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { DateCreated, DateModified, Description, EmailAddress, Gender, LegalName, Lga, MaritalStatus, Name, PhoneNumber, Primary, RegionalCountry, Registration, RegistrationDate, RegistrationNo, Relationship, RelationshipDuration, Salutation, ShortName, State, Status, Structure, SubSector, Ward, Website } from '../../../../Constant';
import noimage from '../../../../assets/images/no-image.png';
import { createDropdownField } from '../../../../Data/FormField/PublicData';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import { useNavigate } from 'react-router-dom';

const CompanyList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const history = useNavigate();

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(org_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const subSectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sub_sector_id',
    'sub_sector_id',
    `${Salutation}`,
    `${SalutationApi}`,
    '',6
  );

  const sectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sector_id',
    'sector_id',
    `${Relationship}`,
    `${SectorApi}`,
    '', 6
  );

  const relationshipDurationDropdownField = createDropdownField(axiosPrivate,
    'sector_id',
    'sector_id',
    `${RelationshipDuration}`,
    `${WardApi}`,
    '', 6
  );

  const genderDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Gender}`,
    `${WardApi}`,
    '', 6
  );
  const maritalStatusDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${MaritalStatus}`,
    `${WardApi}`,
    '', 6
  );
  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${RegionalCountry}`,
    `${RegionalCountryApi}`,
    '',
    12
  );
  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 12
  );
  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 12
  );
  const wardTypeDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [subSectorTypeDropdownField, sectorTypeDropdownField, relationshipDurationDropdownField, genderDropdownField, maritalStatusDropdownField, countryDropdownField, stateDropdownField, lgaDropdownField, wardTypeDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'ward_id');

          await updatedFields[0].fetchOptions();
          //await updatedFields[1].fetchOptions();
          //await updatedFields[2].fetchOptions();
          await updatedFields[indexOfName].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, subSectorTypeDropdownField, sectorTypeDropdownField, wardTypeDropdownField]);

  sectorTypeDropdownField.onChange = async (selectedSectorId, updatedFields) => {
    const subSectorField = updatedFields.find((field) => field.id === 'sub_sector_id');

    if (subSectorField) {
      await subSectorField.fetchOptions(selectedSectorId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'sector_id') {
          return { ...field, value: selectedSectorId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    }
  };

  const getAllCompaniesData = async () => {
    try {
      await axiosPrivate.get(CompanyApi).then((resp) => {
        setAllData(resp.data);
      });
    } catch (error) {
      //console.log('cancelled', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllCompaniesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.applied_shared_fee = row.apply_shared_service_fee
    row.sector_id = row.sub_sector.sector_id;
    sectorTypeDropdownField.onChange(row.sector_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    const editRecordTemp = new FormData();
    const logo = typeof editRecord.logo[0] === 'undefined' ? '' : editRecord.logo[0];

    //editRecordTemp.append('organisation_type_id', editRecord.organisation_type_id);
    editRecordTemp.append('sub_sector_id', editRecord.sub_sector_id);
    editRecordTemp.append('full_name', editRecord.full_name);
    editRecordTemp.append('shortname', editRecord.shortname);
    editRecordTemp.append('foreign_affliation', editRecord.foreign_affliation);
    editRecordTemp.append('apply_shared_service_fee', editRecord.applied_shared_fee);
    editRecordTemp.append('fee_amount', editRecord.fee_amount);
    editRecordTemp.append('registration_number', editRecord.registration_number);
    editRecordTemp.append('registration_date', editRecord.registration_date);
    editRecordTemp.append('description', editRecord.description);
    editRecordTemp.append('is_registered', editRecord.is_registered);
    editRecordTemp.append('email_address', editRecord.email_address);
    editRecordTemp.append('phone_number', editRecord.phone_number);
    editRecordTemp.append('website', editRecord.website);
    editRecordTemp.append('logo', logo);
    editRecordTemp.append('street_number', editRecord.street_number);
    editRecordTemp.append('street_name', editRecord.street_name);
    editRecordTemp.append('nearest_landmark', editRecord.nearest_landmark);
    editRecordTemp.append('city', editRecord.city);
    editRecordTemp.append('ward_id', editRecord.ward_id);
    editRecordTemp.append('is_active', editRecord.is_active);

    try {
      await axiosPrivate.put(`${CompanyApi}/${id}`, editRecordTemp,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.full_name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return;
    }

    try {
      const response = await axiosPrivate.delete(`${CompanyApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const makeOrganisationPrimary = async (row) => {
    const makePrimary = window.confirm(`Are you sure you want to make  ${row.full_name} the Primary?`);

    if (!makePrimary) {
      return;
    }
    setSelectedRowData(row);

    try {
      await axiosPrivate.put(`${CompanyApi}/${row.id}/toggle-organisation`, {})
        .then((resp) => {
          const updatedData = allData.map((rowD) => {}
            //row.id === rowD.id ? { ...rowD, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.full_name} has been successfully updated.`)
        });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.detail);
      } else if (error.response && error.response.status === 404) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/administration/settings/guarantor-information/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${Name}`,
      selector: row => row.logo,
      sortable: true,
      center: false,
      cell: row => (row.logo ? <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: `${row.full_name}` }} />
        <Media body className="align-self-center">
          <div>{row.full_name}</div>
        </Media>
      </Media> :

        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: `${row.full_name}` }} />
          <Media body className="align-self-center">
            <div>{row.full_name}</div>
          </Media>
        </Media>)
    },
    {
      name: `${ShortName}`,
      selector: row => `${row.shortname}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => row.description,
      wrap: true,
      sortable: true,
      center: false,
      format: row => `${row.description ? row.description.slice(0, 30) : ''}...`,
    },
    {
      name: `${SubSector}`,
      selector: row => `${row.sub_sector.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Primary}`,
      selector: row => `${row.is_primary}`,
      sortable: true,
      center: false,
      cell: row => (<Media body className=" icon-state">
        <Label className='switch'>
          <Input id='primary' type='checkbox'
            checked={row.is_primary}
            onChange={() => makeOrganisationPrimary(row)}
          />
          <span className='switch-state'></span>
        </Label>
      </Media>)
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (
        <>
          {row.is_active ? <span className="badge badge-light-success">Active</span>
            : <span className="badge badge-light-danger">Inactive</span>}
          {row.is_primary ? (
            <span className="badge badge-light-success">Primary</span>
          ) : (
            <span className="badge badge-light-secondary">Secondary</span>
          )}
        </>

      )
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const openStructure = async (row) => {
    const structureLink = `${process.env.PUBLIC_URL}/organisations/companies/${row.id}/structures/divisions/${layoutURL}`;
    history(structureLink);
  }

  const addToDefaultAction = [
    {
      label: `${Structure}`,
      action: openStructure,
      color: 'warning',
      icon: 'icon-book',
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Organisations" title="Guarantor's Information" mainTitle="Guarantor's Information" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  addToDefaultAction={addToDefaultAction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{LegalName}</th>
                    <td>{selectedRowData.full_name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ShortName}</th>
                    <td>{selectedRowData.shortname}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Registration}</th>
                    <td>{selectedRowData.is_registered ? <span className="badge badge-light-success">Yes</span> : <span className="badge badge-light-danger">No</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{RegistrationNo}</th>
                    <td>{selectedRowData.registration_number}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{RegistrationDate}</th>
                    <td>{selectedRowData.registration_date}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EmailAddress}</th>
                    <td>{selectedRowData.email_address}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PhoneNumber}</th>
                    <td>{selectedRowData.phone_number}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Website}</th>
                    <td>{selectedRowData.website}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ?
                      <span className="badge badge-light-success">Active</span> :
                      <span className="badge badge-light-danger">Inactive</span>}
                      {selectedRowData.is_primary ?
                        <span className="badge badge-light-success">Primary</span> :
                        <span className="badge badge-light-secondary">Secondary</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default CompanyList;