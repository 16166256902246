/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useIncomeDescriptionListController from "./IncomeDescriptionList.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import {
  DescriptionName,
  Description,
  Settings,
  IncomeGroup,
  IncomeCategory,
  IncomeClass,
  IncomeType,
  Status,
  DateCreated,
  DateModified,
} from "../../../../../Constant";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

const IncomeDescriptionList = () => {
  const {
    data,
    tableColumns,
    newBtn,
    editMode,
    selectedRowData,
    viewModalOpen,
    title,
    formFields,
    setViewModalOpen,
    handleView,
    handleEdit,
    loading,
    memoizedHandleEdit,
  } = useIncomeDescriptionListController();

  return (
    <Fragment>
      <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={title}
                btnUrl={newBtn}
                span1={undefined}
                span2={undefined}
                mainClasses={undefined}
              />
              <CardBody>
                <DataTableComponent
                  tabledata={data}
                  tableColumns={tableColumns}
                  isLoading={false}
                  onView={handleView}
                  // onDelete={handleDelete}
                  onEdit={handleEdit}
                  useTableAction={undefined}
                  addToDefaultAction={undefined}
                  onDelete={undefined}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          onSubmit={undefined}
          modalTitle={undefined}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className="border-bottom-primary">
                    <th scope="row">{DescriptionName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{IncomeCategory}</th>
                    <td>
                      {
                        selectedRowData.income_type.income_class.income_group
                          .income_category.name
                      }
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{IncomeGroup}</th>
                    <td>
                      {
                        selectedRowData.income_type.income_class.income_group
                          .name
                      }
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{IncomeClass}</th>
                    <td>{selectedRowData.income_type.income_class.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{IncomeType}</th>
                    <td>{selectedRowData.income_type.name}</td>
                  </tr>

                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>

                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>

                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default IncomeDescriptionList;
