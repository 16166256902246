import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Description, Location, Members, ServiceTeam, Status, Team, TeamMember, TeamType, Unit, description } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { LocationDropdownApi, TeamApi, TeamMemberApi, TeamTypeApi, UnitApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';

const TeamList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${Team}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const [isNewMFieldAdded, setIsNewMFieldAdded] = useState(false);
  
  const teamTypeDropdown = createDropdownField(axiosPrivate,
    'team_type_id',
    'team_type_id',
    `${TeamType}`,
    `${TeamTypeApi}?organisation_id=${userOrganisationId}`,
    '', 12
    );
    
    const unitDropdown = createDropdownField(axiosPrivate,
      'unit_id',
      'unit_id',
      `${Unit}`,
      `${UnitApi(userOrganisationId)}`,
      '', 12, false
    );
  
    const locationTypeDropdown = createDropdownField(axiosPrivate,
      'location_id',
      'location_id',
      `${Location}`,
      `${LocationDropdownApi(userOrganisationId)}`,
      '', 12, false
    );

    const teamDropdown = createDropdownField(axiosPrivate,
      'team_id',
      'team_id',
      `${Team}`,
      '',
      '', 12
      );
      
    const member_form_fields = [
      {
        id: 'contacts',
        name: 'contacts',
        label: `${TeamMember}`,
        type: 'checkbox',
        value: '',
        required: false,
        colSize: 12,
        options: []
      },
    ];

    const [formMemberFields, setFormMemberFields] = useState(member_form_fields);
      
  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [teamTypeDropdown, ...formFields, unitDropdown, locationTypeDropdown];
          
          await updatedFields[0].fetchOptions();
          await unitDropdown.fetchOptions();
          await locationTypeDropdown.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, teamTypeDropdown]);

  useEffect(() => {

    const fetchMemberData = async () => {
      try {
        if (!isNewMFieldAdded) {

          const updatedFields = [teamTypeDropdown, teamDropdown, ...formMemberFields];
          
          await updatedFields[0].fetchOptions();
          
          setFormMemberFields(updatedFields);
          setIsNewMFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchMemberData();
  }, [formMemberFields, isNewMFieldAdded, teamTypeDropdown, teamDropdown]);

  teamTypeDropdown.onChange = async (selectedTeamTypeId, updatedFields) => {    
    const teamField = updatedFields.find((field) => field.id === 'team_id');
    
    if (teamField) {
      const endPoint = `${TeamApi}?organisation_id=${userOrganisationId}&team_type_id=${selectedTeamTypeId}`
      await teamField.fetchOptions(selectedTeamTypeId, false, endPoint);
      setFormMemberFields([...updatedFields]);
    } 
  };

  teamDropdown.onChange = async (selectedTeamId, updatedFields) => {    
    const indexOfContact = updatedFields.findIndex((updatedField) => updatedField.id === 'contacts');
    
    if (indexOfContact !== -1) {
      updatedFields[indexOfContact].options.splice(0);
      const endPoint = `${TeamMemberApi}/by-team-unit?organisation_id=${userOrganisationId}&team_id=${selectedTeamId}`
      try {
        await axiosPrivate.get(endPoint).then((resp) => {
          resp.data.forEach(option => {
            updatedFields[indexOfContact].options.push({ value: option.id, label: option.name });
          });
        });
      } catch (error) { }

      setFormMemberFields([...updatedFields]);
    } 
  };

  const getAllTeamsData = async () => {
    try {
      await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
        setAllData(resp.data);
        //setIsLoading(false);
      });
    } catch (error) {
      toast.error(error.response.data.detail)
      //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllTeamsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      team_type_id: editRecord.team_type_id,
      name: editRecord.name,
      description: editRecord.name,
      is_active: editRecord.is_active,
      unit_id: editRecord.unit_id,
      location_id: editRecord.location_id,
    };

    try {
      await axiosPrivate.put(`${TeamApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const OpenMemberModal = async (row) => {
    setMemberModalOpen(true);
    setEditMode(true);
    row.team_id = row.id;
    teamTypeDropdown.onChange(row.team_type_id, formMemberFields);
    teamDropdown.onChange(row.team_id, formMemberFields) 
    setSelectedRowData(row);
  }

  const addToDefaultAction = [
    {
      label: `${Members}`,
      action: OpenMemberModal,
      color: 'warning',
      icon: 'icon-user',
    },
  ];

  const handleMemberCreateUpdate = async (id, editRecord) => {
    editRecord.contacts = editRecord.contacts.map(contact => {
      return { 
        "contact_id": parseInt(contact),
        "is_active": true
      }; 
    });

    const editRecordTemp = {
      team_id: editRecord.team_id,
      contacts: editRecord.contacts
    }

    try {
      await axiosPrivate.post(`${TeamMemberApi}`, editRecordTemp).then((resp) => {
        toast.success(`Team Member is successfully updated.`)
        handleMemberCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  }

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);
  const memoizedOpenMemberModal = useMemo(() => handleMemberCreateUpdate, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleMemberCloseModal = () => {
    setMemberModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${TeamApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/hrm/personnel/teams/create/${layoutURL}`;

  const tableColumns = [
    {
      name: `${TeamType}`,
      selector: row => `${row.team_type.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Team}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="Team" mainTitle="Team" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  //addToDefaultAction={addToDefaultAction}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                <tr className='border-bottom-secondary'>
                    <th scope='row'>{TeamType}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Team}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        <ViewModal
          isOpen={memberModalOpen}
          toggle={() => setMemberModalOpen(!memberModalOpen)}
          rowData={selectedRowData}
          formFields={formMemberFields}
          editMode={editMode}
          onEdit={memoizedOpenMemberModal}
        />   
      </Container>
    </Fragment>
  );
};

export default TeamList;