import { Fragment, useState } from 'react';
import { AssignedTo, CaseGroup, CaseTypeName, DelegateTeam, Impact, Priority, ResolutionStatus, ServiceTeam, Urgency } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import DropdownCommon2 from '../../../../Common/Dropdown/index-d';
import { CaseAssignedToUri, CaseDelegateToTeamMemberUri, CaseDelegateToTeamUri, CasePriorityUri, CaseResolutionStatusUri } from '../../../../../api';
import SLATimeCounter from '../../../../Common/Component/SLATimeCounter';
import SweetAlert from 'sweetalert2';

const CaseInfo = ({ details, priorities, resolutionStatuses, teams, teamMembers, delegateTeamMembers, onCaseUpdate }) => {
    const [isFilter, setIsFilter] = useState(true);
    
    const options = [
        { id: 1, value: "Option 1" },
        { id: 2, value: "Option 2" },
        { id: 3, value: "Option 3" },
      ];
      
    const handleResolutionStatusUpdate = async (id) => {
        const methodName = `${CaseResolutionStatusUri}`;
        const payload = { resolution_status_id : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handlePriorityUpdate = async (id) => {
        const methodName = `${CasePriorityUri}`;
        const payload = { priority_id : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleAssignedToUpdate = async (id) => {
        const methodName = `${CaseAssignedToUri}`;
        const payload = { assigned_to : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleDelegateAssignedToUpdate = async (id) => {
        const methodName = `${CaseDelegateToTeamMemberUri}`;
        const payload = { assigned_to : id};
        
        await onCaseUpdate(methodName, payload);
    };

    const handleDelegateTeamUpdate = async (id) => {
        const methodName = `${CaseDelegateToTeamUri}`;
        
        SweetAlert.fire({
            title: 'Reason for Delegating',
            input: 'text',
            showCancelButton: true, // Add this to show Cancel button
        }).then(async (result) => {
            if (result.value) {
                const payload = {
                    team_id: id,
                    message: result.value
                };
                await onCaseUpdate(methodName, payload);
                //SweetAlert.fire(`You typed: ${result.value}`);
            } else if (result.dismiss === "cancel") {
                SweetAlert.fire('You cancelled the delegation');
            } else {
                SweetAlert.fire('You did not enter any reason for delegation');
            }
        });    

    };

    const caseContactDelegate = details.assigned_to ? teamMembers.find(member => member.id === details.assigned_to)?.name : "Not Assigned";
    const caseTeamsForDelegate = teams.filter(team => team.id !== details?.case_type?.service_team_id);
    const caseTeamsDelegatedID = details?.case_delegates?.find(delegate => delegate.is_active === true )?.team_id;
    const caseTeamDelegated = caseTeamsDelegatedID ? teams.find(team => team.id === caseTeamsDelegatedID)?.name : "Not Delegated";
    const caseTeamsDelegatedContactID = details?.case_delegates?.find(delegate => delegate.is_active === true )?.employee_id;
    const caseDelegateContact = caseTeamsDelegatedContactID ? delegateTeamMembers.find(member => member.id === caseTeamsDelegatedContactID)?.name : "Not Assigned";
 
    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >&nbsp;
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                            <Table>
                                <tbody>
                                    { !details.resolved_at && 
                                    <tr className=''>
                                        <th scope='row' colSpan={2}>
                                            <SLATimeCounter dueDate={details.due_date} />
                                        </th>
                                    </tr>
                                    }
                                    <tr className=''>
                                        <th scope='row'>{CaseGroup}</th>
                                        <td>{details?.case_type?.case_group?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{CaseTypeName}</th>
                                        <td>{details?.case_type?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{ResolutionStatus}</th>
                                        <td>
                                            <span style={{color: details?.resolution_status?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-bell"></i> {details?.resolution_status?.name}</span>
                                                { !details.resolved_at &&
                                                <DropdownCommon2 
                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                    options={resolutionStatuses} 
                                                    iconName='icon-angle-down' 
                                                    btn={{ tag: 'span' }} 
                                                    onClick={handleResolutionStatusUpdate} />}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Impact}</th>
                                        <td>
                                            <span style={{color: details?.priority?.impact?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.impact?.name}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Urgency}</th>
                                        <td>
                                            <span style={{color: details?.priority?.urgency?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.urgency?.name}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Priority}</th>
                                        <td>
                                            <span style={{color: details?.priority?.color}} className='d-flex'>
                                             <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.name}</span>
                                             { !details.resolved_at && (<DropdownCommon2 
                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                options={priorities} 
                                                iconName='icon-angle-down' 
                                                btn={{ tag: 'span' }}
                                                onClick={handlePriorityUpdate} />)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{ServiceTeam}</th>
                                        <td>
                                            <span className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-users"></i> {details?.case_type?.service_team?.name}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{AssignedTo}</th>
                                        <td>
                                            <span className='d-flex'>
                                             <span className='m-r-5'><i className="fa fa-user"></i> {caseContactDelegate}</span>
                                             { !(details?.resolved_at || caseTeamsDelegatedID) && (
                                             <DropdownCommon2 
                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                options={teamMembers} 
                                                iconName='icon-angle-down' 
                                                btn={{ tag: 'span' }}
                                                onClick={handleAssignedToUpdate} />
                                            )}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DelegateTeam}</th>
                                        <td>
                                            <span className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-users"></i> {caseTeamDelegated}</span>
                                                { !details.resolved_at && (<DropdownCommon2 
                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                    options={caseTeamsForDelegate} 
                                                    iconName='icon-angle-down' 
                                                    btn={{ tag: 'span' }}
                                                    onClick={handleDelegateTeamUpdate} />)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{AssignedTo}</th>
                                        <td>
                                            <span className='d-flex'>
                                             <span className='m-r-5'><i className="fa fa-user"></i> {caseDelegateContact}</span>
                                             { !details.resolved_at && (<DropdownCommon2 
                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                options={delegateTeamMembers} 
                                                iconName='icon-angle-down' 
                                                btn={{ tag: 'span' }}
                                                onClick={handleDelegateAssignedToUpdate} />)}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default CaseInfo;