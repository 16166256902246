import { Subject, Message, } from "../../../../Constant"
export const interaction_form_fields = [
    {
      id: 'subject',
      name: 'subject',
      label: `${Subject}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'message',
      name: 'message',
      label: `${Message}`,
      // type: 'simpleEditor',
      type: 'textarea',
      value: '',
      required: true,
      colSize: 12,
    },
    
];

