import { useEffect, useState } from 'react';
import Context from './index';
import { ApplicationApi } from '../../../api';
import { toast } from 'react-toastify';
import axios from '../../../api/axios';

const ApplicationProvider = (props) => {
    const [allData, setAllData] = useState([]);
    const [application, setApplication] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getAllApplicationData = async () => {
        try {
            await axios.get(ApplicationApi).then((resp) => {
                setAllData(resp.data);
                setIsLoading(false);
            });
        } catch (error) {
            //console.log('cancelled', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
       // getAllApplicationData();
    }, [setAllData, setApplication]);

    const addNewApplication =  async (applicationData) => {
        const applicationTemp = {
            //id: allData.length + 1,
            title: applicationData.title,
            //icon: applicationData.icon,
            body: applicationData.description
        };
        setIsLoading(true);
        //setAllData([...allData, applicationTemp]);
        try {
             await axios.post(ApplicationApi, applicationTemp).then((resp) => {
                setAllData([...allData, resp.data]);
                setIsLoading(false);
                toast.success(`${resp.data.title} is successfully added.`)
            });
        } catch (error) {
            //console.log('Error Posting', error);
        }  
    };

    return (
        <Context.Provider
            value={{
                ...props,
                addNewApplication: addNewApplication,
                application,
                allData,
                isLoading,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ApplicationProvider;