import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
//import NavAuth from './Nav';
//import LoginTab from './Tabs/LoginTab';
//import AuthTab from './Tabs/AuthTab';


import LoginForm from './LoginForm';
import { Image } from '../AbstractElements';
import { useCookies } from 'react-cookie';
import CookieConsent from '../Components/CookieConsent';

const Logins = () => {
  //const [selected, setSelected] = useState('simpleLogin');
  const [cookies] = useCookies(["cookieConsent"]);

  /* const callbackNav = (select) => {
    setSelected(select);
  }; */

  return (
    <Fragment>
      <section>
      {!cookies.cookieConsent && <CookieConsent />}
        <Container fluid={true}>
        
          <Row>
            
            <Col xl='7' className='b-center bg-size' style={{ backgroundImage: `url(${require('../assets/images/login/2.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
              <Image attrImage={{ className: 'bg-img-cover bg-center d-none', src: `${require('../assets/images/login/2.jpg')}`, alt: 'looginpage' }} />
            </Col>
            <Col xl='5 p-0'>
              <LoginForm logoClassMain='text-start' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
/*   return (
    <Container fluid={true} className='p-0 login-page'>
      <Row>
        <Col xs='12'>
          <div className='login-card'>
            <div className='login-main login-tab'>
              <NavAuth callbackNav={callbackNav} selected={selected} />
              <TabContent activeTab={selected} className='content-login'>
                <TabPane className='fade show' tabId={selected === 'simpleLogin' ? 'simpleLogin' : 'jwt'}>
                  <LoginTab selected={selected} />
                </TabPane>
                <TabPane className='fade show' tabId='auth0'>
                  <AuthTab />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  ); */
};

export default Logins;
