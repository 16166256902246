import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PfaApi, PfcApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {base_form_fields, code_field, createDropdownField, short_name_field} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { PfaName, Pfc, PfaCode } from '../../../../../Constant';

const PfaNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/pfas/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${PfaName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const pfcDropdownField = createDropdownField(axiosPrivate,
    'pfc_id',
    'pfc_id',
    `${Pfc}`,
    `${PfcApi}`, '', 12
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (!isNewFieldAdded) {
            // Find the index of the 'name' object
            const indexOfName = formFields.findIndex((formField) => formField.id === 'name');

            // Insert the new object after the 'name' object
            const updatedFields = [pfcDropdownField, ...formFields]; 
            updatedFields.splice(indexOfName + 2, 0, code_field(`${PfaCode}`), short_name_field());
            await pfcDropdownField.fetchOptions();
            // Update the state and set the flag
            setFormFields(updatedFields);
            setIsNewFieldAdded(true);
          }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, code_field, short_name_field]);

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          name: newRecord.name,
          pfc_id: newRecord.pfc_id,
          code: newRecord.code,
          short_name: newRecord.short_name,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(PfaApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Pfa" mainTitle="New Pfa" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PfaNew;