export const hasPermission = (requiredPermission, userPermissions) => {
    // Check if the user has the required permission
    return true
    //return userPermissions.includes(requiredPermission) || requiredPermission == false;
};

export const generateRoutesJson = (routes) => {
    return routes.reduce((acc, route) => {
      let { path, requiredPermission } = route;
      // Remove '/:layout' from the path if it exists
      path = path.replace(/\/:layout$/, '');
      if (requiredPermission) {
        acc[requiredPermission] = path;
      } else {
        //acc['no_permission'] = path;
        if (!acc['no_permission']) {
            acc['no_permission'] = [];
        }
        acc['no_permission'].push({ path });
      }
      return acc;
    }, {});
};

export const buildMenuItems = (menus, routes) => {
  if (!Array.isArray(menus) || menus.length === 0) {
    return [];
  }

  return menus
    .filter(menu => menu.visible !== 0)
    .map(menu => ({
        menutitle: menu.application,
        menucontent: 'Dashboards,Widgets', // Adjust content as needed
        Items: menu.modules
            .filter(module => module.visible !== 0)
            .map(module => ({
            title: module.title,
            icon: 'sample-page', // Adjust icon as needed
            type: module.tasks && module.tasks.length > 0 ? 'sub' : 'link',
            children: module.tasks
                .filter(task => task.visible !== 0)
                .map(task => ({
                active: false,
                path: routes[task.path_code] ? routes[task.path_code].replace('/:layout', '') : '',
                title: task.title,
                type: 'link'
                }))
            }))
    }));
};

export const buildMenuItems2 = (menus, routes) => {
  if (!Array.isArray(menus) || menus.length === 0) {
    return [];
  }

  return menus
    .filter(menu => menu.visible !== 0)
    .map(menu => ({
        menutitle: menu.menutitle,
        menucontent: 'Dashboards,Widgets', // Adjust content as needed
        Items: menu.Items
            .filter(module => module.visible !== 0)
            .map(module => ({
            title: module.title,
            icon: 'sample-page', // Adjust icon as needed
            type: module.tasks && module.tasks.length > 0 ? 'sub' : 'link',
            children: module.children
                .filter(task => task.visible !== 0)
                .map(task => ({
                active: false,
                path: routes[task.path_code] ? routes[task.path_code].replace('/:layout', '') : '',
                title: task.title,
                type: 'link'
                }))
            }))
    }));
};
  