import { Input, Button, Form, FormGroup, Label } from "reactstrap";
import useGeneralLedgerTypeReport from "./controller";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Report } from "../../../../Constant";
import DatePicker from "react-datepicker";
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper";

export default function SubLedgerClassReport() {
    const {
        printedData,
        GLGroupDropDown,
        startDate,
        endDate,
        setDateRange,
        exportToCSV,
        generatePrint,
        getTableFunction,
        setGLGroupDropDownValue,
    } = useGeneralLedgerTypeReport();

    return (
        <div>
            <Breadcrumbs
                parent={Report}
                title={"Sub Ledger Class Report"}
                mainTitle={"Sub ledger Class Report"}
            />

            <br />

            <div className="row gap-y-3">
                <div className="col-md-4 mb-3">
                    <p className="mb-0">General Ledger Group</p>
                    <Input
                        onChange={(e) =>
                            setGLGroupDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>Default Select</option>
                        {GLGroupDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4  mb-3">
                    <p className="mb-0">Date Range</p>
                    <DatePicker
                        className="form-control w-100"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update: Date[] | any) => {
                            setDateRange(update);
                            console.log(formatDateWithTimeZone(update[0]));
                        }}
                        withPortal
                    />
                </div>

                <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
                    <Button
                        color="success"
                        onClick={getTableFunction}
                        className="mt-4"
                    >
                        Generate Table
                    </Button>
                </div>
            </div>

            <br />
            <br />

            {printedData.length !== 0 && (
                <>
                    <div className="row">
                        <Form className="col">
                            <FormGroup>
                                <Label for="search">Search</Label>
                                <Input id="search" />
                            </FormGroup>
                        </Form>
                        <div className="col d-flex gap-2 justify-content-end align-items-center">
                            <Button
                                color="info"
                                onClick={() => generatePrint(printedData)}
                            >
                                Generate Print
                            </Button>

                            <Button
                                color="info"
                                onClick={() => exportToCSV(printedData)}
                            >
                                Generate CSV
                            </Button>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Code</th>
                                <th>Balance Type</th>
                                <th>Report Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {printedData.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.code}</td>
                                    <td>{item.gl_group.name}</td>
                                    <td>{item.gl_group.report_type.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
}
