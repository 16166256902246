import { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ApplicationApi, ModuleApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, createDropdownField, icon_field, visibility_field } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { ApplicationName, ModuleName } from '../../../../Constant';

const ModuleNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/access-control/modules/${layoutURL}`;

  const { formState: { errors }, } = useForm();

  const [formFields, setFormFields] = useState(base_form_fields(`${ModuleName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const ApplicationDropDown = createDropdownField(axiosPrivate,
    'application_id',
    'application_id',
    `${ApplicationName}`,
    `${ApplicationApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const indexOfName = formFields.findIndex((formField) => formField.id === 'description');

          const updatedFields = [ApplicationDropDown, ...formFields];
          updatedFields.splice(indexOfName + 2, 0, icon_field(), visibility_field);


          await updatedFields[0].fetchOptions();

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, ApplicationDropDown]);

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      application_id: newRecord.application_id,
      name: newRecord.name,
      description: newRecord.description,
      is_active: newRecord.is_active,
      icon: newRecord.icon,
      visibility: newRecord.visibility,
      //sort_order: 0
    };

    try {
      await axiosPrivate.post(ModuleApi, newRecordTemp).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="New Module" mainTitle="New Module" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ModuleNew;