import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useGeneralLedgerGroupListController from "./GeneralLedgerGroupList.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import {
  BalanceLedger,
  Code,
  DateCreated,
  DateModified,
  Description,
  GroupName,
  Report,
  Settings,
  Status,
} from "../../../../../Constant";
import { IGeneralLedgerGroupResponse } from "../GeneralLedgerGroup.types";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
// import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

const GeneralLedgerGroupList = () => {
  const {
    data,
    tableColumns,
    newBtn,
    editMode,
    selectedRowData,
    viewModalOpen,
    title,
    formFields,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
  } = useGeneralLedgerGroupListController();
 
  return (
    <Fragment>
      <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard
                title={title}
                btnUrl={newBtn}
                span1={undefined}
                span2={undefined}
                mainClasses={undefined}
              />
              <CardBody>
                <DataTableComponent
                  tabledata={data}
                  tableColumns={tableColumns}
                  isLoading={false}
                  onView={handleView}
                  // onDelete={handleDelete}
                  onEdit={handleEdit}
                  useTableAction={undefined}
                  addToDefaultAction={undefined}
                  onDelete={undefined}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          onSubmit={undefined}
          modalTitle={undefined}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className="border-bottom-primary">
                    <th scope="row">{GroupName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Code}</th>
                    <td>{selectedRowData.code}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{BalanceLedger}</th>
                    <td>
                      {selectedRowData.balance_type ? (
                        <span className="badge badge-light-success">
                          Credit
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Debit
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Report}</th>
                    <td>{selectedRowData.report_type.name}</td>
                  </tr>

                  <tr className="border-bottom-primary">
                    <th scope="row">{Status}</th>
                    <td>
                      {selectedRowData.is_active ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr className="border-bottom-secondary">
                    <th scope="row">{DateCreated}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.created_at)}
                    </td>
                  </tr>

                  <tr className="border-bottom-secondary">
                    <th scope="row">{DateModified}</th>
                    <td>
                      {formatDateWithTimeZone(selectedRowData.updated_at)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default GeneralLedgerGroupList;

export const StatusRow = (row: IGeneralLedgerGroupResponse) => {
  return row.is_active ? (
    <span className="badge badge-light-success">Active</span>
  ) : (
    <span className="badge badge-light-danger">Inactive</span>
  );
};
