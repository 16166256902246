import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./SubLedgerTypeList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    GeneralLedgerAccount,
    GeneralLedgerClass,
    GeneralLedgerType,
    GeneralLedgerGroup,
    Description,
    Name,
    Status,
    SubLedgerClass,
    SubLedgerType,
    Code,
    Report,
    CreatedBy,
    ModifiedBy,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    financesGeneralLedgerAccountApi,
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
    financesGeneralLedgerTypeApi,
    financeSubLedgerClassApi,
    financeSubLedgerTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { ISubLedgerTypesResponse } from "../SubLedgerType.types";
import useAuth from "../../../../../Hooks/useAuth";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { toast } from "react-toastify";

export default function useSubLedgerTypeListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = SubLedgerType;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_class_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "sl_class_id",
        "sl_class_id",
        SubLedgerClass,
        ``,
        "",
        6
    );
    
    const generalLedgerGroupDropDown2 = createDropdownField(
        axiosPrivate,
        "gl_group_id2",
        "gl_group_id2",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );

    generalLedgerGroupDropDown2.onChange = async (id, updatedFields) => {
        const nextField2 = updatedFields.find(
            (field: any) => field.id === "gl_class_id"
        );
        if (nextField2) {
            const endPoint = `${financesGeneralLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField2.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id2") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const generalLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "gl_class_id",
        "gl_class_id",
        GeneralLedgerClass,
        ``,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_type_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerTypeApi}?organisation_id=${userOrganisationId}&gl_class_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_class_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        //updateFields();
    };

    const generalLedgerTypeDropDown = createDropdownField(
        axiosPrivate,
        "gl_type_id",
        "gl_type_id",
        GeneralLedgerType,
        ``,
        "",
        6
    );

    generalLedgerTypeDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_account_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerAccountApi}?organisation_id=${userOrganisationId}&gl_type_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_type_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        //updateFields();
    };

    const generalLedgerAccountDropDown = createDropdownField(
        axiosPrivate,
        "gl_account_id",
        "gl_account_id",
        GeneralLedgerAccount,
        ``,
        "",
        6
    );

    async function updateFields() {
        const updatedFields = [
            generalLedgerGroupDropDown,
            subLedgerClassDropDown,
            {
                type: 'group-title',
                label: `Mapping of Control GL Account `,
            },
            generalLedgerGroupDropDown2,
            generalLedgerClassDropDown,
            generalLedgerTypeDropDown,
            generalLedgerAccountDropDown,
            {
                type: 'group-title',
                label: `SL Type`,
            },
            ...formFields,
        ];
        await generalLedgerGroupDropDown.fetchOptions();
        await generalLedgerGroupDropDown2.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Code,
            selector: (row: (typeof data)[0]) => `${row.code}`,
            sortable: false,
            center: false,
        },

        {
            name: GeneralLedgerGroup,
            selector: (row: (typeof data)[0]) =>
                `${row.sl_class.gl_group.name}`,
            sortable: true,
            center: false,
        },

        {
            name: SubLedgerClass,
            selector: (row: (typeof data)[0]) => `${row.sl_class.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerAccount,
            selector: (row: (typeof data)[0]) =>
                `${row.gl_account.name}`,
            sortable: true,
            center: false,
        },

        /* {
            name: GeneralLedgerClass,
            selector: (row: (typeof data)[0]) =>
                `${row.sl_class.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerType,
            selector: (row: (typeof data)[0]) =>
                `${row.gl_account.gl_type.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerAccount,
            selector: (row: (typeof data)[0]) => `${row.gl_account.name}`,
            sortable: true,
            center: false,
        }, */

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financeSubLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: ISubLedgerTypesResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-type/create/${layoutURL}`;
    // const gotoEdit = (row: any) => {
    //     navigate(
    //         `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-type/edit/${row.id}/${layoutURL}`
    //     );
    // };
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ISubLedgerTypesResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const addToDefaultAction: any[] = [
        // {
        //     label: `${Edit}`,
        //     action: gotoEdit,
        //     color: "info",
        //     icon: "icon-user",
        // },
    ];

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);

        row.gl_group_id = row.sl_class.gl_group.id;
        generalLedgerGroupDropDown.onChange(row.gl_group_id, formFields);

        row.sl_class_id = row.sl_class.id;

        row.gl_group_id2 = row.gl_account.gl_type.gl_class.gl_group.id;
        row.gl_class_id = row.gl_account.gl_type.gl_class.id;
        row.gl_type_id = row.gl_account.gl_type.id;
        row.gl_account_id = row.gl_account.id;
        generalLedgerGroupDropDown2.onChange(row.gl_group_id2, formFields);
        generalLedgerClassDropDown.onChange(row.gl_class_id, formFields);
        generalLedgerTypeDropDown.onChange(row.gl_type_id, formFields);

        setSelectedRowData(row);
    };

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        
        await axiosPrivate
            .put(`${financeSubLedgerTypeApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id
                        ? { ...row, ..._res.data }
                        : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
                //toast.error(err.response.message || "Something Went Wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        addToDefaultAction,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
