import React from 'react';

const Spinner = (props) => {
  const { iconId, ...res } = props;
  return (
    <span>
      {props.name ? props.name : 'Processing'}...<i className='fa fa-spinner fa-spin'></i>
    </span>
  );
};

export default Spinner;
