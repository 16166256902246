import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  Code,
  Description,
  GeneralLedgerGroup,
  GroupGLClass,
  Name,
  Status,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesGeneralLedgerGroupApi,
  financesGroupGeneralLedgerClassApi,
} from "../../../../../api";
import { IGroupGeneralLedgerClassPayload } from "../GroupGeneralLedgerClass.types";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useGroupGeneralLedgerClassController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = GroupGLClass;
  const { userOrganisationId }: any = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class/${layoutURL}`;
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const glGroupIdDropDown = createDropdownField(
    axiosPrivate,
    "gl_group_id",
    "gl_group_id",
    GeneralLedgerGroup,
    `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  glGroupIdDropDown.onChange = async (id, fields) => {
    console.log(fields);
    console.log(id);
    updateFields();
  };

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [
      glGroupIdDropDown,
      ...formFields,

      // balanceLedgerDropDown,
    ];
    await glGroupIdDropDown.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  async function onSubmit(data: IGroupGeneralLedgerClassPayload) {
    data.organisation_id = userOrganisationId;
    await axiosPrivate
      .post(financesGroupGeneralLedgerClassApi, data)
      .then((_res) => {
        toast.success(`${_res.data.name} Created Successfully.`);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.warn("Group GL Class not found");
        }
        toast.error(err.response.message || "Something Went Wrong");
      });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
