import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Description, ProductCategory, FixedAssetsDescription, Status, FixedAssetsGroup, FixedAssetsClass, FixedAssetsType } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ProductCategoryApi, FixedAssetsDescriptionApi, ProductGroupApi, FixedAssetsClassApi, FixedAssetsGroupApi, FixedAssetsTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import {base_form_fields, createDropdownField, salutation_form_fields} from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
//import SweetAlert from 'sweetalert2';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import { useForm } from 'react-hook-form';

const FixedAssetsDescriptionList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  
  const [formFields, setFormFields] = useState(base_form_fields(`${FixedAssetsDescription}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const { formState: { errors },} = useForm(); 

  const FixedAssetsGroupDropdownField = createDropdownField(axiosPrivate,
    'fixed_assets_group_id',
    'fixed_assets_group_id',
    `${FixedAssetsGroup}`,
    `${FixedAssetsGroupApi}`,
    '', 12
  );

  const FixedAssetsClassDropdownField = createDropdownField(axiosPrivate,
    'fixed_assets_class_id',
    'fixed_assets_class_id',
    `${FixedAssetsClass}`,
    `${FixedAssetsClassApi}`,
    '', 12
  );
  const FixedAssetsTypeDropdownField = createDropdownField(axiosPrivate,
    'fixed_assets_type_id',
    'fixed_assets_type_id',
    `${FixedAssetsType}`,
    `${FixedAssetsTypeApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [FixedAssetsGroupDropdownField, FixedAssetsClassDropdownField, FixedAssetsTypeDropdownField, ...formFields];
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, FixedAssetsGroupDropdownField]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [FixedAssetsClassDropdownField, ...formFields];
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, FixedAssetsClassDropdownField]);

  const getAllFixedAssetsDescriptionsData = async () => {
      try {
          await axiosPrivate.get(FixedAssetsDescriptionApi).then((resp) => {
              setAllData(resp.data);
              //setIsLoading(false);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
          //setIsLoading(false);
      } finally {
        // Set loading to false after data is fetched (regardless of success or failure)
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllFixedAssetsDescriptionsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      name: editRecord.name,
      fixed_assets_type_id: editRecord.fixed_assets_type_id,
      description: editRecord.description,
      is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${FixedAssetsDescriptionApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${FixedAssetsDescriptionApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-description/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${FixedAssetsType}`,
      selector: row => `${row.fixed_assets_type.name}`,
      sortable: true,
      center: false,
    },
    {
        name: `${FixedAssetsDescription}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Fixed Assets Description" mainTitle="Fixed Assets Description" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FixedAssetsDescription}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FixedAssetsGroup}</th>
                    <td>{selectedRowData.fixed_assets_group.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FixedAssetsClass}</th>
                    <td>{selectedRowData.fixed_assets_class.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FixedAssetsType}</th>
                    <td>{selectedRowData.fixed_assets_type.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                  </tbody>
                </Table>
            </>
          )}
          
        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default FixedAssetsDescriptionList;