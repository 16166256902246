import { DOB, Department, Division, EmailAddress, FirstName, FormerOrMaidenName, NIN, Organization, OrganizationType, OtherOrMiddleName, PhoneNumber, PhoneNumberAlt, PhotoID, Status, Surname } from "../../../Constant";
import { CompanyApi, DepartmentApi, DivisionApi, OrganisationTypeApi } from "../../../api";
import axios from "../../../api/axios";

export const spouse_form_fields = [
    {
    id: 'logo',
    name: 'logo',
    label: `${PhotoID}`,
    type: 'file',
    value: '',
    required: false,
    colSize: 6,
    },
    {
      id: 'full_name',
      name: 'full_name',
      label: `${Surname}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
        id: 'shortname',
        name: 'shortname',
        label: `${FirstName}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
      id: 'shortname',
      name: 'shortname',
      label: `${OtherOrMiddleName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'shortname',
      name: 'shortname',
      label: `${FormerOrMaidenName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
  },
  {
    id: 'shortname',
    name: 'shortname',
    label: `${DOB}`,
    type: 'text',
    value: '',
    required: true,
    colSize: 6,
},
  {
    id: 'shortname',
    name: 'shortname',
    label: `${NIN}`,
    type: 'text',
    value: '',
    required: true,
    colSize: 6,
},
{
  id: 'email_address',
  name: 'email_address',
  label: `${EmailAddress}`,
  type: 'text',
  value: '',
  required: true,
  colSize: 6,
},
{
  id: 'phone_number',
  name: 'phone_number',
  label: `${PhoneNumber}`,
  type: 'text',
  value: '',
  required: true,
  colSize: 6,
},
{
  id: 'shortname',
  name: 'shortname',
  label: `${PhoneNumberAlt}`,
  type: 'text',
  value: '',
  required: true,
  colSize: 6,
},

    /* {
        id: 'street_number',
        name: 'street_number',
        label: `${StreetNo}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'street_name',
        name: 'street_name',
        label: `${StreetName}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'nearest_landmark',
        name: 'nearest_landmark',
        label: `${NearestLandmark}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'city',
        name: 'city',
        label: `${TownCity}`,
        type: 'text',
        value: '',
        required: true,
    }, */
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
];

/* export const org_type_dropdown_field = {
    id: 'organisation_type_id',
    name: 'organisation_type_id',
    label: `${OrganizationType}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions() {
      try {
        const response = await axios.get(OrganisationTypeApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching sector options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_dropdown_field = {
    id: 'organisation_id',
    name: 'organisation_id',
    label: `${Organization}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions() {
      try {
        const response = await axios.get(CompanyApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.full_name }));
      } catch (error) {
        console.error('Error fetching company options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_division_dropdown_field = {
    id: 'division_id',
    name: 'division_id',
    label: `${Division}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id) {
      try {
        const response = await axios.get(DivisionApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching division options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_dept_dropdown_field = {
    id: 'department_id',
    name: 'department_id',
    label: `${Department}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id, division_id) {
      try {
        const response = await axios.get(DepartmentApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching department options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */
