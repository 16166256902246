import React, { useEffect, useState } from "react";

export default function useGeneralLedgerAssign(data: any[]) {
    const [searched, setSearched] = useState<typeof data>([]);
    const [searchNameInput, setSearchNameInput] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [assigned, setAssigned] = React.useState<typeof data>([]);
    const [unassigned, setUnassigned] = React.useState<typeof data>([]);
    const [selectedItem, setSelectedItem] = useState<
        (typeof data)[0] | null | undefined
    >(null);

    useEffect(() => {
        if (searched) {
            setAssigned(searched.filter((item) => item.is_assigned));
            setUnassigned(
                searched.filter((item) => item.is_assigned === false)
            );
        }
    }, [searched]);

    const searchFilter = () => {
        let result: any;

        if (searchNameInput) {
            result = data.filter((item) => {
                return item.programmes
                    .toLowerCase()
                    .includes(searchNameInput.toLowerCase());
            });
        }

        if (selectedCountry) {
            result = data.filter((item) => {
                return item.country
                    .toLowerCase()
                    .includes(selectedCountry.toLowerCase());
            });
        }

        setSearched(result);
    };

    const selectRow = (id: string | number | undefined) => {
        const item = data.find((item) => item.id === id);
        selectedItem?.id === id ? setSelectedItem(null) : setSelectedItem(item);
    };

    const addToAssign = (id: string | number | undefined) => {
        const item = data.find((item) => item.id === id);
        if (item) {
            item.is_assigned = true;
            if (assigned.find((item) => item.id === id)) return;
            setAssigned([...assigned, item]);
            setUnassigned(unassigned.filter((item) => item.id !== id));
        }
    };

    const addToUnAssign = (id: string | number | undefined) => {
        const item = data.find((item) => item.id === id);
        if (item) {
            item.is_assigned = false;
            if (unassigned.find((item) => item.id === id)) return;
            setUnassigned([...unassigned, item]);
            setAssigned(assigned.filter((item) => item.id !== id));
        }
    };

    return {
        assigned,
        unassigned,
        selectedItem,
        searchNameInput,
        selectRow,
        setSelectedCountry,
        setSearchNameInput,
        searchFilter,
        setSelectedItem,
        addToAssign,
        addToUnAssign,
    };
}
