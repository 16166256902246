import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BankApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {base_form_fields2, bank_type_dropdown_field, code_field, short_name_field} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { BankCode, BankName } from '../../../../../Constant';

const BankNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/banks/${layoutURL}`;
  
  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields2(`${BankName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          // Find the index of the 'name' object
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
    
          const updatedFields = [bank_type_dropdown_field, ...formFields];
          updatedFields.splice(indexOfName + 2, 0, code_field(`${BankCode}`, 'number'), short_name_field());
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, bank_type_dropdown_field, code_field, short_name_field]);

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          bank_type_id: newRecord.bank_type_id,
          name: newRecord.name,
          short_name: newRecord.short_name,
          code: newRecord.code,
          description: newRecord.name,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(BankApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Bank" mainTitle="New Bank" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BankNew;