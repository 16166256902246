import { Fragment, useState } from 'react';
import { DateCreated, DateModified, Impact, InteractionGroup, InteractionType, Priority, InteractionStatus, TrackingNumber, Urgency, InteractionLocation, InteractionPurpose } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import DropdownCommon2 from '../../../../Common/Dropdown/index-d';
import { InteractionPriorityUri, InteractionResolutionStatusUri } from '../../../../../api';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const InteractionInfo = ({ details, priorities, resolutionStatuses, onInteractionUpdate }) => {
    const [isFilter, setIsFilter] = useState(true);
    
    const handleResolutionStatusUpdate = async (id) => {
        const methodName = `${InteractionResolutionStatusUri}`;
        const payload = { resolution_status_id : id};
        
        await onInteractionUpdate(methodName, payload);
    };

    const handlePriorityUpdate = async (id) => {
        const methodName = `${InteractionPriorityUri}`;
        const payload = { priority_id : id};
        
        await onInteractionUpdate(methodName, payload);
    };

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >&nbsp;
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                            <Table>
                                <tbody>
                                    <tr className=''>
                                        <th scope='row'>{TrackingNumber}</th>
                                        <td>{details.track_number}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{InteractionGroup}</th>
                                        <td>{details?.interaction_type?.interaction_group?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{InteractionType}</th>
                                        <td>{details?.interaction_type?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{InteractionLocation}</th>
                                        <td>{details?.interaction_location?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{InteractionPurpose}</th>
                                        <td>{details?.interaction_purpose?.name}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{InteractionStatus}</th>
                                        <td>
                                            <span style={{color: details?.resolution_status?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-bell"></i> {details?.resolution_status?.name}</span>
                                                { !details.resolved_at &&
                                                <DropdownCommon2 
                                                    dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                    options={resolutionStatuses} 
                                                    iconName='icon-angle-down' 
                                                    btn={{ tag: 'span' }} 
                                                    onClick={handleResolutionStatusUpdate} />}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Impact}</th>
                                        <td>
                                            <span style={{color: details?.priority?.impact?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.impact?.name}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Urgency}</th>
                                        <td>
                                            <span style={{color: details?.priority?.urgency?.color}} className='d-flex'>
                                                <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.urgency?.name}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Priority}</th>
                                        <td>
                                            <span style={{color: details?.priority?.color}} className='d-flex'>
                                             <span className='m-r-5'><i className="fa fa-flag"></i> {details?.priority?.name}</span>
                                             { !details.resolved_at && (<DropdownCommon2 
                                                dropdownMain={{ className: 'icon-dropdown mt-1', direction: 'start' }} 
                                                options={priorities} 
                                                iconName='icon-angle-down' 
                                                btn={{ tag: 'span' }}
                                                onClick={handlePriorityUpdate} />)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DateCreated}</th>
                                        <td>{formatDateWithTimeZone(details.created_at)}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DateModified}</th>
                                        <td>{formatDateWithTimeZone(details.updated_at)}</td>
                                    </tr>
                                 </tbody>
                            </Table>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default InteractionInfo;