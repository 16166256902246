import { ApplySharedServiceFee, BusinessSegment, Department, Description, Division, EmailAddress, FeeAmount, ForeignAffliate, LegalName, LocationType, Logo, Name, NearestLandmark, Organization, OrganizationType, PhoneNumber, Region, Registration, RegistrationDate, RegistrationNo, SharedServiceFee, ShortName, Status, StreetName, StreetNo, TownCity, Website, Zone } from "../../../Constant";
import { BusinessSegmentApi, CompanyApi, DepartmentApi, DivisionApi, LocationTypeApi, OrgZoneApi, OrganisationTypeApi, RegionApi } from "../../../api";
import axios from "../../../api/axios";

export const org_form_fields = [
    {
      id: 'full_name',
      name: 'full_name',
      label: `${LegalName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
        id: 'shortname',
        name: 'shortname',
        label: `${ShortName}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
      id: 'foreign_affliation',
      name: 'foreign_affliation',
      label: `${ForeignAffliate}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
    {
      id: 'description',
      name: 'description',
      label: `${Description}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: 6,
    },
    {
        id: 'is_registered',
        name: 'is_registered',
        label: `${Registration}`,
        type: 'radio',
        value: '',
        required: true,
        colSize: 6,
        options: [
            {'label': 'Yes', 'value': 1},
            {'label': 'No', 'value': 0},
        ],
    },
    {
        id: 'registration_number',
        name: 'registration_number',
        label: `${RegistrationNo}`,
        type: 'text',
        value: '',
        required: false,
        colSize: 6,
    },
    {
        id: 'registration_date',
        name: 'registration_date',
        label: `${RegistrationDate}`,
        type: 'date',
        value: '',
        required: false,
        colSize: 6,
    },
    {
        id: 'email_address',
        name: 'email_address',
        label: `${EmailAddress}`,
        type: 'email',
        value: '',
        required: true,
        colSize: 6,
    },
    {
        id: 'phone_number',
        name: 'phone_number',
        label: `${PhoneNumber}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
        id: 'website',
        name: 'website',
        label: `${Website}`,
        type: 'url',
        value: '',
        required: false,
        colSize: 6,
    },
    {
        id: 'logo',
        name: 'logo',
        label: `${Logo}`,
        type: 'file',
        value: '',
        required: false,
        colSize: 6,
    },
    {
        type: 'group-title',
        label: `${SharedServiceFee}`,
    },
    {
        id: 'applied_shared_fee',
        name: 'applied_shared_fee',
        label: `${ApplySharedServiceFee}`,
        type: 'radio',
        value: '',
        required: true,
        colSize: 6,
        options: [
            {'label': 'Yes', 'value': 1},
            {'label': 'No', 'value': 0},
        ],
    },
    {
        id: 'fee_amount',
        name: 'fee_amount',
        label: `${FeeAmount}`,
        type: 'number',
        value: '',
        required: false,
        colSize: 6,
    },
    /* {
        id: 'street_number',
        name: 'street_number',
        label: `${StreetNo}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'street_name',
        name: 'street_name',
        label: `${StreetName}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'nearest_landmark',
        name: 'nearest_landmark',
        label: `${NearestLandmark}`,
        type: 'text',
        value: '',
        required: true,
    },
    {
        id: 'city',
        name: 'city',
        label: `${TownCity}`,
        type: 'text',
        value: '',
        required: true,
    }, */
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
];

/* export const org_type_dropdown_field = {
    id: 'organisation_type_id',
    name: 'organisation_type_id',
    label: `${OrganizationType}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions() {
      try {
        const response = await axios.get(OrganisationTypeApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching sector options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const location_type_dropdown_field = {
    id: 'location_type_id',
    name: 'location_type_id',
    label: `${LocationType}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions() {
      try {
        const response = await axios.get(LocationTypeApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching sector options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_dropdown_field = {
    id: 'organisation_id',
    name: 'organisation_id',
    label: `${Organization}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions() {
      try {
        const response = await axios.get(CompanyApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.full_name }));
      } catch (error) {
        console.error('Error fetching company options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_division_dropdown_field = {
    id: 'division_id',
    name: 'division_id',
    label: `${Division}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id) {
      try {
        const response = await axios.get(DivisionApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching division options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_dept_dropdown_field = {
    id: 'department_id',
    name: 'department_id',
    label: `${Department}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id, division_id) {
      try {
        const response = await axios.get(DepartmentApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching department options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_zone_dropdown_field = {
    id: 'zone_id',
    name: 'zone_id',
    label: `${Zone}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id) {
      try {
        const response = await axios.get(OrgZoneApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching zone options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

 /*  export const org_region_dropdown_field = {
    id: 'region_id',
    name: 'region_id',
    label: `${Region}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id, zone_id) {
      try {
        const response = await axios.get(RegionApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching region options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */

/*   export const org_business_segment_zone_dropdown_field = {
    id: 'business_segment_id',
    name: 'business_segment_id',
    label: `${BusinessSegment}`,
    type: 'select',
    required: true,
    options: [], 
    async fetchOptions(organisation_id) {
      try {
        const response = await axios.get(BusinessSegmentApi);
        
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error('Error fetching zone options:', error);
      }
    },
    // onChange: (value) => console.log(value), // handle the change event here
  }; */