import { BandwidthCost } from "../../../Constant";
import { Rate } from "../../../Constant";

export const bandwidth_cost_field = (colSize) => ({
    id: 'bandwidth_cost',
    name: 'bandwidth_cost',
    label: `${BandwidthCost}`,
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  });


export function rate_field(colSize) {
  return ({
    id: 'rate',
    name: 'rate',
    label: `${Rate}`,
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  });
}