import React, { Fragment } from "react";
import sad from '../../../assets/images/other-images/sad.png';
import { Container , Media} from 'reactstrap'
import { H2, H4 } from "../../../AbstractElements";

const NotActivated = () => {
    return (
        <Fragment>
            <section className="maintenance-sec">
                <div className="page-wrapper">
                    <div className="error-wrapper maintenance-bg">
                        <Container>
                            <Media body className="img-100" style={{width: '350px !important'}} src={sad} alt="" />
                            <div className="maintenance-heading">
                                <H2 attrH2={{ className: "headline" }} >Account Not Activated</H2>
                            </div>
                            <H4 attrH4={{ className: "sub-content" }} >Sorry, profile is not successfully activated<br />Kindly contact your administrator</H4>
                            <div><a className="btn btn-primary btn-lg text-light" href="/">BACK TO LOGIN</a></div>
                        </Container>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default NotActivated;