import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  ExpenseGroup,
  Description,
  ExpenseCategory,
  Status,
  Name,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { financesExpenseCategoryApi } from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useExpenseGroupController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();
  const formTitle = ExpenseGroup;

  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const expenseCategory = createDropdownField(
    axiosPrivate,
    "expense_category_id",
    "expense_category_id",
    ExpenseCategory,
    `${financesExpenseCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [expenseCategory, ...formFields];
    await expenseCategory.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-group/${layoutURL}`;

  const onSubmit = async (data: any) => {
  
    data.organisation_id = userOrganisationId;
    await axiosPrivate
        .post("/finances/expense-groups", data)
        .then((_res) => {
          toast.success(`${_res.data.name} Created Successfully.`)
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
            toast.error('Validation Errors');
            } else if (err.response && err.response.status === 400) {
            toast.error(err.response.data.detail);
            } else {
            toast.error('Something Went Wrong');
            }
            throw err;
        });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
