import React from 'react'
import PGForm from './Form/PGForm'

function CreatePage() {
    return (
        <div>
            <PGForm />
        </div>
    )
}

export default CreatePage