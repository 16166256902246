import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const history = useNavigate();

    const refresh = async () => {
        try{
            const response = await axios.post('/auth/refresh', {}, {
                withCredentials: true
            });

            if (response.data.access_token) {
                setAuth(prev => {
                    //console.log(JSON.stringify(prev));
                    //console.log(JSON.stringify(response.data));
                    //console.log(response.data.access_token);
                    const { access_token, permissions, users, menus } = response.data;

                    return { 
                        ...prev, 
                        accessToken: access_token,
                        permissions: permissions,
                        users: users,
                        menus: menus
                    };
                });

                return response.data.access_token;
            } else {
                // No access token received, redirect to login
                history('/login');
                return null;
            }

        } catch (error) {
            // Handle any errors here
            //console.error('Error refreshing token:', error);
            // Redirect to login page on error
            //history.push('/login');
            history('/login');
            return null;
        }
    }
    return refresh;
};

export default useRefreshToken;