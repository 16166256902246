import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, Table, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Description, ProductClass, Speed, InvoiceType, InvoiceCycle, CreditTerm, Tenor, ProductType, Status, Negotiable, TaxInclusive, ProductGroup } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ProductTypeApi, ProductClassApi, InvoiceTypeApi, InvoiceCycleApi, CreditTermApi, TenorApi, ProductGroupApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { createDropdownField} from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import { useNavigate } from 'react-router-dom';
import { productType_form_fields } from './FormType/ProductType'; 
import useAuth from '../../../../Hooks/useAuth';

const ProductTypeList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const history = useNavigate();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [formFields, setFormFields] = useState(productType_form_fields(`${ProductType}`, 6));
  
  const productGroupDropdownField = createDropdownField(axiosPrivate,
    'product_group_id',
    'product_group_id',
    `${ProductGroup}`,
    `${ProductGroupApi}?organisation_id=${userOrganisationId}`,
    '',
    6
  );

  const productClassDropdownField = createDropdownField(axiosPrivate,
    'product_class_id',
    'product_class_id',
    `${ProductClass}`,
    `${ProductClassApi}?organisation_id=${userOrganisationId}`,
    '',
    6
  );

const invoiceTypeDropdownField = createDropdownField(axiosPrivate,
    'invoice_type_id',
    'invoice_type_id',
    `${InvoiceType}`,
    `${InvoiceTypeApi}?organisation_id=${userOrganisationId}`,
    '',
    6
);

const invoiceCycleDropdownField = createDropdownField(axiosPrivate,
    'invoice_cycle_id',
    'invoice_cycle_id',
    `${InvoiceCycle}`,
    `${InvoiceCycleApi}?organisation_id=${userOrganisationId}`,
    '',
    6
);

const creditTermDropdownField = createDropdownField(axiosPrivate,
    'credit_term_id',
    'credit_term_id',
    `${CreditTerm}`,
    `${CreditTermApi}?organisation_id=${userOrganisationId}`,
    '',
    6
);


useEffect(() => {

    const fetchData = async () => {
        try {
            if (!isNewFieldAdded) {
            
            const updatedFields = [productGroupDropdownField, productClassDropdownField, invoiceTypeDropdownField, invoiceCycleDropdownField, creditTermDropdownField, ...formFields];
    
            await updatedFields[0].fetchOptions();
            await invoiceTypeDropdownField.fetchOptions();
            await invoiceCycleDropdownField.fetchOptions();
            await creditTermDropdownField.fetchOptions();
            
            setFormFields(updatedFields);

            setIsNewFieldAdded(true);
            }
        } catch (error) {
            //console.error('Error fetching options:', error);
        }
    };

    fetchData();
}, [formFields, isNewFieldAdded, productClassDropdownField]); 

productGroupDropdownField.onChange = async (selectedPGId, updatedFields) => { 
  const PCField = updatedFields.find((field) => field.id === 'product_class_id');
  
  if (PCField) {
    const endPoint = `${ProductClassApi}?organisation_id=${userOrganisationId}&product_group_id=${selectedPGId}`
    await PCField.fetchOptions(selectedPGId, false, endPoint);
    setFormFields([...updatedFields]);
  } 
};

  const getAllProductGroupsData = async () => {
    try {
          await axiosPrivate.get(`${ProductTypeApi}?organisation_id=${userOrganisationId}`).then((resp) => {
            setAllData(resp.data);
            //setIsLoading(false);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
        //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
};

useEffect(() => {
  const abortController = new AbortController();
  getAllProductGroupsData();

  return () => {
    abortController.abort();
  };
}, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.product_group_id = row.product_class.product_group_id
    productGroupDropdownField.onChange(row.product_group_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
            organisation_id: userOrganisationId,
            product_class_id: editRecord.product_class_id,
            name: editRecord.name,
            description: editRecord.name,
            invoice_type_id: editRecord.invoice_type_id,
            invoice_cycle_id: editRecord.invoice_cycle_id,
            credit_term_id: editRecord.credit_term_id,
            is_negotiable: editRecord.is_negotiable,
            is_tax_inclusive: editRecord.is_tax_inclusive,
            tenor: editRecord.tenor,
            speed: editRecord.speed,
    };
    
    try {
        await axiosPrivate.put(`${ProductTypeApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ProductTypeApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/sales/settings/product-types/create/${layoutURL}`;
  
  const tableColumns = [
    {
        name: `${ProductType}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${ProductClass}`,
      selector: row => `${row.product_class.name}`,
      sortable: true,
      center: false,
  },
  {
    name: `${Speed}`,
    selector: row => `${row.speed}`,
    sortable: true,
    center: false,
},
{
  name: `${Tenor}`,
  selector: row => `${row.tenor}`,
  sortable: true,
  center: false,
},
    {
      name: 'Status',
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Sales" title="Product Types" mainTitle="Product Types" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
            <Table>
                <tbody>
                <tr className='border-bottom-primary'>
                    <th scope='row'>{ProductClass}</th>
                    <td>{selectedRowData.product_class.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{ProductType}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{InvoiceType}</th>
                    <td>{selectedRowData.invoice_type.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{InvoiceCycle}</th>
                    <td>{selectedRowData.invoice_cycle.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{CreditTerm}</th>
                    <td>{selectedRowData.credit_term.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Negotiable}</th>
                    <td>{selectedRowData.is_negotiable ? <span className="badge badge-light-success">Yes</span> : <span className="badge badge-light-danger">No</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{TaxInclusive}</th>
                    <td>{selectedRowData.is_tax_inclusive ? <span className="badge badge-light-success">Yes</span> : <span className="badge badge-light-danger">No</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Speed}</th>
                    <td>{selectedRowData.speed}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Tenor}</th>
                    <td>{selectedRowData.tenor}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default ProductTypeList;