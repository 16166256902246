import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { ContactTypeVal, createDropdownField } from '../../../../../Data/FormField/PublicData';
import { ContactType, Gender, MaritalStatus, Salutation } from '../../../../../Constant';
import { ContactBasicInfoUri, ContactMgmtApi, ContactTypeApi, GenderApi, MaritalStatusApi, SalutationApi } from '../../../../../api';
import { contact_form_fields } from '../../../../../Data/FormField/CrmData';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { toast } from 'react-toastify';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const BasicInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields, setFormFields] = useState(contact_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const contactTypeDropdownField = createDropdownField(axiosPrivate,
    'contact_type_id',
    'contact_type_id',
    `${ContactType}`,
    `${ContactTypeApi}`,
    '', 6
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const salutation_dropdown_field = createDropdownField(axiosPrivate,
            'salutation_id',
            'salutation_id',
            `${Salutation}`,
            `${SalutationApi}`,
          );

          const gender_dropdown_field = createDropdownField(axiosPrivate,
            'gender_id',
            'gender_id',
            `${Gender}`,
            `${GenderApi}`,
          );

          const marital_dropdown_field = createDropdownField(axiosPrivate,
            'marital_status_id',
            'marital_status_id',
            `${MaritalStatus}`,
            `${MaritalStatusApi}`,
          );

          const updatedFields = [...formFields ];

          const indexOfLN = updatedFields.findIndex((updatedField) => updatedField.id === 'last_name');
          updatedFields.splice(indexOfLN, 0, salutation_dropdown_field);

          const indexOfDob = updatedFields.findIndex((updatedField) => updatedField.id === 'dob');
          updatedFields.splice(indexOfDob + 1, 0, gender_dropdown_field, marital_dropdown_field);
          
          rowData.email = rowData.user_login.email;
          rowData.contact_type_id = rowData.contact_type;
          rowData.is_active = rowData.user_login.is_active;
          rowData.dob = formatDateWithTimeZone(rowData.date_of_birth, "", "YYYY-MM-DD");

          //await updatedFields[0].fetchOptions();
          await salutation_dropdown_field.fetchOptions();
          await marital_dropdown_field.fetchOptions();
          await gender_dropdown_field.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, contactTypeDropdownField]);

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      email: editRecord.email,
      is_active: editRecord.is_active,
      contact: {
          contact_type: editRecord.contact_type_id,
          salutation_id: editRecord.salutation_id,
          last_name: editRecord.last_name,
          first_name: editRecord.first_name,
          middle_name: editRecord.middle_name,
          maiden_name: editRecord.maiden_name,
          gender_id: editRecord.gender_id,
          marital_status_id: editRecord.marital_status_id,
          phone_number: editRecord.phone_number,
          nin: editRecord.nin,
          date_of_birth: editRecord.dob ? editRecord.dob : "0000-00-00",

          ...(editRecord.contact_type_id === `${ContactTypeVal.Corporate}` && {
            company_name: editRecord.company_name,
            company_short_name: editRecord.company_short_name,
            foreign_affliation: editRecord.foreign_affliation,
            is_registered: editRecord.is_registered,
            registration_number: editRecord.registration_number,
            registration_date:  editRecord.registration_date ? editRecord.registration_date : "0000-00-00",
          }),
      }
    };
    
    try {
        await axiosPrivate.put(`${ContactMgmtApi}/${id}/${ContactBasicInfoUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.last_name} ${resp.data.first_name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='basic-info'>
        <Row>
          <Col sm='12'>
            <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default BasicInformation;