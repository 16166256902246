const api = `${process.env.PUBLIC_URL}/api`;

const api2 = ``;
//============ PUBLIC DATA ==============
export const SalutationApi = `${api2}/public-data/salutations`;
export const GenderApi = `${api2}/public-data/genders`;
export const MaritalStatusApi = `${api2}/public-data/marital-statuses`;
export const RelationshipApi = `${api2}/public-data/relationships`;
export const MedicalAilmentApi = `${api2}/public-data/medical-ailments`;
export const BloodGroupApi = `${api2}/public-data/blood-groups`;
export const GenotypeApi = `${api2}/public-data/genotypes`;
export const CurrencyApi = `${api2}/public-data/currencies`;
export const ReligionApi = `${api2}/public-data/religions`;
export const ContactTypeApi = `${api2}/public-data/contact_types`;
export const SectorApi = `${api2}/public-data/sectors`;
export const SubSectorApi = `${api2}/public-data/sub-sectors`;
export const RegionalContinentApi = `${api2}/public-data/continents`;
export const RegionalCountryApi = `${api2}/public-data/countries`;
export const RegionalStateApi = `${api2}/public-data/states`;
export const RegionalLgaApi = `${api2}/public-data/lgas`;
export const ZoneApi = `${api2}/public-data/zones`;
export const WardApi = `${api2}/public-data/wards`;
export const BankTypeApi = `${api2}/public-data/bank-types`;
export const BankApi = `${api2}/public-data/banks`;
export const PfcApi = `${api2}/public-data/pfcs`;
export const PfaApi = `${api2}/public-data/pfas`;
export const PfaBankAccountApi = `${api2}/public-data/pfa-bank-accounts`;
export const TaxAuthorityApi = `${api2}/public-data/tax-authorities`;
export const JobTitleApi = `${api2}/public-data/job-titles`;
export const AccountVerifyApi = `${api2}/users/verify`;
export const ResetPasswordApi = `${api2}/auth/reset-password`;

//============ ORGANISATION ==============
export const OrganisationTypeApi = `${api2}/organisations/types`;
export const LocationTypeApi = `${api2}/organisations/location-types`;
export const CompanyApi = `${api2}/organisations/companies`;
export const DivisionApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/divisions`;
};
export const DepartmentApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/departments`;
};
export const UnitApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/units`;
};
export const CostCentreCategoryApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/cost-centre-categories`;
};
export const CostCentreApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/cost-centres`;
};
export const OrgZoneApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/zones`;
};    
export const RegionApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/regions`;
};    
export const BranchApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/branches`;
};  
export const StoreApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/stores`;
};  
export const LocationApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/locations`;
}; 
export const LocationDropdownApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/locations-dropdown`;
};    
export const BusinessSegmentApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/business-segments`;
};    
export const SubBusinessSegmentApi = (org_id) => {
    return `${api2}/organisations/companies/${org_id}/structures/sub-business-segments`;
};    

//============ CRM ==============
export const ContactMgmtApi = `${api2}/crm/contacts`;
export const ContactBranchApi = `${api2}/crm/contacts/branches`;
export const ContactCorporateInfoUri = `corporate-information`;
export const ContactBranchInfoUri = `branch-information`;
export const ContactBasicInfoUri = `basic-information`;
export const ContactNationalityInfoUri = `nationality`;
export const ContactElectronicAddressUri = `electronic-address`;
export const ContactPhysicalAddressUri = `physical-address`;
export const PriorityApi = `${api2}/crm/service-managements/priorities`;
export const ImpactApi = `${api2}/crm/service-managements/impacts`;
export const UrgencyApi = `${api2}/crm/service-managements/urgencies`;
export const MessageGroupApi = `${api2}/crm/message-groups`;
export const MessageTypeApi = `${api2}/crm/message-types`;
export const ResolutionStatusApi = `${api2}/crm/service-managements/resolution-statuses`;
export const SlaApi = `${api2}/crm/service-managements/slas`;
export const ServiceTypeApi = `${api2}/crm/service-managements/service-types`;
export const CaseGroupApi = `${api2}/crm/service-managements/case-groups`;
export const CaseTypeApi = `${api2}/crm/service-managements/case-types`;
export const CaseApi = `${api2}/crm/service-managements/cases`;
export const CaseResolutionStatusUri = 'update-resolution-status';
export const CasePriorityUri = 'update-priority';
export const CaseAssignedToUri = 'update-assigned-to';
export const CaseDelegateToTeamUri = 'update-delegate-team';
export const CaseDelegateToTeamMemberUri = 'update-delegate-team-member';
export const CaseEscalateUri = 'escalate';
export const CaseResolutionResolvedUri = 'update-resolution-resolved';
export const RootCauseApi = `${api2}/crm/service-managements/root-causes`;
export const ResolutionCodeApi = `${api2}/crm/service-managements/resolution-codes`;

export const CaseGroupSLAReportApi = `${api2}/crm/service-managements/cases/report/group_sla_statistics`;

export const EscalationCategoryApi = `${api2}/crm/service-managements/escalation-categories`;
export const EscalationReasonApi = `${api2}/crm/service-managements/escalation-reasons`;
export const InteractionApi = `${api2}/crm/service-managements/interactions`;
export const InteractionGroupApi = `${api2}/crm/interaction-groups`;
export const InteractionTypeApi = `${api2}/crm/interaction-types`;
export const InteractionLocationApi = `${api2}/crm/interaction-locations`;
export const InteractionPurposeApi = `${api2}/crm/interaction-purposes`;
export const InteractionResolutionStatusUri = 'update-resolution-status';
export const InteractionPriorityUri = 'update-priority';

export const ApplicationApi = `${api2}/access-controls/applications`;
export const ModuleApi = `${api2}/access-controls/modules`;
export const UserTypeApi = `${api2}/access-controls/user-types`;
export const CategoryApi = `${api2}/access-controls/access-categories`;
export const GroupApi = `${api2}/access-controls/access-groups`;
export const TaskApi = `${api2}/access-controls/tasks`;
export const RouteDetailApi = `${api2}/access-controls/route-details`;
export const userTypeApi = `${api2}/access-controls/user-types`;
export const accessGroupsApi = `${api2}/access-controls/access-groups`;
export const accessGroupTaskApi = `${api2}/access-controls/groups`;

//============ ADMINISTRATION ==============
export const InventoryCategoryApi = `${api2}/public-data/salutations`;
export const InventoryClassApi = `${api2}/public-data/salutations`;
export const InventoryGroupApi = `${api2}/public-data/salutations`;
export const InventoryDescriptionApi = `${api2}/public-data/salutations`;
export const InventoryTypeApi = `${api2}/public-data/salutations`;
export const FixedAssetsGroupApi = `${api2}/public-data/salutations`;
export const FixedAssetsClassApi = `${api2}/public-data/salutations`;
export const FixedAssetsDescriptionApi = `${api2}/public-data/salutations`;
export const FixedAssetsTypeApi = `${api2}/public-data/salutations`;
export const SpouseApi = `${api2}/public-data/salutations`;
export const NextOfKinApi = `${api2}/public-data/salutations`;
export const PaymentOnDeathAPi = `${api2}/public-data/salutations`;

//============ EMPLOYEE ==============
export const EmployeeCategoryApi = `${api2}/hrm/employee-categories`;
export const EmployeeApi = `${api2}/hrm/employees`;
export const TeamTypeApi = `${api2}/hrm/team-types`;
export const TeamApi = `${api2}/hrm/teams`;
export const TeamMemberApi = `${api2}/hrm/team-members`;

//============ SALES ==============
export const CustomerApi = `${api2}/sales/customers`;
export const CustomerLinkApi = `${api2}/sales/customers/links`;
export const SalesLinkApi = `${api2}/sales/links`;
export const ProductGroupApi = `${api2}/sales/product-groups`;
export const ProductTypeApi = `${api2}/sales/product-types`;
export const ProductClassApi = `${api2}/sales/product-classes`;
export const ProductApi = `${api2}/sales/products`;
export const CapacityTypeApi = `${api2}/sales/capacity-types`;
export const CapacityApi = `${api2}/sales/capacities`;
export const PaymentApi = `${api2}/payments`;

export const CaseCategoryApi = `${api2}/public-data/salutations`;
export const IssueGroupApi = `${api2}/public-data/salutations`;
export const InvoiceTypeApi = `${api2}/sales/invoice-types`;
export const InvoiceCycleApi = `${api2}/sales/invoice-cycles`;
export const CreditTermApi = `${api2}/sales/credit-terms`;
export const TaxApi = `${api2}/sales/taxes`;

export const LinkTypeApi = `${api2}/technical/devices/link-types`;
export const LinkApi = `${api2}/sales/links`;
export const AccessNamesApi = `${api2}/technical/devices/access-names`;
export const DeviceInventoryApi = `${api2}/public-data/salutations`;
export const MakersApi = `${api2}/technical/devices/makers`;
export const ModelsApi = `${api2}/technical/devices/models`;
export const RadioTypesApi = `${api2}/technical/devices/radio-types`;
export const SwitchTypesApi = `${api2}/technical/devices/switch-types`;

export const PopApi = `${api2}/public-data/salutations`;
export const CustomerBranchApi = `${api2}/public-data/salutations`;

export const financesReportTypeApi = `${api2}/finances/report-types`;

export const financesGeneralLedgerGroupApi = `${api2}/finances/general-ledger-groups`;
export const financesGeneralLedgerClassApi = `${api2}/finances/general-ledger-classes`;
export const financesGeneralLedgerTypeApi = `${api2}/finances/general-ledger-types`;
export const financesGeneralLedgerAccountApi = `${api2}/finances/general-ledger-accounts`;

export const financesGroupGeneralLedgerNumberApi = `${api2}/finances/group-general-ledger-numbers`;
export const financesGroupGeneralLedgerNumber = `${api2}/finances/group-general-ledger-numbers`;
export const financesGroupGeneralLedgerClassApi = `${api2}/finances/group-general-ledger-classes`;
export const financesGroupGeneralLedgerTypeApi = `${api2}/finances/group-general-ledger-types`;

export const financeSubLedgerClassApi = `${api2}/finances/sub-ledger-classes`;
export const financeSubLedgerTypeApi = `${api2}/finances/sub-ledger-types`;
export const financeSubLedgerAccountApi = `${api2}/finances/sub-ledger-accounts`;


//============ EXPENSE ==============
export const financesExpenseCategoryApi = `${api2}/finances/expense-categories`;
export const financesExpenseGroupApi = `${api2}/finances/expense-groups`;
export const financesExpenseClassApi = `${api2}/finances/expense-classes`;
export const financesExpenseTypeApi = `${api2}/finances/expense-types`;
export const financesExpenseDescriptionApi = `${api2}/finances/expense-descriptions`;

//============ INCOME ==============
export const financesIncomeCategoryApi = `${api2}/finances/income-categories`;
export const financesIncomeGroupApi = `${api2}/finances/income-groups`;
export const financesIncomeClassApi = `${api2}/finances/income-classes`;
export const financesIncomeTypeApi = `${api2}/finances/income-types`;
export const financesIncomeDescriptionApi = `${api2}/finances/income-descriptions`;






