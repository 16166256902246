import React, { Fragment, useContext } from 'react';
import { VDTEMAIL, VDTNAME } from '../../Constant';
import { Link } from 'react-router-dom';
import { H6, Image, P } from '../../AbstractElements';
import CustomizerContext from '../../_helper/Customizer';

const CompanyProfile = ({profile}) => {
    const { layoutURL } = useContext(CustomizerContext);
    return (
        <Fragment>
            <div className="media align-items-center">
                <div className="media-size-email">
                    <Image attrImage={{ className: 'me-3 rounded-circle', src: `${profile.imgSrc}`, alt: '' }} />
                </div>
                <div className="media-body">
                    <Link to={`${profile.link}`}>
                        <H6 attrH6={{ className: 'f-w-600' }} >{profile.name}</H6></Link>
                    <P>{profile.email}</P>
                </div>
            </div>
        </Fragment>
    );
};

export default CompanyProfile;