import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Description, GroupName, Priority, ServiceType, Status, description } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CaseGroupApi, ServiceTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';

const CaseGroupList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${GroupName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const serviceTypeDropdown = createDropdownField(axiosPrivate,
    'service_type_id',
    'service_type_id',
    `${ServiceType}`,
    `${ServiceTypeApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [serviceTypeDropdown, ...formFields];

          await updatedFields[0].fetchOptions();

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded]);



  const getAllCaseGroupsData = async () => {
    try {
      await axiosPrivate.get(`${CaseGroupApi}?organisation_id=${userOrganisationId}`).then((resp) => {
        setAllData(resp.data);
        //setIsLoading(false);
      });
    } catch (error) {
      toast.error(error.response.data.detail)
      //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllCaseGroupsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      organisation_id: userOrganisationId,
      name: editRecord.name,
      description: editRecord.description,
      service_type_id: editRecord.service_type_id,
      is_active: editRecord.is_active,
    };

    try {
      await axiosPrivate.put(`${CaseGroupApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${CaseGroupApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/settings/case-group/create/${layoutURL}`;

  const tableColumns = [
    {
      name: `${ServiceType}`,
      selector: row => `${row.service_type.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${GroupName}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Case Group " mainTitle="Case Group" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{ServiceType}</th>
                    <td>{selectedRowData.service_type.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{GroupName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default CaseGroupList;