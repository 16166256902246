import React, { Fragment, useContext, useState, useMemo, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import { Breadcrumbs, Btn, Image } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BusinessSegmentApi, ContactMgmtApi, CustomerApi } from '../../../../api';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { CustomerForMapping, ContactID, ContactSearch, ContactType, Email, FullName, MapContactCustomer, MapCustomer, Status, BusinessSegment } from '../../../../Constant';
import FindContact from '../../../Common/Component/FindContact';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import HeaderCard from '../../../Common/Component/HeaderCard';
import noimage from '../../../../assets/images/no-image.png';
import ViewModal from '../../../Common/Component/ViewModal';
import useAuth from '../../../../Hooks/useAuth';

const CustomersMapping = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/sales/customers/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const contact_form_fields2 = [
    /* {
      type: 'group-title',
      label: `${CompanyInformation}`,
    }, */
    {
      id: 'segments',
      name: 'segments',
      label: `${BusinessSegment}`,
      type: 'checkbox2',
      value: '',
      required: true,
      colSize: 12,
      minSelection: 1,
      options: []
    },
  ];

  const contact_form_fields3 = [
    /* {
      type: 'group-title',
      label: `${CompanyInformation}`,
    }, */
    {
      id: 'segments2',
      name: 'segments2',
      label: `${BusinessSegment}`,
      type: 'checkbox2',
      value: '',
      required: true,
      colSize: 12,
      minSelection: 2,
      options: [
        { value: 'technology', label: 'Technology' },
        { value: 'finance', label: 'Finance' },
        { value: 'healthcare', label: 'Healthcare' },
        { value: 'education', label: 'Education' },
      ]
    },
  ];

  const [formFields, setFormFields] = useState(contact_form_fields2);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const addBtn = ""; //`${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;

  /* const businessSegmentDropdownField = createDropdownField(axiosPrivate,
    'business_segment_id',
    'business_segment_id',
    `${BusinessSegment}`,
    `${BusinessSegmentApi(userOrganisationId)}`,
    '', 12
  ); */

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [...formFields]; //, ...contact_form_fields3
          const indexOfSegment = updatedFields.findIndex((updatedField) => updatedField.id === 'segments');

          try {
            await axiosPrivate.get(`${BusinessSegmentApi(userOrganisationId)}`).then((resp) => {
              resp.data.forEach(option => {
                updatedFields[indexOfSegment].options.push({ value: option.id, label: option.name });
              });
            });
          } catch (error) { }
          
          //await businessSegmentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded]);
  
  const handleSubmit = async (searchValue) => {
    setIsLoading(true);
    try {
      await axiosPrivate.get(`${ContactMgmtApi}?organisation_id=${userOrganisationId}&search_value=${searchValue}&is_customer=false`).then((resp) => {
        setAllData(resp.data.items);

        toast.success(`Contact Found.`)
      });
    } catch (error) {
        setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const openCustomerMapView = async (row) => {
    setViewModalOpen(true);
    setEditMode(true);
    row.is_active = row.user_login.is_active
    setSelectedRowData(row);
  }

  const handleCustomerMapping = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    let segments = []
    if (editRecord.segments && Array.isArray(editRecord.segments)) {
      segments = editRecord.segments 
      .filter(segment => segment !== null)
      .map(segment => {
        return { "business_segment_id": parseInt(segment) }; 
      });
    }

    const editRecordTemp = {
      contact_id: id,
      is_active: true,
      segments: segments
    };

    try {
      await axiosPrivate.post(`${CustomerApi}/map-contact`, editRecordTemp).then((resp) => {
        toast.success(`Contact is successfully mapped as Customer.`)
        history(listUrl);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const memoizedHandleEdit = useMemo(() => handleCustomerMapping, [allData, selectedRowData]);

  const customerMapAction = [
    {
      label: `${MapCustomer}`,
      action: openCustomerMapView,
      color: 'warning',
      icon: 'icon-book',
    },
  ];

  const tableColumns = [
    {
      name: `${FullName}`,
      selector: row => row.last_name,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
                          <Media body className="align-self-center">
                              <div>{row.last_name} {row.first_name} {row.middle_name}</div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    {
      name: `${ContactID}`,
      selector: row => row.contact_number,
      sortable: true,
      center: false,
    },
    {
      name: `${Email}`,
      selector: row => row.user_login.email,
      sortable: true,
      center: false,
    },
    /* {
      name: `${PhoneNumber}`,
      selector: row => `${row.phone_number}`,
      sortable: true,
      center: false,
    }, */
    {
      name: `${ContactType}`,
      selector: row => row.contact_type == 1 ? "Individual" : "Corporate",
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.user_login.is_active,
      sortable: true,
      center: false,
      cell: row => (
        <>
          {row.user_login.is_active ? (
            <span className="badge badge-light-success">Active</span>
          ) : (
            <span className="badge badge-light-danger">Inactive</span>
          )}
          {row.user_login.email_verified_at ? (
            <span className="badge badge-light-success">Verified</span>
          ) : (
            <span className="badge badge-light-danger">Not Verified</span>
          )}
        </>
      ),
    },
    /* {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    }, */
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="Find Contact" mainTitle="Find Contact" />
      <Container fluid={true}>
        <Row className='learning-block'>
          
          <Col xl='3' className='xl-40 box-col-12 learning-filter order-1 order-xl-1'>
            <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>{ContactSearch}</Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc'>
                  <Row>
                    <FindContact onSubmit={handleSubmit} />
                  </Row>
                </div>
              </div>
            </div>
          </Col>

          <Col xl='9' className='xl-60 box-col-8 order-2 order-xl-2'>
            <Row>
              <Col sm="12">
                <Card>
                  <HeaderCard title={CustomerForMapping} btnUrl={addBtn} />
                  <CardBody>
                    <DataTableComponent
                      tabledata={allData}
                      tableColumns={tableColumns}
                      isLoading={isLoading}
                      useTableAction={customerMapAction}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          modalTitle={MapContactCustomer}
        >

        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default CustomersMapping;