import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import cubaimg from "../../assets/images/logo/logo-icon.png"
import CustomizerContext from '../../_helper/Customizer';
import { H4 } from '../../AbstractElements';
import { Cuba } from '../../Constant';

const SidebarIcon = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`}>
        <H4>{Cuba}</H4>
        {/* <img
          className="img-fluid"
          src={cubaimg}
          alt=""
        /> */}
      </Link>
    </div>
  );
};

export default SidebarIcon;