import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form'; 
import { Fragment } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Card} from 'reactstrap';
import {CardBody} from 'reactstrap';
import { BackToList, Makers } from '../../../../Constant'; 
import { RadioTypes } from '../../../../Constant';  
import GenerateForm from '../../../Common/Component/GenerateForm'; 
import { base_form_fields } from '../../../../Data/FormField/PublicData'; 
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import CustomizerContext from '../../../../_helper/Customizer'; 
import { Breadcrumbs } from '../../../../AbstractElements'; 
import { Models } from '../../../../Constant';
import { MakersApi, ModelsApi, RadioTypesApi } from '../../../../api';
import { createDropdownField } from '../../../../Data/FormField/PublicData'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';

function RadioTypesCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${RadioTypes}`));
    const lists = `${process.env.PUBLIC_URL}/technical/radio-types/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    
    const { formState: { errors },} = useForm();

    const makersDropdownField = createDropdownField(axiosPrivate,
        'maker_id',
        'maker_id',
        `${Makers}`,
        `${MakersApi}?organisation_id=${userOrganisationId}`,
        '',12
    );

    const modelsDropdownField = createDropdownField(axiosPrivate,
      'device_model_id',
      'device_model_id',
      `${Models}`,
      '',
      '',12
    );
  
    const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
          errors.showMessages();
      }

        const newRecordTemp = {
          organisation_id: userOrganisationId,
          device_model_id: newRecord.device_model_id,
          name: newRecord.name,
          description: newRecord.name,
          is_active: newRecord.is_active,
        };

      try {
          await axiosPrivate.post(RadioTypesApi, newRecordTemp, {
              headers: {
                  'Content-Type': 'application/json',
              },
          }).then((resp) => {
              toast.success(`${resp.data.name} is successfully added.`)
              history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
              toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
              toast.error(error.response.data.detail);
          }
          else {
              toast.error('Form submission error');
          }
          throw error;
      }  
  };

    useEffect(() => {
  
      const fetchData = async () => {
        try {
          if (!isNewFieldAdded) {
            const updatedFields = [makersDropdownField, modelsDropdownField, ...formFields];
            
            await updatedFields[0].fetchOptions();
            
            setFormFields(updatedFields);
            setIsNewFieldAdded(true);
          }
        } catch (error) {
          //console.error('Error fetching options:', error);
        }
      };
  
      fetchData();
  
    }, [formFields, isNewFieldAdded, modelsDropdownField]);
   
    makersDropdownField.onChange = async (selectedMakerId, updatedFields) => { 
        const modelField = updatedFields.find((field) => field.id === 'device_model_id');
        
        if (modelField) {
          const endPoint = `${ModelsApi}?organisation_id=${userOrganisationId}&maker_id=${selectedMakerId}`
          await modelField.fetchOptions(selectedMakerId, false, endPoint);
          setFormFields([...updatedFields]);
        } 
    };

    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Technical" title="Radio Types" mainTitle="Radio Types" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Radio Types"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default RadioTypesCreate