import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, Table, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { Capacity, DateCreated, DateModified, DeleteSuccess, Description, PriceAmount, Product, ProductClass, ProductGroup, ProductType, SourceSystem, SourceSystemProduct, SourceSystemUser, Status, SubBusinessSegment, Unit } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CapacityApi, ProductApi, ProductClassApi, ProductGroupApi, ProductTypeApi, SubBusinessSegmentApi, UnitApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper"
import { product_form_fields } from './Product';
import useAuth from '../../../../Hooks/useAuth';
import { createDropdownField } from '../../../../Data/FormField/PublicData';

const ProductList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(product_form_fields(`${Product}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const productGroupDropdownField = createDropdownField(axiosPrivate,
    'product_group_id',
    'product_group_id',
    `${ProductGroup}`,
    `${ProductGroupApi}?organisation_id=${userOrganisationId}`,
    '',
    6
  );

  const productClassDropdownField = createDropdownField(axiosPrivate,
      'product_class_id',
      'product_class_id',
      `${ProductClass}`,
      '',
      '',
      6
  );

  const productTypeDropdownField = createDropdownField(axiosPrivate,
      'product_type_id',
      'product_type_id',
      `${ProductType}`,
      `${ProductTypeApi}?organisation_id=${userOrganisationId}`,
      '',
      6
  );

  const capacityDropdownField = createDropdownField(axiosPrivate,
      'capacity_id',
      'capacity_id',
      `${Capacity}`,
      `${CapacityApi}?organisation_id=${userOrganisationId}`,
      '',
      6, '', 'capacity, capacity_type.name'
  );

  const unitDropdownField = createDropdownField(axiosPrivate,
      'unit_id',
      'unit_id',
      `${Unit}`,
      `${UnitApi(userOrganisationId)}`,
      '',
      6
  );

  const bizSubSegmentDropdownField = createDropdownField(axiosPrivate,
      'business_sub_segment_id',
      'business_sub_segment_id',
      `${SubBusinessSegment}`,
      `${SubBusinessSegmentApi(userOrganisationId)}`,
      '',
      6
  );

  useEffect(() => {

    const fetchData = async () => {
        try {
            if (!isNewFieldAdded) {
            
            const updatedFields = [productGroupDropdownField, 
                productClassDropdownField, 
                productTypeDropdownField, 
                capacityDropdownField,  
                ...formFields,
                unitDropdownField,
                bizSubSegmentDropdownField
            ];
    
            await updatedFields[0].fetchOptions();
            await productClassDropdownField.fetchOptions();
            await productTypeDropdownField.fetchOptions();
            await capacityDropdownField.fetchOptions();
            await unitDropdownField.fetchOptions();
            await bizSubSegmentDropdownField.fetchOptions();
            
            
            setFormFields(updatedFields);
            setIsNewFieldAdded(true);
            }
        } catch (error) {
            //console.error('Error fetching options:', error);
        }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, productGroupDropdownField]); 

  productGroupDropdownField.onChange = async (selectedPGId, updatedFields) => { 
      const PCField = updatedFields.find((field) => field.id === 'product_class_id');
      
      if (PCField) {
        const endPoint = `${ProductClassApi}?organisation_id=${userOrganisationId}&product_group_id=${selectedPGId}`
        await PCField.fetchOptions(selectedPGId, false, endPoint);
        setFormFields([...updatedFields]);
      } 
  };

  const getAllProductData = async (page = 1) => {
      try {
          setIsLoading(true);
          await axiosPrivate.get(`${ProductApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
              setAllData(resp.data.items);
              setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
          //setIsLoading(false);
      } finally {
        // Set loading to false after data is fetched (regardless of success or failure)
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllProductData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.product_group_id = row.product_type.product_class.product_group_id
    row.product_class_id = row.product_type.product_class_id
    productGroupDropdownField.onChange(row.product_group_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      product_type_id: editRecord.product_type_id,
      unit_id: editRecord.unit_id,
      business_sub_segment_id: editRecord.business_sub_segment_id,
      name: editRecord.name,
      description: editRecord.description,
      capacity_id: editRecord.capacity_id,
      base_price: editRecord.tariff_price,
      tariff_price: editRecord.tariff_price,
      is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${ProductApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ProductApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/sales/settings/products/create/${layoutURL}`;
  
  const tableColumns = [
    {
        name: `${Product}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${PriceAmount}`,
      selector: row => `${row.tariff_price}`,
      sortable: true,
      center: false,
    },
    {
      name: `${ProductType}`,
      selector: row => `${row.product_type.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Capacity}`,
      selector: row => `${row.capacity.capacity} ${row.capacity.capacity_type.name}`,
      sortable: true,
      center: false,
    },
    /*{ 
      name: 'Status',
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    }, */
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const handlePageChange = page => {
    getAllProductData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${ProductApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Sales" title="Product" mainTitle="Product" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} span1={undefined} span2={undefined} mainClasses={undefined} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Product}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ProductType}</th>
                    <td>{selectedRowData.product_type.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Capacity}</th>
                    <td>{selectedRowData.capacity.capacity_type.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Unit}</th>
                    <td>{selectedRowData.unit.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{SubBusinessSegment}</th>
                    <td>{selectedRowData.business_sub_segment.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{SourceSystem}</th>
                    <td>{selectedRowData.business_sub_segment?.business_segment?.source_system?.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{SourceSystemProduct}</th>
                    <td>{selectedRowData.source_system_product_id}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  {/* <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr> */}
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default ProductList;