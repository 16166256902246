import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ApplicationApi, ModuleApi, RouteDetailApi } from '../../../../api';
import { Application, ApplicationName, Code, DateCreated, DateModified, Description, ExternalUrl, Icon, Module, ModuleName, Name, Status, Task, TaskName, View, Visibility } from '../../../../Constant';
import { base_form_fields, code_field, createDropdownField, external_url_field, icon_field, visibility_field } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import ViewModal from '../../../Common/Component/ViewModal';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import DataTableComponent from '../../../Common/Component/DataTableComponent';

const RouteDetailList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(base_form_fields(`${TaskName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const getAllRouteDetailData = async () => {
      try {
          await axiosPrivate.get(RouteDetailApi).then((resp) => {
              setAllData(resp.data);
              setIsLoading(false);
          });
      } catch (error) {
          setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    
    getAllRouteDetailData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    const editRecordTemp = {
      module_id: editRecord.module_id,
      name: editRecord.name,
      code: editRecord.code,
      icon: editRecord.icon,
      description: editRecord.description,
      visibility: editRecord.visibility,
      external_url: editRecord.external_url,
      is_active: editRecord.is_active,
    };

    try {
      await axiosPrivate.put(`${RouteDetailApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return;
    }

    try {
      const response = await axiosPrivate.delete(`${RouteDetailApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = ``;
  const tableColumns = [
    {
      name: `${Name}`,
      selector: row => `${row.human_readable_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${'Path'}`,
      selector: row => `${row.path}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Code}`,
      selector: row => `${row?.route_code}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="Route Details" mainTitle="Route Details" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  useDefaultAction={false}
                  //onView={handleView}
                  //onDelete={handleDelete}
                  //onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RouteDetailList;