import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BackToList, ProductType, InvoiceType, InvoiceCycle, CreditTerm, ProductGroup } from "../../../../../Constant"
import GenerateForm from '../../../../Common/Component/GenerateForm'; 
import { createDropdownField } from '../../../../../Data/FormField/PublicData'; 
import { ProductClassApi, InvoiceTypeApi, InvoiceCycleApi, CreditTermApi, ProductGroupApi, ProductTypeApi } from "../../../../../api"
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../../_helper/Customizer';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs';
import { ProductClass } from '../../../../../Constant';
import { productType_form_fields } from './ProductType'; 
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form'; 
import useAuth from '../../../../../Hooks/useAuth';

function FormType() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    const [formFields, setFormFields] = useState(productType_form_fields(`${ProductType}`,6));
    const ProductTypeList = `${process.env.PUBLIC_URL}/sales/settings/product-types/${layoutURL}`;

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }
  
        const newRecordTemp = {
            organisation_id: userOrganisationId,
            product_class_id: newRecord.product_class_id,
            name: newRecord.name,
            description: newRecord.name,
            invoice_type_id: newRecord.invoice_type_id,
            invoice_cycle_id: newRecord.invoice_cycle_id,
            credit_term_id: newRecord.credit_term_id,
            is_negotiable: newRecord.is_negotiable,
            is_tax_inclusive: newRecord.is_tax_inclusive,
            tenor: newRecord.tenor,
            speed: newRecord.speed,
        };

        try {
            await axiosPrivate.post(ProductTypeApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(ProductTypeList);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };
  
    const productGroupDropdownField = createDropdownField(axiosPrivate,
        'product_group_id',
        'product_group_id',
        `${ProductGroup}`,
        `${ProductGroupApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );

    const productClassDropdownField = createDropdownField(axiosPrivate,
        'product_class_id',
        'product_class_id',
        `${ProductClass}`,
        '',
        '',
        6
    );

    const invoiceTypeDropdownField = createDropdownField(axiosPrivate,
        'invoice_type_id',
        'invoice_type_id',
        `${InvoiceType}`,
        `${InvoiceTypeApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );

    const invoiceCycleDropdownField = createDropdownField(axiosPrivate,
        'invoice_cycle_id',
        'invoice_cycle_id',
        `${InvoiceCycle}`,
        `${InvoiceCycleApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );

    const creditTermDropdownField = createDropdownField(axiosPrivate,
        'credit_term_id',
        'credit_term_id',
        `${CreditTerm}`,
        `${CreditTermApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );


    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {
                
                const updatedFields = [productGroupDropdownField, productClassDropdownField, invoiceTypeDropdownField, invoiceCycleDropdownField, creditTermDropdownField, ...formFields];
        
                await updatedFields[0].fetchOptions();
                await invoiceTypeDropdownField.fetchOptions();
                await invoiceCycleDropdownField.fetchOptions();
                await creditTermDropdownField.fetchOptions();
                
                setFormFields(updatedFields);
                setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };
    
        fetchData();
    }, [formFields, isNewFieldAdded, productGroupDropdownField]); 

    productGroupDropdownField.onChange = async (selectedPGId, updatedFields) => { 
        const PCField = updatedFields.find((field) => field.id === 'product_class_id');
        
        if (PCField) {
          const endPoint = `${ProductClassApi}?organisation_id=${userOrganisationId}&product_group_id=${selectedPGId}`
          await PCField.fetchOptions(selectedPGId, false, endPoint);
          setFormFields([...updatedFields]);
        } 
    };

    return (
        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Product Type" mainTitle="Product Type" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={ProductTypeList}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={ProductTypeList} formTitle={"Product Type"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>
    )
}

export default FormType