import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CustomizerContext from '../../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BranchApi, CostCentreApi, CostCentreCategoryApi, UnitApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../../Common/Component/CardRibbonWithForm';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { base_form_fields, createDropdownField, short_name_field } from '../../../../../../Data/FormField/PublicData';
import { AddNewCostCentre, Branch, CostCentre, CostCentreCategory, Unit } from '../../../../../../Constant';
import OrganisationContext from '../../../../../../_helper/System/Organisation';

const CostCentreNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { organisation_id  } = useContext(OrganisationContext);
  const unitApi = UnitApi(organisation_id);
  const costCentreCategoryApi = CostCentreCategoryApi(organisation_id);
  const branchApi = BranchApi(organisation_id);
  const costCentreApi = CostCentreApi(organisation_id);
  const lists = `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/cost-centres/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${CostCentre}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const unitDropdownField = createDropdownField(axiosPrivate,
    'unit_id',
    'unit_id',
    `${Unit}`,
    `${unitApi}`,
    '',
    12
  );

  const branchDropdownField = createDropdownField(axiosPrivate,
    'branch_id',
    'branch_id',
    `${Branch}`,
    `${branchApi}`,
    '',
    12
  );

  const costCentreCategoryDropdownField = createDropdownField(axiosPrivate,
    'cost_centre_category_id',
    'cost_centre_category_id',
    `${CostCentreCategory}`,
    `${costCentreCategoryApi}`,
    '',
    12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [unitDropdownField, branchDropdownField, costCentreCategoryDropdownField, ...formFields];
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
          updatedFields.splice(indexOfName + 4, 0, short_name_field());

          await updatedFields[0].fetchOptions();
          await branchDropdownField.fetchOptions();
          await costCentreCategoryDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, unitDropdownField, branchDropdownField, costCentreCategoryDropdownField]);

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          unit_id: newRecord.unit_id,
          branch_id: newRecord.branch_id,
          cost_centre_category_id: newRecord.cost_centre_category_id,
          name: newRecord.name,
          short_name: newRecord.short_name,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(costCentreApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm 
              formFields={formFields} 
              onSubmit={addNewRecord} 
              ribbonUrl={lists} 
              formTitle={AddNewCostCentre} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CostCentreNew;