import { useContext, useLayoutEffect, useState } from "react";
import {
    Description,
    GeneralLedgerAccount,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    Name,
    Status,
    SubLedgerClass,
    SubLedgerType,
} from "../../../../../Constant";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    financesGeneralLedgerAccountApi,
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
    financesGeneralLedgerTypeApi,
    financeSubLedgerClassApi,
} from "../../../../../api";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useSubLedgerTypeController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const formTitle = SubLedgerType;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_class_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "sl_class_id",
        "sl_class_id",
        SubLedgerClass,
        ``,
        "",
        6
    );
    
    const generalLedgerGroupDropDown2 = createDropdownField(
        axiosPrivate,
        "gl_group_id2",
        "gl_group_id2",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );

    generalLedgerGroupDropDown2.onChange = async (id, updatedFields) => {
        const nextField2 = updatedFields.find(
            (field: any) => field.id === "gl_class_id"
        );
        if (nextField2) {
            const endPoint = `${financesGeneralLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField2.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id2") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const generalLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "gl_class_id",
        "gl_class_id",
        GeneralLedgerClass,
        ``,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_type_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerTypeApi}?organisation_id=${userOrganisationId}&gl_class_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_class_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        //updateFields();
    };

    const generalLedgerTypeDropDown = createDropdownField(
        axiosPrivate,
        "gl_type_id",
        "gl_type_id",
        GeneralLedgerType,
        ``,
        "",
        6
    );

    generalLedgerTypeDropDown.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_account_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerAccountApi}?organisation_id=${userOrganisationId}&gl_type_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_type_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        //updateFields();
    };

    const generalLedgerAccountDropDown = createDropdownField(
        axiosPrivate,
        "gl_account_id",
        "gl_account_id",
        GeneralLedgerAccount,
        ``,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            generalLedgerGroupDropDown,
            subLedgerClassDropDown,
            {
                type: 'group-title',
                label: `Mapping of Control GL Account `,
            },
            generalLedgerGroupDropDown2,
            generalLedgerClassDropDown,
            generalLedgerTypeDropDown,
            generalLedgerAccountDropDown,
            {
                type: 'group-title',
                label: `SL Type`,
            },
            ...formFields,
        ];
        await generalLedgerGroupDropDown.fetchOptions();
        await generalLedgerGroupDropDown2.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-type/${layoutURL}`;

    async function onSubmit(data: any) {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/sub-ledger-types", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
            });
    }

    return { formTitle, backBtn, formFields, onSubmit };
}
