import React, { Fragment, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormGroup, Label, Input, Form, Button, Card, CardBody } from 'reactstrap';
import { BackToList, ProductGroup } from '../../../../../Constant';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { base_form_fields } from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../../_helper/Customizer';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs';
import { useForm } from 'react-hook-form';
import { ProductGroupApi } from "../../../../../api"
import { toast } from 'react-toastify';
import useAuth from '../../../../../Hooks/useAuth';

function PGForm() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${ProductGroup}`));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/product-groups/${layoutURL}`;

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            name: newRecord.name,
            description: newRecord.name,
            is_active: newRecord.is_active,
        };

        try {
            await axiosPrivate.post(ProductGroupApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(lists);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };
    
    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Product Group" mainTitle="Product Group" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle="Product Group" onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default PGForm