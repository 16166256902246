import React from 'react'
import FormCapacity from './CapacityTypeForm/FormCapacity'

function CreateCapacityType() {
    return (
        <div>
            <FormCapacity />
        </div>
    )
}

export default CreateCapacityType