import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./GroupGeneralLedgerNumberList";
import CustomizerContext from "../../../../../_helper/Customizer";
import { GroupGeneralLedgerNumberResponse } from "../GroupGLNumber.types";
import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";

import {
  financesGeneralLedgerClassApi,
  financesGeneralLedgerGroupApi,
  financesGroupGeneralLedgerClassApi,
  financesGroupGeneralLedgerNumberApi,
  financesGroupGeneralLedgerTypeApi,
} from "../../../../../api";

import {
  DateCreated,
  DateModified,
  GroupGLClass,
  GeneralLedgerGroup,
  GroupGLType,
} from "../../../../../Constant";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";

export default function useGroupGeneralLedgerNumberListController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: `Name`,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: `Description`,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: `Status`,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: `Name`,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },

    {
      name: `Description`,
      selector: (row: (typeof data)[0]) => `${row.description}`,
      sortable: false,
      center: false,
    },

    {
      name: `Status`,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    // (typeof data)[0]
    {
      name: `Group GL Type`,
      selector: (row: (typeof data)[0]) => `${row.group_gl_type.name}`,
      sortable: true,
      center: false,
    },

    {
      name: `${DateCreated}`,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const generalLedgerGroup = createDropdownField(
    axiosPrivate,
    "general_ledger_group",
    "general_ledger_group",
    GeneralLedgerGroup,
    `${financesGeneralLedgerGroupApi}?&organisation_id=${userOrganisationId}&group_gl_class_id=${1}`,
    "",
    6
  );
  generalLedgerGroup.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "group_gl_class_id"
    );

    if (nextField) {
      const endPoint = `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${id}`;

      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "general_ledger_group") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };
  const groupGLClassIdDropDown = createDropdownField(
    axiosPrivate,
    "group_gl_class_id",
    "group_gl_class_id",
    GroupGLClass,
    `${financesGroupGeneralLedgerClassApi}&organisation_id=${userOrganisationId}&gl_group_id=${1}`,
    "",
    6
  );

  groupGLClassIdDropDown.onChange = async (id, fields) => {
    console.log(fields);
    const nextField = fields.find(
      (field: any) => field.id === "group_gl_type_id"
    );

    if (nextField) {
      const endPoint = `${financesGroupGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&group_gl_class_id=${id}`;
      await nextField.fetchOptions(id, false, endPoint);

      const updatedFormFields = fields.map((field: any) => {
        if (field.id === "group_gl_class_id") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const groupGLTypeIdDropDown = createDropdownField(
    axiosPrivate,
    "group_gl_type_id",
    "group_gl_type_id",
    GroupGLType,
    `${financesGroupGeneralLedgerTypeApi}?organisation_id=${userOrganisationId}&group_gl_class_id=${1}`,
    "",
    6
  );
  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    (async function () {
      const updatedFields = [
        generalLedgerGroup,
        groupGLClassIdDropDown,
        groupGLTypeIdDropDown,
        ...formFields,
      ];
      await generalLedgerGroup.fetchOptions();
      setFormFields(updatedFields);
    })();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-number/create/${layoutURL}`;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<GroupGeneralLedgerNumberResponse[]>([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);

  const [editMode, setEditMode] = useState(false);

  function fetchData() {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesGroupGeneralLedgerNumberApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&group_gl_type_id=${2}`
      )
      .then((res) => {
        const response: GroupGeneralLedgerNumberResponse[] = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
    console.log(row);
  };

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0]
  ) => {
    console.log(id, editRecord, "");
    await axiosPrivate
      .put(`${financesGroupGeneralLedgerNumberApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.name}, 'Edited Successfully'`);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.warn("Group GL Number not found");
          return;
        }
        toast.error(err.response.data.message || "Something Went Wrong");
      });
  };

  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    setViewModalOpen,
    editMode,
    newBtn,
    handleView,
    handleEdit,
    memoizedHandleEdit,
    loading,
    formFields,
  };
}
