import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";

import GreetingCard from "./GreetingCard";
import UpcomingSchedule from './UpcomingSchedule';
import Calender from '../../Common/CommonWidgets/Calender';

/* import OverallBalance from "./OverallBalance";

import WidgetsWrapper from "./WidgetsWraper";
import RecentOrders from "./RecentOrders";
import ActivityCard from "./ActivityCard";
import RecentSales from "./RecentSales";
import TimelineCard from "./TimelineCard";
import PreAccountCard from "./PreAccountCard";
import TotalUserAndFollower from "./TotalUserAndFollower";
import PaperNote from "./PaperNote"; */


const Dashboard = () => {
  
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
            <Col xxl='3' xl='5' sm='6' className='col-ed-5 box-col-5'>
              <Calender />
            </Col>
            <Col xxl='3' md='6' className='col-ed-6 box-col-5'>
              <UpcomingSchedule />
            </Col>
          {/*<WidgetsWrapper />
          <OverallBalance />
          <RecentOrders />
          <ActivityCard />
          <RecentSales />
          <TimelineCard />
          <PreAccountCard />
          <TotalUserAndFollower />
          <PaperNote /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
