import React, { Fragment, useContext } from 'react';
import { Card, TabPane, CardBody, Row , Col, FormGroup, Label, Input } from 'reactstrap';
import { BlockQuotes, P } from '../../../../../AbstractElements';
import { GenerateMyReferralLink, NoReferralLink, ReferralCode, ReferralLink, ReferralMessage, ReferralTitle } from '../../../../../Constant';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import no_record_img from '../../../../../assets/images/other-images/no_record_found.jpg'
import NoRecord from '../../../../Common/Component/NoRecord';
import { ContactMgmtApi } from '../../../../../api';


const MyReferralLink = ({ referralLinkDetails, setReferralLinkDetails }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const generateReferralLink = async () => {
    try {
      await axiosPrivate.post(`${ContactMgmtApi}/generate-referral-code`).then((resp) => {
        const referralCode = resp.data.referral_code;
        setReferralLinkDetails(referralCode ? { code: referralCode } : {});
      });
    } catch (error) {
       // console.log('cancelled', error);
    } finally {
      //setIsLoading(false);
    }
  };

  const btnAction = {
    label: `${GenerateMyReferralLink}`,
    action: generateReferralLink,
    color: 'primary',
    icon: 'icofont icofont-link-alt',
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='referral-link'>
      <Row>
          {Object.keys(referralLinkDetails).length === 0 ?
          <NoRecord
            msg={NoReferralLink}
            btnAction={btnAction}
            backgroundImg={no_record_img}
          />
          :    
          <Col sm='8'>
            <Card>
              <CardBody>
                <Row>
                    <Col>
                      {/* <h5>{ReferralTitle}</h5>
                      <hr className="mt-4 mb-4"></hr> */}
                      <P attrPara={{ className: 'sub-title' }}>{ReferralTitle}</P>
                      <BlockQuotes attrBlockQuote={{ className: 'blockquote mb-0' }}>
                        <P attrPara={{ className: 'mb-0' }}>{ReferralMessage}</P>
                      </BlockQuotes>
                        
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label" htmlFor="referral_link">
                          {ReferralLink}
                        </Label>
                        <Col sm="7">
                          <Input className="form-control" type="text" disabled id="referral_link"
                              placeholder={ `${process.env.REACT_APP_API_BASE_URL}/crm/link/referral/${referralLinkDetails.code}` }
                              value={ `${process.env.REACT_APP_API_BASE_URL}/crm/link/referral/${referralLinkDetails.code}` }
                             />
                        </Col>
                      </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label" htmlFor="referral_code">
                            {ReferralCode} 
                          </Label>
                          <Col sm="5">
                            <Input className="form-control" type="text" disabled id="referral_code"
                              placeholder={referralLinkDetails.code}
                              value={referralLinkDetails.code}
                             />
                          </Col>
                        </FormGroup>
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          }
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default MyReferralLink;