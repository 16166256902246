import React, { Fragment } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Structure from '../Components/System/Organisation/Company/Structure';
import DivisionList from '../Components/System/Organisation/Company/Structure/Division/List';
import DivisionNew from '../Components/System/Organisation/Company/Structure/Division/Create';
import DepartmentList from '../Components/System/Organisation/Company/Structure/Department/List';
import DepartmentNew from '../Components/System/Organisation/Company/Structure/Department/Create';
import UnitList from '../Components/System/Organisation/Company/Structure/Unit/List';
import CostCentreList from '../Components/System/Organisation/Company/Structure/CostCentre/List';
import ZoneList from '../Components/System/Organisation/Company/Structure/Zone/List';
import UnitNew from '../Components/System/Organisation/Company/Structure/Unit/Create';
import CostCentreNew from '../Components/System/Organisation/Company/Structure/CostCentre/Create';
import ZoneNew from '../Components/System/Organisation/Company/Structure/Zone/Create';
import RegionList from '../Components/System/Organisation/Company/Structure/Region/List';
import RegionNew from '../Components/System/Organisation/Company/Structure/Region/Create';
import BusinessSegmentList from '../Components/System/Organisation/Company/Structure/BusinessSegment/List';
import BusinessSegmentNew from '../Components/System/Organisation/Company/Structure/BusinessSegment/Create';
import SubBusinessSegmentList from '../Components/System/Organisation/Company/Structure/SubBusinessSegment/List';
import SubBusinessSegmentNew from '../Components/System/Organisation/Company/Structure/SubBusinessSegment/Create';
import BranchList from '../Components/System/Organisation/Company/Structure/Branch/List';
import BranchNew from '../Components/System/Organisation/Company/Structure/Branch/Create';
import LocationList from '../Components/System/Organisation/Company/Structure/Location/List';
import LocationNew from '../Components/System/Organisation/Company/Structure/Location/Create';
import OrganisationProvider from '../_helper/System/Organisation/OrganisationProvider';
import StoreList from '../Components/System/Organisation/Company/Structure/Store/List';
import StoreNew from '../Components/System/Organisation/Company/Structure/Store/Create';
import CostCentreCategoryList from '../Components/System/Organisation/Company/Structure/CostCentreCategory/List';
import CostCentreCategoryNew from '../Components/System/Organisation/Company/Structure/CostCentreCategory/Create';
import { hasPermission } from '../_helper/rbac';

const OrganisationRoutes = ({ authPermissions}) => {
  const location = useLocation();

  const routes = [
    { path: `/divisions/:layout`, Component: () => <DivisionList />, requiredPermission: "read:company-divisions" },
    { path: `/divisions/create/:layout`, Component: () => <DivisionNew />, requiredPermission: "create:company-division" },
    { path: `/departments/:layout`, Component: () => <DepartmentList />, requiredPermission: "read:company-departments" },
    { path: `/departments/create/:layout`, Component: () => <DepartmentNew />, requiredPermission: "create:company-department" },
    { path: `/units/:layout`, Component: () => <UnitList />, requiredPermission: "read:company-units" },
    { path: `/units/create/:layout`, Component: () => <UnitNew />, requiredPermission: "create:company-unit" },
    { path: `/cost-centre-categories/:layout`, Component: () => <CostCentreCategoryList />, requiredPermission: "read:company-cost-centre-categories" },
    { path: `/cost-centre-categories/create/:layout`, Component: () => <CostCentreCategoryNew />, requiredPermission: "create:company-cost-centre-category" },
    { path: `/cost-centres/:layout`, Component: () => <CostCentreList />, requiredPermission: "read:company-cost-centres" },
    { path: `/cost-centres/create/:layout`, Component: () => <CostCentreNew />, requiredPermission: "create:company-cost-centre" },
    { path: `/zones/:layout`, Component: () => <ZoneList />, requiredPermission: "read:company-zones" },
    { path: `/zones/create/:layout`, Component: () => <ZoneNew />, requiredPermission: "create:company-zone" },
    { path: `/regions/:layout`, Component: () => <RegionList />, requiredPermission: "read:company-regions" },
    { path: `/regions/create/:layout`, Component: () => <RegionNew />, requiredPermission: "create:company-region" },
    { path: `/branches/:layout`, Component: () => <BranchList />, requiredPermission: "read:company-branches" },
    { path: `/branches/create/:layout`, Component: () => <BranchNew />, requiredPermission: "create:company-branch" },
    { path: `/stores/:layout`, Component: () => <StoreList />, requiredPermission: "read:company-stores" },
    { path: `/stores/create/:layout`, Component: () => <StoreNew />, requiredPermission: "create:company-store" },
    { path: `/locations/:layout`, Component: () => <LocationList />, requiredPermission: "read:company-locations" },
    { path: `/locations/create/:layout`, Component: () => <LocationNew />, requiredPermission: "create:company-location" },
    { path: `/business-segments/:layout`, Component: () => <BusinessSegmentList />, requiredPermission: "read:company-business-segments" },
    { path: `/business-segments/create/:layout`, Component: () => <BusinessSegmentNew />, requiredPermission: "create:company-business-segment" },
    { path: `/sub-business-segments/:layout`, Component: () => <SubBusinessSegmentList />, requiredPermission: "read:company-sub-business-segments" },
    { path: `/sub-business-segments/create/:layout`, Component: () => <SubBusinessSegmentNew />, requiredPermission: "create:company-sub-business-segment" },
  ];

  return ( 
    <>
      <OrganisationProvider>
        <Routes>
          <Route element={<Structure />}>
            {/* {routes.map(({ path, Component }, i) => (
              <Fragment key={i}> 
                <Route element={<Structure />} key={i}>
                  <Route path={path} element={Component} />
                </Route>
              </Fragment>
            ))} */}

            {routes.map(({ path, Component, requiredPermission }, i) => (
              <Route key={i} path={path} element={
                hasPermission(requiredPermission, authPermissions) ? (
                  <Component />
                ) : (
                  // Navigate only when the permission is not met
                  <Navigate
                    to={`${process.env.PUBLIC_URL}/unathorised`}
                    state={{ from: location }}
                    replace
                  />
                )
              } />
            ))}
          </Route>
        </Routes>
      </OrganisationProvider>
    </> 
  );
};

export default OrganisationRoutes;

