import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import HeaderCard from '../../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../../_helper/Customizer';
import { CostCentreApi, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, StoreApi, WardApi, ZoneApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../../Common/Component/ViewModal';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { Branch, CostCentre, Country, DateCreated, DateModified, Description, EmailAddress, Lga, Name, PhoneNumber, Region, RegionalContinent, ShortName, State, Status, Store, Unit, Ward, Website, Zone } from '../../../../../../Constant';
import { base_form_fields, createDropdownField, short_name_field } from '../../../../../../Data/FormField/PublicData';
import OrganisationContext from '../../../../../../_helper/System/Organisation';
import { electronic_address_form_fields, physical_address_form_fields } from '../../../../../../Data/FormField/CrmData';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';

const StoreList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { organisation_id  } = useContext(OrganisationContext);
  const storeApi = StoreApi(organisation_id);
  const costCentreApi = CostCentreApi(organisation_id);

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${Branch}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const costCentreDropdownField = createDropdownField(axiosPrivate,
    'cost_centre_id',
    'cost_centre_id',
    `${CostCentre}`,
    `${costCentreApi}`,
    '',
    6
  );

  //========= Physical Address ============== //
  const wardDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 6,
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 6,
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    '', 6,
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    '', 6,
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
    '', 6,
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
          const updatedFields = [costCentreDropdownField, ...formFields];
          
          updatedFields.splice(indexOfName + 2, 0, short_name_field('', 6));
          const indexOfStatus = updatedFields.findIndex((updatedField) => updatedField.id === 'is_active');
        
          updatedFields.splice(indexOfStatus , 0, 
            ...electronic_address_form_fields, 
            ...physical_address_form_fields,
            continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField);
          
            await updatedFields[0].fetchOptions();
            await continentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, costCentreDropdownField]);

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    //const selectedCountry = selectedCountryId > 0 ? selectedCountryId : 0;
    if (zoneField) { 
      await zoneField.fetchOptions(selectedCountryId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const getAllStoresData = async () => {
      try {
          await axiosPrivate.get(storeApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllStoresData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.regional_continent_id = row.ward.lga.state.zone.country.regional_continent_id;
    row.regional_country_id = row.ward.lga.state.zone.regional_country_id;
    row.regional_zone_id = row.ward.lga.state.regional_zone_id;
    row.regional_state_id = row.ward.lga.regional_state_id;
    row.regional_lga_id = row.ward.regional_lga_id;
    row.email = row.email_address;
    continentDropdownField.onChange(row.continent_id, formFields);
    countryDropdownField.onChange(row.regional_country_id, formFields);
    zoneDropdownField.onChange(row.regional_zone_id, formFields);
    stateDropdownField.onChange(row.regional_state_id, formFields);
    lgaDropdownField.onChange(row.regional_lga_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
        cost_centre_id: editRecord.cost_centre_id,
        name: editRecord.name,
        short_name: editRecord.short_name,
        description: editRecord.description,
        is_active: editRecord.is_active,
        email_address: editRecord.email,
        phone_number: editRecord.phone_number,
        alt_phone_number: editRecord.alt_phone_number,
        website: editRecord.website,
        street_number: editRecord.street_number,
        street_name: editRecord.street_name,
        nearest_landmark: editRecord.nearest_landmark,
        city: editRecord.city,
        geo_tag: editRecord.geo_tag,
        ward_id: editRecord.ward_id,
    };
    
    try {
        await axiosPrivate.put(`${storeApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${storeApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/stores/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${Name}`,
      selector: row => `${row.name}`,
      sortable: true,
    },
    {
        name: `${ShortName}`,
        selector: row => `${row.short_name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${CostCentre}`,
      selector: row => `${row.cost_centre.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Branch}`,
      selector: row => `${row.cost_centre.branch.short_name}`,
      sortable: true,
      center: false,
    },
    /* {
      name: `${Description}`,
      selector: row => row.description,
      wrap: true,
      sortable: true,
      center: false,
      format: row => `${row.description.slice(0, 30)}...`,
    }, */
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <div className='email-right-aside bookmark-tabcontent contacts-tabs'>
                <div className='email-body radius-left'>
                  <div className='ps-0'>
                    <Card>
                      <HeaderCard title="List Stores" btnUrl={addBtn} />
                      <CardBody>
                        <DataTableComponent 
                          tabledata={allData}
                          tableColumns={tableColumns}
                          isLoading={isLoading}
                          onView={handleView}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
      </div>
        
      <ViewModal 
        isOpen={viewModalOpen} 
        toggle={() => setViewModalOpen(!viewModalOpen)} 
        rowData={selectedRowData} 
        formFields={formFields}
        editMode={editMode}
        onEdit={memoizedHandleEdit}
      >
        {selectedRowData && (
          <>
            <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Store}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ShortName}</th>
                    <td>{selectedRowData.short_name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EmailAddress}</th>
                    <td>{selectedRowData.email_address}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PhoneNumber}</th>
                    <td>{selectedRowData.phone_number}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Website}</th>
                    <td>{selectedRowData.website}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CostCentre}</th>
                    <td>{selectedRowData.cost_centre.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Branch}</th>
                    <td>{selectedRowData.cost_centre.branch.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Ward}</th>
                    <td>{selectedRowData.ward.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? 
                    <span className="badge badge-light-success">Active</span> : 
                    <span className="badge badge-light-danger">Inactive</span>}
                    </td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
          </>
        )}
        
      </ViewModal>
    </Fragment>
  );
};

export default StoreList;