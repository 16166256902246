import React, { Fragment, useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H4, Image, Alerts } from '../../../AbstractElements';
import logoWhite from '../../../assets/images/logo/logo.png';
import logoDark from '../../../assets/images/logo/logo_dark.png';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AccountVerifyApi, ResetPasswordApi } from '../../../api';
import CustomizerContext from '../../../_helper/Customizer';
import axios from '../../../api/axios';

const ResetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const location = useLocation();

  const {
    //register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [errMsg, setErrMsg] = useState('');
  const errRef = useRef();

  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('');
  }, [pwd, matchPwd])
  //const { id } = useParams();

  const handleFormSubmit = async (data) => {
      if (data === '') { 
        errors.showMessages();
      }
    
      if (!validPwd) {
        setErrMsg('The supplied password is not valid');
        return false
      }

      if (!validMatch) {
        setErrMsg('The supplied password does not match');
        return false
      }

      const queryParams = new URLSearchParams(location.search);
      //const v2 = PWD_REGEX.test(data.password);
      const dataTemp = {
          password: pwd,
          password_confirmation: matchPwd,
          token: queryParams.get('token'),
          email: queryParams.get('email'),
      };

      try {
          await axios.put(ResetPasswordApi, dataTemp).then((resp) => {
            setSuccessMsg(resp.data.message)
            toast.success(resp.data.message)
            setTimeout(() => {
              history(`${process.env.PUBLIC_URL}/login`);
            }, 2000);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
            setErrMsg('Fill all the fields provided');
          }  else if (error.response && error.response.status === 400) {
            setErrMsg(error.response.data.detail);
          }else {
            setErrMsg('Form submission error');
          }
           //throw error;
      } 
  }

  return (
    <Fragment>
      <section>
        <Container fluid={true} className='p-0 login-page'>
          <Row className='m-0'>
            <Col xl='12 p-0'>
              <div className='login-card'>
                <div>
                  <div>
                    <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                      <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                      <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
                    </Link>
                  </div>
                  <div className='login-main'>
                    {errMsg && <Alerts attrAlert={{ color: 'danger ' }} >
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    </Alerts>}

                    {successMsg && <Alerts attrAlert={{ color: 'success ' }} >
                    <p className={successMsg ? "" : "offscreen"} aria-live="assertive">{successMsg}</p>
                    </Alerts>} 

                    <Form className='theme-form login-form' onSubmit={handleSubmit(handleFormSubmit)}>
                      <H4>Reset Your Password</H4>
                      <FormGroup className='position-relative'>
                        <Label className='m-0 col-form-label'>New Password</Label>
                        <div className='position-relative'>
                          <input className="form-control" type={togglePassword ? 'text' : 'password'} id='password' 
                            placeholder='*********' 
                            onChange={(e) => setPwd(e.target.value)} 
                            value={pwd}
                            aria-invalid={validPwd}
                            aria-describedby='pwdnote'
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                            required
                            /* {...register('password', { required: true })}  */
                            />
                            
                          <span style={{ color: 'red' }}>{errors['password'] && `Password is required`}</span>
                          <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                          </div>
                          {/* <span id='pwdnote' className={pwdFocus && !validPwd ? "errmsg" : "offscreen"}>
                            Minimum of 8 character <br />
                            Must contain Letter, Number
                          </span> */}
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className='m-0 col-form-label'>Retype Password</Label>
                        <div className='position-relative'>
                          <input className='form-control' type={togglePassword2 ? 'text' : 'password'} placeholder='*********' 
                          /* {...register('password_confirmation', { required: true })}/> */
                            onChange={(e) => setMatchPwd(e.target.value)} 
                            value={matchPwd}
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                            required
                            />
                          <span style={{ csetMatchFocusolor: 'red' }}>{errors['password_confirmation'] && `Password Confirmation is required`}</span>
                          <div className='show-hide' onClick={() => setTogglePassword2(!togglePassword2)}>
                            <span className={togglePassword2 ? '' : 'show'}></span>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'submit' }}>Create</Btn>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ResetPwd;
