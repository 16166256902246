import React, { Fragment, useContext } from 'react';
import CustomizerContext from '../../../../../_helper/Customizer'
import OrganisationContext from '../../../../../_helper/System/Organisation';
import { FilePlus, CheckCircle } from 'react-feather';
import InnerStructure from '../../../../../Layout/InnerStructure';

const Structure = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const { organisation_id, profile  } = useContext(OrganisationContext);

  const SideNavMenus = [
    {
      title: 'Division',
      icon: <FilePlus />,
      color: 'primary',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/divisions/${layoutURL}`,
    },
    {
      title: 'Department',
      icon: <CheckCircle />,
      color: 'success',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/departments/${layoutURL}`,
    },
    {
      title: 'Unit',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/units/${layoutURL}`,
    },
    {
      title: 'Cost Centre Category',
      icon: <CheckCircle />,
      color: 'info',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/cost-centre-categories/${layoutURL}`,
    },
    {
      title: 'Cost Centre',
      icon: <CheckCircle />,
      color: 'info',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/cost-centres/${layoutURL}`,
    },
    {
      title: 'Zone',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/zones/${layoutURL}`,
    },
    {
      title: 'Region',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/regions/${layoutURL}`,
    },
    {
      title: 'Branch',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/branches/${layoutURL}`,
    },
    {
      title: 'Location',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/locations/${layoutURL}`,
    },
    {
      title: 'Store',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/stores/${layoutURL}`,
    },
    {
      title: 'Business Segment',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/business-segments/${layoutURL}`,
    },
    {
      title: 'Sub Business Segment',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/sub-business-segments/${layoutURL}`,
    },
  ];

  return (
    <Fragment>
      <InnerStructure 
        BreadcrumbParent="Organisations"
        BreadcrumbTitle="Structures" 
        BreadcrumbMainTitle={`Structures: ${profile.shortname}`}
        SideNavMenus = {SideNavMenus}
        profile = {profile}
      />
    </Fragment>
  );
};

export default Structure;