import { Fragment, useRef, useState } from 'react';
import { Card, CardBody, Col, Label, Media, Row, TabPane } from 'reactstrap';
import { Btn, H5, H6, Image, P } from '../../../../../../AbstractElements';
import three from '../../../../../../assets/images/user/3.jpg';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import { AddNewNote, AddNote } from '../../../../../../Constant';
import { useForm } from 'react-hook-form';
import InteractionNoteList from '../InteractionNoteList';
import Spinner from '../../../../../Common/Component/Spinner';

const InteractionMessage = ({ Details,onInteractionNoteSubmit }) => {
  const { contact, interaction_notes } = Details;
  const messageInputRef = useRef(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    //event.preventDefault();
    setBtnLoading(true);
    if (data !== '') {
      await onInteractionNoteSubmit(data);
      setBtnLoading(false);
      setShowForm(!showForm);
    } else {
      setBtnLoading(false);
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='interaction-detail'>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                <div className='new-users-social'>
                  <Media className='align-items-center'>
                    <Image attrImage={{ className: 'rounded-circle image-radius m-r-15', src: `${three}`, alt: '' }} />
                    <Media body>
                      <H6 attrH6={{ className: 'mb-0 f-w-700' }}>
                        {contact && (
                          contact.company_name ? contact.company_name :
                          (contact.last_name ? `${contact.last_name} ${contact.first_name} ${contact.middle_name}` : '')
                        )}
                      </H6>
                      <P>{formatDateWithTimeZone(Details.created_at)}</P>
                    </Media>
                    <span className='d-flex justify-content-between pull-right mt-0 '>
                      { !Details.resolved_at && 
                        ( <><span className='m-r-20' 
                          style={{ cursor: 'pointer' }}
                          onClick={toggleFormVisibility}>
                          <i className='fa fa-save font-primary'></i> {AddNewNote}
                        </span></>)
                      }
                    </span>
                  </Media>
                </div>
                {/* <Image attrImage={{ className: 'img-fluid', alt: '', src: `${timeline1}` }} /> */}
                <div className='timeline-content'>
                  <H5>{Details.subject}</H5>
                  <P>{Details.message}</P>

                  <div className={`display-content ${showForm ? 'show' : 'hide'}`}>
                    {showForm && (
                      <>
                        <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{AddNewNote}</H6>
                        <hr className="mt-1 mb-4" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Row>
                            <Col md='12 mb-3'>
                              <Label className='form-label'>
                                {Comment}
                              </Label>
                              <textarea ref={messageInputRef} 
                              {...register('message', { required: true })}
                              className='form-control' style={{ height: '6rem' }} />
                              <span className='d-block' style={{ color: 'red' }}>{errors.message && 'Root Cause Comment is required'}</span>
                            </Col>
                          </Row>
                          <div className='text-end'>
                            <Btn attrBtn={{ color: 'primary' }}>
                              {btnLoading ? <Spinner /> : <><i className='fa fa-save'></i> {AddNote}</>}
                            </Btn>
                          </div>
                          
                        </form>
                      </>
                    )} 
                  </div>        
                  
                  <div className='social-chat'>
                    {interaction_notes && (<InteractionNoteList Notes={interaction_notes}/>)} 
                  </div> 
                   
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default InteractionMessage;
