import { SearchContact, SearchNow } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import React, { Fragment, useRef, useState } from 'react';
import { Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { Search } from 'react-feather';

const FindContact = ({ onSubmit }) => {
    const [isFilter, setIsFilter] = useState(true);
    const searchInputRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const searchValue = searchInputRef.current ? searchInputRef.current.value : '';
        await onSubmit(searchValue);
    };

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >{SearchContact}</Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                                <form onSubmit={handleSubmit}>
                                    <div className="job-filter">
                                        <div className="faq-form">
                                            <input 
                                                className="form-control" 
                                                ref={searchInputRef}
                                                type="text" placeholder="Search.." />
                                            <Search className="search-icon" />
                                        </div>
                                    </div>
                                    <Btn attrBtn={{ color: 'primary text-center' }}>{SearchNow}</Btn>
                                </form>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default FindContact;