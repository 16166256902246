import React from 'react';
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import axios from '../../api/axios';

const FetchDropDownFieldOptions = async (ApiEndPoint) => { 
    //console.log(`Endpoint1: ${ApiEndPoint}`)
    //const axios = useAxiosPrivate(); // Move the hook call inside the function
    //console.log(`Endpoint2: ${ApiEndPoint}`)
    try {
        const response = await axios.get(ApiEndPoint);
        return response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
        //console.error('Error fetching contact type options:', error);
        return [];
    }
};

export default FetchDropDownFieldOptions;

/* import { useEffect, useState } from 'react';
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

const useFetchDropDownFieldOptions = (apiEndPoint) => {
    const axios = useAxiosPrivate();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiEndPoint);
                const data = response.data.map(item => ({ value: item.id, label: item.name }));
                setOptions(data);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchData();
    }, [axios, apiEndPoint]);

    return options;
};

export default useFetchDropDownFieldOptions;
 */