import { Fragment } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import { hasPermission } from '../_helper/rbac';
import OrganisationRoutes from './OrganisationRoutes';

const LayoutRoutes = ({ authPermissions}) => {
  const location = useLocation();
  /* const authUsrs = {
    id: 1,
    username: 'example_user',
    permissions: ['view-dashboard', 'view_profile', 'view_dashboard', 'edit_profile']
  };
  const requiredPermission = 'view-dashboard';
  const val = authUsrs  && authUsrs.permissions.includes(requiredPermission) ? "Yes": "No";
  console.log(val)*/

  //console.log(` Index of Perm: ${authPermissions.indexOf("view-dashboard")}`) 

 /*  const hasPermission = (requiredPermission, userPermissions) => {
    // Check if the user has the required permission
    return userPermissions.includes(requiredPermission) || requiredPermission == false;
  }; */

  return (
    <>
     {/*  <Routes>
        {routes.map(({ path, Component, requiredPermission }, i) => (
          <Fragment key={i}> 
          <Route element={<AppLayout />} key={i}>
            <Route path={path} 
              element={Component} 
             /> 
          </Route>
          </Fragment>
        ))}
      </Routes> */}

      {/* <Routes>
        <Route element={<AppLayout />}>
          {routes.map(({ path, Component, requiredPermission }, i) => (
            <Fragment key={i}>
              {hasPermission(requiredPermission, authPermissions) ? (
                <Route path={path} element={Component} />
              ) : (
                
                <Navigate
                  to={`${process.env.PUBLIC_URL}/unathorised`}
                  state={{ from: location }}
                  replace
                />
              )}
            </Fragment>
          ))}
        </Route>
      </Routes> */}

      <Routes>
        <Route element={<AppLayout />}>
          <Route path={`/organisations/companies/:id/structures/*`} 
            element={<OrganisationRoutes authPermissions={authPermissions} />} />

          {routes.map(({ path, Component, requiredPermission }, i) => (
            <Route key={i} path={path} element={
              hasPermission(requiredPermission, authPermissions) ? (
                /* Component === OrganisationRoutes ? (
                  <OrganisationRoutes authPermissions={authPermissions} />
                ) : ( */
                  <Component />
                //)
              ) : (
                // Navigate only when the permission is not met
                <Navigate
                  to={`${process.env.PUBLIC_URL}/unathorised`}
                  state={{ from: location }}
                  replace
                />
              )
            } />
          ))}
        </Route>
      </Routes>


      {/* <Routes>
        {routes.map(({ path, Component, requiredPermission }, i) => (
          <Route
            key={i}
            path={path}
            element={
              authUsrs && authUsrs.permissions.includes(requiredPermission) ? (
                <AppLayout>
                  <Component />
                </AppLayout>
              ) : (
                <Navigate
                  to={`${process.env.PUBLIC_URL}/login`}
                  state={{ from: location }}
                  replace
                />
              )
            }
          />
        ))}
      </Routes> */}
    </>
  );
};

export default LayoutRoutes;