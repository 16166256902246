import { Fragment, useContext, useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { BackToList, CapacityType } from '../../../../../Constant';
import { Link, useNavigate } from 'react-router-dom';
import { base_form_fields } from '../../../../../Data/FormField/PublicData';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { bandwidth_cost_field } from '../../../../../Data/FormField/Sales';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs';
import { CapacityTypeApi } from '../../../../../api';  
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useAuth from '../../../../../Hooks/useAuth';


function FormCapacity() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${CapacityType}`));
    const CapacityList = `${process.env.PUBLIC_URL}/sales/settings/capacity-types/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }
  
        const newRecordTemp = {
            organisation_id: userOrganisationId,
            name: newRecord.name,
            description: newRecord.name,
            bandwidth_cost: newRecord.bandwidth_cost,
            is_active: newRecord.is_active,
         };

        try {
            await axiosPrivate.post(CapacityTypeApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(CapacityList);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {

                    const updatedFields = [...formFields];
                    const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
                    updatedFields.splice(indexOfName + 1, 0, bandwidth_cost_field());

                    setFormFields(updatedFields);
                    setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };

        fetchData();
    }, [formFields, isNewFieldAdded, bandwidth_cost_field]);

    

    return (

        <>
            <Fragment>
                <Breadcrumbs parent="Sales" title="Capacity Type" mainTitle="Capacity Type" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={CapacityList}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={CapacityList} formTitle={"Capacity Type"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>
        </>
    )
}

export default FormCapacity