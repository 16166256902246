import React, { Fragment, useContext } from 'react';
import CustomizerContext from '../../../../../_helper/Customizer'
import { FilePlus, CheckCircle } from 'react-feather';
import InnerStructure from '../../../../../Layout/InnerStructure';

const Structure = () => {
  const { layoutURL } = useContext(CustomizerContext);
  
  const SideNavMenus = [
    {
      title: 'Personal Details',
      icon: <FilePlus />,
      color: 'primary',
      link: `${process.env.PUBLIC_URL}/organisations/companies/structures/divisions/${layoutURL}`,
    },
    {
      title: 'Deals',
      icon: <CheckCircle />,
      color: 'success',
      link: `${process.env.PUBLIC_URL}/organisations/companies/structures/departments/${layoutURL}`,
    },
    {
      title: 'Quote',
      icon: <CheckCircle />,
      color: 'danger',
      link: `${process.env.PUBLIC_URL}/organisations/companies/structures/units/${layoutURL}`,
    },
  ];

  return (
    <Fragment>
      <InnerStructure 
        BreadcrumbParent="Contacts"
        BreadcrumbTitle="Details" 
        BreadcrumbMainTitle="Contact Name"
        SideNavMenus = {SideNavMenus}
      />
    </Fragment>
  );
};

export default Structure;