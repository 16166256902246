import { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CaseGroupApi, PriorityApi, CaseTypeApi, ServiceTypeApi, SlaApi, TeamApi, ImpactApi, UrgencyApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, createDropdownField, dynamic_field } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { CaseGroup, CaseTypeName, DelegationSLA, EscalationExecutiveTeam, EscalationSLA, EscalationTeam, Impact, Priority, ResolutionSLA, ServiceTeam, ServiceType, Team, Timeline, Urgency } from '../../../../Constant';
import { CaseCategoryApi } from '../../../../api';
import useAuth from '../../../../Hooks/useAuth';
import { member_auto_assign_field } from '../../../../Data/FormField/CrmData';

const CaseTypeCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/crm/settings/case-type/${layoutURL}`;
  const [formFields, setFormFields] = useState(base_form_fields(`${CaseTypeName}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const { formState: { errors }, } = useForm();

  const serviceTypedropdown = createDropdownField(axiosPrivate,
    'service_type_id',
    'service_type_id',
    `${ServiceType}`,
    `${ServiceTypeApi}`,
    '', 6
  );

  const caseGroupDropdown = createDropdownField(axiosPrivate,
    'case_group_id',
    'case_group_id',
    `${CaseGroup}`,
    `${CaseGroupApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const ImpactDropdown = createDropdownField(axiosPrivate,
    'impact_id',
    'impact_id',
    `${Impact}`,
    `${ImpactApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const UrgencyDropdown = createDropdownField(axiosPrivate,
    'urgency_id',
    'urgency_id',
    `${Urgency}`,
    `${UrgencyApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const PriorityDropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const ResolutionSLAdropdown = createDropdownField(axiosPrivate,
    'sla_id',
    'sla_id',
    `${ResolutionSLA}`,
    ``,
    '', 6
  );

  const DelegationSLAdropdown = createDropdownField(axiosPrivate,
    'delegation_sla_id',
    'delegation_sla_id',
    `${DelegationSLA}`,
    ``,
    '', 6
  );

  const EscalationSLAdropdown = createDropdownField(axiosPrivate,
    'escalation_sla_id',
    'escalation_sla_id',
    `${EscalationSLA}`,
    ``,
    '', 6
  );

  const ServiceTeamDropDown = createDropdownField(axiosPrivate,
    'service_team_id',
    'service_team_id',
    `${ServiceTeam}`,
    ``,
    '', 6
  );
  
  const EscalationTeamDropDown = createDropdownField(axiosPrivate,
    'escalation_team_id',
    'escalation_team_id',
    `${EscalationTeam}`,
    ``,
    '', 6
  );

  const EscalationExecutiveTeamDropDown = createDropdownField(axiosPrivate,
    'escalation_executive_team_id',
    'escalation_executive_team_id',
    `${EscalationExecutiveTeam}`,
    ``,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [serviceTypedropdown, caseGroupDropdown, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
          updatedFields.splice(indexOfName + 1, 0, 
            /* ImpactDropdown, UrgencyDropdown, 
            dynamic_field('priority,', `${Priority}`, 'text-disabled', 6, false),
            dynamic_field('priority_id,', ` `, 'hidden', 6, false), */
            PriorityDropdown, 
            { type: 'group-title', label: `${Team}`},
            ServiceTeamDropDown, EscalationTeamDropDown, EscalationExecutiveTeamDropDown,
            { type: 'group-title', label: `${Timeline}`},
            ResolutionSLAdropdown, DelegationSLAdropdown, EscalationSLAdropdown, 
            member_auto_assign_field(0,0,6));

          await updatedFields[0].fetchOptions();
          await PriorityDropdown.fetchOptions();
          await ImpactDropdown.fetchOptions();
          await UrgencyDropdown.fetchOptions();

          const indexOfServiceTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'service_team_id');
          const indexOfEscalationTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_team_id');
          const indexOfEscalationExecutiveTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_executive_team_id');

          try {
            await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
              resp.data.forEach(option => {
                updatedFields[indexOfServiceTeam].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationTeam].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationExecutiveTeam].options.push({ value: option.id, label: option.name });
              });
            });
          } catch (error) { }

          const indexOfResolutionSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'sla_id');
          const indexOfDelegationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'delegation_sla_id');
          const indexOfEscalationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_sla_id');
          
          try {
            await axiosPrivate.get(`${SlaApi}?organisation_id=${userOrganisationId}`).then((resp) => {
              resp.data.forEach(option => {
                updatedFields[indexOfResolutionSLA].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfDelegationSLA].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationSLA].options.push({ value: option.id, label: option.name });
              });
            });
          } catch (error) { }

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, caseGroupDropdown]);

  serviceTypedropdown.onChange = async (selectedServiceTypeId, updatedFields) => { 
    const caseGroupField = updatedFields.find((field) => field.id === 'case_group_id');
    
    if (caseGroupField) {
      const endPoint = `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=${selectedServiceTypeId}`
      await caseGroupField.fetchOptions(selectedServiceTypeId, false, endPoint);
      setFormFields([...updatedFields]);
    } 
  };

  ImpactDropdown.onChange = async (selectedeId, updatedFields) => { 
    const modelField = updatedFields.find((field) => field.id === 'priority_id');
    const urgencyField = updatedFields.find((field) => field.id === 'urgency_id');
    
    // Get the value of modelField
    const modelFieldValue = modelField ? modelField.value : '';
    
    // Get the value of urgencyField
    const urgencyFieldValue = urgencyField ? urgencyField.value : '';
    
    // Display the values in an alert
    //alert(selectedeId + ', ' + modelFieldValue + ', ' + urgencyFieldValue);
    /* if (modelField) {
      const endPoint = `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=${selectedServiceTypeId}`
      await modelField.fetchOptions(selectedeId, false, endPoint);
      setFormFields([...updatedFields]);
    }  */
  };

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      case_group_id: newRecord.case_group_id,
      priority_id: newRecord.priority_id,
      sla_id: newRecord.sla_id,
      delegation_sla_id: newRecord.delegation_sla_id,
      escalation_sla_id: newRecord.escalation_sla_id,
      service_team_id: newRecord.service_team_id,
      escalation_team_id: newRecord.escalation_team_id,
      escalation_executive_team_id: newRecord.escalation_executive_team_id,
      name: newRecord.name,
      description: newRecord.description,
      is_active: newRecord.is_active,
      auto_assign: newRecord.auto_assign,
     };

    try {
      await axiosPrivate.post(CaseTypeApi, newRecordTemp, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  /*   const getUploadParams = ({ meta }) => {
      return {
        url: 'http://localhost:8001/public-data/upload',
      };
    };
  
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {}; */

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="New Case Type " mainTitle="New Case Type" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CaseTypeCreate;