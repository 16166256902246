import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BankApi, BankTypeApi, TaxAuthorityApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {createDropdownField, tax_authority_fields} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Bank, BankType } from '../../../../../Constant';

const TaxAuthorityNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/tax-authorities/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(tax_authority_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const bank_type_dropdown_field = createDropdownField(axiosPrivate,
    'bank_type_id',
    'bank_type_id',
    `${BankType}`,
    `${BankTypeApi}`,
    '', 12
  );

  const bank_dropdown_field = createDropdownField(axiosPrivate,
    'bank_id',
    'bank_id',
    `${Bank}`,
    `${BankApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          // Find the index of the 'name' object
          const updatedFields = [bank_type_dropdown_field, bank_dropdown_field, ...formFields];
          
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, bank_dropdown_field]);

  bank_type_dropdown_field.onChange = async (selectedId, updatedFields) => { 
    const modelField = updatedFields.find((field) => field.id === 'bank_id');
    
    if (modelField) {
      await modelField.fetchOptions(selectedId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          bank_id: newRecord.bank_id,
          irs_name: newRecord.irs_name,
          irs_short_name: newRecord.irs_short_name,
          state_code: newRecord.state_code,
          payment_code: newRecord.payment_code,
          bank_account: newRecord.bank_account,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(TaxAuthorityApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Tax Authority" mainTitle="New Tax Authority" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TaxAuthorityNew;