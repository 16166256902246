import { useEffect, useState } from "react";
import Context from './index';
import { buildMenuItems, buildMenuItems2, generateRoutesJson } from "../rbac";
import { routes } from "../../Route/Routes";
import { MENUITEMS } from "../../Layout/Sidebar/Menu";

const AuthProvider = (props) => {
    const [auth, setAuth] = useState({});
    const [allRoutes, setAllRoutes] = useState([]);
    const [userMenus, setUserMenus] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    //const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);
    
    const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));
    let userOrganisationId;
    let authenticated;

    if (auth0_profile === null) {
        userOrganisationId = null;
        authenticated = false;
    } else {
        userOrganisationId = auth0_profile.contact.organisation.id;
        authenticated = JSON.parse(localStorage.getItem('authenticated'));
    }

    const Menus = [{
        application: 'General',
        modules: [
          {
            title: 'Dashboard',
            visible: true,
            tasks: [
              {
                title: 'Default',
                visible: false,
                path_code: 'create:crm-contact',
              },
              {
                title: 'Commerce',
                visible: true,
                path_code: 'create:crm-contact',
              },
            ],
          },
          {
            title: 'Sales',
            visible: true,
            tasks: [
              {
                title: 'Overview',
                visible: true,
                path_code: 'view:dashboard',
              },
            ],
          },
        ]
      }];

    useEffect(() => {
        const routeJson = generateRoutesJson(routes)
        //console.log(auth.menus)
        //console.log(routeJson)
        //const generatedMenus = buildMenuItems(auth.menus, routeJson);
        const generatedMenus = buildMenuItems2(MENUITEMS, routeJson);
        setAllRoutes(routeJson);
        setUserMenus(MENUITEMS);
        setMenuItems(generatedMenus);
    }, [auth, setAuth])
    return (
        <Context.Provider value={{ ...props, auth, setAuth,
            auth0_profile, userOrganisationId, authenticated,
            allRoutes, userMenus, MENUITEMS:menuItems
        //, persist, setPersist 
        }}>
            {props.children}
        </Context.Provider>
    )
}

export default AuthProvider;