import { Fragment, useContext, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { corporate_electronic_address_form_fields } from '../../../../../Data/FormField/CrmData';
import { ContactElectronicAddressUri, ContactMgmtApi } from '../../../../../api';
import { toast } from 'react-toastify';
import GenerateForm from '../../../../Common/Component/GenerateForm';

const ElectronicAddressInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields] = useState(corporate_electronic_address_form_fields);

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      contact: {
          company_email_address: editRecord.company_email_address,
          company_phone_number: editRecord.company_phone_number,
          company_alt_phone_number: editRecord.company_alt_phone_number,
          company_website: editRecord.company_website,
       }
    };
    
    try {
        await axiosPrivate.put(`${ContactMgmtApi}/${id}/${ContactElectronicAddressUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.company_name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='electronic-address'>
        <Row>
          <Col sm='12'>
          <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default ElectronicAddressInformation;