import React, { Fragment } from "react";
import sad from '../../../assets/images/auth-img.svg';
import { Container , Media} from 'reactstrap'
import { H2, H4 } from "../../../AbstractElements";

const Activated = () => {
    return (
        <Fragment>
            <section className="maintenance-sec">
                <div className="page-wrapper">
                    <div className="error-wrapper maintenance-bg">
                        <Container>
                            <Media body className="img-100" style={{width: '350px !important'}} src={sad} alt="" />
                            <div className="maintenance-heading">
                                <H2 attrH2={{ className: "headline" }} >Account Activated</H2>
                            </div>
                            <H4 attrH4={{ className: "sub-content" }} >Your profile has been successfully activated<br />You may proceed to login</H4>
                            <div><a className="btn btn-primary btn-lg text-light" href="/">LOGIN</a></div>
                        </Container>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Activated;