import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { LocationTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { base_form_fields, primary_field } from '../../../../Data/FormField/PublicData';
import { DeleteConfirmation, DeleteError, DeleteRequestFailed, DeleteSuccess, Description, Main, Status, Title } from '../../../../Constant';


const LocationTypeList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(base_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  useEffect(() => {
    if (!isNewFieldAdded) {
      // Find the index of the 'name' object
      const indexOfName = formFields.findIndex((formField) => formField.id === 'is_active');

      // Insert the new object after the 'name' object
      const updatedFields = [...formFields];
      updatedFields.splice(indexOfName + 1, 0, primary_field);

      // Update the state and set the flag
      setFormFields(updatedFields);
      setIsNewFieldAdded(true);
    }
  }, [formFields, isNewFieldAdded, primary_field]);

  const getAllLocationTypesData = async () => {
      try {
          await axiosPrivate.get(LocationTypeApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllLocationTypesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
      name: editRecord.name,
      is_primary: editRecord.is_primary,
      description: editRecord.description,
      is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${LocationTypeApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } 
        else if (error.response && error.response.status === 409) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    const shouldDelete = window.confirm(DeleteConfirmation);

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${LocationTypeApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error(DeleteRequestFailed, response.statusText);
      }
    } catch (error) {
      console.error(DeleteError, error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/organisations/location-types/create/${layoutURL}`;
  const tableColumns = [
    {
        name: `${Title}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${Description}`,
      selector: row => row.description,
      wrap: true,
      sortable: true,
      format: row => `${row.description.slice(0, 30)}...`,
    },
    {
      name: `${Main}`,
      selector: row => row.is_primary,
      sortable: true,
      center: false,
      cell: row => (row.is_primary ? <span className="badge badge-light-success">Primary</span> : <span className="badge badge-light-danger">Others</span>)
    },
    {
      
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Locations" title="Location Types" mainTitle="Location Types" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <p>Name: {selectedRowData.name}</p>
              <p>Description: {selectedRowData.description}</p>
              <p>Main: {selectedRowData.is_primary ? <span className="badge badge-light-success">Primary</span> : <span className="badge badge-light-danger">Others</span>}</p>
              <p>Status: {selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</p>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default LocationTypeList;