import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BloodGroupApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {base_form_fields2} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { BloodGroup } from '../../../../../Constant';

const BloodGroupNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/blood-groups/${layoutURL}`;

  const { formState: { errors },} = useForm();

  const blood_group_form_fields = base_form_fields2(`${BloodGroup}`);

    const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
        name: newRecord.name,
        description: newRecord.name,
        is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(BloodGroupApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }  
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Blood Group" mainTitle="New Blood Group" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={blood_group_form_fields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BloodGroupNew;