import React from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import TaskAssignerTwinTables from "../../../Common/TaskAssignerPage/TaskAssignerTwinTables";
import useAccessGroupTask from "./AccessGroupTask.controller";
import { Settings } from "../../../../Constant";
import GenerateForm from "../../../Common/Component/GenerateForm";

export default function AccessGroupTask() {
    const {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    } = useAccessGroupTask();

    return (
        <div className="mt-5 GeneralLedgerAssign">
            <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
            <div className="filter-head-pane">
                <GenerateForm
                    onSubmit={(e: any) => {}}
                    formFields={formFields}
                    formTitle={title}
                    listUrl={undefined}
                    onEdit={undefined}
                    editRecord={undefined}
                />
            </div>

            <br />

            <TaskAssignerTwinTables
                rightTableTitle={"Assign Table"}
                rightTable={assigned}
                rightTableColumns={tableColumns}
                rightButtonClick={(e: any) => assignTask(e)}
                rightButtonTitle={"Assign"}
                //
                leftTableTitle={"Un Assign Table"}
                leftTable={available}
                leftTableColumns={tableColumns}
                leftButtonClick={(e: any) => usassignTask(e)}
                leftButtonTitle={"Un Assign"}
            />
        </div>
    );
}
