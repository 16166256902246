import React from 'react'
import CapacityForm from './CapacityForm/CapacityForm'

function CreateCapacity() {
    return (
        <div>
            <CapacityForm />
        </div>
    )
}

export default CreateCapacity