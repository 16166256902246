
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Card} from 'reactstrap';
import {CardBody} from 'reactstrap';
import { BackToList } from '../../../../Constant';
import { Tax } from '../../../../Constant';
import GenerateForm from '../../../Common/Component/GenerateForm';
import { base_form_fields } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../_helper/Customizer';
import { rate_field } from '../../../../Data/FormField/Sales';
import { Breadcrumbs } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { TaxApi } from '../../../../api'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';


function TaxCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${Tax}`));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/taxes/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    const { formState: { errors },} = useForm();
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {

                    const updatedFields = [...formFields];
                    const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
                    updatedFields.splice(indexOfName + 1, 0, rate_field());

                    setFormFields(updatedFields);
                    setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };

        fetchData();
    }, [formFields, isNewFieldAdded, rate_field()]);


    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            tax_name: newRecord.name,
            description: newRecord.description,
            rate: newRecord.rate,
            is_active: newRecord.is_active,
        };

        try {
            await axiosPrivate.post(TaxApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.tax_name} is successfully added.`)
                history(lists);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };

    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Tax" mainTitle="Tax" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Tax"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default TaxCreate