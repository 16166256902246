import axios from 'axios';
//const BASE_URL = 'http://localhost:8001';
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`; // process.env.NODE_ENV == 'production' ? 'https://api.vdtcomms.com' : 'http://localhost:8001';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});