import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { BackToList } from '../../../Constant';
import GenerateForm from './GenerateForm';
import { Card, CardBody } from 'reactstrap';

const CardRibbonWithForm = ({ formFields, onSubmit, ribbonUrl, formTitle }) => {
  return (
    <Fragment>
      <Card className='ribbon-wrapper-right b-l-secondary border-2'>
        <CardBody>
          <Link to={ribbonUrl}>
            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
              {BackToList}
            </div>
          </Link>

          <GenerateForm 
            formFields={formFields} 
            onSubmit={onSubmit} 
            formTitle={formTitle}
          />

        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CardRibbonWithForm;
