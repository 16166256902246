import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RegionalContinentApi, RegionalCountryApi, RegionalStateApi, ZoneApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {base_form_fields2, state_fields, createDropdownField} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { RegionalContinent, RegionalCountry, StateName, Zone } from '../../../../../Constant';

const RegionalStateNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/regional-states/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields2(`${StateName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    '', 12
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${RegionalCountry}`,
    `${RegionalCountryApi}`,
    zoneDropdownField, 
    12
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'continent_id',
    'continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
    countryDropdownField,
    12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
         // const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
         
          const updatedFields = [continentDropdownField, countryDropdownField, zoneDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'name');
          updatedFields.splice(indexOfName + 1, 0, ...state_fields);
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, state_fields]); 

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          regional_zone_id: newRecord.regional_zone_id,
          name: newRecord.name,
          code: newRecord.code,
          capital: newRecord.capital,
          area_code: newRecord.code,
          slogan: newRecord.slogan,
          description: newRecord.name,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(RegionalStateApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Regional State" mainTitle="New Regional State" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegionalStateNew;