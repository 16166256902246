import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    ContactMgmtApi,
    // crmContactApi,
    financesGeneralLedgerGroupApi,
    financeSubLedgerAccountApi,
    financeSubLedgerClassApi,
    financeSubLedgerTypeApi,
} from "../../../../../api";
import {
    Contact,
    Description,
    ExtAccName,
    ExtAccNumber,
    GeneralLedgerGroup,
    SLAccountName,
    Status,
    SubLedgerAccount,
    SubLedgerClass,
    SubLedgerType,
} from "../../../../../Constant";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useSubLedgerAccountController() {
    const axiosPrivate = useAxiosPrivate();
    const formTitle = SubLedgerAccount;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: SLAccountName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "external_account_number",
            name: "external_account_number",
            label: ExtAccNumber,
            type: "text",
            value: "",
            required: false,
            colSize: 6,
        },

        {
            id: "external_account_name",
            name: "external_account_name",
            label: ExtAccName,
            type: "text",
            value: "",
            required: false,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //?? DROPDOWNS INITS >
    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_class_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "sl_class_id",
        "sl_class_id",
        SubLedgerClass,
        `${financeSubLedgerClassApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    subLedgerClassDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "sl_type_id"
        );
        if (nextField) {
            const endPoint = `${financeSubLedgerTypeApi}?organisation_id=${userOrganisationId}&sl_class_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "sl_class_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const subLedgerTypeDropDown = createDropdownField(
        axiosPrivate,
        "sl_type_id",
        "sl_type_id",
        SubLedgerType,
        `${financeSubLedgerTypeApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );
    subLedgerTypeDropDown.onChange = async (id, updatedFields) => {
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "sl_type_id") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
        updateFields();
    };

    const contactsDropDown = createDropdownField(
        axiosPrivate,
        "contact_id",
        "contact_id",
        Contact,
        `${ContactMgmtApi}?organisation_id=${userOrganisationId}`,
        "first_name",
        6
    );
    contactsDropDown.onChange = async (id, fields) => {
        updateFields();
    };

    const customerDropdownField = {
        name: "contact_id",
        id: "contact_number, company_name, last_name, first_name",
        api: `${ContactMgmtApi}`,
        label: `${Contact}`,
        type: "input-select",
        value: "",
        colSize: 6,
        required: true,
    };

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            generalLedgerGroupDropDown,
            subLedgerClassDropDown,
            subLedgerTypeDropDown,
            //contactsDropDown,
            customerDropdownField,
            ...formFields,
        ];
        await generalLedgerGroupDropDown.fetchOptions();
        //await contactsDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/${layoutURL}`;

    async function onSubmit(data: any) {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post(`${financeSubLedgerAccountApi}`, data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
            });
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
