export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Dashboard',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/dashboard/default`,
            title: 'Default',
            type: 'link',
          },
        ],
      },
    ]
  },
  {
    menutitle: 'System',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Organisation',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          /* {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/types`,
            title: 'Type',
            type: 'link',
          }, 
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/location-types`,
            title: 'Location Type',
            type: 'link',
          },*/
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/companies`,
            title: 'Company',
            type: 'link',
          },
          /* {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/divisions`,
            title: 'Division',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/departments`,
            title: 'Department',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/units`,
            title: 'Unit',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/cost-centres`,
            title: 'Cost Centre',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/regions`,
            title: 'Region',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/branches`,
            title: 'Branch',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/business-segments`,
            title: 'Business Segment',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/organisations/business-sub-segments`,
            title: 'Business Sub Segment',
            type: 'link',
          }, */
        ],
      },
      {
        title: 'Public Data',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/salutations`,
            title: 'Salutation',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/genders`,
            title: 'Gender',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/marital-statuses`,
            title: 'Marital Status',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/relationships`,
            title: 'Relationship',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/medical-ailments`,
            title: 'Medical Ailment',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/blood-groups`,
            title: 'Blood Group',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/genotypes`,
            title: 'Genotype',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/currencies`,
            title: 'Currencies',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/religions`,
            title: 'Religion',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/regional-continents`,
            title: 'Continent',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/regional-countries`,
            title: 'Countries',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/zones`,
            title: 'Zone',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/regional-states`,
            title: 'States',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/regional-lgas`,
            title: 'LGA',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/wards`,
            title: 'Ward',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/sectors`,
            title: 'Sector',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/sub-sectors`,
            title: 'Sub Sector',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/bank-types`,
            title: 'Bank Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/banks`,
            title: 'Bank',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/pfcs`,
            title: 'PFC',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/pfas`,
            title: 'PFA',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/pfa-bank-accounts`,
            title: 'PFA Bank Account',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/tax-authorities`,
            title: 'Tax Authorities',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/public-data/job-titles`,
            title: 'Job Title',
            type: 'link',
          },
        ],
      },
      {
        title: 'Access Control',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/categories`,
            title: 'Categories',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/groups`,
            title: 'Groups',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/applications`,
            title: 'Application',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/modules`,
            title: 'Module',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/tasks`,
            title: 'Task',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/route-details`,
            title: 'Route Details',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/access-group-task`,
            title: 'Access Group Task',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/access-control/access-category-group`,
            title: 'Access Category Group',
            type: 'link',
          },
        ],
      },
      /* {
        title: 'Maintenance',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Service Up',
            type: 'link',
          },
        ],
      },
      {
        title: 'Integration',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Rise Ticket',
            type: 'link',
          },
        ],
      },
      {
        title: 'Audit Trail',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Rise Ticket',
            type: 'link',
          },
        ],
      }, */
    ],
  },
  {
    menutitle: 'CRM',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Contacts',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/contacts`,
            title: 'All Contacts',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/contacts/branches`,
            title: 'Contact Branch',
            type: 'link',
          },
          /* {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/contacts`,
            title: 'KYC Update',
            type: 'link',
          }, */
        ],
      },
      {
        title: 'CRM-Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/contact-types`,
            title: 'Contact Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/service-type`,
            title: 'Service Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/impacts`,
            title: 'Impact ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/urgencies`,
            title: 'Urgency ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/priority`,
            title: 'Priority',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/resolution-status`,
            title: 'Resolution Status',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/sla`,
            title: 'SLA',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/root-causes`,
            title: 'Root Cause',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/resolution-codes`,
            title: 'Resolution Code',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/case-group`,
            title: 'Case Group ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/case-type`,
            title: 'Case Type ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/escalation-categories`,
            title: 'Escalation Category',
            type: 'link',
          }, 
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/escalation-reasons`,
            title: 'Escalation Reason',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/interaction-groups`,
            title: 'Interaction Group ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/interaction-types`,
            title: 'Interaction Type ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/interaction-locations`,
            title: 'Interaction Location ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/interaction-purposes`,
            title: 'Interaction Purpose ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/message-group`,
            title: 'Message Group ',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/settings/message-type`,
            title: 'Message Type ',
            type: 'link',
          },
        ],

      },
      {
        title: 'Case Management',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/case-management/case`,
            title: 'Case',
            type: 'link',
          },
        ],

      },
      {
        title: 'Interaction Management',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/interaction-management/interactions`,
            title: 'Log Interaction',
            type: 'link',
          },
        ],

      },
      {
        title: "CRM-Report",
        icon: "animation",
        type: "sub",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/case`,
            title: "Case Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/case-group-sla`,
            title: "Case Group SLA Report",
            type: "link",
          },
        ]
      },
    ]
  },
  {
    menutitle: 'HRM',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'HRM-Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/settings/employee-categories`,
            title: 'Employee Category',
            type: 'link',
          },
          /* {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/settings/kyc`,
            title: 'KYC',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/settings/kyc-address-proof`,
            title: 'KYC-address-proof',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/settings/bank-payment-info-create`,
            title: 'Bank Payment Info',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/settings/remittance-codes/create`,
            title: 'Remittance Codes',
            type: 'link'
          },
          {
            path: `${process.env.PUBLIC_URL}/hrm/settings/medical-information/create`,
            title: 'Medical Information',
            type: 'link',
          }, */
        ]
      },
      {
        title: 'Personnel',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/personnel/team-types`,
            title: 'Team Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/personnel/teams`,
            title: 'Team',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/personnel/team-members`,
            title: 'Team Member',
            type: 'link',
          },
        ],
      },
      {
        title: 'Employee',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/employees`,
            title: 'All Employees',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/hrm/employees/contact-mapping`,
            title: 'Employee Mapping',
            type: 'link',
          },
        ],
      },
    ]
  },
  {
    menutitle: 'Sales',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'S-Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/capacity-types`,
            title: 'Capacity Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/capacities`,
            title: 'Capacity',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/invoice-types`,
            title: 'Invoice Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/invoice-cycles`,
            title: 'Invoice Cycle',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/credit-terms`,
            title: 'Credit Term',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/taxes`,
            title: 'Tax',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/product-groups`,
            title: 'Product Group',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/product-classes`,
            title: 'Product Class',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/product-types`,
            title: 'Product Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/settings/products`,
            title: 'Product',
            type: 'link',
          },

        ],
      },
      /* {
        title: 'Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Products',
            type: 'link',
          },
        ],
      },
      {
        title: 'SalesForce',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Teams',
            type: 'link',
          },
        ],
      },
      {
        title: 'Pipeline',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Prospect',
            type: 'link',
          },
        ],
      }, */
      {
        title: 'Sales',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/customers/mapping`,
            title: 'Conversion',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/customers`,
            title: 'Customer',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/commission`,
            title: 'My Commission',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/sales/links`,
            title: 'Links',
            type: 'link',
          },
        ],
      },
      /* {
        title: 'After-Sales',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Retention',
            type: 'link',
          },
        ],
      },
      {
        title: 'Partners',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Dealers',
            type: 'link',
          },
        ],
      },
      {
        title: 'Reports',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `#`,
            title: 'Standard',
            type: 'link',
          },
        ],
      }, */
    ]
  },
  {
    menutitle: 'Technical',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            title: 'Device',
            icon: 'sample-page',
            type: 'sub',
            children: [
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/makers`,
                title: 'Makers',
                type: 'link',
              },
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/models`,
                title: 'Models',
                type: 'link',
              },
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/radio-types`,
                title: 'Radio Types',
                type: 'link',
              },
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/switch-types`,
                title: 'Switch Types',
                type: 'link',
              },
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/access-names`,
                title: 'Access Names',
                type: 'link',
              },
              /* {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/device-inventory`,
                title: 'Device Inventory',
                type: 'link',
              }, */
            ],
          },
          {
            title: 'Link',
            icon: 'sample-page',
            type: 'sub',
            children: [
              {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/link-types`,
                title: 'Link Type',
                type: 'link',
              },
              /* {
                active: false,
                path: `${process.env.PUBLIC_URL}/technical/link`,
                title: 'Link',
                type: 'link',
              }, */
            ],
          },

        ],
      },

    ]
  },
  {
    menutitle: 'Finance',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'F-Settings',
        icon: 'animation',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/report-type`,
            title: 'Report Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-group`,
            title: 'General Ledger Group',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-class`,
            title: 'General Ledger Class',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-type`,
            title: 'General Ledger Type',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-account`,
            title: 'General Ledger Account',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-class`,
            title: 'Sub Ledger Class',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-type`,
            title: 'Sub Ledger Type',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account`,
            title: 'Sub Ledger Account',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class`,
            title: 'Group GL Class',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-type`,
            title: 'Group GL Type',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-number`,
            title: 'Group GL Number',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/income-category`,
            title: 'Income Category',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/income-group`,
            title: 'Income Group',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/income-class`,
            title: 'Income Class',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/income-type`,
            title: 'Income Type',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/income-description`,
            title: 'Income Description',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/expense-category`,
            title: 'Expense Category',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/expense-group`,
            title: 'Expense Group',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/expense-class`,
            title: 'Expense Class',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/expense-type`,
            title: 'Expense Type',
            type: 'link',
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/settings/expense-description`,
            title: 'Expense Description',
            type: 'link',
          },
        ]
      },
      {
        title: "F-Report",
        icon: "animation",
        type: "sub",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/general-ledger-class`,
            title: "General Ledger Class",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/general-ledger-type`,
            title: "General Ledger Type",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/general-ledger-account`,
            title: "General Ledger Account",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/sub-ledger-class`,
            title: "Sub Ledger Class Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/sub-ledger-type`,
            title: "Sub Ledger Type Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/sub-ledger-account`,
            title: "Sub Ledger Account Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/income-group`,
            title: "Income Group Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/income-class`,
            title: "Income Class Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/income-type`,
            title: "Income Type Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/income-description`,
            title: "Income Desc. Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/expense-group`,
            title: "Expense Group Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/expense-class`,
            title: "Expense Class Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/expense-type`,
            title: "Expense Type Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/expense-description`,
            title: "Expense Desc. Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/group-general-ledger-type`,
            title: "Group GL Type Report",
            type: "link",
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/finance/report/group-general-ledger-number`,
            title: "Group GL Number Report",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: 'Administration',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'A-Settings',
        icon: 'sample-page',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/inventory-category`,
            title: 'Inventory Category',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/inventory-group`,
            title: 'Inventory Group',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/inventory-class`,
            title: 'Inventory Class',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/inventory-type`,
            title: 'Inventory Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/inventory-description`,
            title: 'Inventory Description',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-group`,
            title: 'Fixed Assets Group',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-class`,
            title: 'Fixed Assets Class',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-type`,
            title: 'Fixed Assets Type',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-description`,
            title: 'Fixed Assets Description',
            type: 'link',
          },
          /*{
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/spouse/create`,
            title: 'Spouse',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/next-of-kin/create`,
            title: 'Next Of Kin',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/payment-on-death/create`,
            title: 'Payment On Death',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/referee-information`,
            title: 'Referee Information',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administration/settings/guarantor-information`,
            title: "Guarantor's Information",
            type: 'link',
          },*/

          // ====PRODUCT DESCRIPTION
          //Name Product-Type Description Status

          //==== Fixed Assets group
          // Name Description and status ------------------->

          //==== Fixed assets class
          // Fixxed asset group, Name, Description and Status, Useful life (txt box), Capitalization limit ------------->

          //==== Fixed asset type
          //Group and class (Select), Name, Description and Status.  ------------------->

          //===== Fixed assets description
          // select group class and type, Name Description and status ---------->
        ],
      },
    ]
  },
];
