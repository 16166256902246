import { Input, Button, Form, FormGroup } from "reactstrap";
import useCaseGroupSLAReport from "./controller";
import { Breadcrumbs } from "../../../../AbstractElements";
import { CaseGroup, CaseTypeName, DateRange, DownloadCSV, GenerateReport, PrintReport, Priority, Report, ResolutionStatus, SelectOption, TrackingNumber } from "../../../../Constant";
import DatePicker from "react-datepicker";
import Spinner from "../../../Common/Component/Spinner";
import { IReport, IServiceTeamReport } from "./types";
import ReactDOMServer from 'react-dom/server';
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper";
import ViewModal from "../../../Common/Component/ViewModal";

export default function CaseGroupSLAReport() {
    const {
        printedData,
        CaseGroupDropDown,
        CaseTypeDropDown,
        PriorityDropDown,
        ResolutionStatusDropDown,
        startDate,
        endDate,
        loading,
        showPopup,
        caseSLADetails,
        setDateRange,
        exportToExcel,
        exportSLADetailsToCSV,
        generateSLADetailsPrint,
        getTableFunction,
        getContactDetails,
        getEmployeeContactDetails,
        fetchCaseDetails,
        handlePopupClose,
        setCaseGroupDropDownValue,
        setCaseTypeDropDownValue,
        setPriorityDropDownValue,
        setResolutionStatusDropDownValue,
        setTrackingNumberValue,
    } = useCaseGroupSLAReport();

    const generateTable = (case_group_id: number, serviceTeamReports: IServiceTeamReport[]) => {
        // Get unique months dynamically
        const allMonths = [...new Set(serviceTeamReports.flatMap(teamReport =>
          teamReport.sla_stats.map(stat => stat.month)
        ))];
      
        // Generate table headers
        const tableHeaders = ['PARAMETERS', 'TOTAL', ...serviceTeamReports.map(team => team.team_name)];
      
        // Generate table rows dynamically based on parameters
        const parameters = ['Met SLA', 'Unmet SLA', 'TOTAL', '%MET', '%NOT MET'];
      
        const rows = parameters.map(param => {
            const totalRow = allMonths.map(month => {
                const totals = serviceTeamReports.map(teamReport => {
                  const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                  if (param === 'Met SLA') return slaStat ? slaStat.met_sla : 0;
                  if (param === 'Unmet SLA') return slaStat ? slaStat.unmet_sla : 0;
                  if (param === 'TOTAL') return slaStat ? slaStat.total : 0;
                  if (param === '%MET') return slaStat ? parseFloat(slaStat.percent_met) : 0;
                  if (param === '%NOT MET') return slaStat ? parseFloat(slaStat.percent_not_met) : 0;
                  return 0;
                });
          
                if (param === '%MET' || param === '%NOT MET') {
                  const total = totals.reduce((acc, val) => acc + val, 0);
                  return `${(total / totals.length).toFixed(2)}%`;
                } else {
                  return totals.reduce((acc, val) => acc + val, 0);
                }
            });
      
            return (
                <tr key={param}>
                <td>{param}</td>
                {totalRow.map((total, index) => (
                    <td className="text-center" key={index}>{total}</td>
                ))} 

                {/* {totalRow.map((total, index) => {
                    const totalValue = typeof total === 'string' ? parseFloat(total) : total;
                    const clickable = (param === 'Met SLA' || param === 'Unmet SLA') && totalValue > 0;    
                    
                    return (
                        <td 
                            className={`text-center ${clickable ? 'clickable' : ''}`} 
                            key={index}
                            onClick={() => {
                                if (clickable) {
                                    fetchCaseDetails(21, 0, '2024-06', param === 'Met SLA' ? 'met' : 'unmet');
                                }
                            }}
                        >
                            {total}
                        </td>
                    );
                })} */}
                {serviceTeamReports.map((teamReport, teamIndex) => {
                    return allMonths.map(month => {
                    const slaStat = teamReport.sla_stats.find(stat => stat.month === month);
                    
                    let value: string | number = ' - ';
                    let clickable = false;
                    let sla_month = '';

                    if (slaStat) {
                        sla_month = slaStat.sla_month;
                        if (param === 'Met SLA') {
                            value = slaStat.met_sla;
                            clickable = slaStat.met_sla > 0;
                        }
                        if (param === 'Unmet SLA') {
                            value = slaStat.unmet_sla;
                            clickable = slaStat.unmet_sla > 0;
                        }
                        if (param === 'TOTAL') value = slaStat.total;
                        if (param === '%MET') value = slaStat.percent_met;
                        if (param === '%NOT MET') value = slaStat.percent_not_met;
                    }
                    //return <td  className="text-center" key={`${teamIndex}-${month}-${param}`}>{value}</td>;
                    return (
                        <td 
                            className={`text-center ${clickable ? 'clickable' : ''}`} 
                            key={`${teamIndex}-${month}-${param}`}
                            onClick={() => {
                                if (clickable) {
                                    fetchCaseDetails(case_group_id, teamReport.team_id, sla_month, param === 'Met SLA' ? 'met' : 'unmet');
                                }
                            }}
                        >
                            {value}
                        </td>
                    );
                    });
                })}
                </tr>
            );
        });
      
        return (
            <table className="table table-bordered">
                <thead>
                <tr>
                    {tableHeaders.map((header, index) => (
                    <th key={index} colSpan={index ? allMonths.length : 1}>{header}</th>
                    ))}
                </tr>
                <tr>
                    <th>Month</th>
                    {tableHeaders.map((header, index) => (
                        index > 0 && allMonths.map(month => (
                        <th className="text-center" key={`month-${month}`}>{month}</th>
                        ))
                    ))}
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    };

    const generatePrintSLA = (printedData: IReport[]) => {
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <style>
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            td,
            th {
                border: 1px solid #dddddd;
                text-align: left;
                padding: 8px;
            }

            tr:nth-child(even) {
                background-color: #dddddd;
            }
            </style>
        </head>
        <body>
            ${printedData.map((reportGroup, groupIndex) => (
                `<div key=${groupIndex}>
                    <h5>${reportGroup.case_group_name}</h5>
                    ${ReactDOMServer.renderToStaticMarkup(generateTable(reportGroup.case_group_id, reportGroup.service_team_reports))}
                    <br/><br/>
                </div>`
            )).join('')}
            <script>
                window.print();
                setTimeout(() => {
                window.close();
                if (!window.closed) {
                alert('Please allow popups for this website to close the window automatically.');
                }
                }, 100);
            </script>
        </body>
        </html>`;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
        URL.revokeObjectURL(blobUrl);
    };

    return (
        <div>
            <Breadcrumbs
                parent={Report}
                title={"Case Grop SLA Report"}
                mainTitle={"Case Grop SLA Report"}
            />

            <br />

            <div className="row gap-y-3">
                <div className="col-md-4  mb-3">
                    <p className="mb-0">{DateRange}</p>
                    <DatePicker
                        className="form-control w-100"
                        required
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update: Date[] | any) => {
                            setDateRange(update);
                        }}
                        withPortal
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <p className="mb-0">{CaseGroup}</p>
                    <Input
                        onChange={(e) =>
                            setCaseGroupDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {CaseGroupDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4  mb-3">
                    <p className="mb-0">{CaseTypeName}</p>
                    <Input
                        onChange={(e) =>
                            setCaseTypeDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {CaseTypeDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{Priority}</p>
                    <Input
                        onChange={(e) =>
                            setPriorityDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {PriorityDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{ResolutionStatus}</p>
                    <Input
                        onChange={(e) =>
                            setResolutionStatusDropDownValue(e.target.value)
                        }
                        type="select"
                    >
                        <option hidden>{SelectOption}</option>
                        {ResolutionStatusDropDown.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>

                <div className="col-md-4 mb-3">
                    <p className="mb-0">{TrackingNumber}</p>
                    <Input
                        onChange={(e) =>
                            setTrackingNumberValue(e.target.value)
                        }
                        type="text"
                    />
                </div>

                <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
                    <Button
                        color="primary"
                        onClick={getTableFunction}
                        className="mt-4"
                    >
                        <i className="fa fa-book"></i>&nbsp;
                        {GenerateReport}
                    </Button>
                </div>
            </div>

            <br />
            <br />

            {loading ? <Spinner/> : ""}            
            {printedData.length !== 0 && (
                <>
                    <div className="row">
                        <Form className="col">
                            <FormGroup>
                                {/* <Label for="search">Search</Label>
                                <Input id="search" /> */}
                            </FormGroup>
                        </Form><br/><br />
                        <div className="col d-flex gap-2 justify-content-end align-items-center">
                            <Button
                                color="primary"
                                className="btn-xs"
                                onClick={() => generatePrintSLA(printedData)}
                            >
                                <i className="fa fa-print"></i>&nbsp;
                                {PrintReport}
                            </Button>

                            {/* <Button
                                color="warning"
                                className="btn-xs"
                                onClick={() => exportToExcel(printedData)}
                            >
                                <i className="fa fa-cloud-download"></i>&nbsp;
                                {DownloadExcel}
                            </Button> */}
                        </div>
                    </div>

                    <div className="table-responsive">
                        {printedData.map((reportGroup, groupIndex) => (
                            <div key={groupIndex}>
                                <h5>{reportGroup.case_group_name}</h5>
                                {generateTable(reportGroup.case_group_id, reportGroup.service_team_reports)}
                                <br/><br/>
                            </div>
                        ))}
                    </div>
                </>
            )}

            <ViewModal
                isOpen={showPopup}
                toggle={handlePopupClose}
                rowData={undefined}
                formFields={undefined}
                editMode={undefined}
                onEdit={undefined}
                modalTitle={undefined}
                onSubmit={undefined}
            >
                <>
                <div className="row">
                        <Form className="col">
                            <FormGroup>
                                {/* <Label for="search">Search</Label>
                                <Input id="search" /> */}
                            </FormGroup>
                        </Form><br/><br />
                        <div className="col d-flex gap-2 justify-content-end align-items-center">
                            <Button
                                color="primary"
                                className="btn-xs"
                                onClick={() => generateSLADetailsPrint(caseSLADetails)}
                            >
                                <i className="fa fa-print"></i>&nbsp;
                                {PrintReport}
                            </Button>

                            <Button
                                color="warning"
                                className="btn-xs"
                                onClick={() => exportSLADetailsToCSV(caseSLADetails)}
                            >
                                <i className="fa fa-cloud-download"></i>&nbsp;
                                {DownloadCSV}
                            </Button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th className="text-center">S/N</th>
                                <th>Track Number</th>
                                <th>Contact</th>
                                <th>Contact Type</th>
                                <th>Link</th>
                                <th>Case</th>
                                <th>Case Group</th>
                                <th>Case Type</th>
                                <th>Impact</th>
                                <th>Urgency</th>
                                <th>Priority</th>
                                <th>Resolution Status</th>
                                <th>Opened By</th>
                                <th>Date Created</th>
                                <th>Due Date</th>
                                <th>Service Team</th>
                                <th>Resolved By</th>
                                <th>Resolved Date</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {/* contact_branch.contact_number, contact_branch.name | contact.contact_number, contact.company_name | contact.contact_number, contact.last_name, contact.first_name | [product.name] */}
                                {caseSLADetails.map((item, index) => (
                                    <tr key={item.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{item.track_number}</td>
                                        <td>{item.contact_name}</td>
                                        <td>{item.contact_type}</td>
                                        <td>{item.link}</td>
                                        <td>{item.subject}</td>
                                        <td>{item.case_group}</td>
                                        <td>{item.case_type}</td>
                                        <td>{item.impact}</td>
                                        <td>{item.urgency}</td>
                                        <td>{item.priority}</td>
                                        <td>{item.resolution_status}</td>
                                        <td>({item.opened_by.contact_number}) {item.opened_by.last_name} {item.opened_by.first_name} {item.opened_by.middle_name}</td>
                                        <td>{formatDateWithTimeZone(item.created_at)}</td>
                                        <td>{formatDateWithTimeZone(item.due_date)}</td>
                                        <td>{item.service_team}</td>
                                        <td>
                                            {item.resolved_by && (
                                                <>
                                                ({item.resolved_by.contact_number}) {item.resolved_by.last_name} {item.resolved_by.first_name} {item.resolved_by.middle_name}
                                                </>
                                            )}
                                            </td>
                                        <td>{item.resolved_at ? formatDateWithTimeZone(item.resolved_at) : ""}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
            </ViewModal>
            
        </div>
    );
}
