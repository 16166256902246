import { useLayoutEffect, useState } from "react";
import {
    AccessGroup,
    AccessGroupTask,
    Name,
    Status,
    UserType,
} from "../../../../Constant";
import { createDropdownField } from "../../../../Data/FormField/PublicData";
import useAuth from "../../../../Hooks/useAuth";
import { axiosPrivate } from "../../../../api/axios";
import { StatusRow } from "../../../Finance/Settings/GeneralLedgerGroup/List/GeneralLedgerGroupList";
import { toast } from "react-toastify";
import {
    accessGroupsApi,
    accessGroupTaskApi,
    userTypeApi,
} from "../../../../api";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";

export default function useAccessGroupTask() {
    const axiosPrivate = useAxiosPrivate()
    const title = AccessGroupTask;
    const { userOrganisationId }: any = useAuth();
    const [accessGroupId, setAccessGroupId] = useState(0);
    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [formFields, setFormFields] = useState<any[]>([]);

    //?? DROPDOWNS INITS >
    const userType = createDropdownField(axiosPrivate,
        "user_type",
        "user_type",
        UserType,
        `${userTypeApi}`,
        "",
        6,
        false,
        "table_title"
    );
    userType.onChange = async (id, updatedFields) => {
        setAccessGroupId(0);
        setAssigned([]);
        setAvailable([]);
        const accessGroupsDropDown = updatedFields.find(
            (field: any) => field.id === "access_group_id"
        );
        if (accessGroupsDropDown) {
            const endPoint = `${accessGroupsApi}?skip=0&limit=10&with_trashed=false&organisation_id=${userOrganisationId}&user_type_id=${id}`;
            await accessGroupsDropDown.fetchOptions(id, false, endPoint);
            setFormFields([...updatedFields]);
        }
    };
    const accessGroups = createDropdownField(axiosPrivate,
        "access_group_id",
        "access_group_id",
        AccessGroup,
        "",
        "",
        6
    );

    accessGroups.onChange = async (id, updatedFields) => {
        try {
            setAccessGroupId(id);
            const endPoint = `${accessGroupTaskApi}/${id}/tasks?organisation_id=${userOrganisationId}`;
            axiosPrivate
                .get(endPoint)
                .then((res) => {
                    //console.log(res.data);
                    setAssigned([]);
                    setAvailable([]);

                    if (res.data) {
                        setAssigned(res.data.assigned_items);
                        setAvailable(res.data.available_items);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error: Please Select All Fields");
                    setAssigned([]);
                    setAvailable([]);
                });
        } catch (error) {
            //console.log("CAUGHT ERROR:", error);
        }
    };

    const tableColumns = [
        {
            name: Name,
            selector: (row: any) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Status,
            selector: (row: any) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: any) => StatusRow(row),
        },
    ];

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, userType, accessGroups];
        await userType.fetchOptions();
        setFormFields(updatedFields);
        //console.log(updatedFields);
    }
    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const assignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No tasks selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/access-controls/groups/${accessGroupId}/assign-tasks?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Tasks assigned successfully");
                //console.log(res.data);
            });
    };

    const usassignTask = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No tasks selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/access-controls/groups/${accessGroupId}/remove-tasks?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Tasks unassigned successfully");
                //console.log(res.data);
            });
    };

    return {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignTask,
        usassignTask,
    };
}
