import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LocationDropdownApi, TeamApi, TeamTypeApi, UnitApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { Location, Team, TeamType, Unit } from '../../../../Constant';
import useAuth from '../../../../Hooks/useAuth';

const TeamCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/hrm/personnel/teams/${layoutURL}`;
  const [formFields, setFormFields] = useState(base_form_fields(`${Team}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const { formState: { errors }, } = useForm();

  const teamTypeDropdown = createDropdownField(axiosPrivate,
    'team_type_id',
    'team_type_id',
    `${TeamType}`,
    `${TeamTypeApi}?organisation_id=${userOrganisationId}`,
    '', 12
  );

  const unitDropdown = createDropdownField(axiosPrivate,
    'unit_id',
    'unit_id',
    `${Unit}`,
    `${UnitApi(userOrganisationId)}`,
    '', 12, false
  );

  const locationTypeDropdown = createDropdownField(axiosPrivate,
    'location_id',
    'location_id',
    `${Location}`,
    `${LocationDropdownApi(userOrganisationId)}`,
    '', 12, false
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [teamTypeDropdown, ...formFields, unitDropdown, locationTypeDropdown];
          
          await updatedFields[0].fetchOptions();
          await unitDropdown.fetchOptions();
          await locationTypeDropdown.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, teamTypeDropdown]);

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      team_type_id: newRecord.team_type_id,
      name: newRecord.name,
      description: newRecord.name,
      is_active: newRecord.is_active,
      unit_id: newRecord.unit_id,
      location_id: newRecord.location_id,
    };

    try {
      await axiosPrivate.post(TeamApi, newRecordTemp, {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="New Team" mainTitle="New Team" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TeamCreate;