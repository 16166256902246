import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    BalanceType,
    Code,
    Description,
    GeneralLedgerGroup,
    Name,
    ReportType,
    Status,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { financesReportTypeApi } from "../../../../../api";
import { toast } from "react-toastify";
import { IGeneralLedgerGroupPayload } from "../GeneralLedgerGroup.types";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useGeneralLedgerGroupController() {
    const axiosPrivate = useAxiosPrivate();
    const formTitle = GeneralLedgerGroup;
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-group/${layoutURL}`;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "balance_type",
            name: "balance_type",
            label: BalanceType,
            type: "radio",
            value: "",
            colSize: 6,
            options: [
                { label: "Credit", value: 1 },
                { label: "Debit", value: 0 },
            ],
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    const reportTypeDropDown = createDropdownField(
        axiosPrivate,
        "report_type_id",
        "report_type_id",
        ReportType,
        `${financesReportTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    reportTypeDropDown.onChange = async (id, fields) => {
        updateFields();
    };

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            reportTypeDropDown,
            ...formFields,
            // balanceLedgerDropDown,
        ];
        await reportTypeDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const onSubmit = async (data: IGeneralLedgerGroupPayload) => {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/general-ledger-groups", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
            });
    };

    return { formTitle, formFields, backBtn, onSubmit };
}
