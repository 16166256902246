import { Fragment, useContext, useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap';
import GenerateForm from '../../../Common/Component/GenerateForm';
import { BackToList, InvoiceType } from '../../../../Constant';
import { Link, useNavigate } from 'react-router-dom';
import { base_form_fields } from '../../../../Data/FormField/PublicData';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { useForm } from 'react-hook-form';
import { InvoiceTypeApi } from '../../../../api'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';


function InvoiceTypeCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${InvoiceType}`));
    const InvoiceTypeList = `${process.env.PUBLIC_URL}/sales/settings/invoice-types/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            name: newRecord.name,
            description: newRecord.name,
            is_active: newRecord.is_active,
        };

        try {
            await axiosPrivate.post(InvoiceTypeApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(InvoiceTypeList);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };


    return (

        <>
            <Fragment>
                <Breadcrumbs parent="Sales" title="Invoice Type" mainTitle="Invoice Type" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={InvoiceTypeList}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={InvoiceTypeList} formTitle={"Invoice Type"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>
        </>
    )
}

export default InvoiceTypeCreate