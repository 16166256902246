import { useLayoutEffect, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    CaseApi,
    CaseGroupApi,
    CaseTypeApi,
    PriorityApi,
    ResolutionStatusApi,
} from "../../../../api";
import useAuth from "../../../../Hooks/useAuth";
import { IResolutionStatusRes, IPriorityRes, ICaseRes, ICaseGroupRes, ICaseTypeRes, IEmployeeBasicRes, IContactBasicRes } from "./types";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { formatDateWithTimeZone } from "../../../../_helper/dateHelper";
import { ContactTypeVal } from "../../../../Data/FormField/PublicData";

export default function useCaseReport() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();

    const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);
    const [startDate, endDate] = dateRange;

    const [loading, setLoading] = useState(false);

    const [PriorityDropDown, setPriorityDropDown] = useState<IPriorityRes[]>([]);
    const [PriorityDropDownValue, setPriorityDropDownValue] = useState("0");

    const [ResolutionStatusDropDown, setResolutionStatusDropDown] = useState<IResolutionStatusRes[]>([]);
    const [ResolutionStatusDropDownValue, setResolutionStatusDropDownValue] = useState("0");

    const [CaseGroupDropDown, setCaseGroupDropDown] = useState<ICaseGroupRes[]>([]);
    const [CaseGroupDropDownValue, setCaseGroupDropDownValue] = useState("0");

    const [CaseTypeDropDown, setCaseTypeDropDown] = useState<ICaseTypeRes[]>([]);
    const [CaseTypeDropDownValue, setCaseTypeDropDownValue] = useState("0");
    
    const [TrackingNumberValue, setTrackingNumberValue] = useState("");

    const [printedData, setPrintedData] = useState<ICaseRes[]>([]);

    async function getPriority() {
        try {
            axiosPrivate
                .get(
                    `${PriorityApi}?&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setPriorityDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getResolutionStatus() {
        try {
            axiosPrivate
                .get(
                    `${ResolutionStatusApi}?&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setResolutionStatusDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getCaseGroup() {
        try {
            axiosPrivate
                .get(
                    `${CaseGroupApi}?organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setCaseGroupDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getCaseType() {
        try {
            axiosPrivate
                .get(
                    `${CaseTypeApi}?organisation_id=${userOrganisationId}&case_group_id=${CaseGroupDropDownValue}`
                )
                .then((res) => {
                    setCaseTypeDropDown(res.data.items);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    useEffect(() => {
        if (CaseGroupDropDownValue !== "0") getCaseType();
    }, [CaseGroupDropDownValue]);

    useLayoutEffect(() => {
        getCaseGroup();
        getPriority();
        getResolutionStatus();
    }, []);

    const getCaseURL = (data = {}) => {
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined && value !== 0)
        );
    
        const params = new URLSearchParams({
            organisation_id: userOrganisationId,
            ...filteredData
        }).toString();
    
        return `${CaseApi}/report?${params}`;
    }

    const getEmployeeContactDetails = (contactDetails: IEmployeeBasicRes) => {
        if (!contactDetails) {
            return "";
        }

        const contactType = contactDetails?.contact?.contact_type;
        const contactName =
          contactType === ContactTypeVal.Individual
            ? `${contactDetails?.contact?.last_name} ${contactDetails?.contact?.first_name} ${contactDetails?.contact?.middle_name}`
            : `${contactDetails?.contact?.company_name}`;
      
        return `${contactName} (${contactDetails?.employee_number})`;
    };

    const getContactDetails = (contactDetails: IContactBasicRes) => {
        if (!contactDetails) {
            return "";
        }

        const contactType = contactDetails?.contact_type;
        const contactName =
          contactType === ContactTypeVal.Individual
            ? `${contactDetails?.last_name} ${contactDetails?.first_name} ${contactDetails?.middle_name}`
            : `${contactDetails?.company_name}`;
      
        return `${contactName} (${contactDetails?.contact_number})`;
    };
      

    async function getTableFunction() {
        const data = {organisation_id: userOrganisationId,
            case_group_id: CaseGroupDropDownValue,
            case_type_id: CaseTypeDropDownValue,
            priority_id: PriorityDropDownValue,
            resolution_status_id: ResolutionStatusDropDownValue,
            track_number:TrackingNumberValue,
            report:true,
            start_date: startDate ? formatDateWithTimeZone(startDate, "", "YYYY-MM-DD") : '', 
            end_date: endDate? formatDateWithTimeZone(endDate, "", "YYYY-MM-DD") : ''
        }
        setLoading(true); 
        setPrintedData([]);   
        await axiosPrivate
            .get(
                getCaseURL(data)
            )
            .then((res) => {
                const result: ICaseRes[] = res.data;
                //const result: ICaseRes[] = res.data.items;
                setPrintedData(result);
                setLoading(false);
            })
            .catch((err) => {
                setPrintedData([]);
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
            })
            .finally(() => {
                setLoading(false);
            });;
    }

    function generatePrint(res: ICaseRes[]) {
        const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th className="text-center">S/N</th>
              <th>Track Number</th>
              <th>Contact</th>
              <th>Contact Type</th>
              <th>Case</th>
              <th>Case Group</th>
              <th>Case Type</th>
              <th>Impact</th>
              <th>Urgency</th>
              <th>Priority</th>
              <th>Resolution Status</th>
              <th>Opened By</th>
              <th>Date Created</th>
              <th>Due Date</th>
              <th>Resolved By</th>
              <th>Resolved Date</th>
            </tr>
          </thead>
          <tbody>
            ${res
                .map(
                    (item, index) =>
                        `<tr>
                        <td className="text-center">${index + 1}</td>
                        <td>${item.track_number}</td>
                        <td>${getContactDetails(item.contact)}</td>
                        <td>${item.contact.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate"}</td>
                        <td>${item.subject}</td>
                        <td>${item.case_type.case_group.name}</td>
                        <td>${item.case_type.name}</td>
                        <td>${item.priority.impact.name}</td>
                        <td>${item.priority.urgency.name}</td>
                        <td>${item.priority.name}</td>
                        <td>${item.resolution_status.name}</td>
                        <td>${getEmployeeContactDetails(item.opened_by_employee)}</td>
                        <td>${formatDateWithTimeZone(item.created_at)}</td>
                        <td>${formatDateWithTimeZone(item.due_date)}</td>
                        <td>${getEmployeeContactDetails(item.resolved_by_employee)}</td>
                        <td>${item.resolved_at ? formatDateWithTimeZone(item.resolved_at) : ""}</td>
                    </tr>`
                )
                .join("")}
            </tbody>
          <tfoot>
            <tr>
                <th className="text-center">S/N</th>
                <th>Track Number</th>
                <th>Contact</th>
                <th>Contact Type</th>
                <th>Case</th>
                <th>Case Group</th>
                <th>Case Type</th>
                <th>Impact</th>
                <th>Urgency</th>
                <th>Priority</th>
                <th>Resolution Status</th>
                <th>Opened By</th>
                <th>Date Created</th>
                <th>Due Date</th>
                <th>Resolved By</th>
                <th>Resolved Date</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
        URL.revokeObjectURL(blobUrl);
    }

    function exportToCSV(res: any[]) {
        const csvHeaders = [
            "S/N",
            "Track Number",
            "Contact",
            "Contact Type",
            "Case",
            "Case Group",
            "Case Type",
            "Impact",
            "Urgency",
            "Priority",
            "Resolution Status",
            "Opened By",
            "Date Created",
            "Due Date",
            "Resolved By",
            "Resolved Date",
        ];
        
        const data = res.map((item, index) => ({
            sn: index + 1,
            track_number: item.track_number,
            contact: getContactDetails(item.contact),
            contact_type: item.contact.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate",
            case: `"${item.subject}"`,
            case_group: item.case_type.case_group.name,
            case_type: item.case_type.name,
            impact: item.priority.impact.name,
            urgency: item.priority.urgency.name,
            priority: item.priority.name,
            resolution_status: item.resolution_status.name,
            opened_by: getEmployeeContactDetails(item.opened_by_employee),
            date_created: `"${formatDateWithTimeZone(item.created_at)}"`,
            due_date: `"${formatDateWithTimeZone(item.due_date)}"`,
            resolved_by: getEmployeeContactDetails(item.resolved_by_employee),
            resolved_at: `"${item.resolved_at ? formatDateWithTimeZone(item.resolved_at) : ""}"`
        }));

        const dataKeys: (keyof (typeof data)[0])[] = [
            "sn",
            "track_number",
            "contact",
            "contact_type",
            "case",
            "case_group",
            "case_type",
            "impact",
            "urgency",
            "priority",
            "resolution_status",
            "opened_by",
            "date_created",
            "due_date",
            "resolved_by",
            "resolved_at",
        ];

        const csvContent =
            csvHeaders.join(",") +
            "\n" +
            data
                .map((row) => `${dataKeys.map((key) => row[key]) + ","}`)
                .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `case_report_${formatDateWithTimeZone(new Date(), "", "YYYY_MM_DD_HH_MM_SS")}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    return {
        printedData,
        CaseGroupDropDown,
        CaseTypeDropDown,
        PriorityDropDown,
        ResolutionStatusDropDown,
        dateRange,
        startDate,
        endDate,
        loading,
        setDateRange,
        exportToCSV,
        generatePrint,
        getTableFunction,
        getContactDetails,
        getEmployeeContactDetails,
        setCaseGroupDropDownValue,
        setCaseTypeDropDownValue,
        setPriorityDropDownValue,
        setResolutionStatusDropDownValue,
        setTrackingNumberValue,
    };
}
