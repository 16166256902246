import React from 'react'
import FormType from './FormType/FormType'

function CreateType() {
    return (
        <div>
            <FormType />
        </div>
    )
}

export default CreateType