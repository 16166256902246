import { Fragment, useState } from 'react';
import { H5, Btn, H6, UL, LI, P } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';

const CaseHistory = ({ histories }) => {
    const [isFilter, setIsFilter] = useState(true);
    
    const ActivityData = [
        {
          title: 'Status',
          subTitle: '8th March, 2022',
          time: '1 day ago',
          dis: 'Quisque a consequat ante sit amet magna...',
          color: 'primary',
        },
        {
          title: 'Priority',
          subTitle: '15th Oct, 2022 ',
          time: 'Today',
          dis: 'Quisque a consequat ante sit amet magna...',
          color: 'warning',
        },
        {
          title: 'Assigned',
          subTitle: '20th Sep, 2022',
          time: '12:00 PM',
          dis: 'Quisque a consequat ante sit amet magna...',
          color: 'secondary',
        },
      ];

    return (
        <Fragment>
            <Col xl="12" className='notification'>
                <Card className='height-equal'>
                    {/* <CardHeader className=''>
                        <div className='header-top'>
                            <H5 attrH5={{ className: 'mb-0' }} >
                                <Btn attrBtn={{
                                    className: 'btn btn-link',
                                    onClick: () => setIsFilter(!isFilter),
                                    color: 'transperant'
                                }} >History</Btn>
                            </H5>
                            <div className='card-header-right-icon'>
                                <DropdownCommon icon={false} options={DailyDropdown} btn={{ caret: true }} />
                            </div>
                        </div>
                    </CardHeader> */}
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >History
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                            <UL>
                                {histories && histories.map((item, i) => (
                                <LI key={i} attrLI={{ className: 'd-flex' }}>
                                    <div className={`activity-dot-${item.color}`} />
                                    <div className='w-100 ms-3'>
                                    <P attrPara={{ className: 'd-flex justify-content-between mb-2' }}>
                                        <span className='date-content light-background'>{item.subTitle}</span>
                                        <span>{item.time}</span>
                                    </P>
                                    <H6>
                                        {item.title}
                                        <span className='dot-notification' />
                                    </H6>
                                    <p className='f-light'>{item.dis}</p>
                                    </div>
                                </LI>
                                ))}
                            </UL>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default CaseHistory;