import { useLayoutEffect, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
    financesGeneralLedgerTypeApi,
} from "../../../../api";
import useAuth from "../../../../Hooks/useAuth";
import { IGLGroupRes, IGLClassRes, IGlTypeRes } from "./types";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";

export default function useGeneralLedgerTypeReport() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();

    const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);
    const [startDate, endDate] = dateRange;

    const [GLGroupDropDown, setGLGroupDropDown] = useState<IGLGroupRes[]>([]);
    const [GLGroupDropDownValue, setGLGroupDropDownValue] = useState("0");

    const [GLClassDropDown, setGLClassDropDown] = useState<IGLClassRes[]>([]);
    const [GLClassDropDownValue, setGLClassDropDownValue] = useState("0");

    const [printedData, setPrintedData] = useState<IGlTypeRes[]>([]);

    async function getGLGroup() {
        try {
            axiosPrivate
                .get(
                    `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
                )
                .then((res) => {
                    setGLGroupDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    async function getGLClass() {
        try {
            axiosPrivate
                .get(
                    `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${GLGroupDropDownValue}`
                )
                .then((res) => {
                    setGLClassDropDown(res.data);
                });
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                toast.error(err.response.data.detail);
            } else {
                toast.error("Something Went Wrong");
            }
        }
    }

    useEffect(() => {
        if (GLGroupDropDownValue !== "0") getGLClass();
    }, [GLGroupDropDownValue]);

    useLayoutEffect(() => {
        getGLGroup();
    }, []);

    async function getTableFunction() {
        axiosPrivate
            .get(
                `${financesGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_class_id=${GLClassDropDownValue}&gl_group_id=${GLGroupDropDownValue}`
            )
            .then((res) => {
                const result: IGlTypeRes[] = res.data;
                console.log(result);
                setPrintedData(result);
            })
            .catch((err) => {
                console.log(err);
                setPrintedData([]);
                if (err.response && err.response.status === 404) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
            });
    }

    function generatePrint(res: IGlTypeRes[]) {
        console.log(res);
        const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
                .map(
                    (item) =>
                        `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                        <td>${item.code}</td>
                        <td>${item.gl_class.gl_group.name}</td>
                        <td>${item.gl_class.gl_group.report_type.name}</td>
                    </tr>`
                )
                .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

        const blob = new Blob([htmlContent], { type: "text/html" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
        URL.revokeObjectURL(blobUrl);
    }

    function exportToCSV(res: any[]) {
        const csvHeaders = [
            "Name",
            "Description",
            "Code",
            "Balance Type",
            "Report Type",
        ];
        const data = res.map((item) => ({
            name: item.name,
            description: item.description,
            code: item.code,
            gl_group: item.gl_class.gl_group.name,
            report_type: item.gl_class.gl_group.report_type.name,
        }));

        const dataKeys: (keyof (typeof data)[0])[] = [
            "name",
            "description",
            "code",
            "gl_group",
            "report_type",
        ];

        const csvContent =
            csvHeaders.join(",") +
            "\n" +
            data
                .map((row) => `${dataKeys.map((key) => row[key]) + ","}`)
                .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "data.csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    return {
        printedData,
        GLClassDropDown,
        GLGroupDropDown,
        dateRange,
        startDate,
        endDate,
        setDateRange,
        exportToCSV,
        generatePrint,
        getTableFunction,
        setGLClassDropDownValue,
        setGLGroupDropDownValue,
    };
}
