import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../Hooks/useAuth";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  ExpenseDescRes,
  ExpenseGroup,
  ExpenseCategory,
  ExpenseClass,
} from "./types";
import {
  financesExpenseGroupApi,
  financesExpenseCategoryApi,
  financesExpenseClassApi,
  financesExpenseTypeApi,
  financesExpenseDescriptionApi,
} from "../../../../api";
import { toast } from "react-toastify";

export default function useExpenseDescController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();

  const [ExpenseCategoryDropdown, setExpenseCategoryDropDown] = useState<
    ExpenseCategory[]
  >([]);
  const [expenseCategoryValue, setExpenseCategoryValue] = useState("0");

  const [ExpenseGroupDropdown, setExpenseGroupDropDown] = useState<
    ExpenseGroup[]
  >([]);
  const [expenseGroupValue, setExpenseGroupValue] = useState("0");

  const [ExpenseClassDropdown, setExpenseClassDropDown] = useState<
    ExpenseClass[]
  >([]);
  const [expenseClassValue, setExpenseClassValue] = useState("0");

  const [ExpenseTypeDropdown, setExpenseTypeDropDown] = useState<
    ExpenseClass[]
  >([]);
  const [expenseTypeValue, setExpenseTypeValue] = useState("0");

  const [printedData, setPrintedData] = useState<ExpenseDescRes[]>([]);

  async function getExpenseCategory() {
    try {
      axiosPrivate
        .get(
          `${financesExpenseCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
        )
        .then((res) => {
          setExpenseCategoryDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }
  async function getExpenseGroup() {
    try {
      await axiosPrivate
        .get(
          `${financesExpenseGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&expense_category_id=${expenseCategoryValue}`
        )
        .then((res) => {
          setExpenseGroupDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setExpenseGroupDropDown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  async function getExpenseClass() {
    try {
      await axiosPrivate
        .get(
          `${financesExpenseClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&expense_group_id=${expenseGroupValue}`
        )
        .then((res) => {
          setExpenseClassDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setExpenseClassDropDown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  async function getExpenseType() {
    try {
      await axiosPrivate
        .get(
          `${financesExpenseTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&expense_class_id=${expenseClassValue}`
        )
        .then((res) => {
          setExpenseTypeDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setExpenseTypeDropDown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  useLayoutEffect(() => {
    getExpenseCategory();
  }, []);

  useEffect(() => {
    if (expenseCategoryValue !== "0") {
      getExpenseGroup();
    } else {
      return;
    }
  }, [expenseCategoryValue]);

  useEffect(() => {
    if (expenseGroupValue !== "0") {
      getExpenseClass();
    } else {
      return;
    }
  }, [expenseGroupValue]);

  useEffect(() => {
    if (expenseClassValue !== "0") {
      getExpenseType();
    } else {
      return;
    }
  }, [expenseClassValue]);

  async function getTableFunction() {
    await axiosPrivate
      .get(
        `${financesExpenseDescriptionApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&expense_type_id=${expenseTypeValue}`
      )
      .then((res) => {
        const result: ExpenseDescRes[] = res.data;
        setPrintedData(result);
      })
      .catch((err) => {
        console.log(err);
        setPrintedData([]);
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  }

  function generatePrint(res: ExpenseDescRes[]) {
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
              .map(
                (item) =>
                  `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                        <td>${item.expense_type.name}</td>
                        <td>${item.name}</td>
                    </tr>`
              )
              .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  }

  function exportToCSV(res: ExpenseDescRes[]) {
    const csvHeaders = [
      "Name",
      "Description",
      "Code",
      "Balance Type",
      "Report Type",
    ];
    const data = res.map((item) => ({
      name: item.name,
      description: item.description,
      expense_type: item.expense_type.name,
      report_type: item.name,
    }));

    const dataKeys: (keyof (typeof data)[0])[] = [
      "name",
      "description",
      "expense_type",
      "report_type",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" +
      data.map((row) => `${dataKeys.map((key) => row[key]) + ","}`).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  return {
    exportToCSV,
    printedData,
    generatePrint,
    getTableFunction,
    ExpenseCategoryDropdown,
    ExpenseClassDropdown,
    ExpenseGroupDropdown,
    ExpenseTypeDropdown,
    setExpenseCategoryValue,
    setExpenseClassValue,
    setExpenseGroupValue,
    setExpenseTypeValue,
  };
}
