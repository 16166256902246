import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import useSubLedgerTypeController from "./SubLedgerType.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import GenerateForm from "../../../../Common/Component/GenerateForm";
import { BackToList, Settings } from "../../../../../Constant";

const SubLedgerType = () => {
    const { formTitle, backBtn, formFields, onSubmit } =
        useSubLedgerTypeController();

    return (
        <div className="mt-5">
            <Breadcrumbs
                parent={Settings}
                title={formTitle}
                mainTitle={formTitle}
            />
            <Fragment>
                <Card className="ribbon-wrapper-right b-l-secondary border-2 mt-5">
                    <CardBody>
                        <Link to={backBtn}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>
                        <GenerateForm
                            onSubmit={onSubmit}
                            formFields={formFields}
                            formTitle={formTitle}
                            listUrl={backBtn}
                            onEdit={undefined}
                            editRecord={undefined}
                        />
                    </CardBody>
                </Card>
            </Fragment>
        </div>
    );
};

export default SubLedgerType;
