import React, { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
} from "reactstrap";
import { axiosPrivate } from "../../../api/axios";
import useAuth from "../../../Hooks/useAuth";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";

type props = {
    mapString: string;
    apiString: string;
    defaultSelectedText?: string;
    onInputChange?: (e: string) => void;
    onSelectedChange?: (e: any) => void;
    onMount?: (e: any) => void;
    disabledStatus?: boolean;
};

export default function InputSelect({
    mapString,
    apiString,
    defaultSelectedText,
    onInputChange,
    onSelectedChange,
    onMount,
    disabledStatus,
}: props) {
    const {
        dropdownOpen,
        dropdownItems,
        filterSelectText,
        handleFilterSelectText,
        setFilterSelectText,
        toggle,
    } = useInputSelect(mapString, apiString, defaultSelectedText, onMount);

    return (
        <>
            <Dropdown 
                disabled={disabledStatus}
                style={{ width: "100%", backgroundColor: "white" }}
                isOpen={dropdownOpen}
                toggle={toggle}
            >
                <DropdownToggle disabled={disabledStatus}
                    style={{ width: "100%", backgroundColor: "white" }}
                    caret
                >
                    <Input
                        type="text"
                        name={mapString || "filter_search_select"}
                        id={mapString || "filter_search_select"}
                        placeholder="Filter Search Select"
                        value={filterSelectText || defaultSelectedText}
                        onChange={(e) => {
                            setFilterSelectText(e.target.value);
                            handleFilterSelectText(e.target.value);
                            onInputChange && onInputChange(e.target.value);
                        }}
                    />
                </DropdownToggle>
                <DropdownMenu>
                    {dropdownItems.map((item) => (
                        <DropdownItem
                            key={item["id" || mapString]}
                            onClick={() => {
                                setFilterSelectText(item["label"]);
                                onSelectedChange && onSelectedChange(item);
                            }}
                        >
                            {item["label"]}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

const useInputSelect = (
    mapString: string,
    apiString: string,
    defaultSelectedText?: string,
    onMount?: (e: any) => void
) => {
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId }: any = useAuth();
    const [filterSelectText, setFilterSelectText] =
        useState(defaultSelectedText);
    const [dropdownItems, setDropdownItems] = useState<any[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState: any) => !prevState);
    const handleFilterSelectText = (e: string) => {
        setFilterSelectText(e);
        if (e.length > 1) {
            handleFetchData(e);
            // const matchingItems = values.filter((item) =>
            //     item[keyString].toLowerCase().includes(e.toLowerCase())
            // );
            // setDropdownItems(matchingItems);
        }
    };

    const handleFetchData = async (e: string) => {
        const queryString = `skip=${0}&limit=${20}&with_trashed=false&organisation_id=${userOrganisationId}&search_value=${e}`;
        apiString = apiString.includes("?")
            ? `${apiString}&${queryString}`
            : `${apiString}?${queryString}`;

        const response = await axiosPrivate.get(apiString);
        const items = response.data.items || response.data;
        const options = items.map((item: any) => ({
            value: item.id,
            label: mapString ? getLabelFromKeys(item, mapString) : item.name,
        }));
        setDropdownOpen(true);
        setDropdownItems(options);
    };

    function getLabelFromKeys(item: any[], itemKeyName: string) {
        const parseItemKeyName = itemKeyName.split(",").map((key) => {
            const keys = key.trim().split(".");
            const originalKey = keys[0];
            const nestedKeys = keys.slice(1);
            return { originalKey, nestedKeys };
        });

        return parseItemKeyName
            .map(
                ({
                    originalKey,
                    nestedKeys,
                }: {
                    originalKey: any;
                    nestedKeys: any[];
                }) => {
                    // Check if the key has nested properties
                    if (nestedKeys.length > 0) {
                        // Retrieve the nested object
                        let nestedObject = item[originalKey];
                        // Iterate through nested keys to access nested properties
                        for (const nestedKey of nestedKeys) {
                            nestedObject = nestedObject[nestedKey];
                        }
                        return nestedObject;
                    } else {
                        // Return value of non-nested key
                        return item[originalKey];
                    }
                }
            )
            .join(" ");
    }

    useEffect(() => {
        //console.log("just for gun");

        onMount && onMount(defaultSelectedText);
    }, [defaultSelectedText]);

    return {
        dropdownOpen,
        dropdownItems,
        filterSelectText,
        handleFilterSelectText,
        setFilterSelectText,
        toggle,
    };
};
