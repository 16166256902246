import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import GenerateForm from '../../../../../Common/Component/GenerateForm';
import { ContactBranchApi, ContactBranchInfoUri, ContactMgmtApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import { branch_form_fields } from '../../../../../../Data/FormField/CrmData';

const BranchInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields] = useState(branch_form_fields);

  useLayoutEffect(() => {
    
    rowData.branch_name = rowData.name;
  });
  
  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      name: editRecord.branch_name
    };
    
    try {
        await axiosPrivate.put(`${ContactBranchApi}/${id}/${ContactBranchInfoUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='branch-info'>
        <Row>
          <Col sm='12'>
          <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default BranchInformation;