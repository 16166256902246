import { Cc, Bcc, Issue, CopyOthers, Status, Subject, Case, DueDate, } from "../../../../Constant"
export const case_form_fields = [
    /* {
        type: 'group-title',
        label: `${CopyOthers}`,
    }, */
    {
      id: 'subject',
      name: 'subject',
      label: `${Subject}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    /* {
      id: 'cc',
      name: 'cc',
      label: `${Cc}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'bcc',
      name: 'bcc',
      label: `${Bcc}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    }, */
    /* {
      id: 'due_date',
      name: 'due_date',
      label: `${DueDate}`,
      type: 'date',
      value: '',
      required: false,
      colSize: 6,
    }, */
    {
      id: 'message',
      name: 'message',
      label: `${Case}`,
      // type: 'simpleEditor',
      type: 'textarea',
      value: '',
      required: true,
      colSize: 12,
    },
    
];

