import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, WardApi, ZoneApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {base_form_fields, code_field, createDropdownField} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Lga, RegionalContinent, RegionalCountry, State, WardCode, WardName, Zone } from '../../../../../Constant';

const WardNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/public-data/wards/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${WardName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 12
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 12
  );

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    '', 12
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${RegionalCountry}`,
    `${RegionalCountryApi}`,
    '', 
    12
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'continent_id',
    'continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
    '',
    12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'name');
          updatedFields.splice(indexOfName + 1, 0, code_field(`${WardCode}`));
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, code_field]); 

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    //const selectedCountry = selectedCountryId > 0 ? selectedCountryId : 0;
    if (zoneField) { 
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      setFormFields([...updatedFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          regional_lga_id: newRecord.regional_lga_id,
          name: newRecord.name,
          code: newRecord.code,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(WardApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Ward" mainTitle="New Ward" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default WardNew;