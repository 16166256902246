import { Fragment, useContext, useEffect,useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InventoryGroupApi, InventoryCategoryApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import {base_form_fields, createDropdownField} from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { InventoryGroup, InventoryCategory } from '../../../../Constant';

const InventoryGroupNew = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/administration/settings/inventory-group/${layoutURL}`;

  const [formFields, setFormFields] = useState(base_form_fields(`${InventoryGroup}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const { formState: { errors },} = useForm(); 

  const inventoryCategoryDropdownField = createDropdownField(axiosPrivate,
    'inventory_category_id',
    'inventory_category_id',
    `${InventoryCategory}`,
    `${InventoryCategoryApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [inventoryCategoryDropdownField, ...formFields];
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, inventoryCategoryDropdownField]); 

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          inventory_category_id: newRecord.inventory_category_id,
          name: newRecord.name,
          description: newRecord.description,
          is_active: newRecord.is_active,
       };

      try {
          await axiosPrivate.post(InventoryGroupApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          }
          else {
            toast.error('Form submission error');
          }
          throw error;
      }  
  };

return (
    <Fragment>
      <Breadcrumbs parent="Administration" title="New Inventory Group" mainTitle="New Inventory Group" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
          <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InventoryGroupNew;