import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Structure from '../Components/Crm/Contact/User/Structure';
import ContactCreate from '../Components/Crm/Contact/User/Create';

const ContactRoutes = () => {
  
  const routes = [
   { path: `/map/:layout`, Component: <ContactCreate />, requiredPermission: "" },
  ];

  return ( 
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}> 
            <Route element={<Structure />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          </Fragment>
        ))}
      </Routes>
    </> 
  );
};

export default ContactRoutes;

