import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CustomizerContext from '../../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CostCentreApi, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, StoreApi, WardApi, ZoneApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../../Common/Component/CardRibbonWithForm';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { base_form_fields, createDropdownField, short_name_field, ward_dropdown_field } from '../../../../../../Data/FormField/PublicData';
import { AddNewStore, CostCentre, Country, Lga, RegionalContinent, State, Store, Ward, Zone } from '../../../../../../Constant';
import OrganisationContext from '../../../../../../_helper/System/Organisation';
import { electronic_address_form_fields, physical_address_form_fields } from '../../../../../../Data/FormField/CrmData';

const StoreNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { organisation_id  } = useContext(OrganisationContext);
  const storeApi = StoreApi(organisation_id);
  const costCentreApi = CostCentreApi(organisation_id);
  const lists = `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/stores/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${Store}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const costCentreDropdownField = createDropdownField(axiosPrivate,
    'cost_centre_id',
    'cost_centre_id',
    `${CostCentre}`,
    `${costCentreApi}`,
    '',
    6
  );

  //========= Physical Address ============== //
  const wardDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 6,
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 6,
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    '', 6,
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    '', 6,
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
    '', 6,
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
          const updatedFields = [costCentreDropdownField, ...formFields];
          
          updatedFields.splice(indexOfName + 2, 0, short_name_field('', 6));
          const indexOfStatus = updatedFields.findIndex((updatedField) => updatedField.id === 'is_active');
          
          updatedFields.splice(indexOfStatus , 0, 
            ...electronic_address_form_fields, 
            ...physical_address_form_fields,
            continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField);
          
            await updatedFields[0].fetchOptions();
            await continentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, costCentreDropdownField, ward_dropdown_field]);

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      setFormFields([...updatedFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      setFormFields([...updatedFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          cost_centre_id: newRecord.cost_centre_id,
          name: newRecord.name,
          short_name: newRecord.short_name,
          description: newRecord.description,
          is_active: newRecord.is_active,
          email_address: newRecord.email,
          phone_number: newRecord.phone_number,
          alt_phone_number: newRecord.alt_phone_number,
          website: newRecord.website,
          street_number: newRecord.street_number,
          street_name: newRecord.street_name,
          nearest_landmark: newRecord.nearest_landmark,
          city: newRecord.city,
          geo_tag: newRecord.geo_tag,
          ward_id: newRecord.ward_id,
      };

      try {
          await axiosPrivate.post(storeApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm 
              formFields={formFields} 
              onSubmit={addNewRecord} 
              ribbonUrl={lists} 
              formTitle={AddNewStore} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StoreNew;