import { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent  } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { MyCommission, Sales, Overview, ReferralLink } from '../../../../Constant';
import CustomizerContext from '../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import MyCommissions from './Tabs/MyCommissions';
import MyReferralLink from './Tabs/MyReferralLink';
import { ContactMgmtApi } from '../../../../api';

const Commission = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const [commissionTab, setCommissionTab] = useState('overview');
  const [referralLinkDetails, setReferralLinkDetails] = useState({});
  //const referralLinkDetails = {link: 'https://', code: 'hhgdtdgd'};

  const getContactReferralCode = async () => {
    try {
        await axiosPrivate.get(`${ContactMgmtApi}/get-referral-code`).then((resp) => {
          const referralCode = resp.data.referral_code;
          setReferralLinkDetails(referralCode ? { code: referralCode } : {});
        });
    } catch (error) {
       // console.log('cancelled', error);
    } finally {
      //setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getContactReferralCode();

    return () => {
      abortController.abort();
    };
  }, [setReferralLinkDetails]);

  return (
    <Fragment>
      <Breadcrumbs parent={Sales} title={MyCommission} mainTitle={MyCommission} />
      <Container fluid={true}>
        <Row>
          <Col sm='12' xl='12' className='xl-100'>
            <Card>
              <CardBody>
                <Nav className='border-tab nav-secondary nav-pills' tabs>
                  <NavItem>
                    <NavLink href='#' className={commissionTab === 'overview' ? 'active' : ''} onClick={() => setCommissionTab('overview')}>
                      <i className='icofont icofont-money-bag'></i>
                      {Overview}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#' className={commissionTab === 'referral-link' ? 'active' : ''} onClick={() => setCommissionTab('referral-link')}>
                      <i className='icofont icofont-share'></i>
                      {ReferralLink}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={commissionTab}>
                  <MyCommissions />
                  <MyReferralLink referralLinkDetails={referralLinkDetails} setReferralLinkDetails={setReferralLinkDetails} />
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Commission;