import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { physical_address_form_fields } from '../../../../../../Data/FormField/CrmData';
import { toast } from 'react-toastify';
import { ContactBranchApi, ContactPhysicalAddressUri, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, WardApi, ZoneApi } from '../../../../../../api';
import { createDropdownField } from '../../../../../../Data/FormField/PublicData';
import { Country, Lga, RegionalContinent, State, Ward, Zone } from '../../../../../../Constant';
import GenerateForm from '../../../../../Common/Component/GenerateForm';

const PhysicalAddressInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields, setFormFields] = useState(physical_address_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  //========= Physical Address ============== //
  const addressWardDropdownField = createDropdownField(axiosPrivate,
    'address_ward_id',
    'address_ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const addressLgaDropdownField = createDropdownField(axiosPrivate,
    'address_regional_lga_id',
    'address_regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
  );

  const addressStateDropdownField = createDropdownField(axiosPrivate,
    'address_regional_state_id',
    'address_regional_state_id',
    `${State}`,
    `${RegionalStateApi}`
  ); 

  const addressZoneDropdownField = createDropdownField(axiosPrivate,
    'address_regional_zone_id',
    'address_regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`
  ); 

  const addressCountryDropdownField = createDropdownField(axiosPrivate,
    'address_regional_country_id',
    'address_regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`
  ); 

  const addressContinentDropdownField = createDropdownField(axiosPrivate,
    'address_regional_continent_id',
    'address_regional_continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [...formFields, 
            addressContinentDropdownField, 
            addressCountryDropdownField, 
            addressZoneDropdownField, 
            addressStateDropdownField, 
            addressLgaDropdownField, 
            addressWardDropdownField ];

          rowData.address_regional_continent_id = rowData?.branch_ward?.lga?.state?.zone?.country?.regional_continent_id;
          rowData.address_regional_country_id = rowData?.branch_ward?.lga?.state?.zone?.regional_country_id;
          rowData.address_regional_zone_id = rowData?.branch_ward?.lga?.state?.regional_zone_id;
          rowData.address_regional_state_id = rowData?.branch_ward?.lga?.regional_state_id;
          rowData.address_regional_lga_id = rowData?.branch_ward?.regional_lga_id;
          rowData.address_ward_id = rowData?.ward_id;
          
          await addressContinentDropdownField.fetchOptions();
          addressContinentDropdownField.onChange(rowData.address_regional_continent_id, updatedFields);
          addressCountryDropdownField.onChange(rowData.address_regional_country_id, updatedFields);
          addressZoneDropdownField.onChange(rowData.address_regional_zone_id, updatedFields);
          addressStateDropdownField.onChange(rowData.address_regional_state_id, updatedFields);
          addressLgaDropdownField.onChange(rowData.address_regional_lga_id, updatedFields);
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, addressContinentDropdownField]);

  //=============== Address dropdown ===============
  addressContinentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'address_regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'address_regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]); 
    } 
  };

  addressCountryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'address_regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressZoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'address_regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressStateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'address_regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressLgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'address_ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
          street_number: editRecord.street_number,
          street_name: editRecord.street_name,
          nearest_landmark: editRecord.nearest_landmark,
          geo_tag: editRecord.geo_tag,
          city: editRecord.city,
          ward_id: editRecord.address_ward_id,
    };
    
    try {
        await axiosPrivate.put(`${ContactBranchApi}/${id}/${ContactPhysicalAddressUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='physical-address'>
        <Row>
          <Col sm='12'>
          <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default PhysicalAddressInformation;