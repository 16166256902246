import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import HeaderCard from '../../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../../_helper/Customizer';
import { DepartmentApi, DivisionApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../../Common/Component/ViewModal';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { DateCreated, DateModified, Department, Description, Division, Name, ShortName, Status } from '../../../../../../Constant';
import { base_form_fields, createDropdownField, short_name_field } from '../../../../../../Data/FormField/PublicData';
import OrganisationContext from '../../../../../../_helper/System/Organisation';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';

const DepartmentList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { organisation_id  } = useContext(OrganisationContext);
  const departmentApi = DepartmentApi(organisation_id);
  const divisionApi = DivisionApi(organisation_id);

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${Department}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const divisionDropdownField = createDropdownField(axiosPrivate,
    'division_id',
    'division_id',
    `${Division}`,
    `${divisionApi}`,
    '',
    12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
          const updatedFields = [divisionDropdownField, ...formFields];
         
          updatedFields.splice(indexOfName + 2, 0, short_name_field());
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, divisionDropdownField]);


  const getAllCompaniesData = async () => {
      try {
          await axiosPrivate.get(departmentApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllCompaniesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
        division_id: editRecord.division_id,
        name: editRecord.name,
        short_name: editRecord.short_name,
        description: editRecord.description,
        is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${departmentApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${departmentApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/departments/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${Name}`,
      selector: row => `${row.name}`,
      sortable: true,
      },
    {
        name: `${ShortName}`,
        selector: row => `${row.short_name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${Division}`,
      selector: row => `${row.division.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <div className='email-right-aside bookmark-tabcontent contacts-tabs'>
                <div className='email-body radius-left'>
                  <div className='ps-0'>
                    <Card>
                      <HeaderCard title="List Departments" btnUrl={addBtn} />
                      <CardBody>
                        <DataTableComponent 
                          tabledata={allData}
                          tableColumns={tableColumns}
                          isLoading={isLoading}
                          onView={handleView}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                        />
                      </CardBody>
                    </Card>
                  </div>
                </div>
      </div>
        
      <ViewModal 
        isOpen={viewModalOpen} 
        toggle={() => setViewModalOpen(!viewModalOpen)} 
        rowData={selectedRowData} 
        formFields={formFields}
        editMode={editMode}
        onEdit={memoizedHandleEdit}
      >
        {selectedRowData && (
          <>
            <Table>
              <tbody>
                <tr className='border-bottom-primary'>
                  <th scope='row'>{Department}</th>
                  <td>{selectedRowData.name}</td>
                </tr>
                <tr className='border-bottom-secondary'>
                  <th scope='row'>{ShortName}</th>
                  <td>{selectedRowData.short_name}</td>
                </tr>
                <tr className='border-bottom-primary'>
                  <th scope='row'>{Description}</th>
                  <td>{selectedRowData.description}</td>
                </tr>
                <tr className='border-bottom-secondary'>
                  <th scope='row'>{Division}</th>
                  <td>{selectedRowData.division.name}</td>
                </tr>
                <tr className='border-bottom-primary'>
                  <th scope='row'>{Status}</th>
                  <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                </tr>
                <tr className='border-bottom-secondary'>
                  <th scope='row'>{DateCreated}</th>
                  <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                </tr>
                <tr className='border-bottom-primary'>
                  <th scope='row'>{DateModified}</th>
                  <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                </tr>
              </tbody>
            </Table>
           </>
        )}
        
      </ViewModal>
    </Fragment>
  );
};

export default DepartmentList;