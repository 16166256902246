import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { TabPane, Row , Col } from 'reactstrap';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { corporate_form_fields } from '../../../../../Data/FormField/CrmData';
import GenerateForm from '../../../../Common/Component/GenerateForm';
import { ContactCorporateInfoUri, ContactMgmtApi } from '../../../../../api';
import { toast } from 'react-toastify';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const CorporateInformation = ({ rowData, index, updateRowRecord }) => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();

  const [formFields] = useState(corporate_form_fields);

  useLayoutEffect(() => {
    
    rowData.registration_date = formatDateWithTimeZone(rowData.registration_date, "", "YYYY-MM-DD");
  });
  
  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      email: editRecord.email,
      is_active: editRecord.is_active,
      contact: {
          company_name: editRecord.company_name,
          company_short_name: editRecord.company_short_name,
          foreign_affliation: editRecord.foreign_affliation,
          is_registered: editRecord.is_registered,
          registration_number: editRecord.registration_number,
          registration_date:  editRecord.registration_date ? editRecord.registration_date : "0000-00-00",
      }
    };
    
    try {
        await axiosPrivate.put(`${ContactMgmtApi}/${id}/${ContactCorporateInfoUri}`, editRecordTemp).then((resp) => {
          updateRowRecord(index, resp.data);
          toast.success(`${resp.data.company_name} is successfully updated.`)
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='corporate-info'>
        <Row>
          <Col sm='12'>
            
          <GenerateForm formFields={formFields} onEdit={handleEditRec} editRecord={rowData} />
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default CorporateInformation;