import { Fragment } from "react";
import sad from '../assets/images/auth-img.svg';
import { Container , Media} from 'reactstrap'
import { H2, H4 } from "../AbstractElements";
import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Fragment>
            <section className="maintenance-sec">
                <div className="page-wrapper">
                    <div className="error-wrapper maintenance-bg">
                        <Container>
                            <Media body className="img-100" style={{width: '350px !important'}} src={sad} alt="" />
                            <div className="maintenance-heading">
                                <H2 attrH2={{ className: "headline" }} >Unauthorized</H2>
                            </div>
                            <H4 attrH4={{ className: "sub-content" }} >You do not have access to the requested page.</H4>
                            <div className="flexGrow">
                                <button 
                                    className="btn btn-primary btn-lg text-light" 
                                    onClick={goBack}><i className="fa fa-angle-double-left"></i> Go Back</button>
                            </div>
                        </Container>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Unauthorized