import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesGeneralLedgerClassApi,
  financesGeneralLedgerGroupApi,
  financesGroupGeneralLedgerClassApi,
  financesGroupGeneralLedgerNumber,
  financesGroupGeneralLedgerTypeApi,
} from "../../../../../api";
import {
  GeneralLedgerGroup,
  GroupGLClass,
  GroupGLType,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export default function useGroupGeneralLedgerNumberController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = "Group GL Number";
  const { layoutURL } = useContext(CustomizerContext);
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: `Name`,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: `Description`,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: `Status`,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const generalLedgerGroup = createDropdownField(
    axiosPrivate,
    "general_ledger_group",
    "general_ledger_group",
    GeneralLedgerGroup,
    `${financesGeneralLedgerGroupApi}?&organisation_id=${userOrganisationId}&group_gl_class_id=${1}`,
    "",
    6
  );
  generalLedgerGroup.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "group_gl_class_id"
    );

    if (nextField) {
      const endPoint = `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${id}`;

      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "general_ledger_group") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };
  const groupGLClassIdDropDown = createDropdownField(
    axiosPrivate,
    "group_gl_class_id",
    "group_gl_class_id",
    GroupGLClass,
    `${financesGroupGeneralLedgerClassApi}&organisation_id=${userOrganisationId}&gl_group_id=${1}`,
    "",
    6
  );

  groupGLClassIdDropDown.onChange = async (id, fields) => {
    console.log(fields);
    const nextField = fields.find(
      (field: any) => field.id === "group_gl_type_id"
    );

    if (nextField) {
      const endPoint = `${financesGroupGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&group_gl_class_id=${id}`;
      await nextField.fetchOptions(id, false, endPoint);

      const updatedFormFields = fields.map((field: any) => {
        if (field.id === "group_gl_class_id") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const groupGLTypeIdDropDown = createDropdownField(
    axiosPrivate,
    "group_gl_type_id",
    "group_gl_type_id",
    GroupGLType,
    `${financesGroupGeneralLedgerTypeApi}?organisation_id=${userOrganisationId}&group_gl_class_id=${1}`,
    "",
    6
  );

  // groupGLTypeIdDropDown.onChange = async (id, updatedFields) => {
  //   const nextField = updatedFields.find(
  //     (field: any) => field.id === "group_gl_number_id"
  //   );
  //   if (nextField) {
  //     const endPoint = `${financesGroupGeneralLedgerNumber}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&group_gl_type_id=${id}`;
  //     await nextField.fetchOptions(id, false, endPoint);

  //     const updatedFormFields = updatedFields.map((field: any) => {
  //       if (field.id === "group_gl_type_id") {
  //         return { ...field, value: id };
  //       }
  //       return field;
  //     });
  //     setFormFields([...updatedFormFields]);
  //   }
  // };

  //?? ==========================================================

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [
      generalLedgerGroup,
      groupGLClassIdDropDown,
      groupGLTypeIdDropDown,
      ...formFields,
    ];
    await generalLedgerGroup.fetchOptions();
    setFormFields(updatedFields);
  }
  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-number/${layoutURL}`;

  async function onSubmit(data: any) {
    // console.log(data);
    await axiosPrivate
      .post(financesGroupGeneralLedgerNumber, {
        ...data,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        toast.success("Created Successfully");
      })
      .catch((err) => {
        toast.error(err.response.message || "Something Went Wrong");
      });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
