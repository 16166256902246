import React from "react";
import "./GeneralLedgerAssign.scss";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { General, Settings } from "../../../../Constant";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { ChevronsDown } from "react-feather";
import useGeneralLedgerAssign from "./GeneralLedgerAssign.controller";
import countries from "./country.json";

interface Props {
    data: any[];
    leftTable?: any[];
    rightTable?: any[];
}

export default function GeneralLedgerAssign({
    data,
    leftTable,
    rightTable,
}: Props) {
    const {
        assigned,
        unassigned,
        selectedItem,
        searchNameInput,
        selectRow,
        setSelectedCountry,
        searchFilter,
        setSearchNameInput,
        addToAssign,
        addToUnAssign,
    } = useGeneralLedgerAssign(data);

    return (
        <div className="mt-5 GeneralLedgerAssign">
            <Breadcrumbs
                parent={Settings}
                title={General}
                mainTitle={General}
            />
            <div className="filter-head-pane">
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="filter_by_name">
                                    Filter By Name
                                </Label>
                                <Input
                                    type="text"
                                    name="filter_by_name"
                                    id="filter_by_name"
                                    placeholder="Filter by Name"
                                    value={searchNameInput}
                                    onChange={(e) =>
                                        setSearchNameInput(e.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col xs="6">
                            <FormGroup>
                                <Label for="filter_by_email">
                                    Filter By Select
                                </Label>
                                <Input
                                    id="exampleSelect"
                                    name="select"
                                    type="select"
                                    onChange={(e) =>
                                        setSelectedCountry(e.target.value)
                                    }
                                >
                                    {countries.map(({ country }) => (
                                        <option value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button onClick={searchFilter} color="primary">
                        Search List
                    </Button>
                </Form>
            </div>

            <br />

            <div className="">
                <div className="">
                    <Row className="p-3 row-tag">
                        <Col
                            xs="6"
                            sm="4"
                            className="bg-white filter-table-title-col1"
                        >
                            <div className="filter-table-title">
                                <p>Available Tasks</p>
                            </div>

                            <div className="filter-table-search-paginate">
                                <div className="filter-tables-paginate">
                                    <p>Show</p>
                                    <select>
                                        <option value="">10</option>
                                    </select>
                                    <p>entries</p>
                                </div>

                                <div className="filter-table-search">
                                    <p>Search:</p>
                                    <input type="text" />
                                </div>
                            </div>

                            <div className="filter-table-div-block">
                                <div className="filter-table-rows-head">
                                    <span className="filter-table-checkbox-col">
                                        <input type="checkbox" />
                                    </span>
                                    <span className="filter-table-name-col">
                                        <p>Group</p>
                                        <ChevronsDown />
                                    </span>
                                </div>
                                {/* BODY */}
                                {assigned.map((item) => (
                                    <div
                                        key={item.id}
                                        onClick={() => selectRow(item.id)}
                                        className={`filter-table-rows-body ${
                                            item.id === selectedItem?.id
                                                ? "active-row"
                                                : ""
                                        }`}
                                    >
                                        <span className="filter-table-checkbox-col-body">
                                            <input
                                                checked={
                                                    item.id === selectedItem?.id
                                                }
                                                type="checkbox"
                                            />
                                        </span>
                                        <span className="filter-table-name-col-body">
                                            <p>{item.name}</p>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col md="2" sm="4" className="">
                            <div className="d-flex align-items-center justify-content-center gap-2 flex-column my-5">
                                <Button
                                    onClick={() =>
                                        addToAssign(selectedItem?.id)
                                    }
                                    color="success"
                                >
                                    Assign
                                </Button>
                                <Button
                                    onClick={() =>
                                        addToUnAssign(selectedItem?.id)
                                    }
                                    color="danger"
                                >
                                    Unassign
                                </Button>
                            </div>
                        </Col>

                        <Col
                            xs="6"
                            sm="4"
                            className="bg-white filter-table-title-col1"
                        >
                            <div className="filter-table-title">
                                <p>Assigned Tasks</p>
                            </div>

                            <div className="filter-table-search-paginate">
                                <div className="filter-tables-paginate">
                                    <p>Show</p>
                                    <select>
                                        <option value="">10</option>
                                    </select>
                                    <p>entries</p>
                                </div>

                                <div className="filter-table-search">
                                    <p>Search:</p>
                                    <input type="text" />
                                </div>
                            </div>

                            <div className="filter-table-div-block">
                                <div className="filter-table-rows-head">
                                    <span className="filter-table-checkbox-col">
                                        <input type="checkbox" />
                                    </span>
                                    <span className="filter-table-name-col">
                                        <p>Group</p>
                                        <ChevronsDown />
                                    </span>
                                </div>
                                {/* BODY */}
                                {unassigned.map((item) => (
                                    <div
                                        onClick={() => selectRow(item.id)}
                                        className={`filter-table-rows-body ${
                                            item.id === selectedItem?.id
                                                ? "active-row"
                                                : ""
                                        }`}
                                    >
                                        <span className="filter-table-checkbox-col-body">
                                            <input
                                                checked={
                                                    item.id === selectedItem?.id
                                                }
                                                type="checkbox"
                                            />
                                        </span>
                                        <span className="filter-table-name-col-body">
                                            <p>{item.name}</p>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
