import { Fragment, useContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { Container, Row, Col, Card, Table, CardBody, Media } from 'reactstrap';
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs"
import { DateCreated, DateModified, DeleteSuccess, Description, Case, Status, TrackingNumber, Priority, InteractionStatus, Contact, InteractionGroup, InteractionType, Interaction, InteractionLocation, InteractionPurpose } from "../../../../Constant"
import HeaderCard from '../../../Common/Component/HeaderCard'; 
import CustomizerContext from '../../../../_helper/Customizer'; 
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../Common/Component/DataTableServerFetchComponent'; 
import ViewModal from '../../../Common/Component/ViewModal'; 
import { interaction_form_fields } from './Form';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper'; 
import { CustomerApi, InteractionApi, InteractionGroupApi, InteractionLocationApi, InteractionPurposeApi, InteractionTypeApi, PriorityApi, ResolutionStatusApi } from '../../../../api';
import useAuth from '../../../../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ContactTypeVal, createDropdownField } from '../../../../Data/FormField/PublicData';

const InteractionList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const history = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(interaction_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const interactionLocationDropdownField = createDropdownField(axiosPrivate,
    'interaction_location_id',
    'interaction_location_id',
    `${InteractionLocation}`,
    `${InteractionLocationApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const interactionPurposeDropdownField = createDropdownField(axiosPrivate,
    'interaction_purpose_id',
    'interaction_purpose_id',
    `${InteractionPurpose}`,
    `${InteractionPurposeApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const interactionGroupDropdownField = createDropdownField(axiosPrivate,
    'interaction_group_id',
    'interaction_group_id',
    `${InteractionGroup}`,
    `${InteractionGroupApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const interactionTypeDropdownField = createDropdownField(axiosPrivate,
    'interaction_type_id',
    'interaction_type_id',
    `${InteractionType}`,
    `${InteractionTypeApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const customerDropdownField = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${CustomerApi}`,
    label: `${Contact}`,
    type: "input-select",
    value: { id: "", label: "" },
    colSize: 6,
    required: true,
    disabled: true,
  };

  const Prioritydropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  const ResolutionStatusdropdown = createDropdownField(axiosPrivate,
    'resolution_status_id',
    'resolution_status_id',
    `${InteractionStatus}`,
    `${ResolutionStatusApi}?organisation_id=${userOrganisationId}`,
    '', 6, '','', true
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [interactionGroupDropdownField, 
            interactionTypeDropdownField, 
            interactionLocationDropdownField,
            interactionPurposeDropdownField,
            customerDropdownField, 
            Prioritydropdown, 
            ResolutionStatusdropdown, 
            ...formFields];
          
          await updatedFields[0].fetchOptions();
          await Prioritydropdown.fetchOptions();
          await interactionLocationDropdownField.fetchOptions();
          await interactionPurposeDropdownField.fetchOptions();
          await ResolutionStatusdropdown.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

         }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, interactionGroupDropdownField, interactionTypeDropdownField, customerDropdownField]);

  interactionGroupDropdownField.onChange = async (selectedId, updatedFields) => { 
    const interactionTypeField = updatedFields.find((field) => field.id === 'interaction_type_id');
    
    const updatedFormFields = updatedFields.map((field) => {
      if (field.id === 'interaction_group_id') {
        return { ...field, value: selectedId };
      }
      return field;
    });

    if (interactionTypeField) {
      const endPoint = `${InteractionTypeApi}?organisation_id=${userOrganisationId}&interaction_group_id=${selectedId}`
      await interactionTypeField.fetchOptions(selectedId, false, endPoint);
    } 
    setFormFields([...updatedFormFields]);
  };

  const getAllInteractionsData = async (page = 1) => {
      try {
          setIsLoading(true);
          await axiosPrivate.get(`${InteractionApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
              setAllData(resp.data.items);
              setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
          //setIsLoading(false);
      } finally {
        // Set loading to false after data is fetched (regardless of success or failure)
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllInteractionsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const showCaseDetails = async (row) => {
    const detailsLink = `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/${row.track_number}/${layoutURL}`;
    history(detailsLink);
  }

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.interaction_group_id = row.interaction_type.interaction_group_id;
    interactionGroupDropdownField.onChange(row.interaction_group_id, formFields);
    const contactID = formFields.filter(
      (field) => field.name === "contact_id"
    );
    contactID[0].value.id = row.contact.id;
    contactID[0].value.label = (row.contact.contact_type === ContactTypeVal.Corporate) ?
    `${row.contact.contact_number} ${row.contact.company_name}` :
    ((row.contact.last_name) ?
      `${row.contact.contact_number} ${row.contact.last_name} ${row.contact.first_name}  ${row.contact.middle_name}` :
      "No Name");

    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      interaction_type_id: editRecord.interaction_type_id,
      interaction_location_id: editRecord.interaction_location_id,
      interaction_purpose_id: editRecord.interaction_purpose_id,
      contact_id: editRecord.contact_id,
      priority_id: editRecord.priority_id,
      resolution_status_id: editRecord.resolution_status_id,
      subject: editRecord.subject,
      message: editRecord.message,
    };
    
    try {
        await axiosPrivate.put(`${InteractionApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);
          //setAllData(resp.data.items);
          //setTotalRows(resp.data.total);

          toast.success(`${resp.data.track_number} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${InteractionApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${Contact}`,
      //selector: row => `${row.subject}`,
      sortable: true,
      center: false,
      minWidth: '20',
      cell: row => (
                      <>
                        <Media className='d-flex'>
                          {/* <Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} /> */}
                          <Media body className="align-self-center">
                              <div>
                                {row.contact.contact_type === ContactTypeVal.Individual ? 
                                  `${row.contact.last_name} ${row.contact.first_name} ${row.contact.middle_name}` : 
                                  `${row.contact?.company_name}`
                                } ({row.contact?.contact_number})
                              </div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    {
        name: `${TrackingNumber}`,
        selector: row => `${row.track_number}`,
        sortable: true,
        center: false,
        minWidth: '10',
    },
    {
      name: `${Interaction}`,
      selector: row => `${row.subject}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${Priority}`,
      selector: row => `${row.priority_id}`,
      sortable: true,
      center: false,
      cell: row => (<span style={{color: row.priority.color}}><i className="fa fa-flag"></i> {row.priority.name}</span>)
    },
    {
      name: `${InteractionStatus}`,
      selector: row => `${row.resolution_status_id}`,
      sortable: true,
      center: false,
      cell: row => (<span style={{color: row.resolution_status.color}}><i className="fa fa-bell"></i> {row.resolution_status.name}</span>)
    },
    /* {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    }, */
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
      wrap: true,
    },
  ];

  const handlePageChange = page => {
    getAllInteractionsData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${InteractionApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Interactions" mainTitle="Interactions" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} span1={undefined} span2={undefined} mainClasses={undefined} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={showCaseDetails}
                  onDelete={handleDelete}
                  onEdit={handleEdit}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                <tr className='border-bottom-primary'>
                    <th scope='row'>{Case}</th>
                    <td>{selectedRowData.subject}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default InteractionList;