import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { BankApi, PfaApi, PfaBankAccountApi, PfcApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import { createDropdownField, pfa_bank_acc_fields } from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Alias, Bank, BankAccount, BankCode, DateCreated, DateModified, FundCode, FundName, Pfa, PfaCode, Pfc, PfcCode, Status } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const PfaBankAccountList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(pfa_bank_acc_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const pfaDropdownField = createDropdownField(axiosPrivate,
    'pfa_id',
    'pfa_id',
    `${Pfa}`,
    `${PfaApi}`, '', 12
  );

  const pfcDropdownField = createDropdownField(axiosPrivate,
    'pfc_id',
    'pfc_id',
    `${Pfc}`,
    `${PfcApi}`, '', 12
  );

  const bankDropdownField = createDropdownField(axiosPrivate,
    'bank_id',
    'bank_id',
    `${Bank}`,
    `${BankApi}`, '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          // Find the index of the 'name' object
          const updatedFields = [pfcDropdownField, pfaDropdownField, bankDropdownField, ...formFields];
          
          await pfcDropdownField.fetchOptions();
          await bankDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, pfaDropdownField, pfcDropdownField, bankDropdownField]);

  pfcDropdownField.onChange = async (selectedpfcId, updatedFields) => { 
    const pfaField = updatedFields.find((field) => field.id === 'pfa_id');
    
    if (pfaField) {
      await pfaField.fetchOptions(selectedpfcId);
      setFormFields([...updatedFields]);
    } 
  };
  const getAllPfaBankAccountsData = async () => {
      try {
          await axiosPrivate.get(PfaBankAccountApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllPfaBankAccountsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.pfc_id = row.pfa.pfc_id;
    pfcDropdownField.onChange(row.pfc_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
          bank_id: editRecord.bank_id,
          pfc_id: editRecord.pfc_id,
          pfa_id: editRecord.pfa_id,
          fund_code: editRecord.fund_code,
          fund_name: editRecord.fund_name,
          bank_account: editRecord.bank_account,
          description: editRecord.description,
          is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${PfaBankAccountApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${PfaBankAccountApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/public-data/pfa-bank-accounts/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${PfaCode}`,
      selector: row => `${row.pfa.code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${PfcCode}`,
      selector: row => `${row.pfa.pfc.code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${FundCode}`,
      selector: row => `${row.fund_code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${FundName}`,
      selector: row => `${row.fund_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Bank}`,
      selector: row => `${row.bank.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${BankAccount}`,
      selector: row => `${row.bank_account}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Pfa Bank Accounts" mainTitle="Pfa Bank Accounts" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PfaCode}</th>
                    <td>{selectedRowData.pfa.code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PfcCode}</th>
                    <td>{selectedRowData.pfa.pfc.code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FundCode}</th>
                    <td>{selectedRowData.fund_code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FundName}</th>
                    <td>{selectedRowData.fund_name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{BankCode}</th>
                    <td>{selectedRowData.bank.code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Alias}</th>
                    <td>{selectedRowData.bank.short_name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{BankAccount}</th>
                    <td>{selectedRowData.bank_account}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default PfaBankAccountList;