import { Fragment, useContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Table, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { Breadcrumbs, Image } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { ContactBranchApi, ContactMgmtApi, GenderApi, MaritalStatusApi, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, SalutationApi, WardApi, ZoneApi } from '../../../../../api';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import {contact_form_fields, electronic_address_form_fields, nationality_address_form_fields, physical_address_form_fields} from '../../../../../Data/FormField/CrmData';
import { ContactTypeVal, createDropdownField } from '../../../../../Data/FormField/PublicData';
import noimage from '../../../../../assets/images/no-image.png';
import { Branch, BranchContactID, Company, ContactPerson, CorporateCompany, Country, DateCreated, DateModified, Details, ElectronicAddress, Email, EmailAddress, FullName, Gender, Lga, Location, MaritalStatus, Nationality, PhoneNumber, PhysicalAddress, RepresentationInfo, Salutation, State, Status, View, Ward, Zone } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import useAuth from '../../../../../Hooks/useAuth';
import BranchInformation from './Tab/BranchInformation';
import BasicInformation from './Tab/BasicInformation';
import NationalityInformation from './Tab/NationalityInformation';
import ElectronicAddressInformation from './Tab/ElectronicAddressInformation';
import PhysicalAddressInformation from './Tab/PhysicalAddressInformation';

const ContactBranchList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowIndexData, setSelectedIndexRowData] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(contact_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);

  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [contactTab, setContactTab] = useState('branch-info');

  //============= Nationality =================//
  const wardDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    wardDropdownField,
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    lgaDropdownField,
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    stateDropdownField,
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    zoneDropdownField,
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    countryDropdownField,
  );

  //========= Physical Address ============== //
  const addressWardDropdownField = createDropdownField(axiosPrivate,
    'address_ward_id',
    'address_ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const addressLgaDropdownField = createDropdownField(axiosPrivate,
    'address_regional_lga_id',
    'address_regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    addressWardDropdownField,
  );

  const addressStateDropdownField = createDropdownField(axiosPrivate,
    'address_regional_state_id',
    'address_regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    addressLgaDropdownField,
  ); 

  const addressZoneDropdownField = createDropdownField(axiosPrivate,
    'address_regional_zone_id',
    'address_regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    addressStateDropdownField,
  ); 

  const addressCountryDropdownField = createDropdownField(axiosPrivate,
    'address_regional_country_id',
    'address_regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    addressZoneDropdownField,
  ); 

  const addressContinentDropdownField = createDropdownField(axiosPrivate,
    'address_regional_continent_id',
    'address_regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    addressCountryDropdownField,
  );

  const contactCorporateHeadDropdownField = createDropdownField(axiosPrivate,
    'contact_id',
    'contact_id',
    `${CorporateCompany}`,
    `${ContactMgmtApi}?organisation_id=${userOrganisationId}&contact_type=${ContactTypeVal.Corporate}`,
    '', 6, '', 'company_name'
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const salutation_dropdown_field = createDropdownField(axiosPrivate,
            'salutation_id',
            'salutation_id',
            `${Salutation}`,
            `${SalutationApi}`,
          );

          const gender_dropdown_field = createDropdownField(axiosPrivate,
            'gender_id',
            'gender_id',
            `${Gender}`,
            `${GenderApi}`,
          );

          const marital_dropdown_field = createDropdownField(axiosPrivate,
            'marital_status_id',
            'marital_status_id',
            `${MaritalStatus}`,
            `${MaritalStatusApi}`,
          );

          const updatedFields = [contactCorporateHeadDropdownField, ...formFields ];

          const indexOfLN = updatedFields.findIndex((updatedField) => updatedField.id === 'last_name');
          updatedFields.splice(indexOfLN, 0, salutation_dropdown_field);

          const indexOfDob = updatedFields.findIndex((updatedField) => updatedField.id === 'dob');
          updatedFields.splice(indexOfDob + 1, 0, gender_dropdown_field, marital_dropdown_field);
          
          const indexOfNin = updatedFields.findIndex((updatedField) => updatedField.id === 'nin');
          updatedFields.splice(indexOfNin + 2, 0, 
            ...nationality_address_form_fields, 
            continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField,
            ...electronic_address_form_fields, 
            ...physical_address_form_fields,
            addressContinentDropdownField, addressCountryDropdownField, addressZoneDropdownField, addressStateDropdownField, addressLgaDropdownField, addressWardDropdownField);
          
          await updatedFields[0].fetchOptions();
          await salutation_dropdown_field.fetchOptions();
          await marital_dropdown_field.fetchOptions();
          await gender_dropdown_field.fetchOptions();
          await continentDropdownField.fetchOptions();
          await addressContinentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded]);

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]); 
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    alert(selectedZoneId)
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  //=============== Address dropdown ===============
  addressContinentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'address_regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  addressCountryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'address_regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  addressZoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'address_regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      setFormFields([...updatedFields]);
    } 
  };

  addressStateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'address_regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      setFormFields([...updatedFields]);
    } 
  };

  addressLgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'address_ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      setFormFields([...updatedFields]);
    } 
  };

  const getAllContactsData = async (page = 1) => {
      try {
          setIsLoading(true);
          await axiosPrivate.get(`${ContactBranchApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
            setAllData(resp.data.items);
            setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllContactsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };


  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.regional_continent_id = row.branch_ward.lga.state.zone.country.regional_continent_id;
    row.regional_country_id = row.branch_ward.lga.state.zone.regional_country_id;
    row.regional_zone_id = row.branch_ward.lga.state.regional_zone_id;
    row.regional_state_id = row.branch_ward.lga.regional_state_id;
    row.regional_lga_id = row.branch_ward.regional_lga_id;

    row.address_regional_continent_id = row.branch_ward_nationality.lga.state.zone.country.regional_continent_id;
    row.address_regional_country_id = row.branch_ward_nationality.lga.state.zone.regional_country_id;
    row.address_regional_zone_id = row.branch_ward_nationality.lga.state.regional_zone_id;
    row.address_regional_state_id = row.branch_ward_nationality.lga.regional_state_id;
    row.address_regional_lga_id = row.branch_ward_nationality.regional_lga_id;

    row.email = row.email_address;
    row.contact_id = row.contact.id;
    continentDropdownField.onChange(row.regional_continent_id, formFields);
    countryDropdownField.onChange(row.regional_country_id, formFields);
    zoneDropdownField.onChange(row.regional_zone_id, formFields);
    stateDropdownField.onChange(row.regional_state_id, formFields);
    lgaDropdownField.onChange(row.regional_lga_id, formFields);

    addressContinentDropdownField.onChange(row.address_regional_continent_id, formFields);
    addressCountryDropdownField.onChange(row.address_regional_country_id, formFields);
    addressZoneDropdownField.onChange(row.address_regional_zone_id, formFields);
    addressStateDropdownField.onChange(row.address_regional_state_id, formFields);
    addressLgaDropdownField.onChange(row.address_regional_lga_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      /* email: editRecord.email,
      //password: 'Password#123',
      is_active: editRecord.is_active,
      contact: { */
          contact_id: editRecord.contact_id,
          salutation_id: editRecord.salutation_id,
          last_name: editRecord.last_name,
          first_name: editRecord.first_name,
          middle_name: editRecord.middle_name,
          maiden_name: editRecord.maiden_name,
          gender_id: editRecord.gender_id,
          marital_status_id: editRecord.marital_status_id,
          phone_number: editRecord.phone_number,
          nin: editRecord.nin,
          date_of_birth: editRecord.dob ? editRecord.dob : "0000-00-00",
          nationality_ward_id: editRecord.ward_id,
          ward_id: editRecord.address_ward_id,
          //company_email_address: editRecord.email,
          company_alt_phone_number: editRecord.alt_phone_number,
          company_website: editRecord.website,
          street_number: editRecord.street_number,
          street_name: editRecord.street_name,
          nearest_landmark: editRecord.nearest_landmark,
          city: editRecord.city,
          geo_tag: editRecord.geo_tag,
      //}
    };
    
    try {
        await axiosPrivate.put(`${ContactBranchApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ContactBranchApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const OpenTabModal = async (row, index) => {
    setTabModalOpen(true);
    setEditMode(false);
    setContactTab('branch-info')
    
    setSelectedRowData(row);
    setSelectedIndexRowData(index);
  }

  const handleTabCloseModal = () => {
    setTabModalOpen(false);
  };

  const handleContactBranchUpdate = async (id, editRecord) => {
    //console.log("button clicked");
  }

  const memoizedOpenTabModal = useMemo(() => handleContactBranchUpdate, [allData, selectedRowData]);

  const useTableAction = [
    {
      label: `${View}`,
      action: handleView,
      color: 'success',
      icon: 'icon-eye',
    },
    {
      label: `${Details}`,
      action: OpenTabModal,
      color: 'warning',
      icon: 'icon-pencil-alt',
    },
  ];

  const updateRowRecord = (index, updatedRecord) => {
    const updatedAllData = [...allData];
    updatedAllData[index] = updatedRecord;
    setAllData(updatedAllData);
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/contacts/branches/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${FullName}`,
      selector: row => row.last_name,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
                          <Media body className="align-self-center">
                              <div>{row.contact.company_name}</div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    {
      name: `${BranchContactID}`,
      selector: row => row.contact_number,
      sortable: true,
      center: false,
    },
    {
      name: `${Branch}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
      wrap: true,
    },
    {
      name: `${Email}`,
      selector: row => row.email_address,
      sortable: true,
      center: false,
    },
    {
      name: `${PhoneNumber}`,
      selector: row => `${row.phone_number}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const handlePageChange = page => {
    getAllContactsData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${ContactBranchApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Crm" title="Contact Branches" mainTitle="Contact Branches" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Contact Branches List" btnUrl={addBtn} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  //onEdit={handleEdit}
                  useTableAction={useTableAction} 

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Company}</th>
                    <td>{selectedRowData.contact.company_short_name} ({selectedRowData.contact.contact_number})</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{BranchContactID}</th>
                    <td>{selectedRowData.contact_number}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Branch}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{ContactPerson}</th>
                    <td>{selectedRowData.last_name} {selectedRowData.first_name} {selectedRowData.middle_name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EmailAddress}</th>
                    <td>{selectedRowData.email_address}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PhoneNumber}</th>
                    <td>{selectedRowData.phone_number}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Location}</th>
                    <td>{selectedRowData.street_number}, {selectedRowData.street_name} {selectedRowData.city}</td>
                  </tr>
                  {/* <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.user_login.is_active ? (
                          <span className="badge badge-light-success">Active</span>
                        ) : (
                          <span className="badge badge-light-danger">Inactive</span>
                        )}
                      
                        {selectedRowData.user_login.email_verified_at ? (
                          <span className="badge badge-light-success">Verified</span>
                        ) : (
                          <span className="badge badge-light-danger">Not Verified</span>
                        )}
                    </td>
                  </tr> */}
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>

        <ViewModal
          isOpen={tabModalOpen}
          toggle={() => setTabModalOpen(!tabModalOpen)}
          rowData={selectedRowData}
          formFields={undefined}
          editMode={editMode}
          onEdit={memoizedOpenTabModal}
        > 
          <Nav className='border-tab nav-secondary nav-pills' tabs>
            {selectedRowData && (
              <>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'branch-info' ? 'active' : ''} onClick={() => setContactTab('branch-info')}>
                    <i className='icofont icofont-ui-office'></i>
                    {Branch}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'electronic-address' ? 'active' : ''} onClick={() => setContactTab('electronic-address')}>
                    <i className='icofont icofont-book'></i>
                    {ElectronicAddress}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'physical-address' ? 'active' : ''} onClick={() => setContactTab('physical-address')}>
                    <i className='icofont icofont-book'></i>
                    {PhysicalAddress}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'basic-info' ? 'active' : ''} onClick={() => setContactTab('basic-info')}>
                    <i className='icofont icofont-worker'></i>
                    {RepresentationInfo}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'nationality-info' ? 'active' : ''} onClick={() => setContactTab('nationality-info')}>
                    <i className='icofont icofont-globe'></i>
                    {Nationality}
                  </NavLink>
                </NavItem>
              </>
            )}

          </Nav>
          <TabContent activeTab={contactTab}>
            <BranchInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <BasicInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <NationalityInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <ElectronicAddressInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <PhysicalAddressInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
          </TabContent>
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default ContactBranchList;