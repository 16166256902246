import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BackToList, ProductClass  } from '../../../../Constant';
import GenerateForm from '../../../Common/Component/GenerateForm';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { ProductGroup } from '../../../../Constant';
import { ProductGroupApi, ProductClassApi} from '../../../../api';
import { useForm } from 'react-hook-form'; 
import CustomizerContext from '../../../../_helper/Customizer';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';

function ProductClassCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${ProductClass}`));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/product-classes/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
          errors.showMessages();
      }

      const newRecordTemp = {
          organisation_id: userOrganisationId,
          product_group_id: newRecord.product_group_id,
          name: newRecord.name,
          description: newRecord.name,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(ProductClassApi, newRecordTemp, {
              headers: {
                  'Content-Type': 'application/json',
              },
          }).then((resp) => {
              toast.success(`${resp.data.name} is successfully added.`)
              history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
              toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
              toast.error(error.response.data.detail);
          }
          else {
              toast.error('Form submission error');
          }
          throw error;
      }  
  };

    const productGroupDropdownField = createDropdownField(axiosPrivate,
        'product_group_id',
        'product_group_id',
        `${ProductGroup}`,
        `${ProductGroupApi}?organisation_id=${userOrganisationId}`,
        '',
        12
    );

    useEffect(() => {
  
        const fetchData = async () => {
          try {
            if (!isNewFieldAdded) {
              const updatedFields = [productGroupDropdownField, ...formFields];
              
              await updatedFields[0].fetchOptions();
              
              setFormFields(updatedFields);
              setIsNewFieldAdded(true);
            }
          } catch (error) {
            //console.error('Error fetching options:', error);
          }
        };
    
        fetchData();
    
      }, [formFields, isNewFieldAdded, productGroupDropdownField]);
  

    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Product Class" mainTitle="Product Class" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Product Class"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>
        </>
    )
}

export default ProductClassCreate