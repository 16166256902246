
import { useState, useEffect, Fragment, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {Card, CardBody} from 'reactstrap';
import { BackToList, Models, Makers } from '../../../../Constant'; 
import GenerateForm from '../../../Common/Component/GenerateForm'; 
import { base_form_fields } from '../../../../Data/FormField/PublicData'; 
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import CustomizerContext from '../../../../_helper/Customizer'; 
import { Breadcrumbs } from '../../../../AbstractElements'; 
import { MakersApi, ModelsApi } from '../../../../api';
import { createDropdownField } from '../../../../Data/FormField/PublicData'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';

function ModelsCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${Models}`));
    const lists = `${process.env.PUBLIC_URL}/technical/models/${layoutURL}`;
    
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
          errors.showMessages();
      }

      const newRecordTemp = {
          organisation_id: userOrganisationId,
          maker_id: newRecord.maker_id,
          name: newRecord.name,
          description: newRecord.name,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(ModelsApi, newRecordTemp, {
              headers: {
                  'Content-Type': 'application/json',
              },
          }).then((resp) => {
              toast.success(`${resp.data.name} is successfully added.`)
              history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
              toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
              toast.error(error.response.data.detail);
          }
          else {
              toast.error('Form submission error');
          }
          throw error;
      }  
    };

    const makersDropdownField = createDropdownField(axiosPrivate,
        'maker_id',
        'maker_id',
        `${Makers}`,
        `${MakersApi}?organisation_id=${userOrganisationId}`,
        '',12
    );
  
    useEffect(() => {
  
      const fetchData = async () => {
        try {
          if (!isNewFieldAdded) {
            const updatedFields = [makersDropdownField, ...formFields];
  
            await updatedFields[0].fetchOptions();
            
            setFormFields(updatedFields);
            setIsNewFieldAdded(true);
          }
        } catch (error) {
          //console.error('Error fetching options:', error);
        }
      };
  
      fetchData();
  
    }, [formFields, isNewFieldAdded, makersDropdownField]);

    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Technical" title="Models" mainTitle="Models" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Models"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default ModelsCreate