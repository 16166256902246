import { useContext, useEffect, useState } from "react";
import {
    Name,
    Description,
    Status,
    SubLedgerClass,
    GeneralLedgerGroup,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { financesGeneralLedgerGroupApi } from "../../../../../api";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useSubLedgerClassController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const formTitle = SubLedgerClass;
    const [formFields, setFormFields] = useState<any>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?organisation_id=${userOrganisationId}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [generalLedgerGroupDropDown, ...formFields];

        await generalLedgerGroupDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-class/${layoutURL}`;

    async function onSubmit(data: any) {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/sub-ledger-classes", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
            });
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
