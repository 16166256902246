import { useLayoutEffect, useState } from "react";
import {
    AccessCategory,
    AccessCategoryGroup,
    AccessGroup,
    Name,
    Status,
    UserType,
} from "../../../../Constant";
import { createDropdownField } from "../../../../Data/FormField/PublicData";
import useAuth from "../../../../Hooks/useAuth";
import { StatusRow } from "../../../Finance/Settings/GeneralLedgerGroup/List/GeneralLedgerGroupList";
import { toast } from "react-toastify";
import { userTypeApi } from "../../../../api";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";

export default function useAccessCategoryGroup() {
    const axiosPrivate = useAxiosPrivate()
    const title = AccessCategoryGroup;
    const { userOrganisationId }: any = useAuth();
    const [accessCategoryId, setAccessGroupId] = useState(0);
    const [available, setAvailable] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const [formFields, setFormFields] = useState<any[]>([]);

    //?? DROPDOWNS INITS >
    const userType = createDropdownField(axiosPrivate,
        "user_type",
        "user_type",
        UserType,
        `${userTypeApi}`,
        "",
        6,
        false,
        "table_title"
    );
    userType.onChange = async (id, updatedFields) => {
        setAccessGroupId(0);
        setAssigned([]);
        setAvailable([]);
        const accessCategoryDropDown = updatedFields.find(
            (field: any) => field.id === "access_category_id"
        );
        if (accessCategoryDropDown) {
            const endPoint = `access-controls/access-categories?skip=0&limit=10&with_trashed=false&organisation_id=${userOrganisationId}&user_type_id=${id}`;
            await accessCategoryDropDown.fetchOptions(id, false, endPoint);
            setFormFields([...updatedFields]);
        }
    };
    const accessCategory = createDropdownField(axiosPrivate,
        "access_category_id",
        "access_category_id",
        AccessCategory,
        "",
        "",
        6
    );

    accessCategory.onChange = async (id, updatedFields) => {
        try {
            setAccessGroupId(id);
            const endPoint = `${"/access-controls/categories"}/${id}/groups?organisation_id=${userOrganisationId}`;
            axiosPrivate
                .get(endPoint)
                .then((res) => {
                    //console.log(res.data);
                    setAssigned([]);
                    setAvailable([]);

                    if (res.data) {
                        setAssigned(res.data.assigned_groups);
                        setAvailable(res.data.available_groups);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                    toast.error("Error: Please Select All Fields");
                    setAssigned([]);
                    setAvailable([]);
                });
        } catch (error) {
           // console.log("CAUGHT ERROR:", error);
        }
    };

    const tableColumns = [
        {
            name: Name,
            selector: (row: any) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Status,
            selector: (row: any) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: any) => StatusRow(row),
        },
    ];

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, userType, accessCategory];
        await userType.fetchOptions();
        setFormFields(updatedFields);
       // console.log(updatedFields);
    }
    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const assignGroup = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No groups selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/access-controls/categories/${accessCategoryId}/assign-groups?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Groups assigned successfully");
                //console.log(res.data);
            });
    };

    const usassignGroup = (data: any[]) => {
        if (data.length === 0) {
            toast.error("No groups selected");
            return;
        }
        // grab an array of ids from the data
        const ids = data.map((item: any) => item.id);
        //console.log(ids);
        axiosPrivate
            .post(
                `/access-controls/categories/${accessCategoryId}/remove-groups?organisation_id=${userOrganisationId}`,
                {
                    items: ids,
                }
            )
            .then((res) => {
                toast.success("Groups unassigned successfully");
                //console.log(res.data);
            });
    };

    return {
        title,
        formFields,
        tableColumns,
        available,
        assigned,
        assignGroup,
        usassignGroup,
    };
}
