import { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CaseGroupApi, ServiceTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { GroupName, ServiceType } from '../../../../Constant';
import useAuth from '../../../../Hooks/useAuth';

const CaseGroupCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/crm/settings/case-group/${layoutURL}`;
  const [formFields, setFormFields] = useState(base_form_fields(`${GroupName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const { formState: { errors }, } = useForm();

  const serviceTypeDropdown = createDropdownField(axiosPrivate,
    'service_type_id',
    'service_type_id',
    `${ServiceType}`,
    `${ServiceTypeApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [serviceTypeDropdown, ...formFields];

          await updatedFields[0].fetchOptions();

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded]);


  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      organisation_id: userOrganisationId,
      name: newRecord.name,
      description: newRecord.description,
      service_type_id: newRecord.service_type_id,
      is_active: newRecord.is_active,
    };

    try {
      await axiosPrivate.post(CaseGroupApi, newRecordTemp, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="New Case Group " mainTitle="New Case Group" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CaseGroupCreate;