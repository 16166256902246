import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const PrivateRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();
  /* const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    localStorage.setItem("authenticated", authenticated);
    localStorage.setItem("login", login);
  }, []);

  return login || authenticated ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} />;
 */
  //console.log('Auth Cred:', auth);
  return (
    //auth?.roles?.find(role => allowedRoles?.includes(role))
    auth?.accessToken
        ? <Outlet />
        /* : auth?.user
            ? <Navigate to="/unauthorized" state={{ from: location }} replace /> */
            : <Navigate exact to={`${process.env.PUBLIC_URL}/login`} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
