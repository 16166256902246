import { useEffect, Fragment, useCallback, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { View } from '../../../Constant';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
//import useAuth from '../../../Hooks/useAuth';

//import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';

const DataTableComponent = ({tabledata, 
  tableColumns, 
  isLoading, 
  onView, 
  onDelete, 
  onEdit, 
  deleteMany = true, 
  clearSelect = false, 
  onRowSelect = (data) => data, 
  useTableAction, 
  addToDefaultAction, 
  useDefaultAction = true}) => {
    
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [noDeleteMany, setNoDeleteMany] = useState(deleteMany);
    //const [data, setData] = useState(tabledata);
    //const [pending, setPending] = useState(isLoading);
        
    // const { auth0_profile } = useAuth();
    // const allowed_email = ['user@example.com', 'admin@vdtcomms.com'];
    
    const customColumns = [...tableColumns,];

    if (useTableAction) {
        /* const tableActions = [
          {
            label: 'View',
            action: onView,
            color: 'success',
            icon: 'icon-eye',
          },
          {
            label: 'Edit',
            action: onEdit,
            color: 'primary',
            icon: 'icon-pencil-alt',
          },
          {
            label: 'Delete',
            action: onDelete,
            color: 'danger',
            icon: 'icon-trash',
          },
        ]; */
  
        customColumns.push({
          name: 'Actions',
          selector: 'actions',
          sortable: false,
          cell: (row) => (
            <div className="btn-showcase">
              {useTableAction.map((action, index) => (
                <button key={index} 
                    className={`btn btn-outline-${action.color} btn-xs`}
                    onClick={() => action.action(row)}
                >
                  <i className={action.icon}></i> {action.label}
                </button>
              ))}
            </div>
          ),
        });
    }

    if (!useTableAction && useDefaultAction) {
        customColumns.push({
        name: 'Actions',
        selector: 'actions',
        sortable: false,
        cell: (row) => (
            <div className="btn-showcase">
            <button className="btn btn-outline-success btn-xs" onClick={() => onView(row)}>
                <i className="icon-eye"></i> View
            </button>
            <button className="btn btn-outline-primary btn-xs" onClick={() => onEdit(row)}>
                <i className="icon-pencil-alt"></i> Edit
            </button>
            {/* { allowed_email.includes(auth0_profile.email) && ( */}
              {/* <button className="btn btn-outline-danger btn-xs" onClick={() => onDelete(row)}>
                  <i className="icon-trash"></i> Delete
              </button> */}
            {/* )} */}
            {addToDefaultAction && addToDefaultAction.length > 0 && (
              addToDefaultAction.map((action, index) => (
                  <button key={index} 
                      className={`btn btn-outline-${action.color} btn-xs`}
                      onClick={() => action.action(row)}
                  >
                    <i className={action.icon}></i> {action.label}
                  </button>
                ))
            )}
            </div>
        ),
        });
    }
    

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
        onRowSelect(state.selectedRows);
        clearSelect = false;
    }, []);

    const handleSelectedDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);

            //setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };

    useEffect(() => {
      //console.log(selectedRows);
      if (clearSelect) {
        setSelectedRows([]);
        //console.log(selectedRows);
      }
    }, [clearSelect])

    //===========================================================================
    /* const handleView = (row) => {
        // Handle the View action
        //console.log('View clicked for row:', row);
    };
    
    const handleDelete = (row) => {
    // Handle the Delete action
    //console.log('Delete clicked for row:', row);
    };

    const handleEdit = (row) => {
    // Handle the Edit action
    //console.log('Edit clicked for row:', row);
    }; */
    //===========================================================================
    let columns = customColumns.map(column => {
        if (typeof column.selector === 'function') {
            return {
                ...column,
                cellExport: row => column.selector(row)
            };
        } else {
            // Handle the case where column.selector is not a function
            return {
                ...column,
                cellExport: row => row[column.selector] // or row[column.selector] || someDefaultValue
            };
        }
    });
    //console.log(columns)
    //let columns = customColumns
    let data = tabledata
    const tableData = {
      columns,
      data,
      print: false,
      exportHeaders: true
    };
    return (
        <Fragment>
            {(selectedRows.length !== 0 && noDeleteMany === true) &&
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                    <Btn attrBtn={{ color: 'danger', onClick: () => handleSelectedDelete() }}>Delete</Btn>
                </div>
            }
            <DataTableExtensions {...tableData}>
              <DataTable
                  data={tabledata}
                  columns={columns}
                  striped={true}
                  center={true}
                  progressPending={isLoading}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleDelet}
              />
            </DataTableExtensions>
        </Fragment>
    )
}
export default DataTableComponent