import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { BankApi, BankTypeApi, TaxAuthorityApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import {tax_authority_fields, createDropdownField} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Alias, Bank, BankAccount, BankCode, BankType, DateCreated, DateModified, Description, IrsName, IrsShortName, PaymentCode, StateCode, Status } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const TaxAuthorityList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(tax_authority_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const bank_type_dropdown_field = createDropdownField(axiosPrivate,
    'bank_type_id',
    'bank_type_id',
    `${BankType}`,
    `${BankTypeApi}`,
    '', 12
  );

  const bank_dropdown_field = createDropdownField(axiosPrivate,
    'bank_id',
    'bank_id',
    `${Bank}`,
    `${BankApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          // Find the index of the 'name' object
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
    
          const updatedFields = [bank_type_dropdown_field, bank_dropdown_field, ...formFields];
          
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, bank_type_dropdown_field, bank_dropdown_field]);

  bank_type_dropdown_field.onChange = async (selectedId, updatedFields) => { 
    const modelField = updatedFields.find((field) => field.id === 'bank_id');
    
    if (modelField) {
      await modelField.fetchOptions(selectedId);
      setFormFields([...updatedFields]);
    } 
  };

  const getAllTaxAuthoritysData = async () => {
      try {
          await axiosPrivate.get(TaxAuthorityApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllTaxAuthoritysData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.bank_type_id = row.bank.bank_type_id;

    bank_type_dropdown_field.onChange(row.bank_type_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
      bank_id: editRecord.bank_id,
      irs_name: editRecord.irs_name,
      irs_short_name: editRecord.irs_short_name,
      state_code: editRecord.state_code,
      payment_code: editRecord.payment_code,
      bank_account: editRecord.bank_account,
      description: editRecord.description,
      is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${TaxAuthorityApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${TaxAuthorityApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/public-data/tax-authorities/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${StateCode}`,
      selector: row => `${row.state_code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${IrsName}`,
      selector: row => `${row.irs_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${IrsShortName}`,
      selector: row => `${row.irs_short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Bank}`,
      selector: row => `${row.bank.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${BankAccount}`,
      selector: row => `${row.bank_account}`,
      sortable: true,
      center: false,
    },
    {
      name: `${PaymentCode}`,
      selector: row => `${row.payment_code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Tax Authorities" mainTitle="Tax Authorities" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{StateCode}</th>
                    <td>{selectedRowData.state_code}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{IrsName}</th>
                    <td>{selectedRowData.irs_name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{IrsShortName}</th>
                    <td>{selectedRowData.irs_short_name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{BankCode}</th>
                    <td>{selectedRowData.bank.code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Alias}</th>
                    <td>{selectedRowData.bank.short_name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{BankAccount}</th>
                    <td>{selectedRowData.bank_account}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PaymentCode}</th>
                    <td>{selectedRowData.payment_code}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                  </tbody>
                </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default TaxAuthorityList;