import { Fragment, useState } from 'react';
import { DateCreated, DateModified, Details, DueDate, Replies, TimeWorked, TrackingNumber } from '../../../../../Constant';
import { H5, Btn } from '../../../../../AbstractElements';
import { Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const CaseMoreInfo = ({ details }) => {
    const [isFilter, setIsFilter] = useState(true);

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardHeader>
                        <H5 attrH5={{ className: 'mb-0' }} >
                            <Btn attrBtn={{
                                className: 'd-flex justify-content-between btn btn-link',
                                onClick: () => setIsFilter(!isFilter),
                                color: 'transperant'
                            }} >{Details}
                            {isFilter ? <ChevronUp className='m-0' /> : <ChevronDown className='m-0' />}
                            </Btn>
                        </H5>
                    </CardHeader>
                    <Collapse isOpen={isFilter}>
                        <div className="collapse show" id="collapseicon" aria-labelledby="collapseicon" data-parent="#accordion">
                            <CardBody className="filter-cards-view animate-chk">
                            <Table>
                                <tbody>
                                    <tr className=''>
                                        <th scope='row'>{TrackingNumber}</th>
                                        <td>{details.track_number}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DateCreated}</th>
                                        <td>{formatDateWithTimeZone(details.created_at)}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DateModified}</th>
                                        <td>{formatDateWithTimeZone(details.updated_at)}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{Replies}</th>
                                        <td>{details.replies_count}</td>
                                    </tr>
                                    {/* <tr className=''>
                                        <th scope='row'>{LastReplier}</th>
                                        <td>Group</td>
                                    </tr> */}
                                    <tr className=''>
                                        <th scope='row'>{TimeWorked}</th>
                                        <td>{details.time_worked}</td>
                                    </tr>
                                    <tr className=''>
                                        <th scope='row'>{DueDate}</th>
                                        <td>{details.due_date ? formatDateWithTimeZone(details.due_date) : ""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            </CardBody>
                        </div>
                    </Collapse>
                </Card>
            </Col>
        </Fragment>
    );
};

export default CaseMoreInfo;