import { RadioIpAddress, InterfaceIpAddress, BaseLockedTo, LatitudeA, LatitudeB, LongitudeA, LongitudeB, CoOrdinateA, CoOrdinateB, } from "../../../../Constant"

export const link_form_fields = [
  {
    id: 'base_locked_to',
    name: 'base_locked_to',
    label: `${BaseLockedTo}`,
    type: 'text',
    value: '',
    required: true,
    colSize: 6,
  },

  {
    id: 'interface_ip_address',
    name: 'interface_ip_address',
    label: `${InterfaceIpAddress}`,
    type: 'text',
    value: '',
    required: true,
    colSize: 6,
  },

    {
        id: 'radio_ip_address',
        name: 'radio_ip_address ',
        label: `${RadioIpAddress}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
   
      {
        id: 'co-ordinateA',
        name: 'co-ordinateA',
        label: `${CoOrdinateA}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'co-ordinateB',
        name: 'co-ordinateB',
        label: `${CoOrdinateB}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'latitudeA',
        name: 'latitudeA',
        label: `${LatitudeA}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'latitudeB',
        name: 'latitudeB',
        label: `${LatitudeB}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'longitudeA',
        name: 'longitudeA',
        label: `${LongitudeA}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'longitudeB',
        name: 'longitudeB',
        label: `${LongitudeB}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
];

