import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  ExpenseType,
  Description,
  TypeName,
  ExpenseGroup,
  ExpenseCategory,
  ExpenseClass,
  Status,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesExpenseCategoryApi,
  financesExpenseClassApi,
  financesExpenseGroupApi,
  financesExpenseTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export default function useExpenseTypeController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = ExpenseType;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: TypeName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const expenseCategory = createDropdownField(
    axiosPrivate,
    "expense_category",
    "expense_category",
    ExpenseCategory,
    `${financesExpenseCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  /* expenseCategory.onChange = async (id, fields) => {
    await expenseGroup.fetchOptions();
    updateFields();
  }; */

  expenseCategory.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
        (field: any) => field.id === "expense_group"
    );
    if (nextField) {
      const endPoint = `${financesExpenseGroupApi}?organisation_id=${userOrganisationId}&expense_category_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "expense_category") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
    }
};

  const expenseGroup = createDropdownField(
    axiosPrivate,
    "expense_group",
    "expense_group",
    ExpenseGroup,
    ``,
    "",
    6
  );
  expenseGroup.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
        (field: any) => field.id === "expense_class_id"
    );
    if (nextField) {
        const endPoint = `${financesExpenseClassApi}?organisation_id=${userOrganisationId}&expense_group_id=${id}`
        await nextField.fetchOptions(id, false, endPoint);
        const updatedFormFields = updatedFields.map((field: any) => {
            if (field.id === "expense_group") {
                return { ...field, value: id };
            }
            return field;
        });
        setFormFields([...updatedFormFields]);
    }
  };

  const expenseClass = createDropdownField(
    axiosPrivate,
    "expense_class_id",
    "expense_class_id",
    ExpenseClass,
    ``,
    "",
    6
  );

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [
      expenseCategory,
      expenseGroup,
      expenseClass,
      ...formFields,
    ];
    await expenseCategory.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-type/${layoutURL}`;

  const onSubmit = async (data: any) => {
      await axiosPrivate
       .post(financesExpenseTypeApi, data)
        .then((_res) => {
         toast.success(`${_res.data.name} Created Successfully.`)
        })
        .catch((err) => {
            if (err.response && err.response.status === 422) {
            toast.error('Validation Errors');
            } else if (err.response && err.response.status === 400) {
            toast.error(err.response.data.detail);
            } else {
            toast.error('Something Went Wrong');
            }
            throw err;
        });
 
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
