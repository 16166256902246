import React, { useEffect, useState } from 'react';
import { H4 } from '../../../AbstractElements';

const SLATimeCounter = ({ dueDate }) => {
  const [timeDifference, setTimeDifference] = useState('');
  const [color, setColor] = useState('success');

  useEffect(() => {
    const dueDateTime = new Date(dueDate);

    const timerInterval = setInterval(() => {
      // Calculate time difference
      const currentTime = new Date();
      const difference = dueDateTime - currentTime;

      // Format time difference
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      let timeDiffText = `${days}d ${hours}h ${minutes}m ${seconds}s`;
     // setTimeDifference(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      if (hours <= 1) {
        setColor('danger');
      } else if (days <= 1) {
        setColor('primary');
      } else {
        setColor('success');
      }

      // Clear interval if due date has passed
      if (difference <= 0) {
        clearInterval(timerInterval);
        timeDiffText = 'SLA Expired';
      }

      // Set time difference text
      setTimeDifference(timeDiffText);

    }, 1000); // Update every second

    return () => clearInterval(timerInterval);
  }, [dueDate]);

  return (
    <div>
      <p>Time remaining until Due Date: <br/>
        {/* <H4 style={{color: 'blue'}} attrH6={{ className: 'text-primary' }}>{timeDifference}</H4> */}
        <h4 className={`text-${color}`}>{timeDifference}</h4>
      </p>
    </div>
  );
};

export default SLATimeCounter;
