import { Fragment, useState, useEffect, useRef } from 'react';
import { Btn, H4, P, Image, Alerts } from '../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';

import { EmailAddress, ResetPassword, SignIn } from '../Constant';

import man from '../assets/images/dashboard/profile.png';

import useAuth from '../Hooks/useAuth';
import axios from '../api/axios';
import Spinner from '../Components/Common/Component/Spinner';
const RESET_PASSWORD_URL = '/auth/forgot-password';


const ForgotPwdForm = ({ logoClassMain }) => {

  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const errRef = useRef();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [value
    //, setValue
    ] = useState(localStorage.getItem('profileURL' || man));
  const [name
    //, setName
    ] = useState(localStorage.getItem('Name'));

  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Guest');
  }, [value, name]);

  useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
      setSuccessMsg('');
  }, [email])
 
 const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
        setLoading(true); 
        await axios.post(RESET_PASSWORD_URL, {email: email}, {
          headers: {
              'Content-Type': 'application/json',
          },
        }).then((resp) => {
            setSuccessMsg(resp.data.message)
            setTimeout(() => {
              setEmail('')
            }, 3000);
        });

    } catch (err) {
      
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 422) {
            setErrMsg('Missing Email');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else if (err.response && err.response.status === 400) {
            setErrMsg(err.response.data.detail);
        } else {
            setErrMsg('Login Failed');
        }
       // errRef.current.focus();
    } 
    finally {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <div className='login-card'>
        <div>
          <div>
            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div>

          <div className='login-main'>
            {errMsg && <Alerts attrAlert={{ color: 'danger ' }} >
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              </Alerts>}
            {successMsg && <Alerts attrAlert={{ color: 'success ' }} >
              <p ref={userRef} className={successMsg ? "" : "offscreen"} aria-live="assertive">{successMsg}</p>
              </Alerts>}  
          
            <Form className='theme-form login-form' onSubmit={handleSubmit}>
              <H4>Forgot Password</H4>
              <P>Kindly fill the field below to reset your password</P>
              <FormGroup>
                <Label className='col-form-label m-0' htmlFor="email">{EmailAddress}</Label>
                <Input className='form-control' type='email'  id='email'
                onChange={(e) => setEmail(e.target.value)} 
                value={email}
                ref={userRef}
                autoComplete="off" required/>
                
              </FormGroup>

              <FormGroup>
                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2' }}>
                  {loading ? <Spinner/> :  `${ResetPassword}`}
                </Btn>
              </FormGroup>

              <P attrPara={{ className: 'text-center mb-0 ' }}>
                Already have an account?
                <Link className='ms-2' to={`/login`}>
                  {SignIn}
                </Link>
              </P>
             </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPwdForm;
