import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
    financesGeneralLedgerTypeApi,
} from "../../../../../api";
import {
    Description,
    GeneralLedgerAccount,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    Name,
    Status,
} from "../../../../../Constant";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";

export default function useGeneralLedgerAccountController() {
    const axiosPrivate = useAxiosPrivate();
    const formTitle = GeneralLedgerAccount;
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-account/${layoutURL}`;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //?? DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerClassDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_class_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerClassApi}?organisation_id=${userOrganisationId}&gl_group_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_group_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        updateFields();
    };

    const generalLedgerClassDropDown = createDropdownField(
        axiosPrivate,
        "gl_class_id",
        "gl_class_id",
        GeneralLedgerClass,
        `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, updatedFields) => {
        // await generalLedgerTypeDropDown.fetchOptions();
        const nextField = updatedFields.find(
            (field: any) => field.id === "gl_type_id"
        );
        if (nextField) {
            const endPoint = `${financesGeneralLedgerTypeApi}?organisation_id=${userOrganisationId}&gl_class_id=${id}`;
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "gl_class_id") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
        updateFields();
    };

    const generalLedgerTypeDropDown = createDropdownField(
        axiosPrivate,
        "gl_type_id",
        "gl_type_id",
        GeneralLedgerType,
        `${financesGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            generalLedgerGroupDropDown,
            generalLedgerClassDropDown,
            generalLedgerTypeDropDown,
            ...formFields,
        ];
        await generalLedgerGroupDropDown.fetchOptions();
        setFormFields(updatedFields);
        console.log(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    async function onSubmit(data: any) {
        console.log(data);
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/general-ledger-accounts", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`);
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                    toast.error("Validation Errors");
                } else if (err.response && err.response.status === 400) {
                    toast.error(err.response.data.detail);
                } else {
                    toast.error("Something Went Wrong");
                }
                throw err;
            });
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
