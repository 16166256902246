import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./GeneralLedgerClassList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Code,
    CreatedBy,
    Description,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    ModifiedBy,
    Name,
    Status,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    financesGeneralLedgerClassApi,
    financesGeneralLedgerGroupApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { IGeneralLedgerClassResponse } from "../GeneralLedgerClass.types";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { toast } from "react-toastify";

export default function useGeneralLedgerClassListController() {
    const axiosPrivate = useAxiosPrivate();
    const title = GeneralLedgerClass;
    const { userOrganisationId }: any = useAuth();
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

       /*  {
            id: "code",
            name: "code",
            label: Code,
            type: "text",
            value: "",
            required: true,
            disabled: true,
            colSize: 6,
        }, */

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Code,
            selector: (row: (typeof data)[0]) => `${row.code}`,
            sortable: false,
            center: false,
        },

        {
            name: GeneralLedgerGroup,
            selector: (row: (typeof data)[0]) =>
                `${row.gl_group.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    //?? DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [generalLedgerGroupDropDown, ...formFields];
            await generalLedgerGroupDropDown.fetchOptions();
            setFormFields(updatedFields);
        })();
    }, []);

    function fetchData() {
        setLoading(true);
        axiosPrivate
            .get(
                `${financesGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: IGeneralLedgerClassResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error('Something Went Wrong');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useLayoutEffect(() => {
        fetchData();
    }, []);

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);

        row.gl_group_id = row?.gl_group?.id;
        //generalLedgerGroupDropDown.onChange(row.gl_group_id, formFields);

        setSelectedRowData(row);
    };

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-class/create/${layoutURL}`;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IGeneralLedgerClassResponse[]>([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        await axiosPrivate
            .put(`${financesGeneralLedgerClassApi}/${id}`, {
                ...editRecord,
                organisation_id: userOrganisationId,
            })
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`)
                //fetchData();
            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                toast.error('Validation Errors');
              } else if (err.response && err.response.status === 400) {
                toast.error(err.response.data.detail);
              } else {
                toast.error('Something Went Wrong');
              }
              throw err;
              //toast.error(err.response.message || "Something Went Wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
