import { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Media, Input } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { Add, Cancel, SelectOption, Update } from '../../../Constant';
import { Btn, Image } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Spinner from './Spinner';
import InputSelect from '../InputSelect/InputSelect';

const GenerateFormTwo = ({ formFields, formTitle, actions }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm(); 

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [loadingAction, setLoadingAction] = useState(null);

  const [switchValues, setSwitchValues] = useState(() => {
    // Initialize the state object based on the dynamically generated form data
    const initialState = {};
    formFields
    .filter((field) => field.type === 'switch')
    .forEach((field) => {
      initialState[field.id] = false; 
    });

    return initialState;
  });
  
  const watchedFields = formFields
    .filter(field => field.type === 'checkbox2')
    .map(field => watch(field.name, [])); 

  useEffect(() => {
    formFields.forEach(field => {
      if (field.type === 'checkbox2') {
        const fieldValues = getValues()[field.name] || [];
        const selectedCount = Array.isArray(fieldValues) ? fieldValues.filter(Boolean).length : 0;
  
        if (selectedCount >= field.minSelection) {
          field.options.forEach((option, index) => {
            const currentValue = getValues()[`${field.name}.${index}`];
          });
        }
      }
    });
  }, [watchedFields, getValues]);

  //================ Handle File Upload ===================================== //
  const [files, setFiles] = useState({});
  //console.log('Uploads:', files);
  const readFile = (event, fieldName) => { 
    if (event.target.files.length === 0) return;

    const mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setFiles((prevFiles) => {
        return { ...prevFiles, [fieldName]: reader.result}
      });
    };
  };

  function deleteFile(fieldName) {
    setFiles((prevUrls) => ({
      ...prevUrls,
      [fieldName]: '',
    }));
    setValue(fieldName, '');
  }
  
  const handleSwitchChange = (switchId) => { 
    setSwitchValues((prev) => ({
      ...prev,
      [switchId]: !prev[switchId],
    }));
  }; 

  const handleFormSubmit = async (data) => { 
    setIsSubmitting(true);
    data = { ...data, ...switchValues };
    //if (currentAction && currentAction.onClick) {
      try{
        if (currentAction && currentAction.onClick) {
          // Execute the action
          /* const action = actions.find((action) => action.name === currentAction);
          if (action) {
            await action.onClick(data); // Call the action's onClick function
          } */
          await currentAction.onClick(data);
        }
        //reset();
        
        //setIsSubmitting(false);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const validationErrors = error.response.data.detail;
          if (Array.isArray(validationErrors)) {
            validationErrors.forEach((error) => {
              if (Array.isArray(error.loc) && error.loc.length > 0) {
                const field = error.loc[1];
                
                setError(field, {
                  type: 'manual',
                  message: `Custom error message: ${error.msg}`,
                });
              }
            });
          }
          else {
            toast.error(validationErrors);
          }
          //setIsSubmitting(false);
        } else {
          //console.error('Form submission error:', error);
          //setIsSubmitting(false);
        }
      } finally {
        setIsSubmitting(false);
        setLoadingAction(null);
        setCurrentAction(null);
      }
    //}
  }; 

  useEffect(() => {
    if (currentAction) {
      handleSubmit(handleFormSubmit)(); // Trigger the form submission
    }
  }, [currentAction]); //, handleSubmit, handleFormSubmit Ensure to include dependencies

  const renderFormFields = () => {
    return (
      <Row>
        {formFields.map((field, index) => (
          <Col md={field.colSize ? field.colSize : 12 } key={index}>
            {field.type === "group-title" ? (
              <>
                <h5>{field.label}</h5>
                <hr className="mt-4 mb-4"></hr>
              </>
            ) : (
                  <>
                    <Label className="col-sm-3 col-form-label" htmlFor={field.id}>
                      {field.label}
                      {field.required && <span className='text-danger'> *</span>}
                    </Label>
                    
                    {getFieldByType(field)}
                    <span style={{ color: 'red' }}>
                      {errors[field.name] ? 
                        (errors[field.name].message && errors[field.name].message.startsWith("Custom error message:") ? 
                          errors[field.name].message.replace("Custom error message:", "") :
                          errors[field.name].message ||
                          `${field.label} is required`
                        ) :
                        null
                      }
                    </span>
                  </>
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const getFieldByType = (field) => {
    switch (field.type) {
      case 'text':
      case 'password':
      case 'email':
      case 'number':
      case 'url':
      case 'date':
      case 'time':
      case 'date-time':
      case 'datetime-local':
      case 'month':
      case 'week':
      case 'color':
      case 'hidden':
        return (
          <input className="form-control" type={field.type} id={field.id} 
                    placeholder={field.label} 
                    disabled={field.disabled} readOnly={field.disabled}
                    {...register(field.name, { required: field.required })} />
        )
      case 'text-disabled':
        return (
          <input className="form-control" type='text' id={field.id} 
                    placeholder={field.label} readOnly disabled
                    {...register(field.name, { required: field.required })} />
        )  
      case 'file':
        return (
          <div>
            <input
              className="form-control"
              type={field.type}
              id={field.id}
              placeholder={field.label}
              disabled={field.disabled} readOnly={field.disabled}
              {...register(field.name, { 
                required: field.required, 
                onChange: (e) => readFile(e, field.name) 
              })}
            />
          
            {files[field.name] ? (
              <div className="file-preview-container">
                <Image
                  attrImage={{
                    className: 'file-preview',
                    alt: '',
                    src: `${files[field.name]}`,
                  }}
                />
                {/* <div className="d-flex justify-content-center"> */}
                {/* <div className="delete-button">
                  <Btn
                    attrBtn={{
                      className: 'mt-2',
                      type: 'button',
                      color: 'danger',
                      onClick: () => deleteFile(field.name),
                    }}
                  >
                    X
                  </Btn>
                </div> */}
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => deleteFile(field.name)}
                  >
                    X
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )
            
      case 'switch':  
          return (
            <Media body className=" icon-state">
              <Label className='switch'> 
                <Input id={field.id} type='checkbox' 
                  {...register(field.name, { required: field.required })}
                  // defaultChecked={switchValues[field.id]} 
                  checked={switchValues[field.id]} 
                  onChange={() => handleSwitchChange(field.id)} 
                />
                <span className='switch-state'></span>
              </Label>
            </Media>
          )
      case 'checkbox':
        return (
          <div className='m-checkbox-inline custom-radio-ml'>
            {field.options.map((option, index) => (
              <div key={index} className='checkbox checkbox-primary'>
                <input className='form-check-input'
                  type="checkbox" id={`${field.name}.${index}`}
                  value={option.value}
                  {...register(`${field.name}.${index}`, { required: field.required })}
                />
                <label htmlFor={`${field.name}.${index}`}>{option.label}</label>
              </div>
            ))}
          </div>
        );
      case 'checkbox2':
        return (
          <div className='m-checkbox-inline custom-radio-ml'>
            {field.options.map((option, index) => (
              <div key={index} className='checkbox checkbox-primary'>
                <Controller
                  name={`${field.name}.${index}`}
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <input
                      className='form-check-input'
                      type="checkbox"
                      id={`${field.name}.${index}`}
                      value={option.value}
                      checked={value}
                      ref={ref}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      {...register(`${field.name}.${index}`, {
                        validate: {
                          minSelection: () => {
                            const minSelection = field.minSelection ?? 1;
                            const fieldValues = getValues()[field.name] || [];
                            const selectedCount = Array.isArray(fieldValues) ? fieldValues.filter(Boolean).length : 0;
                            return selectedCount >= minSelection || `At least ${minSelection} options must be selected`;
                          }
                        }
                      })}
                    />
                  )}
                />
                <label htmlFor={`${field.name}.${index}`}>{option.label}</label>
              </div>
            ))}
          </div>
        );
      case 'radio':
        return (
          <div>
            {field.options.map((option, index) => (
              <div key={index} className='radio radio-primary'>
                <input className='form-check-input'
                  type="radio" id={`${field.name}.${index}`}
                  value={option.value}
                  {...register(field.name, { required: field.required })}
                />
                <label htmlFor={`${field.name}.${index}`}>{option.label}</label>

                {/* <Input id={index} type="radio"
                  value={option.value}
                  {...register(field.name, { required: true })} />
                    <Label htmlFor={index}>
                      {option.label}
                    </Label> */}
              </div>
            ))}
          </div>
        );
      case 'select':
        return (
          <>
            <select className="form-select form-control" id={field.id} 
              disabled={field.disabled} readOnly={field.disabled}
              {...register(field.name, { required: field.required })}
              onChange={(e) => field.onChange && field.onChange(e.target.value, formFields)}
              >
              <option key={0} value="">{SelectOption}</option>
              {field.options.map((option, index) => (
                <option key={index + 1} value={option.value}>
                  {option.label}
                </option>
              ))} 
            </select>
            {field.loading && <span>Loading...</span>}
          </>
        );
      case 'select2': 
        const selField = field;
        return (
          <>
            <Controller
              name={selField.name}
              control={control}
              defaultValue={selField.value}
              render={({ field, value }) => (
                <Select 
                  {...field}
                  name={field.name}
                  options={selField.options}
                  isClearable={true}
                  className="js-example-basic-single col-sm-12"
                  value={value}
                  onChange={(e) => {
                      field.onChange && field.onChange(e ? e.value : null)
                      selField.onChange && selField.onChange(e ? e.value : null, formFields);
                    }
                  }
                />
              )}
            />
          </>
        )
      case 'textarea':
        return <textarea className="form-control" id={field.id}
          disabled={field.disabled} readOnly={field.disabled}
         {...register(field.name, { required: field.required })} />;
      case 'submit':
      case 'cancel':
        return <Btn attrBtn={{ name: `${field.name}`, color: 'success', className: 'me-3' }}>{field.label}</Btn>;
      case 'input-select':
        return <InputSelect
          apiString={field.api}
          mapString={field.id}
          disabledStatus={field.disabled} 
          onMount={(e) => setValue(field.name, field.value.id)}
          defaultSelectedText={field.value?.label}
          {...register(field.name, { required: field.required })}
          onInputChange={(e) => {}}
          onSelectedChange={(e) => {
              setValue(field.name, e.value)
              field.onSelectedChange && field.onSelectedChange(e.value, formFields)
            }
          }
        />;
      default:
        return null;
    }
  };

  const handleActionClick = (action) => { 
    setCurrentAction(action);
    setLoadingAction(action.name); 
  };

  return (
    <Fragment>
      { formTitle && (
        <>
          <h5>{formTitle}</h5>
          <hr className="mt-4 mb-4"></hr>
        </>)
      }
      <Form className='form theme-form' onSubmit={handleSubmit(handleFormSubmit)}>
        
        {renderFormFields()}

        <Row>
          <Col>
            <div className='text-end'> 
              {actions && actions.map((action, index) => (
                <Btn
                  key={index}
                  attrBtn={{
                    name: action.name,
                    color: action.color || 'primary',
                    className: 'me-3',
                    onClick: () => handleActionClick(action),
                    disabled: isSubmitting && loadingAction !== action.name
                    //disabled: action.disabled || isSubmitting,
                  }}
                >
                  {isSubmitting 
                   && loadingAction === action.name
                   //&& action.loading 
                   ? <Spinner /> : action.label}
                </Btn>
              ))}
            </div>
          </Col>
        </Row>
      </Form>
    </Fragment>
    
  );
};

export default GenerateFormTwo;
