import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CustomizerContext from '../../../../../../_helper/Customizer';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DepartmentApi, DivisionApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../../Common/Component/CardRibbonWithForm';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';
import { base_form_fields, createDropdownField, short_name_field } from '../../../../../../Data/FormField/PublicData';
import { AddNewDepartment, Department, Division } from '../../../../../../Constant';
import OrganisationContext from '../../../../../../_helper/System/Organisation';

const DepartmentNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { organisation_id  } = useContext(OrganisationContext);
  const departmentApi = DepartmentApi(organisation_id);
  const divisionApi = DivisionApi(organisation_id);
  const lists = `${process.env.PUBLIC_URL}/organisations/companies/${organisation_id}/structures/departments/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${Department}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const divisionDropdownField = createDropdownField(axiosPrivate,
    'division_id',
    'division_id',
    `${Division}`,
    `${divisionApi}`,
    '',
    12
  );
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
          const updatedFields = [divisionDropdownField, ...formFields];
         
          updatedFields.splice(indexOfName + 2, 0, short_name_field());
          await updatedFields[0].fetchOptions(1);
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, divisionDropdownField]);

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          division_id: newRecord.division_id,
          name: newRecord.name,
          short_name: newRecord.short_name,
          description: newRecord.description,
          is_active: newRecord.is_active,
      };

      try {
          await axiosPrivate.post(departmentApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm 
              formFields={formFields} 
              onSubmit={addNewRecord} 
              ribbonUrl={lists} 
              formTitle={AddNewDepartment} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DepartmentNew;