
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Card} from 'reactstrap';
import {CardBody} from 'reactstrap';
import { BackToList } from '../../../../Constant';  
import {  Pop, Region, Makers, Models, RadioTypes, AccessName } from "../../../../Constant"
import GenerateForm from '../../../Common/Component/GenerateForm'; 
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import CustomizerContext from '../../../../_helper/Customizer'; 
import { Breadcrumbs } from '../../../../AbstractElements'; 
import { inventory_form_fields } from "./Inventory"
import { createDropdownField } from '../../../../Data/FormField/PublicData'; 
import { PopApi, RegionApi, MakersApi, ModelsApi, RadioTypesApi, AccessNamesApi } from "../../../../api"
import useAuth from '../../../../Hooks/useAuth';

function DeviceInventoryCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(inventory_form_fields);
    const lists = `${process.env.PUBLIC_URL}/technical/device-inventory/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    
    const makersDropdownField = createDropdownField(axiosPrivate,
        'makers_id',
        'makers_id',
        `${Makers}`,
        `${MakersApi}`,
        '',
        6
    );

    const modelsDropdownField = createDropdownField(axiosPrivate,
        'models_id',
        'models_id',
        `${Models}`,
        `${ModelsApi}`,
        '',
        6
    );

    const radioTypesDropdownField = createDropdownField(axiosPrivate,
        'radio_types_id',
        'radio_types_id',
        `${RadioTypes}`,
        `${RadioTypesApi}`,
        '',
        6
    );

    const accessNamesDropdownField = createDropdownField(axiosPrivate,
        'access-names_id',
        'access-names_id',
        `${AccessName}`,
        `${AccessNamesApi}`,
        '',
        6
    );
    
    const popDropdownField = createDropdownField(axiosPrivate,
        'pop_id',
        'pop_id',
        `${Pop}`,
        `${PopApi}`,
        '',
        6
    );

    const regionDropdownField = createDropdownField(axiosPrivate,
        'region_id',
        'region_id',
        `${Region}`,
        `${RegionApi}`,
        '',
        6
    );

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {
                
                const updatedFields = [makersDropdownField, modelsDropdownField, radioTypesDropdownField, accessNamesDropdownField, popDropdownField, regionDropdownField,  ...formFields];
        
                await updatedFields[0].fetchOptions();
                
                setFormFields(updatedFields);
                setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };
    
        fetchData();
    }, [formFields, isNewFieldAdded, accessNamesDropdownField]); 

    const onSubmit = () => {
        
    }
    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Technical" title="DeviceInventory" mainTitle="DeviceInventory" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={onSubmit} listUrl={lists} formTitle={"DeviceInventory"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default DeviceInventoryCreate