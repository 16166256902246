import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  GeneralLedgerGroup,
  GroupGLClass,
  GroupGLType,
  Description,
  Name,
  Status,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesGeneralLedgerGroupApi,
  financesGroupGeneralLedgerClassApi,
  financesGroupGeneralLedgerTypeApi,
} from "../../../../../api";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useGroupGeneralLedgerTypeController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = GroupGLType;
  const { userOrganisationId }: any = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-type/${layoutURL}`;

  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const generalLedgerGroup = createDropdownField(
    axiosPrivate,
    "general_ledger_group",
    "general_ledger_group",
    GeneralLedgerGroup,
    `${financesGeneralLedgerGroupApi}?&organisation_id=${userOrganisationId}&group_gl_class_id=${1}`,
    "",
    6
  );
  generalLedgerGroup.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "group_gl_class_id"
    );

    if (nextField) {
      const endPoint = `${financesGroupGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${id}`;

      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "general_ledger_group") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const groupGLClassIdDropDown = createDropdownField(
    axiosPrivate,
    "group_gl_class_id",
    "group_gl_class_id",
    GroupGLClass,
    `${financesGroupGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${1}`,
    "",
    6
  );
  groupGLClassIdDropDown.onChange = async (id, fields) => {
    console.log(fields);
    console.log(id);
    updateFields();
  };

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [
      generalLedgerGroup,
      groupGLClassIdDropDown,
      ...formFields,
      // balanceLedgerDropDown,
    ];
    await generalLedgerGroup.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  async function onSubmit(data: any) {
    console.log(data);
    await axiosPrivate
      .post(financesGroupGeneralLedgerTypeApi, {
        ...data,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        toast.success("Created Successfully");
      })
      .catch((err) => {
        toast.error(err.response.message || "Something Went Wrong");
      });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
