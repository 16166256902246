import { useNavigate } from "react-router";
import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
    const navigate = useNavigate();
    const { setAuth } = useAuth();

    const logout = async () => {
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
        localStorage.removeItem('auth0_profile');
        localStorage.removeItem('Name');
        localStorage.setItem('authenticated', false);
        setAuth({});

        try {
            await axios.post('/auth/logout', {},
            {
                withCredentials: true
            });

            navigate(`${process.env.PUBLIC_URL}/login`);

        } catch (err) {
            //console.error(err);
        }
    }

    return logout;
}

export default useLogout