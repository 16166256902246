import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useSubLedgerAccountListController from "./SubLedgerAccountList.controller";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import {
    Contact,
    DateCreated,
    DateModified,
    ExtAccName,
    ExtAccNumber,
    GeneralLedgerAccount,
    Number,
    SLAccountName,
    SLAccountNumber,
    Settings,
    SubLedgerClass,
} from "../../../../../Constant";
import {
    Description,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    Name,
    Status,
    SubLedgerType,
} from "../../../../../Constant";
import { ISubLedgerAccountResponse } from "../SubLedgerAccount.types";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { ContactTypeVal } from "../../../../../Data/FormField/PublicData";

const SubLedgerAccount = () => {
    const {
        data,
        tableColumns,
        newBtn,
        editMode,
        selectedRowData,
        viewModalOpen,
        formFields,
        title,
        addToDefaultAction,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    } = useSubLedgerAccountListController();

    return (
        <Fragment>
            <Breadcrumbs parent={Settings} title={title} mainTitle={title} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title={title}
                                btnUrl={newBtn}
                                span1={undefined}
                                span2={undefined}
                                mainClasses={undefined}
                            />
                            <CardBody>
                                <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={false}
                                    onView={handleView}
                                    // onDelete={handleDelete}
                                    onEdit={handleEdit}
                                    useTableAction={undefined}
                                    addToDefaultAction={addToDefaultAction}
                                    onDelete={undefined}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    onSubmit={undefined}
                    editMode={editMode}
                    formFields={formFields}
                    onEdit={memoizedHandleEdit}
                    rowData={selectedRowData}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                        <>
                            <Table>
                                <tbody>
                                    <tr className="border-bottom-primary">
                                        <th scope="row">{SLAccountName}</th>
                                        <td>{selectedRowData.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{SLAccountNumber}</th>
                                        <td>{selectedRowData.number}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{ExtAccName}</th>
                                        <td>
                                            {
                                                selectedRowData.external_account_name
                                            }
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{ExtAccNumber}</th>
                                        <td>
                                            {
                                                selectedRowData.external_account_number
                                            }
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Contact}</th>
                                        <td>
                                            {selectedRowData.contact.contact_type === ContactTypeVal.Individual ? 
                                                `${selectedRowData.contact.last_name} ${selectedRowData.contact.first_name} ${selectedRowData.contact.middle_name}` : 
                                                `${selectedRowData.contact.company_name}`
                                            }
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{SubLedgerType}</th>
                                        <td>{selectedRowData.sl_type.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{SubLedgerClass}</th>
                                        <td>{selectedRowData.sl_type.sl_class.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{GeneralLedgerGroup}</th>
                                        <td>{selectedRowData.sl_type.sl_class.gl_group.name}</td>
                                    </tr>
                                    
                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Description}</th>
                                        <td>{selectedRowData.description}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">{Status}</th>
                                        <td>
                                            {selectedRowData.is_active ? (
                                                <span className="badge badge-light-success">
                                                    Active
                                                </span>
                                            ) : (
                                                <span className="badge badge-light-danger">
                                                    Inactive
                                                </span>
                                            )}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-secondary">
                                        <th scope="row">{DateCreated}</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.created_at
                                            )}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-secondary">
                                        <th scope="row">{DateModified}</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.updated_at
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                    )}
                </ViewModal>
            </Container>
        </Fragment>
    );
};

export default SubLedgerAccount;

export const StatusRow = (row: ISubLedgerAccountResponse) => {
    return row.is_active ? (
        <span className="badge badge-light-success">Active</span>
    ) : (
        <span className="badge badge-light-danger">Inactive</span>
    );
};
