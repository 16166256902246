import { useContext, useLayoutEffect, useMemo, useState } from "react";
import {
    CreatedBy,
    Description,
    ModifiedBy,
    Name,
    ReportType,
    Status,
} from "../../../../../Constant";
import { StatusRow } from "./ReportTypeList";
import CustomizerContext from "../../../../../_helper/Customizer";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { IReportTypesResponse } from "../ReportType.types";
import { financesReportTypeApi } from "../../../../../api";
import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useReportTypeList() {
    const axiosPrivate = useAxiosPrivate();
    const title = ReportType;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        (async function () {
            const updatedFields = [...formFields];
            setFormFields(updatedFields);
        })();
    }, []);

    useLayoutEffect(() => {
        setLoading(true);
        axiosPrivate
            .get(
                `${financesReportTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
            )
            .then((res) => {
                const response: IReportTypesResponse[] = res.data;
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                  toast.error(err.response.data.detail);
                } else {
                  toast.error('Something Went Wrong');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleEdit = (row: (typeof data)[0] | any) => {
        setViewModalOpen(true);
        setEditMode(true);
        setSelectedRowData(row);
    };

    const [loading, setLoading] = useState(false);
    const { userOrganisationId }: any = useAuth();
    const [data, setData] = useState<IReportTypesResponse[] | []>([]);

    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Description,
            selector: (row: (typeof data)[0]) =>
                `${row.description.slice(0, 15).trim()}`,
            sortable: false,
            center: false,
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.is_active}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: CreatedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.created_at)}`,
            sortable: false,
            center: false,
        },

        {
            name: ModifiedBy,
            selector: (row: (typeof data)[0]) =>
                `${formatDateWithTimeZone(row.updated_at)}`,
            sortable: false,
            center: false,
        },
    ];

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/report-type/create/${layoutURL}`;
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
        editRecord.organisation_id = userOrganisationId;
        await axiosPrivate
            .put(`${financesReportTypeApi}/${id}`, editRecord)
            .then((_res) => {
                const updatedData = data.map((row) =>
                    row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
                );
                setData(updatedData);
                toast.success(`${_res.data.name} Editted Successfully.`)
                //fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                toast.error('Validation Errors');
                } else if (err.response && err.response.status === 400) {
                toast.error(err.response.data.detail);
                } else {
                toast.error('Something Went Wrong');
                }
                throw err;
                //toast.error(err.response.message || "Something Went Wrong");
            });
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        title,
        data,
        tableColumns,
        newBtn,
        viewModalOpen,
        editMode,
        selectedRowData,
        formFields,
        loading,
        handleEdit,
        setViewModalOpen,
        memoizedHandleEdit,
        handleView,
        handleEditRec,
    };
}
