import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { TimeWorked } from '../../../Constant';

const TimeCounter = forwardRef(({ onStop }, ref) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [isRunning]);

/*   useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      if (time > 0) {
        onStop(time); // Call onStop callback when stopped
      }
    }
    return () => clearInterval(timer);
  }, [isRunning, time, onStop]); */

  const startPauseTimer = () => {
    setIsRunning(!isRunning);
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    onStop(time);
    //setTime(0);
  };

  useImperativeHandle(ref, () => ({
    getCurrentTime() {
      return time;
    }
  }));

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <div className='d-flex justify-content-between'> {TimeWorked}: {formatTime(time)}
        {/* <span onClick={startPauseTimer}><i className={isRunning ? 'fa fa-pause' : 'fa fa-play'}></i></span> */}
        {/* <span onClick={pauseTimer}><i className="fa fa-pause"></i></span> */}
        {/* <span onClick={resetTimer}><i className="fa fa-stop"></i></span> */}
      </div>
    </div>
  );
});

export default TimeCounter;
