import { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ImpactApi, PriorityApi, UrgencyApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, color_field, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { Impact, Priority, PriorityColor, Urgency } from '../../../../Constant';
import useAuth from '../../../../Hooks/useAuth';

const PriorityCreate = () => {
  const [formFields, setFormFields] = useState(base_form_fields(`${Priority}`))
  const [IsNewFieldAdded, setIsNewFieldAdded] = useState(false)
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/crm/settings/Priority/${layoutURL}`;

  const { formState: { errors }, } = useForm();

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      organisation_id: userOrganisationId,
      impact_id: newRecord.impact_id,
      urgency_id: newRecord.urgency_id,
      name: newRecord.name,
      description: newRecord.description,
      color: newRecord.color,
      is_active: newRecord.is_active,
    };

    try {
      await axiosPrivate.post(PriorityApi, newRecordTemp, {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const ImpactDropdownField = createDropdownField(axiosPrivate,
    'impact_id',
    'impact_id',
    `${Impact}`,
    `${ImpactApi}?organisation_id=${userOrganisationId}`,
    '', 12
  );
  const UrgencyDropdownField = createDropdownField(axiosPrivate,
    'urgency_id',
    'urgency_id',
    `${Urgency}`,
    `${UrgencyApi}?organisation_id=${userOrganisationId}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!IsNewFieldAdded) {

          const updatedFields = [ImpactDropdownField, UrgencyDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
          updatedFields.splice(indexOfName + 1, 0, color_field(`${PriorityColor}`));

          await updatedFields[0].fetchOptions();
          await UrgencyDropdownField.fetchOptions();

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, IsNewFieldAdded, ImpactDropdownField, UrgencyDropdownField, color_field]);

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="New Priority" mainTitle="New Priority" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PriorityCreate;