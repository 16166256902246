import { useContext, useLayoutEffect, useMemo, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  DescriptionName,
  Description,
  IncomeCategory,
  IncomeGroup,
  IncomeClass,
  IncomeType,
  Name,
  Status,
  ModifiedBy,
  CreatedBy,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import {
  financesIncomeCategoryApi,
  financesIncomeClassApi,
  financesIncomeGroupApi,
  financesIncomeTypeApi,
} from "../../../../../api";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { IIncomeType } from "../IIncomeType.types";
import { toast } from "react-toastify";
import { StatusRow } from "../../ReportType/List/ReportTypeList";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

export default function useIncomeTypeListController() {
  const axiosPrivate = useAxiosPrivate();
  const title = IncomeType;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  //?? DROPDOWNS INITS >
  const incomeCategoryDropDown = createDropdownField(
    axiosPrivate,
    "income_category_id",
    "income_category_id",
    IncomeCategory,
    `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  incomeCategoryDropDown.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_group"
    );
    if (nextField) {
      const endPoint = `${financesIncomeGroupApi}?organisation_id=${userOrganisationId}&income_category_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_category_id") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeGroupDropDown = createDropdownField(
    axiosPrivate,
    "income_group",
    "income_group",
    IncomeGroup,
    ``,
    "",
    6
  );
  incomeGroupDropDown.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_class_id"
    );
    if (nextField) {
      const endPoint = `${financesIncomeClassApi}?organisation_id=${userOrganisationId}&income_group_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_group") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeClassDropDown = createDropdownField(
    axiosPrivate,
    "income_class_id",
    "income_class_id",
    IncomeClass,
    ``,
    "",
    6
  );

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    (async function () {
      const updatedFields = [
        incomeCategoryDropDown,
        incomeGroupDropDown,
        incomeClassDropDown,
        ...formFields,
      ];
      await incomeCategoryDropDown.fetchOptions();
      setFormFields(updatedFields);
    })();
  }, []);

  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: IncomeClass,
      selector: (row: (typeof data)[0]) => `${row.income_class.name}`,
      sortable: true,
      center: false,
    },
    {
      name: IncomeGroup,
      selector: (row: (typeof data)[0]) =>
        `${row.income_class.income_group.name}`,
      sortable: true,
      center: false,
    },
    {
      name: IncomeCategory,
      selector: (row: (typeof data)[0]) =>
        `${row.income_class.income_group.income_category.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);

    incomeCategoryDropDown.onChange(
      row.income_class.income_group.income_category.id,
      formFields
    );

    row.income_category_id = row.income_class.income_group.income_category.id
    row.income_class_id = row.income_class.id
    incomeGroupDropDown.onChange(row.income_class.income_group.id, formFields);
    setSelectedRowData(row);
  };

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/settings/income-type/create/${layoutURL}`;
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IIncomeType[]>([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesIncomeTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: IIncomeType[] = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    fetchData();
  }, []);

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0]
  ) => {
      //dynmically send the selected income_class_id to the server
    //let income_class_id = Number(editRecord.income_class);
    //editRecord.income_class_id = income_class_id;
    await axiosPrivate
      .put(`${process.env.PUBLIC_URL}/finances/income-types/${id}`, editRecord)
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.name} Editted Successfully.`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Something Went Wrong");
      });
  };

  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
  };
}
