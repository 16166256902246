import {
  financesIncomeClassApi,
  financesIncomeCategoryApi,
  financesIncomeGroupApi,
  financesIncomeTypeApi,
} from "../../../../api";
import {
  IncomeClass,
  IncomeCategory,
  IncomeDescRes,
  IncomeGroup,
} from "./types";
import useAuth from "../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";

export default function useIncomeDescriptionController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();

  const [incomeCategoryDropdown, setIncomeCategoryDropDown] = useState<
    IncomeCategory[]
  >([]);
  const [incomeCategoryValue, setIncomeCategoryValue] = useState("0");

  const [incomeGroupDropDown, setIncomeGroupDropdown] = useState<IncomeGroup[]>(
    []
  );
  const [incomeGroupValue, setIncomeGroupValue] = useState("0");

  const [incomeClassDropDown, setIncomeClassDropdown] = useState<IncomeClass[]>(
    []
  );
  const [incomeClassValue, setIncomeClassValue] = useState("0");

  const [incomeTypeDropDown, setIncomeTypeDropdown] = useState<IncomeClass[]>(
    []
  );
  const [incomeTypeValue, setIncomeTypeValue] = useState("0");

  const [printedData, setPrintedData] = useState<IncomeDescRes[]>([]);

  async function getIncomeCategory() {
    try {
      axiosPrivate
        .get(
          `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
        )
        .then((res) => {
          setIncomeCategoryDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  async function getIncomeGroup() {
    try {
      await axiosPrivate
        .get(
          `${financesIncomeGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&income_category_id=${incomeCategoryValue}`
        )
        .then((res) => {
          setIncomeGroupDropdown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setIncomeGroupDropdown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  async function getIncomeClass() {
    // console.log(incomeGroupValue);
    try {
      axiosPrivate
        .get(
          `${financesIncomeClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&income_group_id=${incomeGroupValue}`
        )
        .then((res) => {
          setIncomeClassDropdown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setIncomeClassDropdown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }
  async function getIncomeType() {
    // console.log(incomeClassValue);
    try {
      axiosPrivate
        .get(
          `${financesIncomeTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&income_class_id=${incomeClassValue}`
        )
        .then((res) => {
          setIncomeTypeDropdown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
        setIncomeClassDropdown([]);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  useLayoutEffect(() => {
    getIncomeCategory();
  }, []);

  useEffect(() => {
    if (incomeCategoryValue !== "0") {
      getIncomeGroup();
    } else {
      return;
    }
  }, [incomeCategoryValue]);

  useEffect(() => {
    if (incomeGroupValue !== "0") {
      getIncomeClass();
    } else {
      return;
    }
  }, [incomeGroupValue]);

  useEffect(() => {
    if (incomeClassValue !== "0") {
      getIncomeType();
    } else {
      return;
    }
  }, [incomeClassValue]);

  async function getTableFunction() {
    axiosPrivate
      .get(
        `${financesIncomeTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&income_class_id=${incomeClassValue}`
      )
      .then((res) => {
        const result: IncomeDescRes[] = res.data;
        // console.log(result);
        setPrintedData(result);
      })
      .catch((err) => {
        // console.log(err);
        setPrintedData([]);
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  }
  function generatePrint(res: IncomeDescRes[]) {
    // console.log(res);
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
              .map(
                (item) =>
                  `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                        <td>${item.income_type.name}</td>
                        <td>${item.name}</td>
                    </tr>`
              )
              .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  }

  function exportToCSV(res: IncomeDescRes[]) {
    const csvHeaders = [
      "Name",
      "Description",
      "Code",
      "Balance Type",
      "Report Type",
    ];
    const data = res.map((item) => ({
      name: item.name,
      description: item.description,
      income_category:
        item.income_type.income_class.income_group.income_category.name,
      report_type: item.name,
    }));

    const dataKeys: (keyof (typeof data)[0])[] = [
      "name",
      "description",
      "income_category",
      "report_type",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" +
      data.map((row) => `${dataKeys.map((key) => row[key]) + ","}`).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return {
    setIncomeCategoryValue,
    setIncomeGroupValue,
    setIncomeClassValue,
    setIncomeTypeValue,
    generatePrint,
    exportToCSV,
    getTableFunction,
    incomeCategoryDropdown,
    incomeClassDropDown,
    incomeGroupDropDown,
    incomeTypeValue,
    printedData,
    incomeTypeDropDown,
  };
}
