import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../Hooks/useAuth";
import { useLayoutEffect, useState } from "react";
import { GroupGlClass, GroupGLTypeRes } from "./types";

import { toast } from "react-toastify";
import {
  financesGroupGeneralLedgerTypeApi,
  financesGroupGeneralLedgerClassApi,
} from "../../../../api";

export default function useGroupGeneralLedgerTypeController() {
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId }: any = useAuth();
  const [GroupGLClassDropdown, setGroupGLClassDropDown] = useState<
    GroupGlClass[]
  >([]);
  const [GroupGLClassValue, setGroupGLClassValue] = useState("0");
  const [printedData, setPrintedData] = useState<GroupGLTypeRes[]>([]);

  async function getGroupGlClass() {
    try {
      axiosPrivate
        .get(
          `${financesGroupGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
        )
        .then((res) => {
          setGroupGLClassDropDown(res.data);
        });
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        toast.error(err.response.data.detail);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  }

  useLayoutEffect(() => {
    getGroupGlClass();
  }, []);

  async function getTableFunction() {
    await axiosPrivate
      .get(
        `${financesGroupGeneralLedgerTypeApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&group_gl_class_id=${GroupGLClassValue}`
      )
      .then((res) => {
        const result: GroupGLTypeRes[] = res.data;
        setPrintedData(result);
      })
      .catch((err) => {
        setPrintedData([]);
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  }

  function generatePrint(res: GroupGLTypeRes[]) {
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <style>
          table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }

          td,
          th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
          }

          tr:nth-child(even) {
            background-color: #dddddd;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
             <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </thead>
          <tbody>
            ${res
              .map(
                (item) =>
                  `<tr>
                        <td>${item.name}</td>
                        <td>${item.description}</td>
                          <td>${item.code}</td>
                        <td>${item.group_gl_class.gl_group.balance_type}</td>
                        <td>${item.group_gl_class.gl_group.report_type.name}</td>
                    </tr>`
              )
              .join("")}
            </tbody>
          <tfoot>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Code</th>
              <th>Balance Type</th>
              <th>Report Type</th>
            </tr>
          </tfoot>
        </table>

        <script>
        window.print();
        setTimeout(() => {
          window.close();
        if (!window.closed) {
          alert('Please allow popups for this website to close the window automatically.');
         }
        }, 100);
        </script>
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: "text/html" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  }

  function exportToCSV(res: GroupGLTypeRes[]) {
    const csvHeaders = [
      "Name",
      "Description",
      "Code",
      "Balance Type",
      "Report Type",
    ];
    const data = res.map((item) => ({
      name: item.name,
      description: item.description,
      GroupGLType: item.name,
      report_type: item.name,
    }));

    const dataKeys: (keyof (typeof data)[0])[] = [
      "name",
      "description",
      "GroupGLType",
      "report_type",
    ];

    const csvContent =
      csvHeaders.join(",") +
      "\n" +
      data.map((row) => `${dataKeys.map((key) => row[key]) + ","}`).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  return {
    exportToCSV,
    printedData,
    getTableFunction,
    GroupGLClassDropdown,
    generatePrint,
    setGroupGLClassValue,
  };
}
