import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CategoryApi, UserTypeApi } from '../../../../api';
import { AccessCategory, DateCreated, DateModified, Description, Status, UserType } from '../../../../Constant';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import useAuth from '../../../../Hooks/useAuth';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import { toast } from 'react-toastify';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import DataTableComponent from '../../../Common/Component/DataTableComponent';

const CategoryList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(base_form_fields(`${AccessCategory}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const userTypeDropDown = createDropdownField(axiosPrivate,
    'user_type_id',
    'user_type_id',
    `${UserType}`,
    `${UserTypeApi}`,
    '', 12, '', 'table_title'
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [userTypeDropDown, ...formFields];
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, userTypeDropDown]); 


  const getAllCategorysData = async () => {
    try {
      await axiosPrivate.get(`${CategoryApi}?organisation_id=${userOrganisationId}`).then((resp) => {
        setAllData(resp.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    getAllCategorysData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    const editRecordTemp = {
      organisation_id: userOrganisationId,
      user_type_id: editRecord.user_type_id,
      name: editRecord.name,
      description: editRecord.description,
      is_active: editRecord.is_active,
    };

    try {
      await axiosPrivate.put(`${CategoryApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return;
    }

    try {
      const response = await axiosPrivate.delete(`${CategoryApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/access-control/categories/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${UserType}`,
      selector: row => `${row.user_type.table_title}`,
      sortable: true,
      center: false,
    },
    {
      name: `${AccessCategory}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => row.description,
      wrap: true,
      sortable: true,
      format: row => `${row.description.slice(0, 200)}...`,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="Categories" mainTitle="Categories" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{UserType}</th>
                    <td>{selectedRowData.user_type.table_title}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{AccessCategory}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default CategoryList;