import { Pregnant, PreviousCaesareanSection, KnownAllegies, KnownAilments, OtherRelevantDetails,} from "../../../../Constant"

export const sales_form_fields = [
  {
    id: 'is_pregnant',
    name: 'is_pregnant',
    label: `${Pregnant}`,
    type: 'radio',
    value: '',
    required: true,
    colSize: 6,
    options: [
        {'label': 'Yes', 'value': 1},
        {'label': 'No', 'value': 0},
    ],
},
{
  id: 'is_previous_caesarean_section',
  name: 'is_previous_caesarean_section',
  label: `${PreviousCaesareanSection}`,
  type: 'radio',
  value: '',
  required: true,
  colSize: 6,
  options: [
      {'label': 'Yes', 'value': 1},
      {'label': 'No', 'value': 0},
  ],
},
    {
      id: 'known_allegies',
      name: 'known_allegies',
      label: `${KnownAllegies}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'other_relevant_details',
      name: 'other_relevant_details',
      label: `${OtherRelevantDetails}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'known_ailments',
      name: 'known_ailments',
      label: `${KnownAilments}`,
      type: 'checkbox',
      value: '',
      required: true,
      colSize: 6,
      options: [
          {'label': 'Yes', 'value': 1},
          {'label': 'No', 'value': 0},
      ],
    },
];

