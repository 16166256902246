import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { financesGeneralLedgerGroupApi } from "../../../../../api";
import {
    GeneralLedgerGroup,
    Status,
    Name,
    Description,
    GeneralLedgerClass,
} from "../../../../../Constant";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useGeneralLedgerClassController() {
    const axiosPrivate = useAxiosPrivate();
    const formTitle = GeneralLedgerClass;
    const { userOrganisationId }: any = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-class/${layoutURL}`;
    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    const generalLedgerGroupDropDown = createDropdownField(
        axiosPrivate,
        "gl_group_id",
        "gl_group_id",
        GeneralLedgerGroup,
        `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, fields) => {
        updateFields();
    };

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [generalLedgerGroupDropDown, ...formFields];
        await generalLedgerGroupDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const onSubmit = async (data: any) => {
        data.organisation_id = userOrganisationId;
        await axiosPrivate
            .post("/finances/general-ledger-classes", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`)
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                toast.error('Validation Errors');
                } else if (err.response && err.response.status === 400) {
                toast.error(err.response.data.detail);
                } else {
                toast.error('Something Went Wrong');
                }
                throw err;
            });
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
