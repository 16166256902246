import { Fragment, useContext, useEffect,useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InventoryTypeApi, InventoryGroupApi, InventoryClassApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import {base_form_fields, createDropdownField} from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { InventoryCategory, InventoryType, InventoryGroup, InventoryClass } from '../../../../Constant';

const InventoryTypeNew = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/administration/settings/inventory-type/${layoutURL}`;

  const [formFields, setFormFields] = useState(base_form_fields(`${InventoryType}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const { formState: { errors },} = useForm(); 

  const InventoryGroupDropdownField = createDropdownField(axiosPrivate,
    'inventory_group_id',
    'inventory_group_id',
    `${InventoryGroup}`,
    `${InventoryGroupApi}`,
    '', 12
  );
  const InventoryClassDropdownField = createDropdownField(axiosPrivate,
    'inventory_class_id',
    'inventory_class_id',
    `${InventoryClass}`,
    `${InventoryClassApi}`,
    '', 12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [InventoryGroupDropdownField, InventoryClassDropdownField, ...formFields];
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, InventoryGroupDropdownField]); 

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          name: newRecord.name,
          InventoryCategory: `${InventoryCategory}`,
          description: newRecord.description,
          is_active: newRecord.is_active,
       };

      try {
          await axiosPrivate.post(InventoryTypeApi, newRecordTemp, {
            headers: {
              //'Content-Type': 'multipart/form-data',
              'Content-Type': 'application/json',
            },
          }).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          }
          else {
            toast.error('Form submission error');
          }
          throw error;
      }  
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Administration" title="New Inventory Type" mainTitle="New Inventory Type" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
          <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InventoryTypeNew;