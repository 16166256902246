import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { StatusRow } from "./GroupGeneralLedgerClassList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  Code,
  CreatedBy,
  Description,
  GeneralLedgerGroup,
  GroupGLClass,
  ModifiedBy,
  Name,
  Status,
} from "../../../../../Constant";
import useAuth from "../../../../../Hooks/useAuth";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesGeneralLedgerGroupApi,
  financesGroupGeneralLedgerClassApi,
} from "../../../../../api";

import { IGroupGeneralLedgerClassResponse } from "../GroupGeneralLedgerClass.types";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useGroupGeneralLedgerClassListController() {
  const axiosPrivate = useAxiosPrivate();
  const title = GroupGLClass;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: Name,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);
  const tableColumns = [
    {
      name: Name,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },

    {
      name: Code,
      selector: (row: (typeof data)[0]) => `${row.code}`,
      sortable: true,
      center: false,
    },

    {
      name: GeneralLedgerGroup,
      selector: (row: (typeof data)[0]) => `${row.gl_group.name}`,
      sortable: true,
      center: false,
    },
    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },

    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  const glGroupIdDropDown = createDropdownField(
    axiosPrivate,
    "gl_group_id",
    "gl_group_id",
    GeneralLedgerGroup,
    `${financesGeneralLedgerGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  glGroupIdDropDown.onChange = async (id, updatedFields) => {
    const updatedFormFields = updatedFields.map((field: any) => {
      if (field.id === "gl_group_id") {
        return { ...field, value: id };
      }
      return field;
    });
    setFormFields([...updatedFormFields]);
  };

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    (async function () {
      const updatedFields = [glGroupIdDropDown, ...formFields];
      await glGroupIdDropDown.fetchOptions();
      setFormFields(updatedFields);
    })();
  }, []);

  function fetchData() {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesGroupGeneralLedgerClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}&gl_group_id=${1}`
      )
      .then((res) => {
        const response: IGroupGeneralLedgerClassResponse[] = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
    })
      .finally(() => {
        setLoading(false);
      });
  }

  useLayoutEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(true);
    row.gl_group_id = row.gl_group.id
    setSelectedRowData(row);
  };

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class/create/${layoutURL}`;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IGroupGeneralLedgerClassResponse[]>([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0]
  ) => {
    await axiosPrivate
      .put(`${financesGroupGeneralLedgerClassApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.name}, 'Edited Successfully'`);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast.warn("Group GL Class not found");
          return;
        }
        toast.error(err.response.data.message || "Something Went Wrong");
      });
  };

  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
  };
}
