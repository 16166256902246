import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const DropdownCommon2 = ({ dropdownMain, icon = true, iconName, btn, options, onClick = () => {} }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown {...dropdownMain} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle {...btn}>
        {icon && <i className={iconName}></i>}
        {!icon && options[0].value}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((item, i) => (
          <DropdownItem onClick={() => onClick(item.id)} key={i}>{item.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownCommon2;
