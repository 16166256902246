import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs"
import CustomizerContext from '../../../../_helper/Customizer'; 
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CompanyApi, GenotypeApi, BloodGroupApi,} from '../../../../api';
import { toast } from 'react-toastify'; 
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';  
import { sales_form_fields } from './sales';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import { createDropdownField } from '../../../../Data/FormField/PublicData'; 
import { Genotype, BloodGroup } from "../../../../Constant"

const MedicalInformationCreate = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}//companies/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(sales_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const genotypeTypeDropdownField = createDropdownField(axiosPrivate,
    'genotype_id',
    'genotype_id',
    `${Genotype}`,
    `${GenotypeApi}`,
    '',6
  );

  const bloodGroupTypeDropdownField = createDropdownField(axiosPrivate,
    'blood_group_id',
    'blood_group_id',
    `${BloodGroup}`,
    `${BloodGroupApi}`,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [genotypeTypeDropdownField, bloodGroupTypeDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'genotype_id');

          await updatedFields[0].fetchOptions();
          //await updatedFields[1].fetchOptions();
          //await updatedFields[2].fetchOptions();
          await updatedFields[indexOfName].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, genotypeTypeDropdownField, bloodGroupTypeDropdownField]);


  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }
      
      const newRecordTemp = new FormData();

      //newRecordTemp.append('organisation_type_id', newRecord.organisation_type_id);
      newRecordTemp.append('description', newRecord.description);
      newRecordTemp.append('is_active', newRecord.is_active);

      try {
          await axiosPrivate.post(CompanyApi, newRecordTemp, 
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }).then((resp) => {
            toast.success(`${resp.data.full_name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Settings" title="Medical Information" mainTitle="Medical Information" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MedicalInformationCreate;