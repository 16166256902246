import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Application, ApplicationName, DateCreated, DateModified, DeleteSuccess, Description, Icon, Status, View, Visibility } from '../../../../Constant';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ApplicationApi } from '../../../../api';
import axios, { axiosPrivate } from '../../../../api/axios';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../Common/Component/DataTableComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';

const ApplicationList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const addBtn = `${process.env.PUBLIC_URL}/access-control/applications/create/${layoutURL}`;

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const formFields = [
    {
      id: 'title',
      name: 'title',
      label: `${ApplicationName}`,
      type: 'text',
      value: '',
      required: true,
    },
    {
      id: 'description',
      name: 'description',
      label: `${Description}`,
      type: 'textarea',
      value: '',
      required: true,
    },
    {
      id: 'icon',
      name: 'icon',
      label: `${Icon}`,
      type: 'text',
      value: '',
      required: true,
    },
    {
      id: 'visibility',
      name: 'visibility',
      label: `${Visibility}`,
      type: 'switch',
      value: '1',
      required: false,
    },
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
    },
  ];

  useEffect(() => {
    const abortController = new AbortController();
    
    const getAllApplicationsData = async () => {
        try {
            await axiosPrivate.get(ApplicationApi , { signal: abortController.signal}
              ).then((resp) => {
                setAllData(resp.data);
                setIsLoading(false);
            });
        } catch (error) {
            console.log('cancelled', error);
            if(error.response){
              /* console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers) */
            } else {
              //console.log(`Error: ${error.message}`)
            }
            
            setIsLoading(false);
        }
    };

    getAllApplicationsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);


  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.title = row.name;
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      name: editRecord.title,
      description: editRecord.description,
      icon: editRecord.icon,
      visibility: editRecord.visibility,
      is_active: editRecord.is_active,
      //sort_order: 0,
    };
    
    try {
        await axiosPrivate.put(`${ApplicationApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ApplicationApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };


  const tableColumns = [
    {
        name: `${ApplicationName}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="Applications" mainTitle="Applications" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
{/* This is the table part to pick all the items and put them inside the list */}
        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Application}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                  </tbody>
                </Table>
            </>
          )}
          
        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default ApplicationList;