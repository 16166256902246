import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Outlet } from 'react-router-dom';
import SideNav from './SideNav';

const InnerStructure = ({
  BreadcrumbParent,
  BreadcrumbTitle, 
  BreadcrumbMainTitle,
  SideNavMenus=[],
  profile = [] }) => {

  return (
    <Fragment>
      <Breadcrumbs parent={BreadcrumbParent} title={BreadcrumbTitle} mainTitle={BreadcrumbMainTitle} />
      <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
        
          <Row>
            <Col xl="3" className='box-col-4'>
              <SideNav SideNavMenus={SideNavMenus} profile={profile} />
            </Col>

            <Col xl='9' md='12' className='box-col-12'>
              <div>
                <Outlet />
              </div>
            </Col>
          </Row>
        
      </Container>
    </Fragment>
  );
};

export default InnerStructure;