import { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

import ForgotPwdForm from './ForgetPwdForm';
import { Image } from '../AbstractElements';

const ForgetPwd = () => {
  return (
    <Fragment>
      <section>
        <Container fluid={true}>
        
          <Row>
            
            <Col xl='7' className='b-center bg-size' style={{ backgroundImage: `url(${require('../assets/images/login/forgot-password.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
              <Image attrImage={{ className: 'bg-img-cover bg-center d-none', src: `${require('../assets/images/login/forgot-password.jpg')}`, alt: 'looginpage' }} />
            </Col>
            <Col xl='5 p-0'>
              <ForgotPwdForm logoClassMain='text-start' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
