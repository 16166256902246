import React, { useEffect, useState } from "react";
import DataTableComponent from "../Component/DataTableComponent";
import { Row, Col, Button } from "reactstrap";

export type tableColumns = {
    name: string;
    sortable: boolean;
    center: boolean;
    selector: (row: any) => string;
    leftCell?: (row: { [x: string]: any }) => JSX.Element;
}[];

interface Props {
    tableData?: { [x: string]: any }[];

    rightTable: { [x: string]: any }[];
    rightTableColumns: tableColumns;
    rightTableTitle?: string;
    rightButtonTitle?: string;
    rightButtonClick?: (e: any) => void;

    leftTable: { [x: string]: any }[];
    leftTableColumns: tableColumns;
    leftTableTitle?: string;
    leftButtonTitle?: string;
    leftButtonClick?: (e: any) => void;
}

export default function TaskAssignerTwinTables({
    tableData,

    rightTable,
    rightTableColumns,
    rightTableTitle,
    rightButtonTitle = "Move to Right",
    rightButtonClick,

    leftTable,
    leftTableColumns,
    leftTableTitle,
    leftButtonTitle = "Move to Left",
    leftButtonClick,
}: Props) {
    const {
        LTable,
        RTable,
        clearRowActive,
        setClearRowActive,
        setSelectedItems,
        moveToRight,
        moveToLeft,
    } = useTaskAssignerTwinTables(
        tableData,
        rightTable,
        leftTable,
        rightButtonClick,
        leftButtonClick
    );

    return (
        <>
            <div className="">
                <div className="">
                    <Row className="p-3 row-tag">
                        <Col
                            xs="6"
                            sm="4"
                            className="bg-white filter-table-title-col1"
                        >
                            <div className="filter-table-title">
                                <p>{leftTableTitle}</p>
                            </div>

                            <DataTableComponent
                                onRowSelect={(e: any) => {
                                    setSelectedItems(e);
                                    setClearRowActive(false);
                                }}
                                tabledata={LTable}
                                tableColumns={leftTableColumns}
                                deleteMany={false}
                                clearSelect={clearRowActive}
                                isLoading={false}
                                onView={undefined}
                                useDefaultAction={false}
                                onEdit={undefined}
                                useTableAction={undefined}
                                addToDefaultAction={undefined}
                                onDelete={undefined}
                            />
                        </Col>

                        <Col md="2" sm="4" className="">
                            <div className="d-flex align-items-center justify-content-center gap-2 flex-column my-5">
                                <Button
                                    onClick={() => moveToRight()}
                                    color="success"
                                >
                                    {rightButtonTitle}
                                </Button>
                                <Button
                                    onClick={() => moveToLeft()}
                                    color="danger"
                                >
                                    {leftButtonTitle}
                                </Button>
                                
                            </div>
                        </Col>

                        <Col
                            xs="6"
                            sm="4"
                            className="bg-white filter-table-title-col1"
                        >
                            <div className="filter-table-title">
                                <p>{rightTableTitle}</p>
                            </div>

                            <DataTableComponent
                                onRowSelect={(e: any) => {
                                    setSelectedItems(e);
                                    setClearRowActive(false);
                                }}
                                tabledata={RTable}
                                tableColumns={rightTableColumns}
                                clearSelect={clearRowActive}
                                deleteMany={false}
                                isLoading={false}
                                onView={undefined}
                                useDefaultAction={false}
                                onEdit={undefined}
                                useTableAction={undefined}
                                addToDefaultAction={undefined}
                                onDelete={undefined}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

type IUseTaskAssignerTwinTables = (
    tableData: { [x: string]: any }[] | undefined,
    rightTable: { [x: string]: any }[],
    leftTable: { [x: string]: any }[],
    rightButtonClick?: (e: any) => void,
    leftButtonClick?: (e: any) => void
) => {
    LTable: {
        [x: string]: any;
    }[];
    RTable: {
        [x: string]: any;
    }[];
    clearRowActive: boolean;
    setClearRowActive: (value: React.SetStateAction<boolean>) => void;
    setSelectedItems: React.Dispatch<any>;
    moveToRight: () => void;
    moveToLeft: () => void;
};

const useTaskAssignerTwinTables: IUseTaskAssignerTwinTables = (
    tableData,
    rightTable,
    leftTable,
    rightButtonClick,
    leftButtonClick
) => {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [LTable, setLTable] = useState(leftTable);
    const [RTable, setRTable] = useState(rightTable);
    const [clearRowActive, setClearRowActive] = useState(false);

    useEffect(() => {
        setLTable(leftTable);
        setRTable(rightTable);
    }, [leftTable, rightTable]);

    const moveToRight = () => {
        let newLTable = LTable;
        let newRTable = RTable;

        selectedItems.forEach((item: any) => {
            const isDuplicate = newRTable.some((rItem: any) => {
                return JSON.stringify(rItem) === JSON.stringify(item);
            });

            if (!isDuplicate) {
                newRTable.push(item);
            }

            const alreadyExist = newLTable.some((lItem: any) => {
                return JSON.stringify(lItem) === JSON.stringify(item);
            });

            // If it's a duplicate, remove it from newLTable
            if (alreadyExist) {
                newLTable = newLTable.filter((i: any) => {
                    return JSON.stringify(i) !== JSON.stringify(item);
                });
            }
        });

        setRTable([...newRTable]);
        setLTable(newLTable);
        if (rightButtonClick) rightButtonClick(selectedItems);
        setSelectedItems([]);
        setClearRowActive(true);
    };

    const moveToLeft = () => {
        let newRTable = RTable;
        let newLTable = LTable;

        selectedItems.forEach((item: any) => {
            // Check if item already exists in newRTable
            const isDuplicate = newLTable.some((lItem: any) => {
                return JSON.stringify(lItem) === JSON.stringify(item);
            });

            if (!isDuplicate) {
                newLTable.push(item);
            }

            const alreadyExist = newRTable.some((rItem: any) => {
                return JSON.stringify(rItem) === JSON.stringify(item);
            });

            // If it's a duplicate, remove it from newRTable
            if (alreadyExist) {
                newRTable = newRTable.filter((i: any) => {
                    return JSON.stringify(i) !== JSON.stringify(item);
                });
            }
        });

        setLTable([...newLTable]);
        setRTable(newRTable);
        if (leftButtonClick) leftButtonClick(selectedItems);
        setSelectedItems([]);
        setClearRowActive(true);
    };

    return {
        LTable,
        RTable,
        clearRowActive,
        setClearRowActive,
        setSelectedItems,
        moveToRight,
        moveToLeft,
    };
};
