import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Row, TabPane  } from 'reactstrap';
import { P } from '../../../../../AbstractElements';
import { CommissionEarned, CurrencyCode, DateInitiated, NoRecordCommissionFound, Referral } from '../../../../../Constant';
import CustomizerContext from '../../../../../_helper/Customizer';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import SmallWidgets from '../../../../Common/CommonWidgets/SmallWidgets';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import { SalutationApi } from '../../../../../api';
import { toast } from 'react-toastify';
import NoRecord from '../../../../Common/Component/NoRecord';
import no_record_img from '../../../../../assets/images/other-images/no_record_found.jpg'


const MyCommissions = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const SmallWidgetsData = [
   {
      title: 'Number of Transactions from Referral',
      'desc': 'The total number of transactions the merchants you referred have done within the period specified',
      color: 'warning',
      total: 0,
      gros: 0,
      icon: 'customers',
    },
    {
      title: 'Total Commission Earned',
      desc: "The total value of commission you've earned within the period specified",
      color: 'success',
      total: 0,
      gros: 0,
      prefix: '₦',
      icon: 'profit',
    },
    {
      title: 'Total Commission Withdrawn',
      desc: "The total value of commission you've earned within the period specified",
      color: 'primary',
      total: 0,
      gros: 0,
      prefix: '₦',
      icon: 'profit',
    },

  ];

  const getAllCommisionData = async () => {
      try {
          await axiosPrivate.get(SalutationApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    //getAllCommisionData();
    setIsLoading(false);
    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const tableColumns = [
    {
        name: `${Referral}`,
        selector: row => `${row.name}`,
        sortable: true,
        center: false,
    },
    {
      name: `${CommissionEarned}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${CurrencyCode}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateInitiated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
  ];

 /*  const onView = () => {}
  const onEdit = () => {}

  const tableActions = [
    {
      label: 'View',
      action: onView,
      color: 'success',
      icon: 'icon-eye',
    },
    {
      label: 'Withdraw',
      action: onEdit,
      color: 'primary',
      icon: 'icon-pencil-alt',
    },
  ]; */

  return (
    <Fragment>
      <TabPane className='fade show' tabId='overview'>
        <Row className='general-widget g-sm-3 height-equal-2 widget-charts'>
          {SmallWidgetsData.map((data, i) => (
            <Col sm='4' key={i}>
              <SmallWidgets mainClass='mb-sm-0' data={data} />
            </Col>
          ))}
        </Row>
        <br />
        <Row>
          <Col sm='12'>
            {!allData ? 
            <NoRecord
              msg={NoRecordCommissionFound}
              backgroundImg={no_record_img}
            />
            :
            <DataTableComponent 
                tabledata={allData}
                tableColumns={tableColumns}
                isLoading={isLoading}
                useDefaultAction={false}
                //useTableAction={tableActions}
            />
            }
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default MyCommissions;