import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    ExpenseDescription,
    Description,
    ExpenseGroup,
    ExpenseClass,
    ExpenseType,
    Name,
    Status,
    ExpenseCategory,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    financesExpenseCategoryApi,
    financesExpenseClassApi,
    financesExpenseGroupApi,
    financesExpenseTypeApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import useAuth from "../../../../../Hooks/useAuth";
import { toast } from "react-toastify";

export default function useExpenseDescriptionController() {
    const axiosPrivate = useAxiosPrivate();
    const { userOrganisationId }: any = useAuth();
    const formTitle = ExpenseDescription;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "is_active",
            name: "is_active",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    const expenseCategory = createDropdownField(
        axiosPrivate,
        "expense_category",
        "expense_category",
        ExpenseCategory,
        `${financesExpenseCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    expenseCategory.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "expense_group"
        );
        if (nextField) {
            const endPoint = `${financesExpenseGroupApi}?organisation_id=${userOrganisationId}&expense_category_id=${id}`
            await nextField.fetchOptions(id, false, endPoint); 
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "expense_category") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const expenseGroup = createDropdownField(
        axiosPrivate,
        "expense_group",
        "expense_group",
        ExpenseGroup,
        `${financesExpenseGroupApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    expenseGroup.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "expense_class"
        );
        if (nextField) {
            const endPoint = `${financesExpenseClassApi}?organisation_id=${userOrganisationId}&expense_group_id=${id}`
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "expense_group") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const expenseClass = createDropdownField(
        axiosPrivate,
        "expense_class",
        "expense_class",
        ExpenseClass,
        `${financesExpenseClassApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
        "",
        6
    );
    expenseClass.onChange = async (id, updatedFields) => {
        const nextField = updatedFields.find(
            (field: any) => field.id === "expense_type"
        );
        if (nextField) {
            const endPoint = `${financesExpenseTypeApi}?organisation_id=${userOrganisationId}&expense_class_id=${id}`
            await nextField.fetchOptions(id, false, endPoint);
            const updatedFormFields = updatedFields.map((field: any) => {
                if (field.id === "expense_class") {
                    return { ...field, value: id };
                }
                return field;
            });
            setFormFields([...updatedFormFields]);
        }
    };

    const expenseType = createDropdownField(
        axiosPrivate,
        "expense_type",
        "expense_type",
        ExpenseType,
        ``,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            expenseCategory,
            expenseGroup,
            expenseClass,
            expenseType,
            ...formFields,
        ];
        await expenseCategory.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-description/${layoutURL}`;

    const onSubmit = async (data: any) => {
        data.organisation_id = userOrganisationId;
        data.expense_type_id = data.expense_type;
        // remove data.expense_type
        delete data.expense_type;
        await axiosPrivate
            .post("/finances/expense-descriptions", data)
            .then((_res) => {
                toast.success(`${_res.data.name} Created Successfully.`)
            })
            .catch((err) => {
                if (err.response && err.response.status === 422) {
                toast.error('Validation Errors');
                } else if (err.response && err.response.status === 400) {
                toast.error(err.response.data.detail);
                } else {
                toast.error('Something Went Wrong');
                }
                throw err;
            });
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
