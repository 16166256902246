import { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CompanyApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, SalutationApi, SectorApi, WardApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import {spouse_form_fields} from '../../../../Data/FormField/Spouse';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { createDropdownField } from '../../../../Data/FormField/PublicData';
import { Gender, Lga, MaritalStatus, RegionalCountry, Relationship, RelationshipDuration, Salutation, State, Ward } from '../../../../Constant';

const CompanyNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/administration/settings/referee-information/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(spouse_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  /* const orgTypeDropdownField = createDropdownField(axiosPrivate,
    'organisation_type_id',
    'organisation_type_id',
    `${OrganizationType}`,
    `${OrganisationTypeApi}`,
    '',12
  ); */

  const subSectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sub_sector_id',
    'sub_sector_id',
    `${Salutation}`,
    `${SalutationApi}`,
    '',6
  );

  const sectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sector_id',
    'sector_id',
    `${Relationship}`,
    `${SectorApi}`,
    '', 6
  );

  const relationshipDurationDropdownField = createDropdownField(axiosPrivate,
    'sector_id',
    'sector_id',
    `${RelationshipDuration}`,
    `${WardApi}`,
    '', 6
  );

  const genderDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Gender}`,
    `${WardApi}`,
    '', 6
  );
  const maritalStatusDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${MaritalStatus}`,
    `${WardApi}`,
    '', 6
  );
  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${RegionalCountry}`,
    `${RegionalCountryApi}`,
    '',
    12
  );
  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 12
  );
  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 12
  );
  const wardTypeDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [subSectorTypeDropdownField, sectorTypeDropdownField, relationshipDurationDropdownField, genderDropdownField, maritalStatusDropdownField, countryDropdownField, stateDropdownField, lgaDropdownField, wardTypeDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'ward_id');

          await updatedFields[0].fetchOptions();
          //await updatedFields[1].fetchOptions();
          //await updatedFields[2].fetchOptions();
          await updatedFields[indexOfName].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, subSectorTypeDropdownField, sectorTypeDropdownField, wardTypeDropdownField]);

  sectorTypeDropdownField.onChange = async (selectedSectorId, updatedFields) => { 
    const subSectorField = updatedFields.find((field) => field.id === 'sub_sector_id');
    
    if (subSectorField) {
      await subSectorField.fetchOptions(selectedSectorId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'sector_id') {
          return { ...field, value: selectedSectorId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }
      
      const newRecordTemp = new FormData();
      const logo = typeof newRecord.logo[0] === 'undefined' ? '' : newRecord.logo[0];

      //newRecordTemp.append('organisation_type_id', newRecord.organisation_type_id);
      newRecordTemp.append('sub_sector_id', newRecord.sub_sector_id);
      newRecordTemp.append('full_name', newRecord.full_name);
      newRecordTemp.append('shortname', newRecord.shortname);
      newRecordTemp.append('foreign_affliation', newRecord.foreign_affliation);
      newRecordTemp.append('apply_shared_service_fee', newRecord.applied_shared_fee);
      newRecordTemp.append('fee_amount', newRecord.fee_amount);
      newRecordTemp.append('registration_number', newRecord.registration_number);
      newRecordTemp.append('registration_date', newRecord.registration_date);
      newRecordTemp.append('description', newRecord.description);
      newRecordTemp.append('is_registered', newRecord.is_registered);
      newRecordTemp.append('email_address', newRecord.email_address);
      newRecordTemp.append('phone_number', newRecord.phone_number);
      newRecordTemp.append('website', newRecord.website);
      newRecordTemp.append('logo', logo);
      newRecordTemp.append('street_number', newRecord.street_number);
      newRecordTemp.append('street_name', newRecord.street_name);
      newRecordTemp.append('nearest_landmark', newRecord.nearest_landmark);
      newRecordTemp.append('city', newRecord.city);
      newRecordTemp.append('ward_id', newRecord.ward_id);
      newRecordTemp.append('is_active', newRecord.is_active);

      try {
          await axiosPrivate.post(CompanyApi, newRecordTemp, 
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }).then((resp) => {
            toast.success(`${resp.data.full_name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="Organisations" title="New Referee Information" mainTitle="New Referee Information" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CompanyNew;