import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { SalutationApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { base_form_fields, code_field, salutation_form_fields } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { EmployeeCategory, KycCategory } from '../../../../Constant';
//import Dropzone from 'react-dropzone-uploader';

const KycCreate = () => {
  const [formFields, setFormFields] = useState(base_form_fields(`${KycCategory}`))
  const [IsNewFieldAdded, setIsNewFieldAdded] = useState(false)
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/hrm/settings/kyc/${layoutURL}`;

  const { formState: { errors }, } = useForm();

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      name: newRecord.name,
      description: newRecord.name,
      is_active: newRecord.is_active,
      //file: newRecord.file[0]
    };

    try {
      await axiosPrivate.post(SalutationApi, newRecordTemp, {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  /*   const getUploadParams = ({ meta }) => {
      return {
        url: 'http://localhost:8001/public-data/upload',
      };
    };
  
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {}; */
  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!IsNewFieldAdded) {

          const updatedFields = [...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
          updatedFields.splice(indexOfName + 0, 0, code_field());

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, IsNewFieldAdded, code_field]);

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="kyc" mainTitle="new kyc" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default KycCreate;