import React, { Fragment, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Btn, UL, LI } from '../../AbstractElements';
import ScrollBars from 'react-custom-scrollbars-2';
import CompanyProfile from './CompanyProfile';
import { Link } from 'react-router-dom';

const SideNav = ({SideNavMenus, profile}) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>Structure Menu</Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'}`}>
                  <div className='email-sidebar'> 
                    <div className={`email-left-aside`}>
                    <Card>
                        <CardBody>
                        <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                          <CompanyProfile  profile={profile} />
                          <ScrollBars className='vertical-scroll' style={{width: '100%',height:500}}>
                            <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                              {/* <LI attrLI={{ className: 'border-0 nav-item' }}>
                                <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-100' }}>
                                  <CheckCircle className='me-2' />
                                  STRUCTURE
                                </Btn>
                              </LI> */}
                              <hr class="mt-4 mb-4"></hr>
                              {SideNavMenus.map((item, i) => (
                                <LI key={i} attrLI={{ className: 'border-0 nav-item' }}>
                                  <Link to={item.link}>
                                    <span className={`iconbg badge-light-${item.color}`}>{item.icon}</span>
                                    <span className='title ms-2'>{item.title}</span>
                                    {item.badge && <span className={`badge badge-${item.badgeColor ? item.badgeColor : item.color}`}>{item.badge}</span>}
                                  </Link>
                                </LI>
                              ))}
                            </UL>
                          </ScrollBars>
                        </div>
                        </CardBody>
                    </Card>
                    </div>
                </div>
              </div>
      </div>
    </Fragment>
  );
};

export default SideNav;