
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useContext } from 'react';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Card} from 'reactstrap';
import {CardBody} from 'reactstrap';
import { BackToList } from '../../../../Constant';
import { Link, RadioTypes, Pop, LinkType, Customer, CustomerBranch} from '../../../../Constant'; 
import GenerateForm from '../../../Common/Component/GenerateForm';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../_helper/Customizer';
import { Breadcrumbs } from '../../../../AbstractElements';
import { createDropdownField } from '../../../../Data/FormField/PublicData'; 
import { PopApi, RadioTypesApi, CustomerApi, CustomerBranchApi, LinkTypeApi } from "../../../../api"
import { link_form_fields } from './Link';

function LinkCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(link_form_fields);
    const lists = `${process.env.PUBLIC_URL}/technical/link/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
    
    const customerDropdownField = createDropdownField(axiosPrivate,
        'customer_id',
        'customer_id',
        `${Customer}`,
        `${CustomerApi}`,
        '',
        6
    );

    const customerBranchDropdownField = createDropdownField(axiosPrivate,
        'customer_branch_id',
        'customer_branch_id',
        `${CustomerBranch}`,
        `${CustomerBranchApi}`,
        '',
        6
    );

    const linktypeDropdownField = createDropdownField(axiosPrivate,
        'link_type_id',
        'link_type_id',
        `${LinkType}`,
        `${LinkTypeApi}`,
        '',
        6
    );

    const radioTypesDropdownField = createDropdownField(axiosPrivate,
        'radio_types_id',
        'radio_types_id',
        `${RadioTypes}`,
        `${RadioTypesApi}`,
        '',
        6
    );
    
    const popDropdownField = createDropdownField(axiosPrivate,
        'pop_id',
        'pop_id',
        `${Pop}`,
        `${PopApi}`,
        '',
        6
    );


    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {
                
                const updatedFields = [ customerDropdownField, customerBranchDropdownField, linktypeDropdownField, radioTypesDropdownField,  popDropdownField,  ...formFields];
        
                await updatedFields[0].fetchOptions();
                
                setFormFields(updatedFields);
                setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };
    
        fetchData();
    }, [formFields, isNewFieldAdded, linktypeDropdownField]); 

    const onSubmit = () => {
       
    }
    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Technical" title="Link" mainTitle="Link" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={onSubmit} listUrl={lists} formTitle={"Link"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default LinkCreate