import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Label, Media, Row, TabPane } from 'reactstrap';
import { Btn, H6, Image, P } from '../../../../../../AbstractElements';
import three from '../../../../../../assets/images/user/3.jpg';
import { formatDateWithTimeZone } from '../../../../../../_helper/dateHelper';
import { Comment, EscalateCase, EscalateNow, EscalateTeam, EscalateToMember, EscalationCategory, EscalationReason, EscalationTeam, Resolution, ResolutionCode, Save } from '../../../../../../Constant';
import Select from 'react-select';
import { EscalationCategoryApi, EscalationReasonApi, ResolutionCodeApi } from '../../../../../../api';
import { createDropdownField } from '../../../../../../Data/FormField/PublicData';
import useAuth from '../../../../../../Hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '../../../../../Common/Component/Spinner';
import useAxiosPrivate from '../../../../../../Hooks/useAxiosPrivate';

const CaseEscalation = ({ Details, onCaseEscalationSubmit }) => {
  const { contact } = Details; 
  const axiosPrivate = useAxiosPrivate()
  const messageInputRef = useRef(null);
  const { userOrganisationId } = useAuth();
  const [escalationCategoryOptions, setEscalationCategoryOptions] = useState([]);
  const [escalationReasonOptions, setEscalationReasonOptions] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  const EscalationCategoryDropdownField = createDropdownField(axiosPrivate,
    'escalation_category_id',
    'escalation_category_id',
    `${EscalationCategory}`,
    `${EscalationCategoryApi}?organisation_id=${userOrganisationId}`,
    '', '', true,
  );

  const EscalationReasonDropdownField = createDropdownField(axiosPrivate,
    'escalation_reason_id',
    'escalation_reason_id',
    `${EscalationReason}`,
    `${EscalationReasonApi}?organisation_id=${userOrganisationId}`,
    '', '', true,
  );

  const EscalationTeamDropdownField = createDropdownField(axiosPrivate,
    'team_id',
    'team_id',
    `${EscalateTeam}`,
    `${EscalationReasonApi}?organisation_id=${userOrganisationId}`,
    '', '', true,
  );

  const EscalationTeamMemberDropdownField = createDropdownField(axiosPrivate,
    'employee_id',
    'employee_id',
    `${EscalateToMember}`,
    `${EscalationReasonApi}?organisation_id=${userOrganisationId}`,
    '', '', false,
  );

  useLayoutEffect(() => {
    //const abortController = new AbortController();
    const fetchData = async () => {
      setIsLoading((state) => !state)
      
      await EscalationCategoryDropdownField.fetchOptions();
      setEscalationCategoryOptions(EscalationCategoryDropdownField.options)
      await EscalationReasonDropdownField.fetchOptions();
      setEscalationReasonOptions(EscalationReasonDropdownField.options)
      setIsLoading((state) => !state)
    }

   fetchData();

    //return () => {  abortController.abort();};
  },[])
 
 const onSubmit = async (data) => {
    //event.preventDefault();
    setBtnLoading(true);
    if (data !== '') {
      data.escalation_category_id = data.escalation_category_id.value;
      data.escalation_reason_id = data.escalation_reason_id.value;
      data.team_id = data.team_id.value;
      data.employee_id = data?.employee_id?.value;
      
      await onCaseEscalationSubmit(data);
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <TabPane className='fade show' tabId='case-escalation'>
        <Row>
          <Col sm='12'>
            <Card>
              <CardBody>
                <div className='new-users-social'>
                  <Media className='align-items-center'>
                    <Image attrImage={{ className: 'rounded-circle image-radius m-r-15', src: `${three}`, alt: '' }} />
                    <Media body>
                      <H6 attrH6={{ className: 'mb-0 f-w-700' }}>
                        {contact && (
                          contact.company_name ? contact.company_name :
                          (contact.last_name ? `${contact.last_name} ${contact.first_name} ${contact.middle_name}` : '')
                        )}
                      </H6>
                      <P>{formatDateWithTimeZone(Details.created_at)}</P>
                    </Media>
                   </Media>
                </div>
                
                <div className='timeline-content'>
                  <H6 attrH6={{ className: 'pb-2 f-w-600' }}>{EscalateCase}</H6>
                  <hr className="mt-1 mb-4" />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md='6 mb-3'>
                        <Label className='form-label'>
                          {EscalationCategoryDropdownField.label}
                        </Label>
                        <Controller
                          name={EscalationCategoryDropdownField.name}
                          control={control}
                          rules={{ required: EscalationCategoryDropdownField.required }}
                          render={({ field }) => (
                            <Select 
                              {...field}
                              name={field.name}
                              options={escalationCategoryOptions}
                              isClearable={true}
                              className="js-example-basic-single col-sm-12"
                            />
                          )}
                        />
                          {errors[EscalationCategoryDropdownField.name] && (
                            <span className='d-block' style={{ color: 'red' }}>
                              {EscalationCategoryDropdownField.label} is required
                            </span>
                          )}
                      </Col>
                      {/* {Object.keys(errors).map((key, index) => (
                        <div key={index} style={{ color: 'red' }}>
                          {errors[key].message || `${key} is required`}
                        </div>
                      ))} */}
                      <Col md='6 mb-3'>
                        <Label className='form-label'>
                          {EscalationReasonDropdownField.label}
                        </Label>
                        <Controller
                          name="escalation_reason_id"
                          control={control}
                          rules={{ required: EscalationReasonDropdownField.required }}
                          //defaultValue={2}
                          render={({ field, value }) => (
                            <Select 
                              {...field}
                              name={field.name}
                              options={escalationReasonOptions}
                              isClearable={true}
                              className="js-example-basic-single col-sm-12"
                            />
                          )}
                        />
                          {errors[EscalationReasonDropdownField.name] && (
                            <span className='d-block' style={{ color: 'red' }}>
                              {EscalationReasonDropdownField.label} is required
                            </span>
                          )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md='6 mb-3'>
                        <Label className='form-label'>
                          {EscalationTeamDropdownField.label}
                        </Label>
                        <Controller
                          name={EscalationTeamDropdownField.name}
                          control={control}
                          rules={{ required: EscalationTeamDropdownField.required }}
                          render={({ field, value }) => (
                            <Select 
                              {...field}
                              name={field.name}
                              options={escalationCategoryOptions}
                              isClearable={true}
                              className="js-example-basic-single col-sm-12"
                            />
                          )}
                        />
                          {errors[EscalationTeamDropdownField.name] && (
                            <span className='d-block' style={{ color: 'red' }}>
                              {EscalationTeamDropdownField.label} is required
                            </span>
                          )}
                      </Col>

                      <Col md='6 mb-3'>
                        <Label className='form-label'>
                          {EscalationTeamMemberDropdownField.label}
                        </Label>
                        <Controller
                          name={EscalationTeamMemberDropdownField.name}
                          control={control}
                          rules={{ required: EscalationTeamMemberDropdownField.required }}
                          render={({ field, value }) => (
                            <Select 
                              {...field}
                              name={field.name}
                              options={escalationReasonOptions}
                              isClearable={true}
                              className="js-example-basic-single col-sm-12"
                            />
                          )}
                        />
                          {errors[EscalationTeamMemberDropdownField.name] && (
                            <span className='d-block' style={{ color: 'red' }}>
                              {EscalationTeamMemberDropdownField.label} is required
                            </span>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12 mb-3'>
                        <Label className='form-label'>
                          {Comment}
                        </Label> 
                        <textarea ref={messageInputRef} 
                        //defaultValue={Details.resolved_comment} 
                        {...register('message', { required: true })}
                        className='form-control' style={{ height: '6rem' }} />
                        <span className='d-block' style={{ color: 'red' }}>{errors.message && 'Escalation Comment is required'}</span>
                      </Col>
                    </Row>
                    <div className='text-end'>
                      <Btn attrBtn={{ color: 'primary' }}>
                        {btnLoading ? <Spinner /> : <><i className='fa fa-bell'></i> {EscalateNow}</>}
                      </Btn>
                    </div>
                    
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </Fragment>
  );
};

export default CaseEscalation;
