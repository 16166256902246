import { Fragment, useState } from 'react';
import { Media } from 'reactstrap';
import { Image, P } from '../../../../../AbstractElements';
import three from '../../../../../assets/images/user/3.jpg';
import { timeAgo } from '../../../../../_helper/dateHelper';
import { TicketReplyMenu } from '../../../../../Constant';
import DropdownCommon from '../../../../Common/Dropdown';

const CaseReply = ({ Replies }) => {
    const [heart, setHeart] = useState(0);
      
    return (
        <Fragment>
            {Replies.map((item, i) => (
                <div key={i} className={`your-msg ${item.employee_id ? 'font-primary' : ''}`}>
                    <Media>
                        <Image attrImage={{ className: 'img-50 img-fluid m-r-20 rounded-circle', alt: '', src: `${three}` }} />
                        <Media body>
                            <span className='f-w-500'>
                                {item.contact_id ? 
                                `${item.contact.last_name} ${item.contact.first_name}` : 
                                `Staff: ` }
                                &nbsp; 
                            <span>
                            {timeAgo(item.created_at)} <i className='fa fa-reply font-primary'></i>
                            </span>
                            <span className='d-flex justify-content-between pull-right mt-0 '>
                                <span className='m-r-20'><i className='fa fa-edit font-primary'></i> Edit</span>
                                <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={TicketReplyMenu} iconName='icon-more-alt' btn={{ tag: 'span' }} />
                            </span>
                        </span>
                        <P>{item.message}</P>

                        <div className='like-content'>
                            {/* {item.contact_id && (
                                <span>
                                <Rating
                                    initialRating={item.rating}
                                    emptySymbol="fa fa-heart-o fa-2x"
                                    fullSymbol="fa fa-heart fa-2x font-danger"
                                    onClick={(rate) => setHeart(rate)}
                                >
                                </Rating>
                                </span>
                            )} */}
                            <span className='pull-right comment-number'>
                            <span>You Rated: {item.rating} </span>
                            <span>
                                <i className='fa fa-heart me-0'></i>
                            </span>
                            </span>
                        </div>
                        </Media>
                    </Media>
                </div>
            ))}
        </Fragment>
    );
};

export default CaseReply;