import React from 'react';
import Routers from './Route';

import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import ApplicationProvider from './_helper/AccessControl/Application/ApplicationProvider';
import AuthProvider from './_helper/Auth/AuthProvider';
//import { CookiesProvider, useCookies } from "react-cookie";

// function App() {
  const App = () => (
  //return (
    <div className='App'>
      {/* <CookiesProvider> */}
        <AuthProvider>
          <CustomizerProvider>
            <ApplicationProvider>
              <AnimationThemeProvider>
                <Routers />
                </AnimationThemeProvider>
              </ApplicationProvider>
          </CustomizerProvider>
        </AuthProvider>
      {/* </CookiesProvider> */}
    </div>
  );
//} 
//
export default App
