import React, { Fragment, useContext, useState, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContactMgmtApi, ContactBranchApi, GenderApi, MaritalStatusApi, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, SalutationApi, WardApi, ZoneApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import { branch_form_fields, contact_branch_form_fields, electronic_address_form_fields, nationality_address_form_fields, physical_address_form_fields } from '../../../../../Data/FormField/CrmData';
import { ContactTypeVal, createDropdownField } from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { CorporateCompany, Country, Gender, Lga, MaritalStatus, Salutation, State, Ward, Zone } from '../../../../../Constant';
import useAuth from '../../../../../Hooks/useAuth';

const ContactBranchCreate = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/crm/contacts/branches/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(contact_branch_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  //============= Nationality =================//
  const wardDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`, "", 6, false
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    wardDropdownField, 6, false
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    lgaDropdownField, 6, false
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    stateDropdownField, 6, false
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    zoneDropdownField, 6, false
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    countryDropdownField, 6, false
  );

  //========= Physical Address ============== //
  const addressWardDropdownField = createDropdownField(axiosPrivate,
    'address_ward_id',
    'address_ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const addressLgaDropdownField = createDropdownField(axiosPrivate,
    'address_regional_lga_id',
    'address_regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    addressWardDropdownField,
  );

  const addressStateDropdownField = createDropdownField(axiosPrivate,
    'address_regional_state_id',
    'address_regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    addressLgaDropdownField,
  ); 

  const addressZoneDropdownField = createDropdownField(axiosPrivate,
    'address_regional_zone_id',
    'address_regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    addressStateDropdownField,
  ); 

  const addressCountryDropdownField = createDropdownField(axiosPrivate,
    'address_regional_country_id',
    'address_regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    addressZoneDropdownField,
  ); 

  const addressContinentDropdownField = createDropdownField(axiosPrivate,
    'address_regional_continent_id',
    'address_regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    addressCountryDropdownField,
  );

  const contactCorporateHeadDropdownField = createDropdownField(axiosPrivate,
    'contact_id',
    'contact_id',
    `${CorporateCompany}`,
    `${ContactMgmtApi}?organisation_id=${userOrganisationId}&contact_type=${ContactTypeVal.Corporate}`,
    '', 6, '', 'company_name'
  );

  const corporateDropdownField = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${ContactMgmtApi}?contact_type=${ContactTypeVal.Corporate}`,
    label: "Corporate Company",
    type: "input-select",
    value: "",
    colSize: 6,
    required: true
  };

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const salutation_dropdown_field = createDropdownField(axiosPrivate,
            'salutation_id',
            'salutation_id',
            `${Salutation}`,
            `${SalutationApi}`, "", 6, false
          );

          const gender_dropdown_field = createDropdownField(axiosPrivate,
            'gender_id',
            'gender_id',
            `${Gender}`,
            `${GenderApi}`, "", 6, false
          );

          const marital_dropdown_field = createDropdownField(axiosPrivate,
            'marital_status_id',
            'marital_status_id',
            `${MaritalStatus}`,
            `${MaritalStatusApi}`, "", 6, false
          );

          const updatedFields = [//contactCorporateHeadDropdownField, 
            corporateDropdownField,
            ...branch_form_fields, 
            ...electronic_address_form_fields, 
            ...physical_address_form_fields,
            addressContinentDropdownField, addressCountryDropdownField, addressZoneDropdownField, addressStateDropdownField, addressLgaDropdownField, addressWardDropdownField,
            ...formFields ];

          const indexOfLN = updatedFields.findIndex((updatedField) => updatedField.id === 'last_name');
          
          updatedFields.splice(indexOfLN, 0, salutation_dropdown_field);

          const indexOfDob = updatedFields.findIndex((updatedField) => updatedField.id === 'dob');
          updatedFields.splice(indexOfDob + 1, 0, gender_dropdown_field, marital_dropdown_field);
          
          const indexOfNin = updatedFields.findIndex((updatedField) => updatedField.id === 'nin');
          updatedFields.splice(indexOfNin + 2, 0, 
            ...nationality_address_form_fields, 
            continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField,
          );
          
          //await updatedFields[0].fetchOptions();
          await salutation_dropdown_field.fetchOptions();
          await marital_dropdown_field.fetchOptions();
          await gender_dropdown_field.fetchOptions();
          await continentDropdownField.fetchOptions();
          await addressContinentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded]);

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      setFormFields([...updatedFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      setFormFields([...updatedFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      setFormFields([...updatedFields]);
    } 
  };

  //=============== Address dropdown ===============
  addressContinentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'address_regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      setFormFields([...updatedFields]);
    } 
  };

  addressCountryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'address_regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);
      setFormFields([...updatedFields]);
    } 
  };

  addressZoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'address_regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      setFormFields([...updatedFields]);
    } 
  };

  addressStateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'address_regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      setFormFields([...updatedFields]);
    } 
  };

  addressLgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'address_ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }
      
      const newRecordTemp = {
          /* email: newRecord.email,
          is_active: newRecord.is_active,
          contact: { contact_id */
            contact_id: newRecord.contact_id,
            salutation_id: newRecord.salutation_id,
            name: newRecord.branch_name,
            last_name: newRecord.last_name,
            first_name: newRecord.first_name,
            middle_name: newRecord.middle_name,
            maiden_name: newRecord.maiden_name,
            gender_id: newRecord.gender_id,
            marital_status_id: newRecord.marital_status_id,
            phone_number: newRecord.phone_number,
            nin: newRecord.nin,
            date_of_birth: newRecord.dob ? newRecord.dob : "0000-00-00",
            nationality_ward_id: newRecord.ward_id,
            ward_id: newRecord.address_ward_id,
            email_address: newRecord.email,
            company_alt_phone_number: newRecord.alt_phone_number,
            company_website: newRecord.website,
            street_number: newRecord.street_number,
            street_name: newRecord.street_name,
            nearest_landmark: newRecord.nearest_landmark,
            city: newRecord.city,
            geo_tag: newRecord.geo_tag,
          //}
       };
       
      try {
          await axiosPrivate.post(ContactBranchApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(listUrl);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
          throw error;
      }  
  };

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="New Contact Branch" mainTitle="New Contact Branch" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={listUrl} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ContactBranchCreate;