import { IPAddress, CoOrdinateA, CoOrdinateB, Frequency, ChannelWidth, SwitchPorts, SwitchVersion, DeviceCondition, SerialNumber, } from "../../../../Constant"

export const inventory_form_fields = [
    {
        id: 'serial_number ',
        name: 'serial_number ',
        label: `${SerialNumber}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
    {
        id: 'ip_address',
        name: 'ip_address',
        label: `${IPAddress}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'co-ordinateA',
        name: 'co-ordinateA',
        label: `${CoOrdinateA}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'co-ordinateB',
        name: 'co-ordinateB',
        label: `${CoOrdinateB}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'frequency',
        name: 'frequency',
        label: `${Frequency}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'channel_width',
        name: 'channel_width',
        label: `${ChannelWidth}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'switch_ports',
        name: 'switch_ports',
        label: `${SwitchPorts}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'switch_version',
        name: 'switch_version',
        label: `${SwitchVersion}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
      },
      {
        id: 'is_device_condition',
        name: 'is_device_condition',
        label: `${DeviceCondition}`,
        type: 'radio',
        value: '',
        required: true,
        colSize: 6,
        options: [
            {'label': 'In Use', 'value': 1},
            {'label': 'In Store', 'value': 0},
            {'label': 'Faulty', 'value': 1},
            {'label': 'Bad', 'value': 0},
        ],
      },
   
];

