import { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Description, Icon, Status, ApplicationName, Visibility } from '../../../../Constant';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import ApplicationContext from '../../../../_helper/AccessControl/Application';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import { ApplicationApi } from '../../../../api';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';

const ApplicationNew = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const lists = `${process.env.PUBLIC_URL}/access-control/applications/${layoutURL}`;
  
  const { formState: { errors },} = useForm(); 

  const addNewRecord = async (newRecord) => {
    if (newRecord === '') {
      errors.showMessages();
    }

    const newRecordTemp = {
      name: newRecord.title,
      description: newRecord.description,
      icon: newRecord.icon,
      visibility: newRecord.visibility,
      is_active: newRecord.is_active,
      sort_order: 0,
    };

    try {
      await axiosPrivate.post(ApplicationApi, newRecordTemp, {
        headers: {
          //'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }).then((resp) => {
        toast.success(`${resp.data.name} is successfully added.`)
        history(lists);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      }
      else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const formFields = [
      {
        id: 'title',
        name: 'title',
        label: `${ApplicationName}`,
        type: 'text',
        value: '',
        required: true,
      },
      {
        id: 'description',
        name: 'description',
        label: `${Description}`,
        type: 'textarea',
        value: '',
        required: true,
      },
      {
        id: 'icon',
        name: 'icon',
        label: `${Icon}`,
        type: 'text',
        value: '',
        required: true,
      },
      {
        id: 'visibility',
        name: 'visibility',
        label: `${Visibility}`,
        type: 'switch',
        value: '1',
        required: false,
      },
      {
        id: 'is_active',
        name: 'is_active',
        label: `${Status}`,
        type: 'switch',
        value: '1',
        required: false,
      },
    ];

  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="New Application" mainTitle="New Application" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ApplicationNew;