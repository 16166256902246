import { Fragment, useContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Table, Nav, NavItem, NavLink, TabContent, Input, Button } from 'reactstrap';
import { Breadcrumbs, Image } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { ContactMgmtApi, ContactTypeApi, RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, SectorApi, SubSectorApi, WardApi, ZoneApi } from '../../../../api';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import ViewModal from '../../../Common/Component/ViewModal';
import {contact_form_fields, corporate_form_fields } from '../../../../Data/FormField/CrmData';
import { ContactTypeVal, createDropdownField } from '../../../../Data/FormField/PublicData';
import noimage from '../../../../assets/images/no-image.png';
import { BasicInfo, Contact, ContactID, ContactType, Corporate, Country, DateCreated, DateModified, Details, ElectronicAddress, Email, EmailAddress, Filter, FullName, Lga, Nationality, PhoneNumber, PhysicalAddress, RepresentationInfo, Sector, State, Status, SubSector, View, Ward, Zone } from '../../../../Constant';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';
import BasicInformation from './Tab/BasicInformation';
import CorporateInformation from './Tab/CorporateInformation';
import NationalityInformation from './Tab/NationalityInformation';
import ElectronicAddressInformation from './Tab/ElectronicAddressInformation';
import PhysicalAddressInformation from './Tab/PhysicalAddressInformation';
import DataTableServerFetchComponent from '../../../Common/Component/DataTableServerFetchComponent';

const ContactList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowIndexData, setSelectedIndexRowData] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  //const [selectedRowData, setSelectedRowData] = useState({ rowData: null, index: null });
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(contact_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [contactTab, setContactTab] = useState('basic-info');

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState("");

  //============= Nationality =================//
  const wardDropdownField = createDropdownField(axiosPrivate,
    'ward_id',
    'ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    wardDropdownField,
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    lgaDropdownField,
  ); 

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    stateDropdownField,
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    zoneDropdownField,
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'regional_continent_id',
    'regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    countryDropdownField,
  );

  //========= Physical Address ============== //
  const addressWardDropdownField = createDropdownField(axiosPrivate,
    'address_ward_id',
    'address_ward_id',
    `${Ward}`,
    `${WardApi}`,
  );

  const addressLgaDropdownField = createDropdownField(axiosPrivate,
    'address_regional_lga_id',
    'address_regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    addressWardDropdownField,
  );

  const addressStateDropdownField = createDropdownField(axiosPrivate,
    'address_regional_state_id',
    'address_regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    addressLgaDropdownField,
  ); 

  const addressZoneDropdownField = createDropdownField(axiosPrivate,
    'address_regional_zone_id',
    'address_regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    addressStateDropdownField,
  ); 

  const addressCountryDropdownField = createDropdownField(axiosPrivate,
    'address_regional_country_id',
    'address_regional_country_id',
    `${Country}`,
    `${RegionalCountryApi}`,
    addressZoneDropdownField,
  ); 

  const addressContinentDropdownField = createDropdownField(axiosPrivate,
    'address_regional_continent_id',
    'address_regional_continent_id',
    'Continent',
    `${RegionalContinentApi}`,
    addressCountryDropdownField,
  );

  const subSectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sub_sector_id',
    'sub_sector_id',
    `${SubSector}`,
    `${SubSectorApi}`,
    '',6
  );

  const sectorTypeDropdownField = createDropdownField(axiosPrivate,
    'sector_id',
    'sector_id',
    `${Sector}`,
    `${SectorApi}`,
    '', 6
  );

  const contactTypeDropdownField = createDropdownField(axiosPrivate,
    'contact_type_id',
    'contact_type_id',
    `${ContactType}`,
    `${ContactTypeApi}`,
    '', 6
  );

  useLayoutEffect(() => {

    /* const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const salutation_dropdown_field = createDropdownField(axiosPrivate,
            'salutation_id',
            'salutation_id',
            `${Salutation}`,
            `${SalutationApi}`,
          );

          const gender_dropdown_field = createDropdownField(axiosPrivate,
            'gender_id',
            'gender_id',
            `${Gender}`,
            `${GenderApi}`,
          );

          const marital_dropdown_field = createDropdownField(axiosPrivate,
            'marital_status_id',
            'marital_status_id',
            `${MaritalStatus}`,
            `${MaritalStatusApi}`,
          );

          const updatedFields = [contactTypeDropdownField, ...formFields ];

          const indexOfLN = updatedFields.findIndex((updatedField) => updatedField.id === 'last_name');
          updatedFields.splice(indexOfLN, 0, salutation_dropdown_field);

          const indexOfDob = updatedFields.findIndex((updatedField) => updatedField.id === 'dob');
          updatedFields.splice(indexOfDob + 1, 0, gender_dropdown_field, marital_dropdown_field);
          
          const indexOfNin = updatedFields.findIndex((updatedField) => updatedField.id === 'nin');
          updatedFields.splice(indexOfNin + 2, 0, 
            ...nationality_address_form_fields, 
            continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, wardDropdownField,
            ...electronic_address_form_fields, 
            ...physical_address_form_fields,
            addressContinentDropdownField, addressCountryDropdownField, addressZoneDropdownField, addressStateDropdownField, addressLgaDropdownField, addressWardDropdownField);
          
          await updatedFields[0].fetchOptions();
          await salutation_dropdown_field.fetchOptions();
          await marital_dropdown_field.fetchOptions();
          await gender_dropdown_field.fetchOptions();
          await continentDropdownField.fetchOptions();
          await addressContinentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData(); */

  }, [formFields, isNewFieldAdded, contactTypeDropdownField]);
  //}, []);

  contactTypeDropdownField.onChange = async (selectedContactTypeId, updatedFields) => { 
    
    if (selectedContactTypeId === `${ContactTypeVal.Corporate}`) {
      const indexContactType = updatedFields.findIndex((updatedField) => updatedField.id === 'contact_type_id');
      updatedFields.splice(indexContactType + 1, 0, ...corporate_form_fields);
    } else {
      // Remove corporate_form_fields if contact type is not '2'
      updatedFields = updatedFields.filter(field => !corporate_form_fields.includes(field));
    }

    setFormFields([...updatedFields]);
  };

  /* continentDropdownField.onChange = async (selectedContinentId, updatedFields) => {
    const updatedFormFields = await handleDropdownOnChange(selectedContinentId, updatedFields);
    setFormFields([...updatedFormFields]);
  }; */

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]); 
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  lgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  //=============== Address dropdown ===============
  addressContinentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'address_regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'address_regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]); 
    } 
  };

  addressCountryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'address_regional_zone_id');
    
    if (zoneField) {
      await zoneField.fetchOptions(selectedCountryId);

      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressZoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'address_regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressStateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'address_regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  addressLgaDropdownField.onChange = async (selectedLgaId, updatedFields) => { 
    const wardField = updatedFields.find((field) => field.id === 'address_ward_id');
    
    if (wardField) {
      await wardField.fetchOptions(selectedLgaId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'address_regional_lga_id') {
          return { ...field, value: selectedLgaId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  sectorTypeDropdownField.onChange = async (selectedSectorId, updatedFields) => { 
    const subSectorField = updatedFields.find((field) => field.id === 'sub_sector_id');
    
    if (subSectorField) {
      await subSectorField.fetchOptions(selectedSectorId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'sector_id') {
          return { ...field, value: selectedSectorId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const getAllContactsData = async (page = 1) => {
      try {
          setIsLoading(true);
          const search = searchValue ? `&search_value=${searchValue}` : '';
          await axiosPrivate.get(`${ContactMgmtApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}${search}`).then((resp) => {
              setAllData(resp.data.items);
              setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllContactsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row, index) => {
    setViewModalOpen(true);
    setEditMode(false);

    setSelectedRowData(row);
    setSelectedIndexRowData(index);
  };


  const handleEdit = (row, index) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    
    row.regional_continent_id = row.ward.lga.state.zone.country.regional_continent_id;
    row.regional_country_id = row.ward.lga.state.zone.regional_country_id;
    row.regional_zone_id = row.ward.lga.state.regional_zone_id;
    row.regional_state_id = row.ward.lga.regional_state_id;
    row.regional_lga_id = row.ward.regional_lga_id;

    row.address_regional_continent_id = row.ward_nationality.lga.state.zone.country.regional_continent_id;
    row.address_regional_country_id = row.ward_nationality.lga.state.zone.regional_country_id;
    row.address_regional_zone_id = row.ward_nationality.lga.state.regional_zone_id;
    row.address_regional_state_id = row.ward_nationality.lga.regional_state_id;
    row.address_regional_lga_id = row.ward_nationality.regional_lga_id;
    row.address_ward_id = row.ward_id;

    row.email = row.user_login.email;
    row.contact_type_id = row.contact_type;
    contactTypeDropdownField.onChange(row.contact_type_id, formFields);
    continentDropdownField.onChange(row.regional_continent_id, formFields);
    countryDropdownField.onChange(row.regional_country_id, formFields);
    zoneDropdownField.onChange(row.regional_zone_id, formFields);
    stateDropdownField.onChange(row.regional_state_id, formFields);
    lgaDropdownField.onChange(row.regional_lga_id, formFields);

    addressContinentDropdownField.onChange(row.address_regional_continent_id, formFields);
    addressCountryDropdownField.onChange(row.address_regional_country_id, formFields);
    addressZoneDropdownField.onChange(row.address_regional_zone_id, formFields);
    addressStateDropdownField.onChange(row.address_regional_state_id, formFields);
    addressLgaDropdownField.onChange(row.address_regional_lga_id, formFields);
    //console.log(row)
    setSelectedRowData(row);
    setSelectedIndexRowData(index);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      email: editRecord.email,
      //password: 'Password#123',
      is_active: editRecord.is_active,
      contact: {
          
          contact_type: editRecord.contact_type_id,
          salutation_id: editRecord.salutation_id,
          last_name: editRecord.last_name,
          first_name: editRecord.first_name,
          middle_name: editRecord.middle_name,
          maiden_name: editRecord.maiden_name,
          gender_id: editRecord.gender_id,
          marital_status_id: editRecord.marital_status_id,
          phone_number: editRecord.phone_number,
          nin: editRecord.nin,
          date_of_birth: editRecord.dob ? editRecord.dob : "0000-00-00",
          nationality_ward_id: editRecord.ward_id,
          ward_id: editRecord.address_ward_id,
          //company_email_address: editRecord.email,
          company_alt_phone_number: editRecord.alt_phone_number,
          company_website: editRecord.website,
          street_number: editRecord.street_number,
          street_name: editRecord.street_name,
          nearest_landmark: editRecord.nearest_landmark,
          city: editRecord.city,
          geo_tag: editRecord.geo_tag,

          ...(editRecord.c   && {
            company_name: editRecord.company_name,
            company_short_name: editRecord.company_short_name,
            foreign_affliation: editRecord.foreign_affliation,
            is_registered: editRecord.is_registered,
            registration_number: editRecord.registration_number,
            registration_date:  editRecord.registration_date ? editRecord.registration_date : "0000-00-00",
          }),
      }
    };
    
    try {
        await axiosPrivate.put(`${ContactMgmtApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.last_name} ${resp.data.first_name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${ContactMgmtApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const OpenTabModal = async (row, index) => {
    setTabModalOpen(true);
    setEditMode(false);
    if (row.contact_type === ContactTypeVal.Corporate)
      setContactTab('corporate-info')
    else
      setContactTab('basic-info')
    
    setSelectedRowData(row);
    setSelectedIndexRowData(index);
  }

  const handleTabCloseModal = () => {
    setTabModalOpen(false);
  };

  const handleContactUpdate = async (id, editRecord) => {
    //console.log("button clicked");
  }

  const memoizedOpenTabModal = useMemo(() => handleContactUpdate, [allData, selectedRowData]);

  const useTableAction = [
    {
      label: `${View}`,
      action: handleView,
      color: 'success',
      icon: 'icon-eye',
    },
    {
      label: `${Details}`,
      action: OpenTabModal,
      color: 'warning',
      icon: 'icon-pencil-alt',
    },
  ];

  const updateRowRecord = (index, updatedRecord) => {
    const updatedAllData = [...allData];
    updatedAllData[index] = updatedRecord;
    setAllData(updatedAllData);
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;
  
  const tableColumns = [
    {
      name: `${FullName}`,
      selector: row => row.last_name,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
                          <Media body className="align-self-center">
                              <div>
                                {row.contact_type === ContactTypeVal.Individual ? 
                                  `${row.last_name} ${row.first_name} ${row.middle_name}` : 
                                  `${row.company_name}`
                                }
                              </div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    {
      name: `${ContactID}`,
      selector: row => row.contact_number,
      sortable: true,
      center: false,
    },
    {
      name: `${Email}`,
      selector: row => row.user_login.email,
      sortable: true,
      center: false,
    },
    {
      name: `${PhoneNumber}`,
      selector: row => `${row.phone_number}`,
      sortable: true,
      center: false,
    },
    /* {
      name: 'Address',
      selector: row => row.address,
      wrap: true,
      sortable: true,
      format: row => `${row.address.slice(0, 200)}...`,
    }, */
    {
      name: `${ContactType}`,
      selector: row => row.contact_type === 1 ? "Individual" : "Corporate",
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.user_login.is_active,
      sortable: true,
      center: false,
      cell: row => (
        <>
          {row.user_login.is_active ? (
            <span className="badge badge-light-success">Active</span>
          ) : (
            <span className="badge badge-light-danger">Inactive</span>
          )}
          {row.user_login.email_verified_at ? (
            <span className="badge badge-light-success">Verified</span>
          ) : (
            <span className="badge badge-light-danger">Not Verified</span>
          )}
        </>
      ),
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const handlePageChange = page => {
    getAllContactsData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const search = searchValue ? `&search_value=${searchValue}` : '';
    const response = await axiosPrivate.get(`${ContactMgmtApi}?organisation_id=${userOrganisationId}&page=${page}&size=${newPerPage}${search}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Crm" title="Contacts" mainTitle="Contacts" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Contact List" btnUrl={addBtn} />
              <CardBody>

                <div className="row gap-y-3">
                  <div className="col-md-4 mb-3">
                      <p className="mb-0">&nbsp;</p>
                      <Input
                          onChange={(e) =>
                              setSearchValue(e.target.value)
                          }
                          type="text"
                      />
                  </div>

                  <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
                      <Button
                          color="primary"
                          onClick= {() => getAllContactsData()}
                          className="mt-4"
                      >
                          <i className="fa fa-filter"></i>&nbsp;
                          {Filter}
                      </Button>
                  </div>
                </div>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  //onEdit={handleEdit}
                  useTableAction={useTableAction}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{ContactType}</th>
                    <td>{selectedRowData.contact_type === ContactTypeVal.Individual ? "Individual" : "Corporate"}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ContactID}</th>
                    <td>{selectedRowData.contact_number}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FullName}</th>
                    <td>
                      {selectedRowData.contact_type === ContactTypeVal.Individual ? 
                        `${selectedRowData.last_name} ${selectedRowData.first_name} ${selectedRowData.middle_name}` : 
                        `${selectedRowData.company_name}`
                      }
                    </td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EmailAddress}</th>
                    <td>{selectedRowData.user_login.email}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{PhoneNumber}</th>
                    <td>{selectedRowData.phone_number}</td>
                  </tr>
                  {selectedRowData.contact_type === ContactTypeVal.Corporate && (
                    <tr className='border-bottom-primary'>
                      <th scope='row'>{Contact}</th>
                      <td>{selectedRowData.last_name} {selectedRowData.first_name} {selectedRowData.middle_name}</td>
                    </tr>
                  )}
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.user_login.is_active ? (
                          <span className="badge badge-light-success">Active</span>
                        ) : (
                          <span className="badge badge-light-danger">Inactive</span>
                        )}
                      
                        {selectedRowData.user_login.email_verified_at ? (
                          <span className="badge badge-light-success">Verified</span>
                        ) : (
                          <span className="badge badge-light-danger">Not Verified</span>
                        )}
                    </td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>

        <ViewModal
          isOpen={tabModalOpen}
          toggle={() => setTabModalOpen(!tabModalOpen)}
          rowData={selectedRowData}
          formFields={undefined}
          editMode={editMode}
          onEdit={memoizedOpenTabModal}
        > 
          <Nav className='border-tab nav-secondary nav-pills' tabs>
            {selectedRowData && (selectedRowData.contact_type === ContactTypeVal.Corporate) && (
              <>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'corporate-info' ? 'active' : ''} onClick={() => setContactTab('corporate-info')}>
                    <i className='icofont icofont-ui-office'></i>
                    {Corporate}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'electronic-address' ? 'active' : ''} onClick={() => setContactTab('electronic-address')}>
                    <i className='icofont icofont-book'></i>
                    {ElectronicAddress}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'physical-address' ? 'active' : ''} onClick={() => setContactTab('physical-address')}>
                    <i className='icofont icofont-book'></i>
                    {PhysicalAddress}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'basic-info' ? 'active' : ''} onClick={() => setContactTab('basic-info')}>
                    <i className='icofont icofont-worker'></i>
                    {RepresentationInfo}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'nationality-info' ? 'active' : ''} onClick={() => setContactTab('nationality-info')}>
                    <i className='icofont icofont-globe'></i>
                    {Nationality}
                  </NavLink>
                </NavItem>
              </>
            )}

            {selectedRowData && (selectedRowData.contact_type === ContactTypeVal.Individual) && (
              <>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'basic-info' ? 'active' : ''} onClick={() => setContactTab('basic-info')}>
                    <i className='icofont icofont-worker'></i>
                    {BasicInfo}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'nationality-info' ? 'active' : ''} onClick={() => setContactTab('nationality-info')}>
                    <i className='icofont icofont-globe'></i>
                    {Nationality}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={contactTab === 'physical-address' ? 'active' : ''} onClick={() => setContactTab('physical-address')}>
                    <i className='icofont icofont-book'></i>
                    {PhysicalAddress}
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <TabContent activeTab={contactTab}>
            <CorporateInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <BasicInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <NationalityInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <ElectronicAddressInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
            <PhysicalAddressInformation rowData={selectedRowData} index={selectedRowIndexData} updateRowRecord={updateRowRecord} />
          </TabContent>
        </ViewModal>

      </Container>
    </Fragment>
  );
};

export default ContactList;