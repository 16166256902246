import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { RegionalCountryApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import {base_form_fields, continent_dropdown_field, country_fields} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { Capital, CountryCode, CountryName, CurrencyCode, DateCreated, DateModified, ISO2, Nationality, PhoneCode, RegionalContinent, Slogan, Status } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const RegionalCountryList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(base_form_fields(`${CountryName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const indexOfName = formFields.findIndex((formField) => formField.id === 'name');
    
          const updatedFields = [continent_dropdown_field, ...formFields];
          updatedFields.splice(indexOfName + 2, 0, ...country_fields);
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, country_fields, continent_dropdown_field]);

  const getAllRegionalCountrysData = async () => {
      try {
          await axiosPrivate.get(RegionalCountryApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //console.log('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllRegionalCountrysData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
          regional_continent_id: editRecord.regional_continent_id,
          name: editRecord.name,
          short_name: editRecord.name,
          country_code: editRecord.country_code,
          capital: editRecord.capital,
          iso2: editRecord.iso2,
          phone_code: editRecord.phone_code,
          currency_code: editRecord.currency_code,
          nationality: editRecord.nationality,
          slogan: editRecord.slogan,
          flag: editRecord.flag,
          description: editRecord.description,
          is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${RegionalCountryApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${RegionalCountryApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/public-data/regional-countries/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${CountryCode}`,
      selector: row => `${row.country_code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${CountryName}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${RegionalContinent}`,
      selector: row => `${row.continent.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Regional Countries" mainTitle="Regional Countries" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{CountryCode}</th>
                    <td>{selectedRowData.country_code}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CountryName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Capital}</th>
                    <td>{selectedRowData.capital}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{RegionalContinent}</th>
                    <td>{selectedRowData.continent.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{ISO2}</th>
                    <td>{selectedRowData.iso2}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{PhoneCode}</th>
                    <td>{selectedRowData.phone_code}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{CurrencyCode}</th>
                    <td>{selectedRowData.currency_code}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Nationality}</th>
                    <td>{selectedRowData.nationality}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Slogan}</th>
                    <td>{selectedRowData.slogan}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                  </tbody>
                </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default RegionalCountryList;