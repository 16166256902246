import { Address, Status, LastName, FirstName, MiddleName, EmailAddress, ElectronicAddress, PhoneNumber, PhoneNumberAlt, Website, BasicInfo, Nationality, PhysicalAddress,
  StreetName, StreetNo, TownCity, NearestLandmark, GeoTag, MaidenName, DOB, NIN, DueDay, DueHour, DueMinute, CustomerCanChange, AutoAssignToTeamMember, LegalName, ShortName, ForeignAffliate, Registered, RegistrationNo, RegistrationDate, CorporateInfo, 
  Branch,
  BranchInfo} from '../../../Constant';

  export const contact_form_fields = [
    {
      type: 'group-title',
      label: `${BasicInfo}`,
    },
    {
      id: 'last_name',
      name: 'last_name',
      label: `${LastName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'first_name',
      name: 'first_name',
      label: `${FirstName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'middle_name',
      name: 'middle_name',
      label: `${MiddleName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'maiden_name',
      name: 'maiden_name',
      label: `${MaidenName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'dob',
      name: 'dob',
      label: `${DOB}`,
      type: 'date',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'nin',
      name: 'nin',
      label: `${NIN}`,
      type: 'number',
      value: '',
      required: false,
      colSize: 6,
    },
    /* {
      id: 'address',
      name: 'address',
      label: `${Address}`,
      type: 'textarea',
      value: '',
      required: true,
    }, */
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
  ];

  export const contact_branch_form_fields = [
    {
      type: 'group-title',
      label: `${BasicInfo}`,
    },
    {
      id: 'last_name',
      name: 'last_name',
      label: `${LastName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'first_name',
      name: 'first_name',
      label: `${FirstName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'middle_name',
      name: 'middle_name',
      label: `${MiddleName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'maiden_name',
      name: 'maiden_name',
      label: `${MaidenName}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'dob',
      name: 'dob',
      label: `${DOB}`,
      type: 'date',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'nin',
      name: 'nin',
      label: `${NIN}`,
      type: 'number',
      value: '',
      required: false,
      colSize: 6,
    },
    /* {
      id: 'address',
      name: 'address',
      label: `${Address}`,
      type: 'textarea',
      value: '',
      required: true,
    }, */
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
  ];

  export const corporate_form_fields = [
    {
      type: 'group-title',
      label: `${CorporateInfo}`,
    },
    {
      id: 'company_name',
      name: 'company_name',
      label: `${LegalName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
        id: 'company_short_name',
        name: 'company_short_name',
        label: `${ShortName}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
      id: 'foreign_affliation',
      name: 'foreign_affliation',
      label: `${ForeignAffliate}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: 6,
    },
    {
        id: 'is_registered',
        name: 'is_registered',
        label: `${Registered}`,
        type: 'radio',
        value: '',
        required: true,
        colSize: 6,
        options: [
            {'label': 'Yes', 'value': 1},
            {'label': 'No', 'value': 0},
        ],
    },
    {
        id: 'registration_number',
        name: 'registration_number',
        label: `${RegistrationNo}`,
        type: 'text',
        value: '',
        required: false,
        colSize: 6,
    },
    {
        id: 'registration_date',
        name: 'registration_date',
        label: `${RegistrationDate}`,
        type: 'date',
        value: '',
        required: false,
        colSize: 6,
    },
  ];

  export const branch_form_fields = [
    {
      type: 'group-title',
      label: `${BranchInfo}`,
    },
    {
      id: 'branch_name',
      name: 'branch_name',
      label: `${Branch}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
  ]
  export const nationality_address_form_fields = [
    {
      type: 'group-title',
      label: `${Nationality}`,
    },
  ];

  export const electronic_address_form_fields = [
    {
      type: 'group-title',
      label: `${ElectronicAddress}`,
    },
    {
      id: 'email',
      name: 'email',
      label: `${EmailAddress}`,
      type: 'email',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'phone_number',
      name: 'phone_number',
      label: `${PhoneNumber}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'alt_phone_number',
      name: 'alt_phone_number',
      label: `${PhoneNumberAlt}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'website',
      name: 'website',
      label: `${Website}`,
      type: 'url',
      value: '',
      required: false,
      colSize: 6,
    },
  ];

  export const corporate_electronic_address_form_fields = [
    {
      type: 'group-title',
      label: `${ElectronicAddress}`,
    },
    {
      id: 'company_email_address',
      name: 'company_email_address',
      label: `${EmailAddress}`,
      type: 'email',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'company_phone_number',
      name: 'company_phone_number',
      label: `${PhoneNumber}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'company_alt_phone_number',
      name: 'company_alt_phone_number',
      label: `${PhoneNumberAlt}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
    {
      id: 'company_website',
      name: 'company_website',
      label: `${Website}`,
      type: 'url',
      value: '',
      required: false,
      colSize: 6,
    },
  ];

  export const physical_address_form_fields = [
    {
      type: 'group-title',
      label: `${PhysicalAddress}`,
    },
    {
      id: 'street_number',
      name: 'street_number',
      label: `${StreetNo}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
        id: 'street_name',
        name: 'street_name',
        label: `${StreetName}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
        id: 'nearest_landmark',
        name: 'nearest_landmark',
        label: `${NearestLandmark}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
        id: 'city',
        name: 'city',
        label: `${TownCity}`,
        type: 'text',
        value: '',
        required: true,
        colSize: 6,
    },
    {
      id: 'geo_tag',
      name: 'geo_tag',
      label: `${GeoTag}`,
      type: 'text',
      value: '',
      required: false,
      colSize: 6,
    },
  ];

  export const due_day_hr_min_form_fields = [
    {
      id: 'due_day',
      name: 'due_day',
      label: `${DueDay}`,
      type: 'number',
      value: '',
      required: false,
      colSize: 12,
    },
    {
      id: 'due_hour',
      name: 'due_hour',
      label: `${DueHour}`,
      type: 'number',
      value: '',
      required: false,
      colSize: 12,
    },
    {
      id: 'due_minute',
      name: 'due_minute',
      label: `${DueMinute}`,
      type: 'number',
      value: '',
      required: false,
      colSize: 12,
    },
  ]

  export const can_customer_field = (LabelName, IType) => ({
    id: 'can_customer_change',
    name: 'can_customer_change',
    label: LabelName ? `${LabelName}` : `${CustomerCanChange}`,
    type: IType ? `${IType}` : 'switch',
    value: '1',
    required: false,
    colSize: 12,
  });

  export const member_auto_assign_field = (LabelName, IType, colSize) => ({
    id: 'auto_assign',
    name: 'auto_assign',
    label: LabelName ? `${LabelName}` : `${AutoAssignToTeamMember}`,
    type: IType ? `${IType}` : 'switch',
    value: '1',
    required: false,
    colSize: colSize ? `${colSize}` : 12,
  });