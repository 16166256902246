import { useContext, useLayoutEffect, useMemo, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  CategoryName,
  CreatedBy,
  Description,
  IncomeCategory,
  ModifiedBy,
  ShortName,
  Status,
} from "../../../../../Constant";
import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";
import { IIncomeCategoryResponse } from "../IncomeCategory.types";
import { StatusRow } from "../../ReportType/List/ReportTypeList";

import { financesIncomeCategoryApi } from "../../../../../api";
import { toast } from "react-toastify";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useIncomeCategoryListController() {
  const axiosPrivate = useAxiosPrivate();
  const title = IncomeCategory;
  const { userOrganisationId }: any = useAuth();
  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: CategoryName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "short_name",
      name: "short_name",
      label: ShortName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);

  const tableColumns = [
    {
      name: CategoryName,
      selector: (row: (typeof data)[0]) => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: ShortName,
      selector: (row: (typeof data)[0]) => `${row.short_name}`,
      sortable: true,
      center: false,
    },
    {
      name: Description,
      selector: (row: (typeof data)[0]) => `${row.description}`,
      sortable: false,
      center: false,
    },
    {
      name: Status,
      selector: (row: (typeof data)[0]) => `${row.is_active}`,
      sortable: true,
      center: true,
      cell: (row: (typeof data)[0]) => StatusRow(row),
    },
    {
      name: CreatedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.created_at)}`,
      sortable: false,
      center: false,
    },

    {
      name: ModifiedBy,
      selector: (row: (typeof data)[0]) =>
        `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: false,
      center: false,
    },
  ];

  useLayoutEffect(() => {
    // //?? Updates all fields once component is mounted
    (async function () {
      const updatedFields = [...formFields];
      setFormFields(updatedFields);
    })();
  }, []);

  function fetchData() {
    setLoading(true);
    axiosPrivate
      .get(
        `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`
      )
      .then((res) => {
        const response: IIncomeCategoryResponse[] = res.data;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useLayoutEffect(() => {
    fetchData()
  }, []);

  const handleEdit = (row: (typeof data)[0] | any) => {
    setViewModalOpen(true);
    setEditMode(true);
    setSelectedRowData(row);
  };

  const { layoutURL } = useContext(CustomizerContext);
  const newBtn = `${process.env.PUBLIC_URL}/finance/settings/income-category/create/${layoutURL}`;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IIncomeCategoryResponse[]>([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<
    (typeof data)[0] | null
  >(null);
  const [editMode, setEditMode] = useState(false);

  const handleView = (row: (typeof data)[0]) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEditRec = async (
    id: number | string,
    editRecord: (typeof data)[0]
  ) => {
    //console.log(id, { ...editRecord, organisation_id: id }, "");
    await axiosPrivate
      .put(`${financesIncomeCategoryApi}/${id}`, {
        ...editRecord,
        organisation_id: userOrganisationId,
      })
      .then((_res) => {
        const updatedData = data.map((row) =>
          row.id === selectedRowData!.id ? { ...row, ..._res.data } : row
        );
        setData(updatedData);
        toast.success(`${_res.data.name} Editted Successfully.`)
        //fetchData();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Something Went Wrong");
      });
  };

  const memoizedHandleEdit = useMemo(
    () => handleEditRec,
    [data, selectedRowData]
  );

  return {
    data,
    tableColumns,
    viewModalOpen,
    selectedRowData,
    editMode,
    newBtn,
    title,
    formFields,
    loading,
    setViewModalOpen,
    handleView,
    handleEdit,
    memoizedHandleEdit,
  };
}
