import "./TaskAssignerSearchForm.scss";
import React from "react";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

interface Props {
    form: FormField[];
    setForm: React.Dispatch<React.SetStateAction<FormField[]>>;
    onSubmit: (e: FormField[]) => void;
}

export default function TaskAssignerSearchForm({
    form,
    setForm,
    onSubmit,
}: Props) {
    const { handleChange, handleSearch } = useTaskAssignerSearchForm(
        form,
        setForm,
        onSubmit
    );

    return (
        <div className="TaskAssignerSearchForm">
            <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                    {form.map((field) => (
                        <Col xs="6">
                            <FormInputComponent
                                field={field}
                                handleChange={handleChange}
                            />
                        </Col>
                    ))}
                </Row>
                <Button onClick={handleSearch} color="primary">
                    Search List
                </Button>
            </Form>
        </div>
    );
}

const useTaskAssignerSearchForm = (
    form: FormField[],
    setForm: React.Dispatch<React.SetStateAction<FormField[]>>,
    onSubmit: (e: FormField[]) => void
) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (e.target.type === "checkbox") {
            const newForm = form.map((field) => {
                if (field.onChange) {
                    field.onChange(e.target);
                }
                if (field.name === name) {
                    return {
                        ...field,
                        checked: e.target.checked,
                        value: e.target.checked ? "1" : "0",
                    };
                }
                return field;
            });
            setForm(newForm);
            return;
        }

        const newForm = form.map((field) => {
            if (field.onChange) {
                field.onChange(e.target);
            }
            if (field.name === name) {
                return { ...field, value };
            }
            return field;
        });
        setForm(newForm);
    };

    const handleSearch = () => {
        //console.log(form);
        onSubmit(form);
    };

    return { form, handleChange, handleSearch };
};

export type FormField = {
    name: string;
    placeholder?: string;
    label: string;
    value: string;
    checked?: boolean;
    type:
        | "text"
        | "email"
        | "password"
        | "number"
        | "textarea"
        | "select"
        | "switch"; // Union type for allowed input types
    required?: boolean; // Optional boolean indicating if the field is required
    disabled?: boolean; // Optional boolean indicating if the field is disabled
    options?: { label: string; value: string }[]; // Optional array of options for select type
    onChange?: (val: EventTarget) => void;
};

// Component for rendering a single form input field
const FormInputComponent = ({
    field,
    handleChange,
}: {
    field: FormField;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    return (
        <>
            <FormGroup
                switch={field.type === ("switch" as string)}
                className="row mb-3 grid-col-1_2"
            >
                <Label className="col" for={field.name}>
                    {field.label}
                </Label>
                <Input
                    type={field.type as any}
                    name={field.name}
                    checked={
                        field.value.length > 1 ||
                        field.value === "true" ||
                        field.value === "1"
                            ? true
                            : false
                    }
                    id={field.name}
                    value={field.value}
                    className="col-lg w-100"
                    onChange={handleChange}
                    placeholder={field.placeholder}
                >
                    {field.options?.length &&
                        field.type === "select" &&
                        field.options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                </Input>
            </FormGroup>
        </>
    );
};
