import { Fragment, useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { UserType, AccessCategory } from '../../../../Constant';
import { Breadcrumbs } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CategoryApi, UserTypeApi } from '../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm';
import useAuth from '../../../../Hooks/useAuth';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';

const CategoryNew = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/access-control/categories/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(base_form_fields(`${AccessCategory}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const userTypeDropDown = createDropdownField(axiosPrivate,
    'user_type_id',
    'user_type_id',
    `${UserType}`,
    `${UserTypeApi}`,
    '', 12, '', 'table_title'
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [userTypeDropDown, ...formFields];
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, userTypeDropDown]); 

  const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
          errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            user_type_id: newRecord.user_type_id,
            name: newRecord.name,
            description: newRecord.description,
            is_active: newRecord.is_active,
        };

        try {
          await axiosPrivate.post(CategoryApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
        } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
          throw error;
        } 
    };

  return (
    <Fragment>
      <Breadcrumbs parent="Access Control" title="New Category" mainTitle="New Category" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryNew;