import { Fragment, useContext, useState, useLayoutEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InteractionApi, CasePriorityUri, CaseResolutionResolvedUri, CaseResolutionStatusUri } from '../../../../../api';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import InteractionMessage from './Tab/InteractionMessage';
import useAuth from '../../../../../Hooks/useAuth';
import InteractionInfo from './InteractionInfo';
//import Spinner from '../../../../Common/Component/Spinner';
//import InteractionHistory from './InteractionHistory';

const InteractionDetail = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId, auth0_profile } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/${layoutURL}`;

  let {track_number} = useParams();

  const { formState: { errors },} = useForm(); 

  const [allData, setAllData] = useState([]);
  const [prioritiesData, setPrioritiesData] = useState([]);
  const [resolutionStatusesData, setResolutionStatusesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [interactionTab, setInteractionTab] = useState('interaction-detail');
  
  const getInteractionDetails = async () => {
    try {
        await axiosPrivate.get(`${InteractionApi}/${track_number}/by-track-number`).then((resp) => {
            setAllData(resp.data);
        });
    } catch (error) {
        if (error.response && error.response.status === 401) {
          //toast.error(error.response.data.detail);
        } else{
          toast.error(error.response.data.detail)
        }
        //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  const getPriorityResolutionStatus = async () => {
    try {
        await axiosPrivate.get(`${InteractionApi}/priorities-resolution-statuses?organisation_id=${userOrganisationId}`).then((resp) => {
            setPrioritiesData(resp.data.priorities);
            setResolutionStatusesData(resp.data.resolution_statuses);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    const abortController = new AbortController();
    
    getInteractionDetails();
    getPriorityResolutionStatus()

    return () => {
      abortController.abort();
    };
  }, [setAllData]);
  
  //const addBtn = ""; //`${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;
  
  const handleInteractionUpdate = async (methodName, payload) => {
    setIsLoading(true);

    try {
      await axiosPrivate.put(`${InteractionApi}/${allData.id}/${methodName}`, payload).then((resp) => {
        //history(listUrl)
        if (methodName === CaseResolutionStatusUri) {
          setAllData(prevData => ({
            ...prevData,
            resolution_status: resp.data.resolution_status,
            resolution_id: resp.data.resolution_id
          }));

          toast.success(`Interaction ${allData.track_number} resolution status is successful.`)
        }
        
        if (methodName === CasePriorityUri) {
          setAllData(prevData => ({
            ...prevData,
            priority: resp.data.priority,
            priority_id: resp.data.priority_id
          }));

          toast.success(`Interaction ${allData.track_number} priority is successful.`)
        }

      });
    } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Interaction update error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleInteractionNoteSubmit = async (data) => {
    setIsLoading(true);

    try {
      const newInteractionNoteTemp = {
        interaction_id: allData.id,
        message: data.message,
      };

      await axiosPrivate.post(`${InteractionApi}/${allData.id}/note`, newInteractionNoteTemp).then((resp) => {
        //history(listUrl)
        setAllData(prevData => ({
          ...prevData,
          interaction_notes: [resp.data, ...prevData.interaction_notes]
        }));

        toast.success(`Interaction Note added to ${allData.track_number}.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Interaction Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCaseResolutionSubmit = async(data) => {
    
    setIsLoading(true);

    try {
      const newRecTemp = {
        interaction_id: allData.id,
        employee_id: auth0_profile.contact.id,// remember to chnage to employee //CaseRepliedData.employee_id,
        comment: data.message,
        resolution_code_id: data.resolution_code_id,
      };

      await axiosPrivate.put(`${InteractionApi}/${allData.id}/${CaseResolutionResolvedUri}`, newRecTemp).then((resp) => {
        history(listUrl)

        toast.success(`Interaction ${allData.track_number} resolution has been updated.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Interaction Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Interaction Details" mainTitle="Interaction Details" />
      <Container fluid={true}>
        <Row className='learning-block user-profile social-app-profile'>
          {/* { isLoading ? <Spinner /> :
          (

          <> */}
          <Col xl='8' className='xl-60 box-col-8 order-2 order-xl-1'>
            <Row> 
              <Nav className='border-tab nav-secondary nav-pills' tabs>
                <NavItem>
                  <NavLink href='#' className={interactionTab === 'interaction-detail' ? 'active' : ''} onClick={() => setInteractionTab('interaction-detail')}>
                    <i className='icofont icofont-book-alt'></i>
                    Details
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={interactionTab}>
                {allData && (
                  <>
                    <InteractionMessage Details={allData} onInteractionNoteSubmit={handleInteractionNoteSubmit} />
                  </>
                )}
              </TabContent>
            </Row>
          </Col>

          <Col xl='4' className='xl-40 box-col-12 learning-filter order-1 order-xl-2'>
            <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>ContactSearch</Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc1'>
                  <Row>
                    <InteractionInfo details={allData} 
                      priorities={prioritiesData} 
                      resolutionStatuses={resolutionStatusesData}
                      onInteractionUpdate={handleInteractionUpdate} />
                    {/* <InteractionHistory histories={undefined} /> */}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          {/* </>
          )

          } */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default InteractionDetail;