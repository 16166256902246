import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import useGroupGLNumberController from "./controller";

const GroupGLNumberReport = () => {
  const {
    exportToCSV,
    generatePrint,
    getTableFunction,
    setGroupGLTypeValue,
    setGroupGLClassValue,
    printedData,
    GroupGLClassDropdown,
    GroupGLTypeDropdown,
  } = useGroupGLNumberController();
  return (
    <div>
      <Breadcrumbs
        parent={"Report"}
        title={"Group General Ledger Number Report"}
        mainTitle={"Group General Ledger Number Report"}
      />
      <br />

      <div className="row gap-y-3">
        <div className="col-md-4 mb-3">
          <p className="mb-0">Group General Ledger Report</p>
          <Input
            onChange={(e) => setGroupGLClassValue(e.target.value)}
            type="select"
          >
            <option hidden>Default Select</option>
            {GroupGLClassDropdown.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
        </div>

        <div className="col-md-4  mb-3">
          <p className="mb-0">Group General Ledger Type </p>
          <Input
            onChange={(e) => setGroupGLTypeValue(e.target.value)}
            type="select"
          >
            <option hidden>Default Select</option>
            {GroupGLTypeDropdown.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
        </div>

        <div className="col-md-4  mb-3 d-flex align-align-items-center gap-2">
          <Button color="success" onClick={getTableFunction} className="mt-4">
            Generate Table
          </Button>
        </div>
      </div>

      <br />
      <br />

      {printedData.length !== 0 && (
        <>
          <div className="row">
            <Form className="col">
              <FormGroup>
                <Label for="search">Search</Label>
                <Input id="search" />
              </FormGroup>
            </Form>
            <div className="col d-flex gap-2 justify-content-end align-items-center">
              <Button color="info" onClick={() => generatePrint(printedData)}>
                Generate Print
              </Button>

              <Button color="info" onClick={() => exportToCSV(printedData)}>
                Generate CSV
              </Button>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th>Created at</th>
                <th>Updated at</th>
                <th>Balance Type</th>
                <th>Report Type</th>
              </tr>
            </thead>
            <tbody>
              {printedData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.is_active ? "Active" : "Not Active"}</td>
                  <td>{item.created_at}</td>
                  <td>{item.updated_at}</td>
                  <td>
                    {item.group_gl_type.group_gl_class.gl_group.balance_type}
                  </td>
                  <td>
                    {
                      item.group_gl_type.group_gl_class.gl_group.report_type
                        .name
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default GroupGLNumberReport;
