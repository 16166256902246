import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
  IncomeType,
  Description,
  TypeName,
  IncomeGroup,
  IncomeCategory,
  IncomeClass,
  Status,
  Name,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
  financesIncomeCategoryApi,
  financesIncomeClassApi,
  financesIncomeGroupApi,
  financesIncomeTypeApi,
} from "../../../../../api";
import useAuth from "../../../../../Hooks/useAuth";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export default function useIncomeTypeController() {
  const axiosPrivate = useAxiosPrivate();
  const formTitle = IncomeType;
  const { userOrganisationId }: any = useAuth();

  const [formFields, setFormFields] = useState<any[]>([
    {
      id: "name",
      name: "name",
      label: TypeName,
      type: "text",
      value: "",
      required: true,
      colSize: 6,
    },

    {
      id: "description",
      name: "description",
      label: Description,
      type: "textarea",
      value: "",
      required: true,
      colSize: 6,
    },
    {
      id: "is_active",
      name: "is_active",
      label: Status,
      type: "switch",
      value: "1",
      colSize: 6,
    },
  ]);
  //   `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
  const incomeCategory = createDropdownField(
    axiosPrivate,
    "income_category",
    "income_category",
    IncomeCategory,
    `${financesIncomeCategoryApi}?skip=${0}&limit=${10}&with_trashed=${false}&organisation_id=${userOrganisationId}`,
    "",
    6
  );
  incomeCategory.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_group"
    );
    if (nextField) {
      const endPoint = `${financesIncomeGroupApi}?organisation_id=${userOrganisationId}&income_category_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_category") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeGroup = createDropdownField(
    axiosPrivate,
    "income_group",
    "income_group",
    IncomeGroup,
    ``,
    "",
    6
  );
  incomeGroup.onChange = async (id, updatedFields) => {
    const nextField = updatedFields.find(
      (field: any) => field.id === "income_class"
    );
    if (nextField) {
      const endPoint = `${financesIncomeClassApi}?organisation_id=${userOrganisationId}&income_group_id=${id}`
      await nextField.fetchOptions(id, false, endPoint);
      const updatedFormFields = updatedFields.map((field: any) => {
        if (field.id === "income_group") {
          return { ...field, value: id };
        }
        return field;
      });
      setFormFields([...updatedFormFields]);
    }
  };

  const incomeClass = createDropdownField(
    axiosPrivate,
    "income_class",
    "income_class",
    IncomeClass,
    ``,
    "",
    6
  );

  // ?? Call on every dependent change
  async function updateFields() {
    const updatedFields = [
      incomeCategory,
      incomeGroup,
      incomeClass,
      ...formFields,
    ];
    await incomeCategory.fetchOptions();
    setFormFields(updatedFields);
  }

  useLayoutEffect(() => {
    //?? Updates all fields once component is mounted
    updateFields();
  }, []);

  const { layoutURL } = useContext(CustomizerContext);
  const backBtn = `${process.env.PUBLIC_URL}/finance/settings/income-type/${layoutURL}`;

  const onSubmit = async (data: any) => {
    let income_class_id = Number(data.income_class);
    data.income_class_id = income_class_id;
    await axiosPrivate
      .post(financesIncomeTypeApi, data)
      .then((_res) => {
        toast.success(`${_res.data.name} Created Successfully.`)
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          toast.error('Validation Errors');
        } else if (err.response && err.response.status === 400) {
          toast.error(err.response.data.detail);
        } else {
          toast.error('Something Went Wrong');
        }
        throw err;
        //toast.error(err.response.message || "Something Went Wrong");
      });
  }

  return { formTitle, formFields, backBtn, onSubmit };
}
