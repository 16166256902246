import { Fragment, useContext, useState, useLayoutEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CaseApi, CaseAssignedToUri, CaseDelegateToTeamMemberUri, CaseDelegateToTeamUri, CaseEscalateUri, CasePriorityUri, CaseResolutionResolvedUri, CaseResolutionStatusUri, TeamApi, TeamMemberApi } from '../../../../../api';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import CaseHistory from './CaseHistory';
import CaseMessage from './Tab/CaseMessage';
import useAuth from '../../../../../Hooks/useAuth';
import CaseInfo from './CaseInfo';
import CaseMoreInfo from './CaseMoreInfo';
import CaseNote from './Tab/CaseNote';
import CaseResolution from './Tab/CaseResolution';
import Spinner from '../../../../Common/Component/Spinner';
import CaseEscalation from './Tab/CaseEscalation';

const CaseDetail = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId, auth0_profile } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/crm/case-management/case/${layoutURL}`;

  let {track_number} = useParams();

  const { formState: { errors },} = useForm(); 

  const [allData, setAllData] = useState([]);
  const [prioritiesData, setPrioritiesData] = useState([]);
  const [resolutionStatusesData, setResolutionStatusesData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [delegateTeamMembersData, setDelegateTeamMembersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [caseTab, setCaseTab] = useState('case-detail');
  
  const cData = {
    "id": 5,
    "case_type_id": 2,
    "contact_id": 6,
    "link_id": null,
    "priority_id": 3,
    "resolution_status_id": 2,
    "track_number": "CSX-00000002",
    "subject": "string",
    "message": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "message_html": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "opened_by": 5,
    "time_worked": null,
    "archive": false,
    "locked": false,
    "attachments": null,
    "due_date": null,
    "overdue_email_sent": false,
    "satisfaction_email_sent": false,
    "satisfaction_email_dt": null,
    "contact": {
      "id": 6,
      "organisation": {
        "id": 1,
        "full_name": "Agro Chemical Industriy",
        "shortname": "Agro",
        "is_primary": null
      },
      "contact_number": "CT0006",
      "salutation_id": null,
      "last_name": "New data",
      "first_name": "Name",
      "middle_name": "o",
      "phone_number": "08000000001",
      "company_name": null,
      "company_short_name": null
    },
    "case_type": {
      "id": 2,
      "name": "Request Needs",
      "description": "string",
      "is_active": true,
      "created_at": "2024-03-26T06:52:00",
      "updated_at": "2024-03-26T06:52:00",
      "case_group_id": 1,
      "priority_id": 1,
      "sla_id": 1,
      "service_team_id": 1,
      "escalation_team_id": 2,
      "auto_assign": false,
      "case_group": {
        "id": 1,
        "organisation_id": 1,
        "name": "Request",
        "description": "Request",
        "is_active": true,
        "created_at": "2024-03-25T20:17:24",
        "updated_at": "2024-03-25T20:18:43",
        "service_type_id": 1,
        "service_type": {
          "id": 1,
          "name": "External",
          "description": "External",
          "is_active": false,
          "created_at": "2024-03-25T15:33:00",
          "updated_at": "2024-03-25T15:33:57"
        }
      },
      "priority": {
        "id": 1,
        "organisation_id": 1,
        "name": "Critical",
        "description": "Critical",
        "is_active": false,
        "created_at": "2024-03-25T18:14:20",
        "updated_at": "2024-03-26T21:29:31",
        "color": "#d11f1f",
        "is_default": false,
        "sort_order": 0
      },
      "sla": {
        "id": 1,
        "organisation_id": 1,
        "name": "Survey",
        "description": "Survey",
        "is_active": true,
        "created_at": "2024-03-25T20:08:18",
        "updated_at": "2024-03-26T22:47:02",
        "due_day": 0,
        "due_hour": 3,
        "due_minute": 10
      },
      "service_team": {
        "id": 1,
        "name": "Sales Force",
        "description": "string",
        "is_active": true,
        "created_at": "2024-03-25T21:34:53",
        "updated_at": "2024-03-25T21:34:53",
        "team_type_id": 1,
        "unit_id": 1,
        "location_id": null,
        "team_lead_id": null,
        "team_type": {
          "id": 1,
          "organisation_id": 1,
          "name": "Service Team",
          "description": "Service Team",
          "is_active": true,
          "created_at": "2024-03-25T21:15:11",
          "updated_at": "2024-03-31T00:15:42"
        },
        "unit": {
          "id": 1,
          "name": "Biz Intel",
          "description": "Biz Inteliegence",
          "is_active": false,
          "created_at": "2024-03-01T09:36:38",
          "updated_at": "2024-03-01T11:06:13",
          "department_id": 1,
          "short_name": "Biz Intel",
          "department": {
            "id": 1,
            "name": "Legal Ser",
            "description": "Legal",
            "is_active": false,
            "created_at": "2024-03-01T09:00:25",
            "updated_at": "2024-03-01T09:22:50",
            "division_id": 3,
            "short_name": "Legal",
            "division": {
              "id": 3,
              "name": "qdsdsd",
              "description": "qdsd",
              "is_active": true,
              "created_at": "2024-03-01T08:21:47",
              "updated_at": "2024-03-01T08:42:23",
              "organisation_id": 1,
              "short_name": "qdsd",
              "organisation": {
                "id": 1,
                "full_name": "Agro Chemical Industriy",
                "shortname": "Agro",
                "is_primary": null,
                "foreign_affliation": null,
                "apply_shared_service_fee": true,
                "fee_amount": 300004,
                "is_registered": true,
                "registration_number": "2323232",
                "registration_date": "2023-03-23T00:00:00",
                "description": "Agro Chemical Industry",
                "sub_sector_id": 2,
                "sub_sector": {
                  "id": 2,
                  "name": "Crop Production",
                  "description": "\nIf the column is a string, the migration script would look similar, with the modification in the data type of the new column. Here's an example for a string column:",
                  "is_active": true,
                  "created_at": "2024-02-24T19:14:27",
                  "updated_at": "2024-02-24T19:14:55",
                  "code": "CP",
                  "sector_id": 1,
                  "sector": {
                    "id": 1,
                    "name": "Agriculture",
                    "description": "Force quoting of this column’s name on or off, corresponding to True or False. When left at its default of None, the column identifier will be quoted according to whether the name is case sensitive (identifiers with at least one upper case character are treated as case sensitive), or if it’s a reserved word. This flag is only needed to force quoting of a reserved word which is not known by the SQLAlchemy dialect.",
                    "is_active": true,
                    "created_at": "2024-02-01T09:41:20",
                    "updated_at": "2024-02-24T18:49:45",
                    "code": "AG"
                  }
                },
                "email_address": "agro@test.cy",
                "phone_number": "234800000000",
                "alt_phone_number": null,
                "website": "https://www.test.ng",
                "logo": "uploads/20240229214821_2a3e6e.png",
                "street_number": "undefined",
                "street_name": "undefined",
                "nearest_landmark": "undefined",
                "geo_tag": null,
                "city": "undefined",
                "ward_id": 1,
                "employee_range_id": null,
                "is_active": true,
                "created_at": "2024-02-29T22:48:21",
                "updated_at": "2024-03-05T07:11:55"
              }
            }
          }
        },
        "location": null
      },
      "escalation_team": {
        "id": 2,
        "name": "Fire for Fire Team",
        "description": "Fire for Fire Team",
        "is_active": true,
        "created_at": "2024-03-25T21:38:22",
        "updated_at": "2024-03-27T11:31:14",
        "team_type_id": 2,
        "unit_id": 1,
        "location_id": 1,
        "team_lead_id": 5,
        "team_type": {
          "id": 2,
          "organisation_id": 1,
          "name": "Escalation Team",
          "description": "Escalation Team",
          "is_active": true,
          "created_at": "2024-03-25T21:15:24",
          "updated_at": "2024-03-27T11:33:48"
        },
        "unit": {
          "id": 1,
          "name": "Biz Intel",
          "description": "Biz Inteliegence",
          "is_active": false,
          "created_at": "2024-03-01T09:36:38",
          "updated_at": "2024-03-01T11:06:13",
          "department_id": 1,
          "short_name": "Biz Intel",
          "department": {
            "id": 1,
            "name": "Legal Ser",
            "description": "Legal",
            "is_active": false,
            "created_at": "2024-03-01T09:00:25",
            "updated_at": "2024-03-01T09:22:50",
            "division_id": 3,
            "short_name": "Legal",
            "division": {
              "id": 3,
              "name": "qdsdsd",
              "description": "qdsd",
              "is_active": true,
              "created_at": "2024-03-01T08:21:47",
              "updated_at": "2024-03-01T08:42:23",
              "organisation_id": 1,
              "short_name": "qdsd",
              "organisation": {
                "id": 1,
                "full_name": "Agro Chemical Industriy",
                "shortname": "Agro",
                "is_primary": null,
                "foreign_affliation": null,
                "apply_shared_service_fee": true,
                "fee_amount": 300004,
                "is_registered": true,
                "registration_number": "2323232",
                "registration_date": "2023-03-23T00:00:00",
                "description": "Agro Chemical Industry",
                "sub_sector_id": 2,
                "sub_sector": {
                  "id": 2,
                  "name": "Crop Production",
                  "description": "\nIf the column is a string, the migration script would look similar, with the modification in the data type of the new column. Here's an example for a string column:",
                  "is_active": true,
                  "created_at": "2024-02-24T19:14:27",
                  "updated_at": "2024-02-24T19:14:55",
                  "code": "CP",
                  "sector_id": 1,
                  "sector": {
                    "id": 1,
                    "name": "Agriculture",
                    "description": "Force quoting of this column’s name on or off, corresponding to True or False. When left at its default of None, the column identifier will be quoted according to whether the name is case sensitive (identifiers with at least one upper case character are treated as case sensitive), or if it’s a reserved word. This flag is only needed to force quoting of a reserved word which is not known by the SQLAlchemy dialect.",
                    "is_active": true,
                    "created_at": "2024-02-01T09:41:20",
                    "updated_at": "2024-02-24T18:49:45",
                    "code": "AG"
                  }
                },
                "email_address": "agro@test.cy",
                "phone_number": "234800000000",
                "alt_phone_number": null,
                "website": "https://www.test.ng",
                "logo": "uploads/20240229214821_2a3e6e.png",
                "street_number": "undefined",
                "street_name": "undefined",
                "nearest_landmark": "undefined",
                "geo_tag": null,
                "city": "undefined",
                "ward_id": 1,
                "employee_range_id": null,
                "is_active": true,
                "created_at": "2024-02-29T22:48:21",
                "updated_at": "2024-03-05T07:11:55"
              }
            }
          }
        },
        "location": {
          "id": 1,
          "name": "Ikoyi Pop",
          "description": "Ikoyi Pop",
          "is_active": false,
          "created_at": "2024-03-25T21:37:45",
          "updated_at": "2024-03-27T11:04:36",
          "location_type_id": 1,
          "zone_id": 1,
          "short_name": "Ikoyi Pop",
          "email_address": "iks@text.com",
          "phone_number": "08011110000",
          "alt_phone_number": "",
          "website": "",
          "street_number": "23",
          "street_name": "bade",
          "nearest_landmark": "juade",
          "geo_tag": "",
          "city": "Iba",
          "ward_id": 2,
          "location_type": {
            "id": 1,
            "name": "PoP",
            "description": "PoP",
            "is_active": true,
            "created_at": "2024-03-05T07:10:22",
            "updated_at": "2024-03-05T07:10:22",
            "is_primary": false
          },
          "zone": {
            "id": 1,
            "name": "IkoyiL",
            "description": "Ikoyi Lagos",
            "is_active": true,
            "created_at": "2024-03-01T12:57:10",
            "updated_at": "2024-03-01T12:58:32",
            "organisation_id": 1,
            "short_name": "IkoyiL",
            "organisation": {
              "id": 1,
              "full_name": "Agro Chemical Industriy",
              "shortname": "Agro",
              "is_primary": null,
              "foreign_affliation": null,
              "apply_shared_service_fee": true,
              "fee_amount": 300004,
              "is_registered": true,
              "registration_number": "2323232",
              "registration_date": "2023-03-23T00:00:00",
              "description": "Agro Chemical Industry",
              "sub_sector_id": 2,
              "sub_sector": {
                "id": 2,
                "name": "Crop Production",
                "description": "\nIf the column is a string, the migration script would look similar, with the modification in the data type of the new column. Here's an example for a string column:",
                "is_active": true,
                "created_at": "2024-02-24T19:14:27",
                "updated_at": "2024-02-24T19:14:55",
                "code": "CP",
                "sector_id": 1,
                "sector": {
                  "id": 1,
                  "name": "Agriculture",
                  "description": "Force quoting of this column’s name on or off, corresponding to True or False. When left at its default of None, the column identifier will be quoted according to whether the name is case sensitive (identifiers with at least one upper case character are treated as case sensitive), or if it’s a reserved word. This flag is only needed to force quoting of a reserved word which is not known by the SQLAlchemy dialect.",
                  "is_active": true,
                  "created_at": "2024-02-01T09:41:20",
                  "updated_at": "2024-02-24T18:49:45",
                  "code": "AG"
                }
              },
              "email_address": "agro@test.cy",
              "phone_number": "234800000000",
              "alt_phone_number": null,
              "website": "https://www.test.ng",
              "logo": "uploads/20240229214821_2a3e6e.png",
              "street_number": "undefined",
              "street_name": "undefined",
              "nearest_landmark": "undefined",
              "geo_tag": null,
              "city": "undefined",
              "ward_id": 1,
              "employee_range_id": null,
              "is_active": true,
              "created_at": "2024-02-29T22:48:21",
              "updated_at": "2024-03-05T07:11:55"
            }
          },
          "ward": {
            "id": 2,
            "name": "Ward II",
            "description": "Ward 2",
            "is_active": false,
            "created_at": "2024-02-26T14:42:57",
            "updated_at": "2024-02-26T14:42:57",
            "regional_lga_id": 1,
            "code": "W2",
            "lga": {
              "id": 1,
              "name": "Alimosho",
              "description": "Zone",
              "is_active": true,
              "created_at": "2024-02-01T09:44:19",
              "updated_at": "2024-02-01T09:44:19",
              "regional_state_id": 1,
              "code": "A",
              "headquarter": "A",
              "state": {
                "id": 1,
                "name": "Lagos",
                "description": "Lagos",
                "is_active": true,
                "created_at": "2024-02-01T09:43:55",
                "updated_at": "2024-02-29T10:48:40",
                "regional_zone_id": 1,
                "code": "Lag",
                "capital": "Ikeja",
                "area_code": "Lag",
                "slogan": "",
                "zone": {
                  "id": 1,
                  "name": "West",
                  "description": "West",
                  "is_active": true,
                  "created_at": "2024-02-01T09:43:38",
                  "updated_at": "2024-03-15T14:23:48",
                  "regional_country_id": 2,
                  "short_name": "West",
                  "country": {
                    "id": 2,
                    "name": "Cameroon",
                    "description": "Nig",
                    "is_active": true,
                    "created_at": "2024-02-01T09:43:24",
                    "updated_at": "2024-02-01T09:43:24",
                    "regional_continent_id": 2,
                    "short_name": "Cam",
                    "capital": "Abuja",
                    "iso2": null,
                    "country_code": "CM",
                    "phone_code": "234",
                    "currency_code": "566",
                    "nationality": "Nigerian",
                    "slogan": "Giant of Afica",
                    "flag": null,
                    "continent": {
                      "id": 2,
                      "name": "Europe",
                      "description": "Europe",
                      "is_active": true,
                      "created_at": "2024-02-01T09:42:46",
                      "updated_at": "2024-02-01T09:42:46"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "link": null,
    "priority": {
      "id": 3,
      "organisation_id": 1,
      "name": "Low",
      "description": "Low",
      "is_active": true,
      "created_at": "2024-03-25T18:14:20",
      "updated_at": "2024-03-26T21:29:48",
      "color": "#0e3dc8",
      "is_default": false,
      "sort_order": 0
    },
    "resolution_status": {
      "id": 2,
      "organisation_id": 1,
      "name": "Replied",
      "description": "Replied",
      "is_active": true,
      "created_at": "2024-03-25T20:03:09",
      "updated_at": "2024-03-26T23:24:11",
      "color": "#6ae916",
      "is_default": true,
      "can_customer_change": true,
      "sort_order": 0
    },
    "created_at": "2024-03-26T11:20:52",
    "updated_at": "2024-03-26T18:18:17",
    "first_reply_by": null,
    "first_reply_at": null,
    "closed_by": null,
    "closed_at": null,
    "replies_count": 0,
    "employee_replies_count": 0,
    "assigned_to": 0,
    "assigned_by": 0,
    "assigned_at": null,
    "last_replier": false,
    "replier_id": 0,
    "merged": null,
    "history": "[]",
    "case_replies": [
      {
        "id": 5,
        "case_id": 5,
        "contact_id": 6,
        "employee_id": null,
        "message": "Ose",
        "message_html": "Ose",
        "attachments": null,
        "rating": 3,
        "read": false,
        "created_at": "2024-03-26T16:54:45",
        "updated_at": "2024-03-26T16:54:45"
      },
      {
        "id": 6,
        "case_id": 5,
        "contact_id": null,
        "employee_id": 1,
        "message": "Ose omo Banke",
        "message_html": "Ose omo Banke",
        "attachments": null,
        "rating": 0,
        "read": false,
        "created_at": "2024-03-26T16:55:43",
        "updated_at": "2024-03-26T16:55:43"
      },
      {
        "id": 7,
        "case_id": 5,
        "contact_id": null,
        "employee_id": 1,
        "message": "Ose omo Banke",
        "message_html": "Ose omo Banke",
        "attachments": null,
        "rating": 0,
        "read": false,
        "created_at": "2024-03-26T16:55:43",
        "updated_at": "2024-03-26T16:55:43"
      }
    ]
  }

  const getCaseDetails = async () => {
    try {
        await axiosPrivate.get(`${CaseApi}/${track_number}/by-track-number`).then((resp) => {
            setAllData(resp.data);
            let delegate_team_id = resp.data?.case_delegates?.find(delegate => delegate.is_active === true)?.team_id
            getTeams(resp.data?.case_type?.service_team?.team_type_id, delegate_team_id)
            getTeamMembers(resp.data?.case_type?.service_team_id)
            
            //setIsLoading(false);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
        //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  const getPriorityResolutionStatus = async () => {
    try {
        await axiosPrivate.get(`${CaseApi}/priorities-resolution-statuses?organisation_id=${userOrganisationId}`).then((resp) => {
            setPrioritiesData(resp.data.priorities);
            setResolutionStatusesData(resp.data.resolution_statuses);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getTeams = async (team_type_id, team_id) => {
    try {
      //&team_type_id=${team_type_id}
        await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
          const teamsData = resp.data.map(team => ({
            id: team.id,
            name: team.name
          }));
          
          setTeamsData(teamsData);
          getDelegateTeamMembers(team_id)
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getTeamMembers = async (team_id) => {
    try {
        await axiosPrivate.get(`${TeamMemberApi}?organisation_id=${userOrganisationId}&team_id=${team_id}`).then((resp) => {
          const teamMembersData = resp.data.map(teamMember => ({
            id: teamMember.employee_id,
            name: teamMember.contact_name
          }));
          
          setTeamMembersData(teamMembersData);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  const getDelegateTeamMembers = async (team_id) => {
    if (!team_id)
      return

    try {
        await axiosPrivate.get(`${TeamMemberApi}?organisation_id=${userOrganisationId}&team_id=${team_id}`).then((resp) => {
          const teamMembersData = resp.data.map(teamMember => ({
            id: teamMember.employee_id,
            name: teamMember.contact_name
          }));
          
          setDelegateTeamMembersData(teamMembersData);
        });
    } catch (error) {
        toast.error(error.response.data.detail)
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    const abortController = new AbortController();
    getCaseDetails();
    getPriorityResolutionStatus()

    return () => {
      abortController.abort();
    };
  }, [setAllData]);
  
  //const addBtn = ""; //`${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;
  
  const handleCaseReply = async (event, message, timeWorked) => {
    event.preventDefault();
    setIsLoading(true);
    //console.log("CaseRepliedData: ", message);
    //console.log("Time: ", timeWorked);
    try {
      const newRecordTemp = {
        //contact_id: CaseRepliedData.contact_id,
        employee_id: 1, //CaseRepliedData.employee_id,
        message: message,
        time_worked: timeWorked,
        //rating: CaseRepliedData.rating,
      };

      await axiosPrivate.post(`${CaseApi}/${allData.id}/reply`, newRecordTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case ${allData.track_number} replied.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Reply submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCaseUpdate = async (methodName, payload) => {
    setIsLoading(true);

    try {
      await axiosPrivate.put(`${CaseApi}/${allData.id}/${methodName}`, payload).then((resp) => {
        //history(listUrl)
        if (methodName === CaseResolutionStatusUri) {
          setAllData(prevData => ({
            ...prevData,
            resolution_status: resp.data.resolution_status,
            resolution_id: resp.data.resolution_id
          }));

          toast.success(`Case ${allData.track_number} resolution status is successful.`)
        }
        
        if (methodName === CasePriorityUri) {
          setAllData(prevData => ({
            ...prevData,
            priority: resp.data.priority,
            priority_id: resp.data.priority_id
          }));

          toast.success(`Case ${allData.track_number} priority is successful.`)
        }

        if (methodName === CaseAssignedToUri) {
          setAllData(prevData => ({
            ...prevData,
            //assigned_by: resp.data.assigned_by,
            assigned_at: resp.data.assigned_at,
            assigned_to: resp.data.assigned_to
          }));

          toast.success(`Case ${allData.track_number} is successfully assigned to a team member.`)
        }

        if (methodName === CaseDelegateToTeamMemberUri) {
          setAllData(prevData => ({
            ...prevData,
            case_delegates: resp.data.case_delegates,
          }));

          toast.success(`Case ${allData.track_number} is successfully assigned to a team member.`)
        }

        if (methodName === CaseDelegateToTeamUri) {
          setAllData(prevData => ({
            ...prevData,
            case_delegates: resp.data.case_delegates,
          }));
          getDelegateTeamMembers(payload.team_id)

          toast.success(`Case ${allData.track_number} delegation is successful.`)
        }

        
      });
    } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case update error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCaseNoteSubmit = async (data) => {
    // event.preventDefault();
    setIsLoading(true);

    try {
      const newCaseNoteTemp = {
        case_id: allData.id,
        employee_id: auth0_profile.contact.id,// remember to chnage to employee //CaseRepliedData.employee_id,
        message: data.message,
        root_cause_id: data.root_cause_id,
        //ref_root_cause_id: data.ref_root_cause_id,
      };

      await axiosPrivate.post(`${CaseApi}/${allData.id}/note`, newCaseNoteTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case Note added to ${allData.track_number}.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCaseResolutionSubmit = async(data) => {
    
    setIsLoading(true);

    try {
      const newRecTemp = {
        case_id: allData.id,
        employee_id: auth0_profile.contact.id,// remember to chnage to employee //CaseRepliedData.employee_id,
        comment: data.message,
        resolution_code_id: data.resolution_code_id,
      };

      await axiosPrivate.put(`${CaseApi}/${allData.id}/${CaseResolutionResolvedUri}`, newRecTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case ${allData.track_number} resolution has been updated.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Note submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCaseEscalationSubmit = async(data) => {
    setIsLoading(true);
    //console.log(data)
    
    try {
      const newRecTemp = {
        case_id: allData.id,
        team_id: data.team_id,
        employee_id: data.employee_id,
        priority_id: allData.priority_id,
        resolution_status_id: allData.resolution_status_id,
        escalation_category_id: data.escalation_category_id,
        escalation_reason_id: data.escalation_reason_id,
        message: data.message,
      };

      await axiosPrivate.put(`${CaseApi}/${allData.id}/${CaseEscalateUri}`, newRecTemp).then((resp) => {
        history(listUrl)

        toast.success(`Case ${allData.track_number} has been escalated.`)
      });
    } catch (error) {
        //setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Case Escalation submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Case Details" mainTitle="Case Details" />
      <Container fluid={true}>
        <Row className='learning-block user-profile social-app-profile'>
          {/* { isLoading ? <Spinner /> :
          (

          <> */}
          <Col xl='8' className='xl-60 box-col-8 order-2 order-xl-1'>
            <Row> 
              <Nav className='border-tab nav-secondary nav-pills' tabs>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-detail' ? 'active' : ''} onClick={() => setCaseTab('case-detail')}>
                    <i className='icofont icofont-book-alt'></i>
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-note' ? 'active' : ''} onClick={() => setCaseTab('case-note')}>
                    <i className='icofont icofont-notebook'></i>
                    Note
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-resolution' ? 'active' : ''} onClick={() => setCaseTab('case-resolution')}>
                    <i className='icofont icofont-files'></i>
                    Resolution
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#' className={caseTab === 'case-escalation' ? 'active' : ''} onClick={() => setCaseTab('case-escalation')}>
                    <i className='icofont icofont-files'></i>
                    Escalation
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={caseTab}>
                {allData && (
                  <>
                    <CaseMessage Details={allData} onCaseReply={handleCaseReply} />
                    <CaseNote Details={allData} onCaseNoteSubmit={handleCaseNoteSubmit} />
                    <CaseResolution Details={allData} onCaseResolutionSubmit={handleCaseResolutionSubmit} />
                    <CaseEscalation Details={allData} onCaseEscalationSubmit={handleCaseEscalationSubmit} />
                  </>
                )}
              </TabContent>
            </Row>
          </Col>

          <Col xl='4' className='xl-40 box-col-12 learning-filter order-1 order-xl-2'>
            <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>ContactSearch</Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc1'>
                  <Row>
                    <CaseInfo details={allData} 
                      priorities={prioritiesData} 
                      resolutionStatuses={resolutionStatusesData}
                      teamMembers={teamMembersData}
                      delegateTeamMembers={delegateTeamMembersData}
                      teams={teamsData}
                      onCaseUpdate={handleCaseUpdate} />
                    <CaseMoreInfo details={allData} />
                    <CaseHistory histories={undefined} />
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          {/* </>
          )

          } */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default CaseDetail;