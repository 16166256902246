import React, { Fragment, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Label, Input, Form, Button, Card, CardBody } from 'reactstrap';
import { BackToList, InvoiceCycle  } from '../../../../Constant';
import GenerateForm from '../../../Common/Component/GenerateForm';
import { base_form_fields } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../_helper/Customizer';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { useForm } from 'react-hook-form';
import { InvoiceCycleApi } from '../../../../api'; 
import { toast } from 'react-toastify';
import useAuth from '../../../../Hooks/useAuth';


function InvoiceCycleCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(base_form_fields(`${InvoiceCycle}`));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/invoice-cycles/${layoutURL}`;

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
        if (newRecord === '') { 
            errors.showMessages();
        }

        const newRecordTemp = {
            organisation_id: userOrganisationId,
            name: newRecord.name,
            description: newRecord.name,
            is_active: newRecord.is_active,
            //file: newRecord.file[0]
        };

        try {
            await axiosPrivate.post(InvoiceCycleApi, newRecordTemp, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((resp) => {
                toast.success(`${resp.data.name} is successfully added.`)
                history(lists);
            });
        } catch (error) {
            if (error.response && error.response.status === 422) {
                toast.error('Validation Errors');
            } else if (error.response && error.response.status === 400) {
                toast.error(error.response.data.detail);
            }
            else {
                toast.error('Form submission error');
            }
            throw error;
        }  
    };
    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Invoice  Cycle" mainTitle="Invoice Cycle" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Invoice Cycle"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default InvoiceCycleCreate