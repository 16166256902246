import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import CustomizerContext from '../../../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { SalutationApi } from '../../../../../api';
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../../Common/Component/CardRibbonWithForm';
import {salutation_form_fields as formFields} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
//import Dropzone from 'react-dropzone-uploader';

const SalutationNew = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate()
  const { layoutURL } = useContext(CustomizerContext);
  const lists = `${process.env.PUBLIC_URL}/public-data/salutations/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }

      const newRecordTemp = {
          name: newRecord.name,
          description: newRecord.name,
          is_active: newRecord.is_active,
          //file: newRecord.file[0]
      };

      try {
          await axiosPrivate.post(SalutationApi, newRecordTemp, {
            headers: {
              //'Content-Type': 'multipart/form-data',
              'Content-Type': 'application/json',
            },
          }).then((resp) => {
            toast.success(`${resp.data.name} is successfully added.`)
            history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          }
          else {
            toast.error('Form submission error');
          }
          throw error;
      }  
  };

/*   const getUploadParams = ({ meta }) => {
    return {
      url: 'http://localhost:8001/public-data/upload',
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {}; */

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="New Salutation" mainTitle="New Salutation" />
      <Container fluid={true}>
        <Row>
          <Col sm='10'>
          {/* <FormGroup>
            <Label>Upload</Label>
            <Dropzone
              className='dropzone dz-clickable'
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              accept="image/*"
              inputContent='Drop A File'
              styles={{
                dropzone: { width: '100%', height: 150 },
                dropzoneActive: { borderColor: 'green' },
              }}
            />
            </FormGroup> */}
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SalutationNew;