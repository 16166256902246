import React, { Fragment, useContext, useState, useMemo, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import { Breadcrumbs, Btn, Image } from '../../../AbstractElements';
import CustomizerContext from '../../../_helper/Customizer';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContactMgmtApi, CostCentreApi, EmployeeApi, EmployeeCategoryApi, SubBusinessSegmentApi } from '../../../api';
import { toast } from 'react-toastify';
import { contact_form_fields } from '../../../Data/FormField/CrmData';
import useAxiosPrivate from '../../../Hooks/useAxiosPrivate';
import { CompanyInformation, ContactForMapping, ContactID, ContactNumber, ContactSearch, ContactType, CostCentre, DateCreated, DateModified, Email, EmployeeCategory, EmployeeNo, FullName, HasMultiCompany, MapContactEmployee, MapEmployee, PhoneNumber, Status, SubBusinessSegment } from '../../../Constant';
import FindContact from '../../Common/Component/FindContact';
import DataTableComponent from '../../Common/Component/DataTableComponent';
import HeaderCard from '../../Common/Component/HeaderCard';
import noimage from '../../../assets/images/no-image.png';
import ViewModal from '../../Common/Component/ViewModal';
import { ContactTypeVal, createDropdownField } from '../../../Data/FormField/PublicData';
import useAuth from '../../../Hooks/useAuth';

const EmployeeSearch = () => {
  const history = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const { layoutURL } = useContext(CustomizerContext);
  const listUrl = `${process.env.PUBLIC_URL}/hrm/employees/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const contact_number_field = {
    id: 'contact_number',
    name: 'contact_number',
    label: `${ContactNumber}`,
    type: 'text',
    value: '',
    required: true,
    colSize: 6,
  };

  const contact_form_fields2 = [
    {
      type: 'group-title',
      label: `${CompanyInformation}`,
    },
    {
      id: 'employee_number',
      name: 'employee_number',
      label: `${EmployeeNo}`,
      type: 'text',
      value: '',
      required: true,
      colSize: 6,
    },
    {
      id: 'has_multi_company',
      name: 'has_multi_company',
      label: `${HasMultiCompany}`,
      type: 'radio',
      value: '',
      required: true,
      colSize: 6,
      options: [
          {'label': 'Yes', 'value': 1},
          {'label': 'No', 'value': 0},
        ],
      },
    ];

  const [formFields, setFormFields] = useState(contact_form_fields);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const addBtn = ""; //`${process.env.PUBLIC_URL}/crm/contacts/create/${layoutURL}`;
  
  const employeeCategoryDropdownField = createDropdownField(axiosPrivate,
    'employee_category_id',
    'employee_category_id',
    `${EmployeeCategory}`,
    `${EmployeeCategoryApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const costCentreDropdownField = createDropdownField(axiosPrivate,
    'cost_centre_id',
    'cost_centre_id',
    `${CostCentre}`,
    `${CostCentreApi(userOrganisationId)}`,
    '', 6
  );

  const businessSubSegmentDropdownField = createDropdownField(axiosPrivate,
    'business_sub_segment_id',
    'business_sub_segment_id',
    `${SubBusinessSegment}`,
    `${SubBusinessSegmentApi(userOrganisationId)}`,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [...formFields];

          const indexOfLN = updatedFields.findIndex((updatedField) => updatedField.id === 'last_name');
          updatedFields.splice(indexOfLN, 0, contact_number_field);

          const indexOfStatus = updatedFields.findIndex((updatedField) => updatedField.id === 'is_active');
          updatedFields.splice(indexOfStatus + 1, 0, ...contact_form_fields2, employeeCategoryDropdownField, costCentreDropdownField, businessSubSegmentDropdownField);
          
          await employeeCategoryDropdownField.fetchOptions();
          await costCentreDropdownField.fetchOptions();
          await businessSubSegmentDropdownField.fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, contact_number_field]);

  const handleSubmit = async (searchValue) => {
    setIsLoading(true);
    try {
      await axiosPrivate.get(`${ContactMgmtApi}?organisation_id=${userOrganisationId}&search_value=${searchValue}&is_employee=false&contact_type=${ContactTypeVal.Individual}`).then((resp) => {
        setAllData(resp.data.items);

        toast.success(`Contact Found.`)
      });
    } catch (error) {
        setAllData([]);
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
    }
    finally {
      setIsLoading(false);
    }
  };

  const openEmployeeMapView = async (row) => {
    setViewModalOpen(true);
    setEditMode(true);
    row.is_active = row.user_login.is_active
    setSelectedRowData(row);
  }

  const handleEmployeeMapping = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      organisation_id: userOrganisationId,
      contact_id: id,
      employee_category_id: editRecord.employee_category_id,
      cost_centre_id: editRecord.cost_centre_id,
      business_sub_segment_id: editRecord.business_sub_segment_id,
      employee_number: editRecord.employee_number,
      has_multi_company: editRecord.has_multi_company
    };

    try {
      await axiosPrivate.post(`${EmployeeApi}/map-contact`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${editRecord.first_name} ${editRecord.last_name} is successfully mapped as Employee.`)
        //handleCloseModal()
        history(listUrl);
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const memoizedHandleEdit = useMemo(() => handleEmployeeMapping, [allData, selectedRowData]);

  const employeeMapAction = [
    {
      label: `${MapEmployee}`,
      action: openEmployeeMapView,
      color: 'warning',
      icon: 'icon-book',
    },
  ];

  const tableColumns = [
    {
      name: `${FullName}`,
      selector: row => row.last_name,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
                          <Media body className="align-self-center">
                              <div>{row.last_name} {row.first_name} {row.middle_name}</div>
                          </Media>
                        </Media>
                      </>
                    )
    },
    {
      name: `${ContactID}`,
      selector: row => row.contact_number,
      sortable: true,
      center: false,
    },
    {
      name: `${Email}`,
      selector: row => row.user_login.email,
      sortable: true,
      center: false,
    },
    /* {
      name: `${PhoneNumber}`,
      selector: row => `${row.phone_number}`,
      sortable: true,
      center: false,
    }, */
    {
      name: `${ContactType}`,
      selector: row => row.contact_type == 1 ? "Individual" : "Corporate",
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.user_login.is_active,
      sortable: true,
      center: false,
      cell: row => (
        <>
          {row.user_login.is_active ? (
            <span className="badge badge-light-success">Active</span>
          ) : (
            <span className="badge badge-light-danger">Inactive</span>
          )}
          {row.user_login.email_verified_at ? (
            <span className="badge badge-light-success">Verified</span>
          ) : (
            <span className="badge badge-light-danger">Not Verified</span>
          )}
        </>
      ),
    },
    /* {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    }, */
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="HRM" title="Find Contact" mainTitle="Find Contact" />
      <Container fluid={true}>
        <Row className='learning-block'>
          
          <Col xl='3' className='xl-40 box-col-12 learning-filter order-1 order-xl-1'>
            <div className='md-sidebar'>
              <Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>{ContactSearch}</Btn>
              <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
                <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc'>
                  <Row>
                    <FindContact onSubmit={handleSubmit} />
                  </Row>
                </div>
              </div>
            </div>
          </Col>

          <Col xl='9' className='xl-60 box-col-8 order-2 order-xl-2'>
            <Row>
              <Col sm="12">
                <Card>
                  <HeaderCard title={ContactForMapping} btnUrl={addBtn} />
                  <CardBody>
                    <DataTableComponent
                      tabledata={allData}
                      tableColumns={tableColumns}
                      isLoading={isLoading}
                      useTableAction={employeeMapAction}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
          modalTitle={MapContactEmployee}
        >

        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default EmployeeSearch;