import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { RegionalContinentApi, RegionalCountryApi, RegionalLgaApi, RegionalStateApi, WardApi, ZoneApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableComponent from '../../../../Common/Component/DataTableComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import {base_form_fields, code_field, createDropdownField} from '../../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import { DateCreated, DateModified, Lga, LgaCode, RegionalContinent, RegionalCountry, RegionalState, State, Status, WardCode, WardName, Zone } from '../../../../../Constant';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';

const WardList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()

  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState(base_form_fields(`${WardName}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const lgaDropdownField = createDropdownField(axiosPrivate,
    'regional_lga_id',
    'regional_lga_id',
    `${Lga}`,
    `${RegionalLgaApi}`,
    '', 12
  );

  const stateDropdownField = createDropdownField(axiosPrivate,
    'regional_state_id',
    'regional_state_id',
    `${State}`,
    `${RegionalStateApi}`,
    '', 12
  );

  const zoneDropdownField = createDropdownField(axiosPrivate,
    'regional_zone_id',
    'regional_zone_id',
    `${Zone}`,
    `${ZoneApi}`,
    '', 12
  ); 

  const countryDropdownField = createDropdownField(axiosPrivate,
    'regional_country_id',
    'regional_country_id',
    `${RegionalCountry}`,
    `${RegionalCountryApi}`,
    '', 
    12
  ); 

  const continentDropdownField = createDropdownField(axiosPrivate,
    'continent_id',
    'continent_id',
    `${RegionalContinent}`,
    `${RegionalContinentApi}`,
    '',
    12
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          
          const updatedFields = [continentDropdownField, countryDropdownField, zoneDropdownField, stateDropdownField, lgaDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'name');
          updatedFields.splice(indexOfName + 1, 0, code_field(`${WardCode}`));
  
          await updatedFields[0].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, code_field]); 

  continentDropdownField.onChange = async (selectedContinentId, updatedFields) => { 
    const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
    
    if (countryField) {
      await countryField.fetchOptions(selectedContinentId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'continent_id') {
          return { ...field, value: selectedContinentId };
        }
        if (field.id === 'regional_country_id') {
          return { ...field, value: 0 };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  countryDropdownField.onChange = async (selectedCountryId, updatedFields) => { 
    const zoneField = updatedFields.find((field) => field.id === 'regional_zone_id');
    //const selectedCountry = selectedCountryId > 0 ? selectedCountryId : 0;
    if (zoneField) { 
      await zoneField.fetchOptions(selectedCountryId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_country_id') {
          return { ...field, value: selectedCountryId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  zoneDropdownField.onChange = async (selectedZoneId, updatedFields) => { 
    const stateField = updatedFields.find((field) => field.id === 'regional_state_id');
    
    if (stateField) {
      await stateField.fetchOptions(selectedZoneId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_zone_id') {
          return { ...field, value: selectedZoneId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  stateDropdownField.onChange = async (selectedStateId, updatedFields) => { 
    const lgaField = updatedFields.find((field) => field.id === 'regional_lga_id');
    
    if (lgaField) {
      await lgaField.fetchOptions(selectedStateId);
      
      const updatedFormFields = updatedFields.map((field) => {
        if (field.id === 'regional_state_id') {
          return { ...field, value: selectedStateId };
        }
        return field;
      });

      setFormFields([...updatedFormFields]);
    } 
  };

  const getAllWardsData = async () => {
      try {
          await axiosPrivate.get(WardApi).then((resp) => {
              setAllData(resp.data);
          });
      } catch (error) {
          //('cancelled', error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllWardsData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.continent_id = row.lga.state.zone.country.regional_continent_id;
    row.regional_country_id = row.lga.state.zone.regional_country_id;
    row.regional_zone_id = row.lga.state.regional_zone_id;
    row.regional_state_id = row.lga.regional_state_id;
    continentDropdownField.onChange(row.continent_id, formFields);
    countryDropdownField.onChange(row.regional_country_id, formFields);
    zoneDropdownField.onChange(row.regional_zone_id, formFields);
    stateDropdownField.onChange(row.regional_state_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    const editRecordTemp = {
          regional_lga_id: editRecord.regional_lga_id,
          name: editRecord.name,
          code: editRecord.code,
          description: editRecord.description,
          is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${WardApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; 
    }

    try {
      const response = await axiosPrivate.delete(`${WardApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success('Record Delete successfully');
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/public-data/wards/create/${layoutURL}`;
  const tableColumns = [
    {
      name: `${WardCode}`,
      selector: row => `${row.code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${WardName}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${LgaCode}`,
      selector: row => `${row.lga.code}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent="Public Data" title="Wards" mainTitle="Wards" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableComponent 
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
             <Table>
                <tbody>
                <tr className='border-bottom-primary'>
                    <th scope='row'>{WardCode}</th>
                    <td>{selectedRowData.code}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{WardName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Lga}</th>
                    <td>{selectedRowData.lga.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{RegionalState}</th>
                    <td>{selectedRowData.lga.state.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Zone}</th>
                    <td>{selectedRowData.lga.state.zone.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{RegionalCountry}</th>
                    <td>{selectedRowData.lga.state.zone.country.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default WardList;