import { Fragment, useContext, useState, useLayoutEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs"
import CustomizerContext from '../../../../_helper/Customizer'; 
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InteractionApi, CustomerApi, CustomerLinkApi, InteractionGroupApi, InteractionLocationApi, InteractionPurposeApi, InteractionTypeApi, PriorityApi, ResolutionStatusApi, } from "../../../../api"
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm'; 
import { interaction_form_fields } from './Form';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import { createDropdownField } from '../../../../Data/FormField/PublicData';
import { Contact, InteractionGroup, InteractionLocation, InteractionPurpose, InteractionStatus, InteractionType, Priority, ResolutionStatus } from "../../../../Constant"
import useAuth from '../../../../Hooks/useAuth';

const InteractionCreate = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const lists = `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(interaction_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const interactionLocationDropdownField = createDropdownField(axiosPrivate,
    'interaction_location_id',
    'interaction_location_id',
    `${InteractionLocation}`,
    `${InteractionLocationApi}?organisation_id=${userOrganisationId}`,
    '', 6, '',''
  );

  const interactionPurposeDropdownField = createDropdownField(axiosPrivate,
    'interaction_purpose_id',
    'interaction_purpose_id',
    `${InteractionPurpose}`,
    `${InteractionPurposeApi}?organisation_id=${userOrganisationId}`,
    '', 6, '',''
  );

  const interactionGroupDropdownField = createDropdownField(axiosPrivate,
    'interaction_group_id',
    'interaction_group_id',
    `${InteractionGroup}`,
    `${InteractionGroupApi}?organisation_id=${userOrganisationId}`,
    '', 6, '',''
  );

  const interactionTypeDropdownField = createDropdownField(axiosPrivate,
    'interaction_type_id',
    'interaction_type_id',
    `${InteractionType}`,
    `${InteractionTypeApi}?organisation_id=${userOrganisationId}`,
    '', 6, '',''
  );

  const customerDropdownField = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${CustomerApi}`,
    label: `${Contact}`,
    type: "input-select",
    value: { id: "", label: "" },
    colSize: 6,
    required: true,
    disabled: false,
  };

  const Prioritydropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const ResolutionStatusdropdown = createDropdownField(axiosPrivate,
    'resolution_status_id',
    'resolution_status_id',
    `${InteractionStatus}`,
    `${ResolutionStatusApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [interactionGroupDropdownField, 
            interactionTypeDropdownField, 
            interactionLocationDropdownField,
            interactionPurposeDropdownField,
            customerDropdownField, 
            Prioritydropdown, 
            ResolutionStatusdropdown, 
            ...formFields];
          
          await updatedFields[0].fetchOptions();
          //await customerDropdownField.fetchOptions();
          await Prioritydropdown.fetchOptions();
          await interactionLocationDropdownField.fetchOptions();
          await interactionPurposeDropdownField.fetchOptions();
          await ResolutionStatusdropdown.fetchOptions();

          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
      
    };

    fetchData();

  }, [formFields, isNewFieldAdded, interactionGroupDropdownField, interactionTypeDropdownField, customerDropdownField]);

  interactionGroupDropdownField.onChange = async (selectedId, updatedFields) => { 
    const interactionTypeField = updatedFields.find((field) => field.id === 'interaction_type_id');
    
    if (interactionTypeField) {
      const endPoint = `${InteractionTypeApi}?organisation_id=${userOrganisationId}&interaction_group_id=${selectedId}`
      await interactionTypeField.fetchOptions(selectedId, false, endPoint);
    } 
    setFormFields([...updatedFields]);
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }
      
      const newRecordTemp = {
        interaction_type_id: newRecord.interaction_type_id,
        interaction_location_id: newRecord.interaction_location_id,
        interaction_purpose_id: newRecord.interaction_purpose_id,
        contact_id: newRecord.contact_id,
        priority_id: newRecord.priority_id,
        resolution_status_id: newRecord.resolution_status_id,
        subject: newRecord.subject,
        message: newRecord.message,
       };

      try {
          await axiosPrivate.post(InteractionApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.track_number} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="settings" title="Interaction" mainTitle="Interaction" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default InteractionCreate;